import React, { useEffect } from 'react'
import './tc.css'
import video1 from '../../Assets/home.mp4'

import {FiChevronRight} from 'react-icons/fi'
import { MdFlight } from "react-icons/md";
import img from '../../Assets/FearoDealz-Logo.png'

import Aos from 'aos'
import 'aos/dist/aos.css'


const Tc = () => {


    
// scroll animation 
useEffect(()=>{
    Aos.init({duration: 2000})
  }, [])

  return (
    <section className="footer">
      <div className="videoDiv">
      <video src={video1} muted autoPlay loop type="video/mp4"></video>
      </div>

      <div className="secContent container">
        <div className="contactDiv flex">
          <div data-aos="fade-up" className="text">
            <small>KEEP IN TOUCH</small>
            <h2>Terms & Conditions</h2>
          </div>
        </div>

        <div className="footerCard flex">
          <div className="footerIntro flex">
            <div className="logoDiv">
              <a href="#" className='logo flex'>
              <img src={img} alt="Wynva"/>
              </a>
              <a href="#" className='logo flex'>
              Handbook: Terms and Conditions
              </a>
              
            </div>

            <div data-aos="fade-up" className="footerParagraph">
            Welcome to Wynva, your go-to online platform for seamless flight ticket bookings. Before finalizing your booking, we kindly ask you to review our terms and conditions. Your agreement to comply with these terms is implicit when you use our website and services. We value your cooperation in creating a smooth and enjoyable experience for all our users
            </div>
          </div>



        </div>
            <br/>
            <div className="footerCard flex">
        <div className="footerLinks">
            {/* grp one */}
            <div data-aos="fade-up" data-aos-duration="3000" className="linkGroup">
              <span className="groupTitle">
              1. Booking and Reservations
              </span>

              <li className="footerList">
                <FiChevronRight className="icon"/>
                Using Wynva implies your confirmation of being at least 18 years old and possessing the legal authority to enter agreements.
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                All bookings depend on availability, with prices subject to change based on airline policies and external factors.
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Completing a booking requires accurate personal and payment information. We are not liable for inaccuracies leading to booking issues.
              </li>                                                  
            </div>
            {/* grp Two */}
            <div data-aos="fade-up" data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
              2. Payment and Pricing:
              </span>

              <li className="footerList">
                <FiChevronRight className="icon"/>
                Make payments conveniently using valid credit/debit cards, processed securely through our trusted payment gateway.
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Prices showcased on our website include taxes and fees unless stated otherwise. Any additional charges will be communicated during the booking process.
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Wynva reserves the right to adjust prices and fees at any time. However, rest assured that once a booking is confirmed and payment is processed, the price is locked in.
              </li>                                                  
            </div>
            {/* grp 3 */}
            <div data-aos="fade-up" data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
              3. Cancellations and Refunds:
              </span>

              <li className="footerList">
                <FiChevronRight className="icon"/>
                Cancellation policies differ based on the airline and fare category. It is crucial to carefully examine the specific terms and conditions of your reservation.
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Refund eligibility adheres to the policies set by the airline. Wynva is committed to assisting you in seamless refund processing, according to the specific airline guidelines.
              </li>                                                 
            </div>
            {/* grp 4 */}
            <div data-aos="fade-up" data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
              4. Travel Documentation:
              </span>

              <li className="footerList">
                <FiChevronRight className="icon"/>
                Please ensure that you have the necessary travel documents, including passports and visas, before embarking on your journey.
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                It is important to note that Wynva holds no liability for any complications arising from insufficient travel documentation.
              </li>                                                 
            </div> 
            {/* grp 5 */}
            <div data-aos="fade-up" data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
              5. Changes and Modifications:
              </span>

              <li className="footerList">
                <FiChevronRight className="icon"/>
                Changes to your reservation, including adjustments to dates or destinations, may incur airline fees based on availability.
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                Wynva reserves the authority to charge service fees for specific modifications.
              </li>                                                 
            </div>
            {/* grp 6 */}
            <div data-aos="fade-up" data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
              6. Privacy and Security:
              </span>

              <li className="footerList">
                <FiChevronRight className="icon"/>
                Ensuring your privacy is our foremost priority. Please refer to our Privacy Policy, where we outline how we collect, use, and protect your valuable data.
              </li>
              <li className="footerList">
                <FiChevronRight className="icon"/>
                We uphold payment security by implementingindustry-standard measures in our system.
              </li>                                                 
            </div>
            {/* grp 7 */}
            <div data-aos="fade-up" data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
              7. Limitation of Liability:
              </span>

              <li className="footerList">
                <FiChevronRight className="icon"/>
                Wynva is not liable for any damages, direct or indirect, arising from the use of our website or services.
              </li>                                                
            </div>            

            </div>
          </div>
          <br/>
          <div className="footerCard flex">
          <div className="footerIntro flex">

            <div data-aos="fade-up" className="footerParagraph">
            At Wynva, our priority is ensuring your booking experience is seamless and enjoyable. If you have any queries or concerns, please don’t hesitate to reach out to our dedicated customer support team. We wish you a safe journey with Wynva!
            <br/>
            <br/>
            Wynva is committed to providing you with a seamless and enjoyable booking experience. If you have any questions or concerns, please contact our customer support team. Safe travels with Wynva!
            </div>
          </div>

        </div>              

      </div>
    </section>
  )
}

export default Tc
