import React, {useState} from 'react'
import './navbar.css'
// import {MdOutlineTravelExplore} from 'react-icons/md'
import {AiFillCloseCircle} from 'react-icons/ai'
import { NavLink } from 'react-router-dom'
import { BsThreeDotsVertical } from "react-icons/bs"
import { FaHeadset } from "react-icons/fa"
import { MdFlight } from "react-icons/md";
import img from '../../Assets/FearoDealz-Logo.png'
import { IoApps } from "react-icons/io5";


const Navbar = () => {
    const [active, setActive] = useState('navBar')
    const showNav = ()=>{
        setActive('navBar activeNavbar')
    }
    const removeNavbar = ()=>{
        setActive('navBar')
    }
  return (
    <selection className='navBarSection'>
        <header className='header flex'>
        <div className="logoDiv">
            <NavLink to="/"><a href="#" className='logo' onClick={removeNavbar}>
                    {/* <h2>fare<MdFlight className='flighticon'/>dealz.</h2> */}
                    {/* <h2>WYNVA<MdFlight className='flighticon'/></h2>
                    <small className='verysmall'>powered by Addontrip LLC</small> */}
        <img src={img} alt="Wynva"/>
                </a>
            </NavLink>
            {/* <a href="tel:+1(888)-529-5062"><FaHeadset className='logo'/><span className='logo'> +1(888)-529-5062</span></a> */}
            </div>

            <div className={active}>
                <ul className="navLists flex">
                    <li className="navItem">
                    <NavLink to="/"><a href=" " className="navLink" onClick={removeNavbar}>Home</a></NavLink>
                    </li>
                    <li className="navItem">
                    <NavLink to="/flight"><a href=" " className="navLink" onClick={removeNavbar}>Flight</a></NavLink>
                    </li>
                    <li className="navItem">
                    <NavLink to="/hotel">
                    <a href=" " className="navLink" onClick={removeNavbar}>Hotel</a>
                    </NavLink>
                    </li>
                    <li className="navItem">
                    <NavLink to="/package">
                    <a href=" " className="navLink" onClick={removeNavbar}>Package</a>
                    </NavLink>
                    </li>
                    <li className="navItem">
                    <NavLink to="/about">
                    <a href=" " className="navLink"onClick={removeNavbar} >About</a>
                    </NavLink>
                    </li>
                    <button className='btn'>
                        <a href="tel:+1(888)-529-5062">Contact Us</a>
                    </button>
                </ul>

                <div onClick={removeNavbar} className="closeNavbar">
                <AiFillCloseCircle className="icon"/>
                </div>
            </div>
            <a href="tel:+1(888)-529-5062"><FaHeadset className='logo'/><span className='logo'> +1(888)-529-5062</span></a>
            <div onClick={showNav} className="toggleNavbar">
            {/* <BsThreeDotsVertical className="icon"/> */}
            <IoApps className="icon"/>

            </div>

        </header>
    </selection>
  )
}


export default Navbar
