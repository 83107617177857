import React, { useEffect } from 'react'
import './main.css'
import img from '../../Assets/New York.png'
import img2 from '../../Assets/California.png'
import img3 from '../../Assets/Florida.png'
import img4 from '../../Assets/Nevada.png'
import img5 from '../../Assets/California2.png'
import img6 from '../../Assets/Arizona.png'
import img7 from '../../Assets/Illinois.png'
import img8 from '../../Assets/Hawaii.png'
// icons
import {HiOutlineLocationMarker} from 'react-icons/hi'
import {HiOutlineClipboardCheck} from 'react-icons/hi'

import Aos from 'aos'
import 'aos/dist/aos.css'

const Data = [
  {
    id:1,
    imgSrc:img,
    destTitle: 'New York City',
    location: 'New York',
    grade: 'CULTURAL RELAX',
    fees: '$230',
    description: 'Embark on a thrilling journey through the bustling streets of New York City, where every corner holds enchantment and excitement. Uncover the magic that awaits in the Big Apples vibrant adventures!'
  },
  {
    id:2,
    imgSrc:img2,
    destTitle: 'Los Angeles',
    location: 'California',
    grade: 'CULTURAL RELAX',
    fees: '$299',
    description: 'Step into the spotlight and experience the glitz and glam of Los Angeles. From iconic landmarks to celebrity sightings, immerse yourself in the city where dreams come to life.'
  },
  {
    id:3,
    imgSrc:img3,
    destTitle: 'Orlando',
    location: 'Florida',
    grade: 'CULTURAL RELAX',
    fees: '$345',
    description: 'Set out on a magical adventure around the famed theme parks of Orlando. Get ready for exhilarating rides, engaging experiences, and enchanted meetings that will lead to life-changing adventures for guests of all ages.'
  },
  {
    id:4,
    imgSrc:img4,
    destTitle: 'Las Vegas',
    location: 'Nevada',
    grade: 'CULTURAL RELAX',
    fees: '$234',
    description: 'Indulge in the epitome of opulence and excitement as you venture into the dazzling world of Las Vegas. From luxurious accommodations to thrilling entertainment, your ultimate escape begins here.'
  },
  {
    id:5,
    imgSrc:img5,
    destTitle: 'San Francisco',
    location: 'California',
    grade: 'CULTURAL RELAX',
    fees: '$456',
    description: 'Experience the fusion of culture and innovation in San Francisco, where iconic landmarks blend seamlessly with cutting-edge technology. Explore the City by the Bay and uncover its unique charm and endless adventures.'
  },
  {
    id:6,
    imgSrc:img6,
    destTitle: 'Grand Canyon',
    location: 'Arizona',
    grade: 'CULTURAL RELAX',
    fees: '$345',
    description: 'Take a breath-taking journey to the Grand Canyon and see the majesty of nature come to life. Discover expansive vistas, high cliffs, and breathtaking scenery that will stay with you forever.'
  },
  {
    id:7,
    imgSrc:img7,
    destTitle: 'Chicago',
    location: 'Illinois',
    grade: 'CULTURAL RELAX',
    fees: '$453',
    description: 'Experience the dynamic blend of culture and cuisine in Chicago, the Windy City. Delight in iconic landmarks, vibrant neighborhoods, and a culinary scene that reflects the citys diverse heritage and innovation'
  },
  {
    id:8,
    imgSrc:img8,
    destTitle: 'Honolulu',
    location: 'Hawaii',
    grade: 'CULTURAL RELAX',
    fees: '$658',
    description: 'Immerse yourself in the enchanting Aloha Spirit in Honolulu, Hawaii. Discover paradise with stunning beaches, lush landscapes, and rich cultural experiences that await your arrival in this tropical haven.'
  },
]


const Main = () => {

// scroll animation 
useEffect(()=>{
  Aos.init({duration: 2000})
}, [])
  
  return (
    <section className="main container section">
      <div className="secTitle">
        <h3 data-aos="fade-right" className="title">
        Discounted Package Deals:
        </h3>
      </div>


      <div className="secContent grid">
        {
          Data.map(({id,imgSrc,destTitle,location,grade,fees,description })=>{
            return(
              <div data-aos="fade-up" key={id} className="singleDestination">
                <div className="imageDiv">
                  <img src={imgSrc} alt={destTitle} />
                </div>

                <div className="cardInfo">
                  <h4 className="destTitle">{destTitle}</h4>
                  <span className="content flex">
                    <HiOutlineLocationMarker className='icon'/>
                    <span className="location">{location}</span>
                  </span>

                  <div className="fees flex">
                    <div className="grade">
                      <span>{grade}<small>+1</small></span>
                    </div>
                    <div className="price">
                      <h5>{fees}</h5>
                    </div>
                  </div>

                  <div className="desc">
                    <p>{description}</p>
                  </div>

                  <button className="btn flex">
                  <a href="tel:+1(855)-921-5823">DETAILS<HiOutlineClipboardCheck className='icon'/></a>
                  </button>
                </div>
              </div>
            )
          })
        }
      </div>

    </section>
  )
}

export default Main
