const suggestionsList = [
    'AAA  - Anaa Airport ',
    'AAB  - Arrabury Airport ',
    'AAC  - El Arish International Airport ',
    'AAD  - Adado Airport ',
    'AAE  - Rabah Bitat Airport    Les Salines Airport   ',
    'AAF  - Apalachicola Regional Airport ',
    'AAG  - Arapoti Airport ',
    'AAH  - Merzbrück Airport ',
    'AAI  - Arraias Airport ',
    'AAJ  - Cayana Airstrip ',
    'AAK  - Aranuka Airport ',
    'AAL  - Aalborg Airport ',
    'AAM  - Mala Mala Airport ',
    'AAN  - Al Ain International Airport ',
    'AAO  - Anaco Airport ',
    'AAP  - Aji Pangeran Tumenggung Pranoto International Airport ',
    'AAQ  - Anapa Airport ',
    'AAR  - Aarhus Airport ',
    'AAS  - Apalapsili Airport ',
    'AAT  - Altay Airport ',
    'AAU  - Asau Airport ',
    'AAV  - Allah Valley Airport ',
    'AAX  - Araxá Airport ',
    'AAY  - Al Ghaydah Airport ',
    'AAZ  - Quetzaltenango Airport ',
    'ABA  - Abakan International Airport ',
    'ABB  - Asaba International Airport ',
    'ABC  - Albacete Airport ',
    'ABD  - Abadan International Airport ',
    'ABE  - Lehigh Valley International Airport ',
    'ABF  - Abaiang Atoll Airport ',
    'ABG  - Abingdon Airport ',
    'ABH  - Alpha Airport ',
    'ABI  - Abilene Regional Airport ',
    'ABJ  - Port Bouet Airport    Felix Houphouet Boigny Int l   ',
    'ABK  - Kabri Dar Airport ',
    'ABL  - Ambler Airport    FAA  AFM   ',
    'ABM  - Northern Peninsula Airport ',
    'ABN  - Albina Airstrip ',
    'ABO  - Aboisso Airport ',
    'ABP  - Atkamba Airport ',
    'ABQ  - Albuquerque International Sunport ',
    'ABR  - Aberdeen Regional Airport ',
    'ABS  - Abu Simbel Airport ',
    'ABT  - Al Baha Domestic Airport ',
    'ABU  - Haliwen Airport ',
    'ABV  - Nnamdi Azikiwe International Airport ',
    'ABW  - Abau Airport ',
    'ABX  - Albury Airport ',
    'ABY  - Southwest Georgia Regional Airport ',
    'ABZ  - Aberdeen Airport ',
    'ACA  - General Juan N  Álvarez International Airport ',
    'ACB  - Antrim County Airport ',
    'ACC  - Kotoka International Airport ',
    'ACD  - Alcides Fernández Airport ',
    'ACE  - Lanzarote Airport ',
    'ACH  - St  Gallen–Altenrhein Airport ',
    'ACI  - Alderney Airport ',
    'ACJ  - Anuradhapura Airport ',
    'ACK  - Nantucket Memorial Airport ',
    'ACL  - Aguaclara Airport ',
    'ACM  - Arica Airport ',
    'ACN  - Ciudad Acuña International Airport ',
    'ACP  - Sahand Airport ',
    'ACR  - Araracuara Airport ',
    'ACS  - Achinsk Airport ',
    'ACT  - Waco Regional Airport ',
    'ACU  - Achutupo Airport ',
    'ACV  - Arcata Eureka Airport ',
    'ACX  - Xingyi Wanfenglin Airport ',
    'ACY  - Atlantic City International Airport ',
    'ACZ  - Zabol Airport ',
    'ADA  - Adana Şakirpaşa Airport ',
    'ADB  - Adnan Menderes Airport ',
    'ADC  - Andakombe Airport ',
    'ADD  - Bole International Airport ',
    'ADE  - Aden International Airport ',
    'ADF  - Adıyaman Airport ',
    'ADG  - Lenawee County Airport ',
    'ADH  - Aldan Airport ',
    'ADI  - Arandis Airport ',
    'ADJ  - Amman Civil Airport    Marka International Airport   ',
    'ADK  - Adak Airport ',
    'ADL  - Adelaide Airport ',
    'ADM  - Ardmore Municipal Airport ',
    'ADN  - Andes Airport ',
    'ADO  - Andamooka Airport ',
    'ADP  - Ampara Airport ',
    'ADQ  - Kodiak Airport ',
    'ADR  - Robert F  Swinnie Airport    FAA  PHH   ',
    'ADS  - Addison Airport ',
    'ADT  - Ada Municipal Airport    FAA  ADH   ',
    'ADU  - Ardabil Airport ',
    'ADV  - Ed Daein Airport ',
    'ADW  - Andrews Field    Andrews Air Force Base   ',
    'ADX  - Leuchars Station    formerly  RAF Leuchars   ',
    'ADY  - Alldays Airport ',
    'ADZ  - Gustavo Rojas Pinilla International Airport ',
    'AEA  - Abemama Atoll Airport ',
    'AEB  - Baise Bama Airport ',
    'AEE  - Adareil Airport ',
    'AEG  - Aek Godang Airport ',
    'AEH  - Abéché Airport ',
    'AEK  - Aseki Airport ',
    'AEL  - Albert Lea Municipal Airport ',
    'AEM  - Amgu Airport ',
    'AEO  - Aioun el Atrouss Airport ',
    'AEP  - Jorge Newbery Airpark ',
    'AEQ  - Ar Horqin Airport ',
    'AER  - Sochi International Airport ',
    'AES  - Ålesund Airport, Vigra ',
    'AET  - Allakaket Airport    FAA  6A8   ',
    'AEU  - Abu Musa Airport ',
    'AEX  - Alexandria International Airport ',
    'AEY  - Akureyri Airport ',
    'AFA  - San Rafael Airport ',
    'AFD  - Port Alfred Airport ',
    'AFF  - United States Air Force Academy Airfield ',
    'AFI  - Amalfi Airport ',
    'AFK  - Kondavattavan Tank Seaplane Base ',
    'AFL  - Alta Floresta Airport ',
    'AFN  - Jaffrey Airport Silver Ranch ',
    'AFO  - Afton Municipal Airport ',
    'AFR  - Afore Airstrip ',
    'AFS  - Zarafshan Airport ',
    'AFT  - Afutara Airport ',
    'AFW  - Fort Worth Alliance Airport ',
    'AFY  - Afyon Airport ',
    'AFZ  - Sabzevar Airport ',
    'AGA  - Agadir–Al Massira Airport ',
    'AGB  - Augsburg Airport ',
    'AGC  - Allegheny County Airport ',
    'AGD  - Anggi Airport ',
    'AGE  - Wangerooge Airfield ',
    'AGF  - Agen La Garenne Airport ',
    'AGG  - Angoram Airport ',
    'AGH  - Ängelholm–Helsingborg Airport    former ICAO  ESDB   ',
    'AGI  - Wageningen Airstrip ',
    'AGJ  - Aguni Airport ',
    'AGK  - Kagua Airport ',
    'AGL  - Wanigela Airport ',
    'AGN  - Angoon Seaplane Base ',
    'AGO  - Magnolia Municipal Airport ',
    'AGP  - Málaga Airport ',
    'AGQ  - Agrinion Airport ',
    'AGR  - Agra Airport ',
    'AGS  - Augusta Regional Airport  at Bush Field ',
    'AGT  - Guaraní International Airport ',
    'AGU  - Lic  Jesús Terán Peredo International Airport ',
    'AGV  - Oswaldo Guevara Mujica Airport ',
    'AGW  - Agnew Airport ',
    'AGX  - Agatti Aerodrome ',
    'AGZ  - Aggeneys Airport ',
    'AHB  - Abha Regional Airport ',
    'AHC  - Amedee Army Airfield ',
    'AHD  - Ardmore Downtown Executive Airport    FAA  1F0   ',
    'AHE  - Ahe Airport ',
    'AHF  - Arapahoe Municipal Airport    FAA  37V   ',
    'AHH  - Amery Municipal Airport ',
    'AHI  - Amahai Airport ',
    'AHJ  - Hongyuan Airport ',
    'AHL  - Aishalton Airport ',
    'AHM  - Ashland Municipal Airport  1      FAA  S03   ',
    'AHN  - Athens Ben Epps Airport ',
    'AHO  - Alghero Fertilia Airport ',
    'AHS  - Ahuas Airport ',
    'AHU  - Cherif Al Idrissi Airport ',
    'AHW  - Saih Rawl Airport ',
    'AHY  - Ambatolahy Airport ',
    'AHZ  - Alpe d Huez Airport ',
    'AIA  - Alliance Municipal Airport ',
    'AIC  - Ailinglaplap Airok Airport ',
    'AID  - Anderson Municipal Airport    Darlington Field   ',
    'AIE  - Aiome Airport ',
    'AIF  - Assis Airport    former ICAO  SBAS   ',
    'AIG  - Yalinga Airport ',
    'AIH  - Aiambak Airport ',
    'AII  - Ali Sabieh Airport ',
    'AIK  - Aiken Municipal Airport ',
    'AIL  - Ailigandí Airport ',
    'AIM  - Ailuk Airport ',
    'AIN  - Wainwright Airport    FAA  AWI   ',
    'AIO  - Atlantic Municipal Airport ',
    'AIP  - Adampur Airport ',
    'AIR  - Aripuanã Airport ',
    'AIS  - Arorae Island Airport ',
    'AIT  - Aitutaki Airport ',
    'AIU  - Enua Airport ',
    'AIV  - George Downer Airport ',
    'AIW  - Ai Ais Airport ',
    'AIZ  - Lee C  Fine Memorial Airport ',
    'AJA  - Ajaccio Napoleon Bonaparte Airport ',
    'AJF  - Al Jawf Domestic Airport ',
    'AJI  - Ağrı Airport ',
    'AJJ  - Akjoujt Airport ',
    'AJK  - Arak Airport ',
    'AJL  - Lengpui Airport ',
    'AJN  - Ouani Airport ',
    'AJR  - Arvidsjaur Airport ',
    'AJS  - Punta Abreojos Airstrip ',
    'AJU  - Santa Maria Airport ',
    'AJY  - Mano Dayak International Airport ',
    'AKA  - Ankang Wulipu Airport ',
    'AKB  - Atka Airport    FAA  AKA   ',
    'AKC  - Akron Fulton International Airport    FAA  AKR   ',
    'AKD  - Akola Airport ',
    'AKE  - Akieni Airport ',
    'AKF  - Kufra Airport ',
    'AKG  - Anguganak Airport ',
    'AKH  - Prince Sultan Air Base ',
    'AKI  - Akiak Airport ',
    'AKJ  - Asahikawa Airport ',
    'AKK  - Akhiok Airport ',
    'AKL  - Auckland Airport ',
    'AKM  - Zakouma Airport ',
    'AKN  - King Salmon Airport ',
    'AKO  - Colorado Plains Regional Airport ',
    'AKP  - Anaktuvuk Pass Airport ',
    'AKQ  - Gunung Batin Airport ',
    'AKR  - Akure Airport ',
    'AKS  - Auki Gwaunaru u Airport ',
    'AKT  - RAF Akrotiri ',
    'AKU  - Aksu Airport ',
    'AKV  - Akulivik Airport ',
    'AKW  - Aghajari Airport ',
    'AKX  - Aktobe Airport ',
    'AKY  - Sittwe Airport ',
    'ALA  - Almaty International Airport ',
    'ALB  - Albany International Airport ',
    'ALC  - Alicante–Elche Airport ',
    'ALD  - Alerta Airport ',
    'ALE  - Alpine–Casparis Municipal Airport    FAA  E38   ',
    'ALF  - Alta Airport ',
    'ALG  - Houari Boumediene Airport ',
    'ALH  - Albany Airport ',
    'ALI  - Alice International Airport ',
    'ALJ  - Alexander Bay Airport ',
    'ALK  - Asella Airport ',
    'ALL  - Albenga Airport ',
    'ALM  - Alamogordo–White Sands Regional Airport ',
    'ALN  - St  Louis Regional Airport ',
    'ALO  - Waterloo Regional Airport ',
    'ALP  - Aleppo International Airport ',
    'ALQ  - Alegrete Airport ',
    'ALR  - Alexandra Aerodrome ',
    'ALS  - San Luis Valley Regional Airport ',
    'ALT  - Alenquer Airport ',
    'ALU  - Alula Airport ',
    'ALW  - Walla Walla Regional Airport ',
    'ALX  - Thomas C  Russell Field ',
    'ALY  - El Nouzha Airport ',
    'ALZ  - Alitak Seaplane Base ',
    'AMA  - Rick Husband Amarillo International Airport ',
    'AMB  - Ambilobe Airport ',
    'AMC  - Am Timan Airport ',
    'AMD  - Sardar Vallabhbhai Patel International Airport ',
    'AME  - Alto Molocue Airport ',
    'AMF  - Ama Airport ',
    'AMG  - Amboin Airport ',
    'AMH  - Arba Minch Airport ',
    'AMI  - Selaparang Airport    closed 2011   ',
    'AMJ  - Almenara Airport ',
    'AMK  - Animas Air Park    FAA  00C   ',
    'AML  - Puerto Armuelles Airport ',
    'AMM  - Queen Alia International Airport ',
    'AMN  - Gratiot Community Airport ',
    'AMO  - Mao Airport ',
    'AMP  - Ampanihy Airport ',
    'AMQ  - Pattimura Airport ',
    'AMS  - Amsterdam Airport Schiphol ',
    'AMT  - Amata Airport ',
    'AMU  - Amanab Airport ',
    'AMV  - Amderma Airport ',
    'AMW  - Ames Municipal Airport ',
    'AMX  - Ammaroo Airport ',
    'AMY  - Ambatomainty Airport ',
    'AMZ  - Ardmore Airport ',
    'ANA  - Angama Mara Airport ',
    'ANB  - Anniston Regional Airport ',
    'ANC  - Ted Stevens Anchorage International Airport ',
    'AND  - Anderson Regional Airport ',
    'ANE  - Angers – Loire Airport ',
    'ANF  - Cerro Moreno International Airport ',
    'ANG  - Angoulême – Cognac International Airport ',
    'ANI  - Aniak Airport ',
    'ANJ  - Zanaga Airport ',
    'ANK  - Etimesgut Air Base ',
    'ANL  - Andulo Airport ',
    'ANM  - Antsirabato Airport ',
    'ANN  - Annette Island Airport ',
    'ANO  - Angoche Airport ',
    'ANP  - Lee Airport ',
    'ANQ  - Tri State Steuben County Airport ',
    'ANR  - Antwerp International Airport ',
    'ANS  - Andahuaylas Airport ',
    'ANU  - V  C  Bird International Airport ',
    'ANV  - Anvik Airport ',
    'ANW  - Ainsworth Regional Airport ',
    'ANX  - Andøya Airport, Andenes ',
    'ANY  - Anthony Municipal Airport ',
    'ANZ  - Angus Downs Airport ',
    'AOA  - Aroa Airport ',
    'AOB  - Annanberg Airport ',
    'AOC  - Leipzig–Altenburg Airport ',
    'AOD  - Abou Deïa Airport ',
    'AOE  - Anadolu Airport ',
    'AOG  - Anshan Teng ao Airport ',
    'AOH  - Lima Allen County Airport ',
    'AOI  - Ancona Falconara Airport ',
    'AOJ  - Aomori Airport ',
    'AOK  - Karpathos Island National Airport ',
    'AOL  - Paso de los Libres Airport ',
    'AOM  - Adam Airport ',
    'AON  - Arona Airport ',
    'AOO  - Altoona–Blair County Airport ',
    'AOP  - Alférez FAP Alfredo Vladimir Sara Bauer Airport ',
    'AOR  - Sultan Abdul Halim Airport ',
    'AOS  - Amook Bay Seaplane Base    FAA  AK81   ',
    'AOT  - Aosta Valley Airport ',
    'AOU  - Attapeu International Airport ',
    'APA  - Centennial Airport ',
    'APB  - Apolo Airport ',
    'APC  - Napa County Airport ',
    'APF  - Naples Municipal Airport ',
    'APG  - Phillips Army Airfield ',
    'APH  - A P  Hill Army Airfield ',
    'API  - Captain Luis F  Gómez Niño Air Base ',
    'APK  - Apataki Airport ',
    'APL  - Nampula Airport ',
    'APN  - Alpena County Regional Airport ',
    'APO  - Antonio Roldán Betancourt Airport ',
    'APP  - Asapa Airport ',
    'APQ  - Arapiraca Airport ',
    'APR  - April River Airport ',
    'APS  - Anápolis Airport ',
    'APT  - Marion County Airport    Brown Field   ',
    'APU  - Apucarana Airport ',
    'APV  - Apple Valley Airport ',
    'APW  - Faleolo International Airport ',
    'APX  - Arapongas Airport ',
    'APY  - Alto Parnaíba Airport ',
    'APZ  - Zapala Airport ',
    'AQA  - Araraquara Airport ',
    'AQB  - Quiché Airport ',
    'AQG  - Anqing Tianzhushan Airport ',
    'AQI  - Al Qaisumah  Hafr Al Batin Airport ',
    'AQJ  - King Hussein International Airport ',
    'AQM  - Ariquemes Airport ',
    'AQP  - Rodríguez Ballón International Airport ',
    'AQS  - Saqani Airport ',
    'AQY  - Girdwood Airport ',
    'ARA  - Acadiana Regional Airport ',
    'ARB  - Ann Arbor Municipal Airport ',
    'ARC  - Arctic Village Airport ',
    'ARD  - Alor Island Airport ',
    'ARE  - Antonio   Nery   Juarbe Pol Airport    FAA  ABO   ',
    'ARF  - Acaricuara Airport ',
    'ARG  - Walnut Ridge Regional Airport ',
    'ARH  - Talagi Airport ',
    'ARI  - Chacalluta International Airport ',
    'ARJ  - Arso Airport ',
    'ARK  - Arusha Airport ',
    'ARL  - Arly Airport ',
    'ARM  - Armidale Airport ',
    'ARN  - Stockholm Arlanda Airport ',
    'ARO  - Arboletes Airport ',
    'ARP  - Aragip Airport ',
    'ARQ  - El Troncal Airport ',
    'ARR  - Alto Río Senguer Airport ',
    'ARS  - Aragarças Airport ',
    'ART  - Watertown International Airport ',
    'ARU  - Araçatuba Airport ',
    'ARV  - Lakeland Airport    Noble F  Lee Memorial Field   ',
    'ARW  - Arad International Airport ',
    'ARX  - Aracati Airport ',
    'ARY  - Ararat Airport ',
    'ARZ  - N zeto Airport ',
    'ASA  - Assab International Airport ',
    'ASB  - Ashgabat International Airport ',
    'ASC  - Ascención de Guarayos Airport ',
    'ASD  - Andros Town International Airport ',
    'ASE  - Aspen–Pitkin County Airport    Sardy Field   ',
    'ASF  - Narimanovo Airport ',
    'ASG  - Ashburton Aerodrome ',
    'ASH  - Nashua Airport    Boire Field   ',
    'ASI  - RAF Ascension Island    Wideawake Field   ',
    'ASJ  - Amami Airport ',
    'ASK  - Yamoussoukro Airport ',
    'ASL  - Harrison County Airport ',
    'ASM  - Asmara International Airport ',
    'ASN  - Talladega Municipal Airport ',
    'ASO  - Asosa Airport ',
    'ASP  - Alice Springs Airport ',
    'ASQ  - Austin Airport    FAA  9U3   ',
    'ASR  - Erkilet International Airport ',
    'AST  - Astoria Regional Airport ',
    'ASU  - Silvio Pettirossi International Airport ',
    'ASV  - Amboseli Airport ',
    'ASW  - Aswan International Airport ',
    'ASX  - John F  Kennedy Memorial Airport ',
    'ASY  - Ashley Municipal Airport ',
    'ASZ  - Asirim Airport ',
    'ATA  - Comandante FAP Germán Arias Graziani Airport ',
    'ATB  - Atbara Airport ',
    'ATC  - Arthur s Town Airport ',
    'ATD  - Uru Harbour Airport ',
    'ATE  - Antlers Municipal Airport    FAA  80F   ',
    'ATF  - Chachoan Airport ',
    'ATG  - PAF Base Minhas ',
    'ATH  - Athens International Airport    Eleftherios Venizelos Airport   ',
    'ATI  - Artigas International Airport ',
    'ATJ  - Antsirabe Airport ',
    'ATK  - Atqasuk Edward Burnell Sr  Memorial Airport ',
    'ATL  - Hartsfield–Jackson Atlanta International Airport ',
    'ATM  - Altamira Airport ',
    'ATN  - Namatanai Airport ',
    'ATO  - Ohio University Airport    Snyder Field     FAA  UNI   ',
    'ATP  - Aitape Airstrip ',
    'ATQ  - Sri Guru Ram Dass Jee International Airport ',
    'ATR  - Atar International Airport ',
    'ATS  - Artesia Municipal Airport ',
    'ATT  - Atmautluak Airport    FAA  4A2   ',
    'ATU  - Casco Cove Coast Guard Station ',
    'ATV  - Ati Airport ',
    'ATW  - Appleton International Airport ',
    'ATX  - Atbasar Airport ',
    'ATY  - Watertown Regional Airport ',
    'ATZ  - Assiut Airport ',
    'AUA  - Queen Beatrix International Airport ',
    'AUC  - Santiago Pérez Quiroz Airport ',
    'AUD  - Augustus Downs Airport ',
    'AUE  - Abu Rudeis Airport ',
    'AUF  - Auxerre – Branches Aerodrome ',
    'AUG  - Augusta State Airport ',
    'AUH  - Abu Dhabi International Airport ',
    'AUI  - Aua Island Airport ',
    'AUJ  - Ambunti Airport ',
    'AUK  - Alakanuk Airport ',
    'AUL  - Aur Airport ',
    'AUM  - Austin Municipal Airport ',
    'AUN  - Auburn Municipal Airport ',
    'AUO  - Auburn University Regional Airport ',
    'AUP  - Agaun Airport ',
    'AUQ  - Atuona Airport ',
    'AUR  - Aurillac – Tronquières Airport ',
    'AUS  - Austin–Bergstrom International Airport ',
    'AUT  - Atauro Airport ',
    'AUU  - Aurukun Airport ',
    'AUV  - Aumo Airport ',
    'AUW  - Wausau Downtown Airport ',
    'AUX  - Araguaína Airport ',
    'AUY  - Anatom Airport ',
    'AUZ  - Aurora Municipal Airport    FAA  ARR   ',
    'AVA  - Anshun Huangguoshu Airport ',
    'AVB  - Aviano Air Base ',
    'AVG  - Auvergne Airport ',
    'AVI  - Máximo Gómez Airport ',
    'AVK  - Arvaikheer Airport ',
    'AVL  - Asheville Regional Airport ',
    'AVN  - Avignon – Provence Airport ',
    'AVO  - Avon Park Executive Airport ',
    'AVP  - Wilkes Barre  Scranton International Airport ',
    'AVU  - Avu Avu Airport ',
    'AVV  - Avalon Airport ',
    'AVW  - Marana Regional Airport    FAA  AVQ   ',
    'AVX  - Catalina Airport ',
    'AWA  - Awasa Airport ',
    'AWB  - Awaba Airport ',
    'AWD  - Aniwa Airport ',
    'AWE  - Alowe Airport ',
    'AWK  - Wake Island Airfield ',
    'AWM  - West Memphis Municipal Airport ',
    'AWN  - Alton Downs Airport ',
    'AWP  - Austral Downs Airport ',
    'AWR  - Awar Airport ',
    'AWZ  - Ahvaz International Airport ',
    'AXA  - Clayton J  Lloyd International Airport ',
    'AXB  - Maxson Airfield   FAA  89N   ',
    'AXC  - Aramac Airport ',
    'AXD  - Alexandroupoli Airport    Dimokritos Airport   ',
    'AXE  - Xanxere Airport ',
    'AXF  - Alxa Left Banner Bayanhot Airport ',
    'AXG  - Algona Municipal Airport    FAA  AXA   ',
    'AXJ  - Amakusa Airfield ',
    'AXK  - Ataq Airport ',
    'AXL  - Alexandria Airport ',
    'AXM  - El Edén International Airport ',
    'AXN  - Alexandria Municipal Airport    Chandler Field   ',
    'AXP  - Spring Point Airport ',
    'AXR  - Arutua Airport ',
    'AXS  - Altus  Quartz Mountain Regional Airport ',
    'AXT  - Akita Airport ',
    'AXU  - Axum Airport ',
    'AXV  - Neil Armstrong Airport ',
    'AXX  - Angel Fire Airport ',
    'AYA  - Ayapel Airport ',
    'AYC  - Ayacucho Airport ',
    'AYD  - Alroy Downs Airport ',
    'AYG  - Yaguara Airport ',
    'AYI  - Yari Airport ',
    'AYK  - Arkalyk Airport ',
    'AYL  - Anthony Lagoon Airport ',
    'AYM  - Yas Island Seaplane Base ',
    'AYN  - Anyang Airport ',
    'AYO  - Juan de Ayolas Airport ',
    'AYP  - Coronel FAP Alfredo Mendívil Duarte Airport ',
    'AYQ  - Ayers Rock Airport ',
    'AYR  - Ayr Airport ',
    'AYS  - Waycross–Ware County Airport ',
    'AYT  - Antalya Airport ',
    'AYU  - Aiyura Airport ',
    'AYW  - Ayawasi Airport ',
    'AYX  - Tnte  Gral  Gerardo Pérez Pinedo Airport ',
    'AYY  - Arugam Bay Seaplane Base ',
    'AZA  - Phoenix–Mesa Gateway Airport    FAA  IWA   ',
    'AZB  - Amazon Bay Airport ',
    'AZD  - Shahid Sadooghi Airport ',
    'AZG  - Pablo L  Sidar Airport ',
    'AZI  - Al Bateen Executive Airport ',
    'AZL  - Fazenda Tucunaré Airport ',
    'AZN  - Andizhan Airport ',
    'AZO  - Kalamazoo  Battle Creek International Airport ',
    'AZP  - Jorge Jiménez Cantú National Airport ',
    'AZR  - Touat Cheikh Sidi Mohamed Belkebir Airport ',
    'AZS  - Samaná El Catey International Airport ',
    'AZT  - Zapatoca Airport ',
    'AZZ  - Ambriz Airport ',
    'BAA  - Bialla Airport ',
    'BAB  - Beale Air Force Base ',
    'BAC  - Barranca de Upia Airport ',
    'BAD  - Barksdale Air Force Base ',
    'BAE  - Barcelonnette – Saint Pons Airport ',
    'BAF  - Westfield Barnes Regional Airport ',
    'BAG  - Loakan Airport ',
    'BAH  - Bahrain International Airport ',
    'BAI  - Buenos Aires Airport ',
    'BAJ  - Bali Airport ',
    'BAK  - metropolitan area2 ',
    'BAL  - Batman Airport ',
    'BAM  - Battle Mountain Airport    Lander County Airport   ',
    'BAN  - Basongo Airport ',
    'BAP  - Baibara Airport ',
    'BAQ  - Ernesto Cortissoz International Airport ',
    'BAR  - Qionghai Bo ao Airport ',
    'BAS  - Balalae Airport ',
    'BAT  - Chafei Amsei Airport ',
    'BAV  - Baotou Airport ',
    'BAW  - Biawonque Airport ',
    'BAX  - Barnaul Airport ',
    'BAY  - Baia Mare Airport ',
    'BAZ  - Barcelos Airport ',
    'BBA  - Balmaceda Airport ',
    'BBB  - Benson Municipal Airport ',
    'BBC  - Bay City Municipal Airport    FAA  BYY   ',
    'BBD  - Curtis Field ',
    'BBG  - Butaritari Atoll Airport ',
    'BBH  - Stralsund–Barth Airport ',
    'BBI  - Biju Patnaik International Airport ',
    'BBJ  - Bitburg Airport    former Bitburg Air Base   ',
    'BBK  - Kasane Airport ',
    'BBL  - Ballera Airport ',
    'BBM  - Battambang Airport ',
    'BBN  - Bario Airport ',
    'BBO  - Berbera Airport ',
    'BBP  - Bembridge Airport ',
    'BBQ  - Barbuda Codrington Airport ',
    'BBR  - Baillif Airport ',
    'BBS  - Blackbushe Airport ',
    'BBT  - Berbérati Airport ',
    'BBU  - Aurel Vlaicu International Airport    Băneasa Airport   ',
    'BBV  - Nero Mer Airport ',
    'BBW  - Broken Bow Municipal Airport ',
    'BBX  - Wings Field    FAA  LOM   ',
    'BBY  - Bambari Airport ',
    'BBZ  - Zambezi Airport ',
    'BCA  - Gustavo Rizo Airport ',
    'BCB  - Virginia Tech Montgomery Executive Airport ',
    'BCC  - Bear Creek 3 Airport    FAA  Z48   ',
    'BCD  - Bacolod–Silay International Airport ',
    'BCE  - Bryce Canyon Airport ',
    'BCF  - Bouca Airport ',
    'BCG  - Bemichi Airport ',
    'BCH  - Baucau Airport ',
    'BCI  - Barcaldine Airport ',
    'BCK  - Bolwarra Airport ',
    'BCL  - Barra del Colorado Airport ',
    'BCM  - Bacău International Airport ',
    'BCN  - Barcelona–El Prat Airport ',
    'BCO  - Baco Airport    Jinka Airport   ',
    'BCP  - Bambu Airport ',
    'BCQ  - Brak Airport ',
    'BCR  - Novo Campo Airport ',
    'BCS  - Southern Seaplane Airport    FAA  65LA   ',
    'BCT  - Boca Raton Airport ',
    'BCU  - Bauchi State Airport ',
    'BCV  - Hector Silva Airstrip ',
    'BCW  - Benguerra Island Airport ',
    'BCX  - Beloretsk Airport ',
    'BCY  - Bulchi Airport ',
    'BCZ  - Bickerton Island Airport ',
    'BDA  - L F  Wade International Airport ',
    'BDB  - Bundaberg Airport ',
    'BDC  - Barra do Corda Airport ',
    'BDD  - Badu Island Airport ',
    'BDE  - Baudette International Airport ',
    'BDF  - Rinkenberger RLA Airport    FAA  3IS8   ',
    'BDG  - Blanding Municipal Airport ',
    'BDH  - Bandar Lengeh Airport ',
    'BDI  - Bird Island Airport ',
    'BDJ  - Syamsudin Noor Airport ',
    'BDK  - Soko Airport ',
    'BDL  - Bradley International Airport ',
    'BDM  - Bandırma Airport ',
    'BDN  - Talhar Airport ',
    'BDO  - Husein Sastranegara International Airport ',
    'BDP  - Bhadrapur Airport    Chandragadhi Airport   ',
    'BDQ  - Vadodara Airport    Civil Airport Harni   ',
    'BDR  - Igor I  Sikorsky Memorial Airport ',
    'BDS  - Brindisi – Salento Airport ',
    'BDT  - Gbadolite Airport ',
    'BDU  - Bardufoss Airport ',
    'BDV  - Moba Airport ',
    'BDW  - Bedford Downs Airport ',
    'BDX  - Broadus Airport ',
    'BDY  - Bandon State Airport    FAA  S05   ',
    'BDZ  - Baindoung Airport ',
    'BEA  - Bereina Airport ',
    'BEB  - Benbecula Airport ',
    'BEC  - Beech Factory Airport ',
    'BED  - Laurence G  Hanscom Field ',
    'BEE  - Beagle Bay Airport ',
    'BEF  - Bluefields Airport ',
    'BEG  - Belgrade Nikola Tesla Airport ',
    'BEH  - Southwest Michigan Regional Airport ',
    'BEI  - Beica Airport ',
    'BEJ  - Kalimarau Airport ',
    'BEK  - Bareilly Airport ',
    'BEL  - Val de Cans International Airport ',
    'BEM  - Beni Mellal Airport ',
    'BEN  - Benina International Airport ',
    'BEO  - Lake Macquarie Airport    Belmont Airport   ',
    'BEP  - Bellary Airport ',
    'BEQ  - RAF Honington ',
    'BER  - Berlin Brandenburg Airport ',
    'BES  - Brest Bretagne Airport ',
    'BET  - Bethel Airport ',
    'BEU  - Bedourie Airport ',
    'BEV  - Beersheba Airport ',
    'BEW  - Beira Airport ',
    'BEX  - RAF Benson ',
    'BEY  - Beirut–Rafic Hariri International Airport      Beirut Air Base ',
    'BEZ  - Beru Island Airport ',
    'BFA  - Bahía Negra Airport ',
    'BFC  - Bloomfield Airport ',
    'BFD  - Bradford Regional Airport ',
    'BFE  - Bielefeld Airport ',
    'BFF  - Western Nebraska Regional Airport    William B  Heilig Field   ',
    'BFG  - Bullfrog Basin Airport    FAA  U07   ',
    'BFH  - Bacacheri Airport ',
    'BFI  - Boeing Field  King County International Airport ',
    'BFJ  - Bijie Feixiong Airport ',
    'BFK  - Buckley Space Force Base ',
    'BFL  - Meadows Field Airport ',
    'BFM  - Mobile Downtown Airport ',
    'BFN  - Bloemfontein Airport ',
    'BFO  - Buffalo Range Airport ',
    'BFP  - Beaver County Airport    FAA  BVI   ',
    'BFQ  - Bahía Piña Airport ',
    'BFR  - Virgil I  Grissom Municipal Airport ',
    'BFS  - Belfast International Airport ',
    'BFT  - Beaufort County Airport    FAA  ARW   ',
    'BFU  - Bengbu Airport ',
    'BFV  - Buriram Airport ',
    'BFW  - Sidi Bel Abbès Airport ',
    'BFX  - Bafoussam Airport ',
    'BGA  - Palonegro International Airport ',
    'BGB  - Booué Airport ',
    'BGC  - Bragança Airport ',
    'BGD  - Hutchinson County Airport ',
    'BGE  - Decatur County Industrial Air Park ',
    'BGF  - Bangui M Poko International Airport ',
    'BGG  - Bingöl Airport ',
    'BGH  - Abbaye Airport ',
    'BGI  - Grantley Adams International Airport ',
    'BGJ  - Borgarfjörður Eystri Airport ',
    'BGK  - Big Creek Airport ',
    'BGL  - Baglung Airport ',
    'BGM  - Greater Binghamton Airport    Edwin A  Link Field   ',
    'BGN  - Belaya Gora Airport ',
    'BGO  - Bergen Airport, Flesland ',
    'BGP  - Bongo Airport ',
    'BGQ  - Big Lake Airport ',
    'BGR  - Bangor International Airport ',
    'BGT  - Bagdad Airport    FAA  E51   ',
    'BGU  - Bangassou Airport ',
    'BGV  - Bento Gonçalves Airport ',
    'BGW  - Baghdad International Airport ',
    'BGX  - Comandante Gustavo Kraemer Airport ',
    'BGY  - Il Caravaggio International Airport    Orio al Serio Airport   ',
    'BGZ  - Braga Airport ',
    'BHA  - Los Perales Airport ',
    'BHB  - Hancock County Bar Harbor Airport ',
    'BHD  - George Best Belfast City Airport ',
    'BHE  - Woodbourne Airport ',
    'BHF  - Bahía Cupica Airport ',
    'BHG  - Brus Laguna Airport ',
    'BHH  - Bisha Domestic Airport ',
    'BHI  - Comandante Espora Airport ',
    'BHJ  - Bhuj Airport      Bhuj Rudra Mata Air Force Base ',
    'BHK  - Bukhara International Airport ',
    'BHL  - Bahía de los Ángeles Airport ',
    'BHM  - Birmingham–Shuttlesworth International Airport ',
    'BHN  - Beihan Airport ',
    'BHO  - Raja Bhoj Airport ',
    'BHP  - Bhojpur Airport ',
    'BHQ  - Broken Hill Airport ',
    'BHR  - Bharatpur Airport ',
    'BHS  - Bathurst Airport ',
    'BHT  - Brighton Downs Airport ',
    'BHU  - Bhavnagar Airport ',
    'BHV  - Bahawalpur Airport ',
    'BHW  - Bhagatanwala Airport ',
    'BHX  - Birmingham Airport ',
    'BHY  - Beihai Fucheng Airport ',
    'BHZ  - metropolitan area3 ',
    'BIA  - Bastia – Poretta Airport ',
    'BIB  - Baidoa Airport ',
    'BID  - Block Island State Airport ',
    'BIE  - Beatrice Municipal Airport ',
    'BIF  - Biggs Army Airfield ',
    'BIG  - Allen Army Airfield ',
    'BIH  - Eastern Sierra Regional Airport ',
    'BII  - Bikini Atoll Airport ',
    'BIJ  - Biliau Airport ',
    'BIK  - Frans Kaisiepo Airport ',
    'BIL  - Billings Logan International Airport ',
    'BIM  - South Bimini Airport ',
    'BIN  - Bamyan Airport ',
    'BIO  - Bilbao Airport ',
    'BIP  - Bulimba Airport ',
    'BIQ  - Biarritz Pays Basque Airport ',
    'BIR  - Biratnagar Airport ',
    'BIS  - Bismarck Municipal Airport ',
    'BIT  - Baitadi Airport ',
    'BIU  - Bíldudalur Airport ',
    'BIV  - Bria Airport ',
    'BIW  - Billiluna Airport ',
    'BIX  - Keesler Air Force Base ',
    'BIY  - Bhisho Airport ',
    'BIZ  - Bimin Airport ',
    'BJA  - Soummam – Abane Ramdane Airport ',
    'BJB  - Bojnord Airport ',
    'BJC  - Rocky Mountain Metropolitan Airport ',
    'BJD  - Bakkafjörður Airport ',
    'BJE  - Baleela Airport ',
    'BJF  - Båtsfjord Airport ',
    'BJG  - Kotamobagu Mopait Airport ',
    'BJH  - Bajhang Airport ',
    'BJI  - Bemidji Regional Airport ',
    'BJJ  - Wayne County Airport ',
    'BJK  - Benjina Airport    Nangasuri Airport   ',
    'BJL  - Banjul International Airport ',
    'BJM  - Bujumbura International Airport ',
    'BJN  - Bajone Airport ',
    'BJO  - Bermejo Airport ',
    'BJP  - Arthur Siqueira–Bragança Paulista State Airport ',
    'BJQ  - Bahja Airport ',
    'BJR  - Bahir Dar Airport ',
    'BJS  - metropolitan area4 ',
    'BJT  - Bentota River Airport ',
    'BJU  - Bajura Airport ',
    'BJV  - Milas–Bodrum Airport ',
    'BJW  - Bajawa Soa Airport ',
    'BJX  - Del Bajío International Airport    Guanajuato Int l Airport   ',
    'BJY  - Batajnica Air Base ',
    'BJZ  - Badajoz Airport    Talavera la Real Air Base   ',
    'BKB  - Nal Airport ',
    'BKC  - Buckland Airport    FAA  BVK   ',
    'BKD  - Stephens County Airport ',
    'BKE  - Baker City Municipal Airport ',
    'BKF  - Lake Brooks Seaplane Base ',
    'BKG  - Branson Airport    FAA  BBG   ',
    'BKH  - Barking Sands PMRF ',
    'BKI  - Kota Kinabalu International Airport ',
    'BKJ  - Boké Baralande Airport ',
    'BKK  - Suvarnabhumi Airport ',
    'BKL  - Cleveland Burke Lakefront Airport ',
    'BKM  - Bakelalan Airport ',
    'BKN  - Balkanabat Airport ',
    'BKO  - Bamako–Sénou International Airport ',
    'BKP  - Barkly Downs Airport ',
    'BKQ  - Blackall Airport ',
    'BKR  - Bokoro Airport ',
    'BKS  - Fatmawati Soekarno Airport ',
    'BKT  - Blackstone Army Airfield    Allen C  Perkinson Airport   ',
    'BKU  - Betioky Airport ',
    'BKW  - Raleigh County Memorial Airport ',
    'BKX  - Brookings Regional Airport ',
    'BKY  - Kavumu Airport ',
    'BKZ  - Bukoba Airport ',
    'BLA  - General José Antonio Anzoátegui International Airport ',
    'BLB  - Panamá Pacífico International Airport    former  Howard AFB   ',
    'BLC  - Bali Airport ',
    'BLD  - Boulder City Municipal Airport    FAA  61B   ',
    'BLE  - Dala Airport    Borlänge Airport   ',
    'BLF  - Mercer County Airport ',
    'BLG  - Belaga Airport ',
    'BLH  - Blythe Airport ',
    'BLI  - Bellingham International Airport ',
    'BLJ  - Mostépha Ben Boulaid Airport ',
    'BLK  - Blackpool Airport ',
    'BLL  - Billund Airport ',
    'BLM  - Monmouth Executive Airport ',
    'BLN  - Benalla Airport ',
    'BLO  - Blönduós Airport ',
    'BLP  - Huallaga Airport ',
    'BLQ  - Bologna Guglielmo Marconi Airport ',
    'BLR  - Kempegowda International Airport ',
    'BLS  - Bollon Airport ',
    'BLT  - Blackwater Airport ',
    'BLU  - Blue Canyon–Nyack Airport ',
    'BLV  - MidAmerica St  Louis Airport      Scott Air Force Base ',
    'BLX  - Belluno Airport ',
    'BLY  - Belmullet Aerodrome ',
    'BLZ  - Chileka International Airport ',
    'BMA  - Stockholm Bromma Airport ',
    'BMB  - Bumba Airport ',
    'BMC  - Brigham City Airport ',
    'BMD  - Belo sur Tsiribihina Airport ',
    'BME  - Broome International Airport ',
    'BMF  - Bakouma Airport ',
    'BMG  - Monroe County Airport ',
    'BMH  - Bomai Airport ',
    'BMI  - Central Illinois Regional Airport ',
    'BMJ  - Baramita Airport ',
    'BMK  - Borkum Airfield ',
    'BML  - Berlin Regional Airport ',
    'BMM  - Bitam Airport ',
    'BMN  - Bamarni Airport ',
    'BMO  - Bhamo Airport ',
    'BMP  - Brampton Island Airport ',
    'BMQ  - Bamburi Airport ',
    'BMR  - Baltrum Airport ',
    'BMS  - Socrates Mariani Bittencourt Airport ',
    'BMT  - Beaumont Municipal Airport ',
    'BMU  - Sultan Muhammad Salahudin Airport    Bima Airport   ',
    'BMV  - Buon Ma Thuot Airport ',
    'BMW  - Bordj Mokhtar Airport ',
    'BMX  - Big Mountain Air Force Station    FAA  37AK   ',
    'BMY  - Île Art – Waala Airport ',
    'BMZ  - Bamu Airport ',
    'BNA  - Nashville International Airport ',
    'BNB  - Boende Airport ',
    'BNC  - Beni Airport ',
    'BND  - Bandar Abbas International Airport ',
    'BNE  - Brisbane Airport ',
    'BNF  - Warm Springs Bay Seaplane Base ',
    'BNG  - Banning Municipal Airport ',
    'BNI  - Benin Airport ',
    'BNK  - Ballina Byron Gateway Airport ',
    'BNL  - Barnwell Regional Airport ',
    'BNM  - Bodinumu Airport ',
    'BNN  - Brønnøysund Airport, Brønnøy ',
    'BNO  - Burns Municipal Airport ',
    'BNP  - Bannu Airport ',
    'BNQ  - Baganga Airport ',
    'BNR  - Banfora Airport ',
    'BNS  - Barinas Airport ',
    'BNT  - Bundi Airport ',
    'BNU  - Blumenau Airport ',
    'BNV  - Boana Airport ',
    'BNW  - Boone Municipal Airport ',
    'BNX  - Banja Luka International Airport ',
    'BNY  - Bellona  Anua Airport ',
    'BNZ  - Banz Airport ',
    'BOA  - Boma Airport ',
    'BOB  - Bora Bora Airport    Motu Mute Airport   ',
    'BOC  - Bocas del Toro Isla Colón International Airport ',
    'BOD  - Bordeaux–Mérignac Airport ',
    'BOE  - Boundji Airport ',
    'BOG  - El Dorado International Airport ',
    'BOH  - Bournemouth Airport ',
    'BOI  - Boise Air Terminal    Gowen Field   ',
    'BOJ  - Burgas Airport ',
    'BOK  - Brookings Airport ',
    'BOL  - Ballykelly Airfield    RAF Ballykelly   ',
    'BOM  - Chhatrapati Shivaji International Airport ',
    'BON  - Flamingo International Airport ',
    'BOO  - Bodø Airport ',
    'BOP  - Bouar Airport ',
    'BOQ  - Boku Airport ',
    'BOS  - Logan International Airport ',
    'BOT  - Bosset Airport ',
    'BOU  - Bourges Airport ',
    'BOV  - Boang Island Airport ',
    'BOW  - Bartow Municipal Airport ',
    'BOX  - Borroloola Airport ',
    'BOY  - Bobo Dioulasso Airport ',
    'BOZ  - Bozoum Airport ',
    'BPB  - Boridi Airport ',
    'BPC  - Bamenda Airport ',
    'BPD  - Bapi Airport ',
    'BPE  - Qinhuangdao Beidaihe Airport ',
    'BPF  - Batuna Airport ',
    'BPG  - Barra do Garças Airport ',
    'BPH  - Bislig Airport ',
    'BPI  - Miley Memorial Field ',
    'BPK  - Biangabip Airport ',
    'BPL  - Bole Alashankou Airport ',
    'BPM  - Begumpet Airport ',
    'BPN  - Sultan Aji Muhammad Sulaiman Airport ',
    'BPS  - Porto Seguro Airport ',
    'BPT  - Jack Brooks Regional Airport ',
    'BPX  - Qamdo Bamda Airport ',
    'BPY  - Besalampy Airport ',
    'BQA  - Dr  Juan C  Angara Airport ',
    'BQB  - Busselton Margaret River Airport ',
    'BQE  - Bubaque Airport ',
    'BQG  - Bogorodskoye Airport ',
    'BQH  - London Biggin Hill Airport ',
    'BQI  - Bagani Airport ',
    'BQJ  - Batagay Airport ',
    'BQK  - Brunswick Golden Isles Airport ',
    'BQL  - Boulia Airport ',
    'BQN  - Rafael Hernández Airport ',
    'BQO  - Tehini Airport ',
    'BQQ  - Barra Airport ',
    'BQS  - Ignatyevo Airport ',
    'BQT  - Brest Airport ',
    'BQU  - J  F  Mitchell Airport ',
    'BQV  - Bartlett Cove Seaplane Base ',
    'BQW  - Balgo Hill Airport ',
    'BRA  - Barreiras Airport ',
    'BRB  - Barreirinhas Airport ',
    'BRC  - San Carlos de Bariloche Airport ',
    'BRD  - Brainerd Lakes Regional Airport ',
    'BRE  - Bremen Airport ',
    'BRH  - Brahman Airport ',
    'BRI  - Bari Karol Wojtyła Airport ',
    'BRJ  - Bright Airport ',
    'BRK  - Bourke Airport ',
    'BRL  - Southeast Iowa Regional Airport ',
    'BRM  - Jacinto Lara International Airport ',
    'BRN  - Bern Airport ',
    'BRO  - Brownsville  South Padre Island International Airport ',
    'BRP  - Biaru Airport ',
    'BRQ  - Brno–Tuřany Airport ',
    'BRR  - Barra Airport ',
    'BRS  - Bristol Airport ',
    'BRT  - Bathurst Island Airport ',
    'BRU  - Brussels Airport    Zaventem Airport   ',
    'BRV  - Bremerhaven Airport ',
    'BRW  - Wiley Post–Will Rogers Memorial Airport ',
    'BRX  - María Montez International Airport ',
    'BRY  - Samuels Field ',
    'BSA  - Bender Qassim International Airport ',
    'BSB  - Brasília International Airport    Presidente J  Kubitschek Int l Airport   ',
    'BSC  - José Celestino Mutis Airport ',
    'BSD  - Baoshan Yunduan Airport ',
    'BSE  - Sematan Airport ',
    'BSF  - Bradshaw Army Airfield ',
    'BSG  - Bata Airport ',
    'BSI  - Balesin Airport ',
    'BSJ  - Bairnsdale Airport ',
    'BSK  - Biskra Airport ',
    'BSL  - EuroAirport Basel Mulhouse Freiburg ',
    'BSM  - Bishe Kola Air Base ',
    'BSN  - Bossangoa Airport ',
    'BSO  - Basco Airport ',
    'BSP  - Bensbach Airport ',
    'BSQ  - Bisbee Municipal Airport    FAA  P04   ',
    'BSR  - Basra International Airport ',
    'BSS  - Balsas Airport ',
    'BST  - Bost Airport ',
    'BSU  - Basankusu Airport ',
    'BSV  - Besakoa Airport ',
    'BSW  - Boswell Bay Airport    FAA  AK97   ',
    'BSX  - Pathein Airport ',
    'BSY  - Bardera Airport ',
    'BTA  - Bertoua Airport ',
    'BTB  - Bétou Airport ',
    'BTC  - Batticaloa International Airport ',
    'BTD  - Brunette Downs Airport ',
    'BTE  - Sherbro International Airport ',
    'BTF  - Skypark Airport ',
    'BTG  - Batangafo Airport ',
    'BTH  - Hang Nadim Airport ',
    'BTI  - Barter Island LRRS Airport ',
    'BTJ  - Sultan Iskandar Muda International Airport ',
    'BTK  - Bratsk Airport ',
    'BTL  - W  K  Kellogg Airport ',
    'BTM  - Bert Mooney Airport ',
    'BTN  - Marlboro County Jetport    FAA  BBP   ',
    'BTO  - Botopasi Airstrip ',
    'BTP  - Butler County Airport    K W  Scholter Field   ',
    'BTQ  - Butare Airport ',
    'BTR  - Baton Rouge Metropolitan Airport    Ryan Field   ',
    'BTS  - M  R  Štefánik Airport ',
    'BTT  - Bettles Airport ',
    'BTU  - Bintulu Airport ',
    'BTV  - Burlington International Airport ',
    'BTW  - Batu Licin Airport ',
    'BTX  - Betoota Airport ',
    'BTY  - Beatty Airport ',
    'BUA  - Buka Island Airport ',
    'BUB  - Cram Field ',
    'BUC  - Burketown Airport ',
    'BUD  - Budapest Ferenc Liszt International Airport ',
    'BUE  - metropolitan area5 ',
    'BUF  - Buffalo Niagara International Airport ',
    'BUG  - Benguela Airport    Gen  V  Deslandes Airport   ',
    'BUH  - Bucharest Baneasa6 ',
    'BUI  - Bokondini Airport ',
    'BUJ  - Bou Saada Airport ',
    'BUK  - Albuq Airport ',
    'BUL  - Bulolo Airport ',
    'BUM  - Butler Memorial Airport ',
    'BUN  - Gerardo Tobar López Airport ',
    'BUO  - Burao Airport ',
    'BUP  - Bathinda Airport    Bhisiana Air Force Station   ',
    'BUQ  - Joshua Mqabuko Nkomo International Airport ',
    'BUR  - Bob Hope Airport ',
    'BUS  - Batumi International Airport    Alexander Kartveli Batumi Int l Airport   ',
    'BUT  - Bathpalathang Airport ',
    'BUU  - Muara Bungo Airport ',
    'BUV  - Placeres Airport ',
    'BUW  - Betoambari Airport ',
    'BUX  - Bunia Airport ',
    'BUY  - Bunbury Airport ',
    'BUZ  - Bushehr Airport ',
    'BVA  - Beauvais–Tillé Airport ',
    'BVB  - Boa Vista International Airport ',
    'BVC  - Aristides Pereira International Airport    Rabil Airport   ',
    'BVE  - Brive–Souillac Airport ',
    'BVF  - Dama Airport ',
    'BVG  - Berlevåg Airport ',
    'BVH  - Vilhena Airport    Brigadeiro Camarão Airport   ',
    'BVI  - Birdsville Airport ',
    'BVJ  - Bovanenkovo Airport ',
    'BVK  - Huacaraje Airport ',
    'BVL  - Baures Airport ',
    'BVM  - Belmonte Airport ',
    'BVO  - Bartlesville Municipal Airport ',
    'BVP  - Bolovip Airport ',
    'BVR  - Esperadinha Airport ',
    'BVS  - Breves Airport ',
    'BVU  - Beluga Airport    FAA  BLG   ',
    'BVV  - Burevestnik Airport ',
    'BVW  - Batavia Downs Airport ',
    'BVX  - Batesville Regional Airport ',
    'BVY  - Beverly Municipal Airport ',
    'BVZ  - Beverley Springs Airport ',
    'BWA  - Gautam Buddha Airport ',
    'BWB  - Barrow Island Airport ',
    'BWC  - Brawley Municipal Airport ',
    'BWD  - Brownwood Regional Airport ',
    'BWE  - Braunschweig–Wolfsburg Airport ',
    'BWF  - Barrow  Walney Island Airport ',
    'BWG  - Bowling Green Warren County Regional Airport ',
    'BWH  - RMAF Butterworth ',
    'BWI  - Baltimore  Washington International Thurgood Marshall Airport ',
    'BWJ  - Bawan Airport ',
    'BWK  - Bol Airport    Brač Airport   ',
    'BWL  - Blackwell–Tonkawa Municipal Airport  1      FAA  BKN   ',
    'BWM  - Bowman Municipal Airport    FAA  BPP   ',
    'BWN  - Brunei International Airport ',
    'BWO  - Balakovo Airport ',
    'BWP  - Bewani Airport ',
    'BWQ  - Brewarrina Airport ',
    'BWT  - Burnie Airport ',
    'BWU  - Bankstown Airport ',
    'BWW  - Las Brujas Airport ',
    'BWX  - Banyuwangi Airport ',
    'BWY  - RAF Bentwaters ',
    'BXA  - George R  Carr Memorial Air Field ',
    'BXB  - Babo Airport ',
    'BXD  - Bade Airport ',
    'BXE  - Bakel Airport ',
    'BXF  - Bellburn Airstrip ',
    'BXG  - Bendigo Airport ',
    'BXH  - Balkhash Airport ',
    'BXI  - Boundiali Airport ',
    'BXJ  - Boraldai Airport ',
    'BXK  - Buckeye Municipal Airport ',
    'BXL  - Blue Lagoon Seaplane Base ',
    'BXM  - Batom Airport ',
    'BXN  - Bodrum Imsik Airport ',
    'BXO  - Buochs Airport ',
    'BXP  - Biała Podlaska Airport ',
    'BXR  - Bam Airport ',
    'BXS  - Borrego Valley Airport    FAA  L08   ',
    'BXT  - PT Badak Bontang Airport ',
    'BXU  - Bancasi Airport ',
    'BXV  - Breiðdalsvík Airport ',
    'BXW  - Bawean Airport ',
    'BXX  - Borama Airport ',
    'BXY  - Krayniy Airport ',
    'BXZ  - Bunsil Airport ',
    'BYA  - Boundary Airport ',
    'BYB  - Dibba Airport ',
    'BYC  - Yacuiba Airport ',
    'BYD  - Al Bayda Airport ',
    'BYF  - Albert – Picardie Airport ',
    'BYG  - Johnson County Airport ',
    'BYH  - Arkansas International Airport ',
    'BYI  - Burley Municipal Airport ',
    'BYJ  - Beja Airport ',
    'BYK  - Bouaké Airport ',
    'BYL  - Bella Yella Airport ',
    'BYM  - Carlos Manuel de Céspedes Airport ',
    'BYN  - Bayankhongor Airport ',
    'BYO  - Bonito Airport ',
    'BYP  - Barimunya Airport ',
    'BYQ  - Bunyu Airport ',
    'BYR  - Læsø Airport ',
    'BYS  - Bicycle Lake Army Airfield    Fort Irwin   ',
    'BYT  - Bantry Aerodrome ',
    'BYU  - Bindlacher Berg Airport ',
    'BYV  - Beira Lake Seaplane Base ',
    'BYW  - Blakely Island Airport    FAA  38WA   ',
    'BYX  - Baniyala Airport ',
    'BZA  - San Pedro Airport ',
    'BZB  - Bazaruto Island Airport ',
    'BZC  - Umberto Modiano Airport ',
    'BZD  - Balranald Airport ',
    'BZE  - Philip S  W  Goldson International Airport ',
    'BZF  - Benton Field ',
    'BZG  - Bydgoszcz Ignacy Jan Paderewski Airport ',
    'BZH  - Bumi Hills Airstrip ',
    'BZI  - Balıkesir Airport    Merkez Airport   ',
    'BZK  - Bryansk International Airport ',
    'BZL  - Barisal Airport ',
    'BZM  - Bemolanga Airport ',
    'BZN  - Bozeman Yellowstone International Airport    Gallatin Field   ',
    'BZO  - Bolzano Airport ',
    'BZP  - Bizant Airport ',
    'BZR  - Béziers Cap d Agde Airport ',
    'BZT  - Eagle Air Park ',
    'BZU  - Buta Zega Airport ',
    'BZV  - Maya Maya Airport ',
    'BZX  - Bazhong Enyang Airport ',
    'BZY  - Bălți International Airport ',
    'BZZ  - RAF Brize Norton ',
    'CAA  - El Aguacate Airport ',
    'CAB  - Cabinda Airport ',
    'CAC  - Cascavel Airport    Adalberto Mendes da Silva Airport   ',
    'CAD  - Wexford County Airport ',
    'CAE  - Columbia Metropolitan Airport ',
    'CAF  - Carauari Airport ',
    'CAG  - Cagliari Elmas Airport ',
    'CAH  - Cà Mau Airport ',
    'CAI  - Cairo International Airport ',
    'CAJ  - Canaima Airport ',
    'CAK  - Akron Canton Airport ',
    'CAL  - Campbeltown Airport      RAF Machrihanish ',
    'CAM  - Camiri Airport ',
    'CAN  - Guangzhou Baiyun International Airport ',
    'CAO  - Clayton Municipal Airpark ',
    'CAP  - Hugo Chávez International Airport ',
    'CAQ  - Juan H  White Airport ',
    'CAR  - Caribou Municipal Airport ',
    'CAS  - Anfa Airport ',
    'CAT  - Cascais Municipal Aerodrome ',
    'CAU  - Caruaru Airport    Oscar Laranjeiras Airport   ',
    'CAV  - Cazombo Airport ',
    'CAW  - Bartolomeu Lysandro Airport ',
    'CAX  - Carlisle Lake District Airport ',
    'CAY  - Cayenne – Félix Eboué Airport ',
    'CAZ  - Cobar Airport ',
    'CBB  - Jorge Wilstermann International Airport ',
    'CBC  - Cherrabun Airport ',
    'CBD  - Car Nicobar Air Force Base ',
    'CBE  - Greater Cumberland Regional Airport ',
    'CBF  - Council Bluffs Municipal Airport ',
    'CBG  - Cambridge Airport ',
    'CBH  - Boudghene Ben Ali Lotfi Airport ',
    'CBI  - Cape Barren Island Airport ',
    'CBJ  - Cabo Rojo Airport ',
    'CBK  - Colby Municipal Airport    Shalz Field   ',
    'CBL  - Tomás de Heres Airport ',
    'CBM  - Columbus Air Force Base ',
    'CBN  - Penggung Airport    Cakrabuwana Airport   ',
    'CBO  - Awang Airport ',
    'CBP  - Coimbra Airport ',
    'CBQ  - Margaret Ekpo International Airport ',
    'CBR  - Canberra Airport ',
    'CBS  - Oro Negro Airport ',
    'CBT  - Catumbela Airport ',
    'CBU  - Cottbus Drewitz Airport ',
    'CBV  - Cobán Airport ',
    'CBW  - Campo Mourão Airport ',
    'CBX  - Condobolin Airport ',
    'CBY  - Canobie Airport ',
    'CCA  - Chimore Airport ',
    'CCB  - Cable Airport ',
    'CCC  - Jardines del Rey Airport ',
    'CCF  - Carcassonne Airport ',
    'CCG  - Crane County Airport    FAA  E13   ',
    'CCH  - Chile Chico Airfield ',
    'CCI  - Concórdia Airport ',
    'CCJ  - Calicut International Airport ',
    'CCK  - Cocos   Keeling   Islands Airport ',
    'CCL  - Chinchilla Airport ',
    'CCM  - Diomício Freitas Airport ',
    'CCN  - Chaghcharan Airport ',
    'CCO  - Carimagua Airport ',
    'CCP  - Carriel Sur International Airport ',
    'CCR  - Buchanan Field Airport ',
    'CCS  - Simón Bolívar International Airport ',
    'CCT  - Colonia Catriel Airport ',
    'CCU  - Netaji Subhas Chandra Bose International Airport ',
    'CCV  - Craig Cove Airport ',
    'CCW  - Cowell Airport ',
    'CCX  - Cáceres Airport ',
    'CCY  - Northeast Iowa Regional Airport ',
    'CCZ  - Chub Cay International Airport ',
    'CDA  - Cooinda Airport ',
    'CDB  - Cold Bay Airport ',
    'CDC  - Cedar City Regional Airport ',
    'CDD  - Cauquira Airport ',
    'CDE  - Chengde Puning Airport ',
    'CDG  - Charles de Gaulle Airport    Roissy Airport   ',
    'CDH  - Harrell Field ',
    'CDI  - Cachoeiro de Itapemirim Airport    Raimundo de Andrade Airport   ',
    'CDJ  - Conceição do Araguaia Airport ',
    'CDK  - George T  Lewis Airport ',
    'CDL  - Candle 2 Airport    FAA  AK75   ',
    'CDN  - Woodward Field ',
    'CDO  - Cradock Airport ',
    'CDP  - Kadapa Airport ',
    'CDQ  - Croydon Airport ',
    'CDR  - Chadron Municipal Airport ',
    'CDS  - Childress Municipal Airport ',
    'CDT  - Castellón–Costa Azahar Airport ',
    'CDU  - Camden Airport ',
    'CDV  - Merle K    Mudhole   Smith Airport ',
    'CDW  - Essex County Airport ',
    'CDY  - Cagayan de Sulu Airport ',
    'CEA  - Cessna Aircraft Field ',
    'CEB  - Mactan–Cebu International Airport ',
    'CEC  - Del Norte County Airport    Jack McNamara Field   ',
    'CED  - Ceduna Airport ',
    'CEE  - Cherepovets Airport ',
    'CEF  - Westover Metropolitan Airport      Westover Air Reserve Base ',
    'CEG  - Hawarden Airport    Chester Airport   ',
    'CEH  - Chelinda Airport ',
    'CEI  - Chiang Rai International Airport    Mae Fah Luang Int l   ',
    'CEK  - Chelyabinsk Airport ',
    'CEL  - Canela Airport ',
    'CEM  - Central Airport ',
    'CEN  - Ciudad Obregón International Airport ',
    'CEO  - Waco Kungo Airport ',
    'CEP  - Concepción Airport ',
    'CEQ  - Cannes – Mandelieu Airport ',
    'CER  - Cherbourg – Maupertus Airport ',
    'CES  - Cessnock Airport ',
    'CET  - Cholet Le Pontreau Airport ',
    'CEU  - Oconee County Regional Airport ',
    'CEV  - Mettel Field ',
    'CEW  - Bob Sikes Airport ',
    'CEX  - Chena Hot Springs Airport    FAA  AK13   ',
    'CEY  - Murray Calloway County Airport ',
    'CEZ  - Cortez Municipal Airport ',
    'CFB  - Cabo Frio International Airport ',
    'CFC  - Caçador Airport ',
    'CFD  - Coulter Field ',
    'CFE  - Clermont Ferrand Auvergne Airport ',
    'CFF  - Cafunfo Airport ',
    'CFG  - Jaime González Airport ',
    'CFH  - Clifton Hills Airport ',
    'CFI  - Camfield Airport ',
    'CFK  - Chlef International Airport ',
    'CFM  - Conklin   Leismer   Airport ',
    'CFN  - Donegal Airport ',
    'CFO  - Confresa Airport ',
    'CFP  - Carpentaria Downs Airport ',
    'CFQ  - Creston Aerodrome  1      TC  CAJ3   ',
    'CFR  - Caen – Carpiquet Airport ',
    'CFS  - Coffs Harbour Airport ',
    'CFT  - Greenlee County Airport ',
    'CFU  - Corfu International Airport    Ioannis Kapodistrias Int l Airport   ',
    'CFV  - Coffeyville Municipal Airport ',
    'CGA  - Craig Seaplane Base ',
    'CGB  - Marechal Rondon International Airport ',
    'CGC  - Cape Gloucester Airport ',
    'CGD  - Changde Taohuayuan Airport ',
    'CGE  - Cambridge–Dorchester Airport ',
    'CGF  - Cuyahoga County Airport ',
    'CGG  - Casiguran Airport ',
    'CGH  - São Paulo  Congonhas–Deputado Freitas Nobre Airport ',
    'CGI  - Cape Girardeau Regional Airport ',
    'CGJ  - Kasompe Airport ',
    'CGK  - Soekarno–Hatta International Airport ',
    'CGM  - Camiguin Airport ',
    'CGN  - Cologne Bonn Airport ',
    'CGO  - Zhengzhou Xinzheng International Airport ',
    'CGP  - Shah Amanat International Airport ',
    'CGQ  - Changchun Longjia International Airport ',
    'CGR  - Campo Grande International Airport ',
    'CGS  - College Park Airport ',
    'CGT  - Chinguetti Airport ',
    'CGV  - Caiguna Airport ',
    'CGY  - Laguindingan Airport ',
    'CGZ  - Casa Grande Municipal Airport ',
    'CHA  - Chattanooga Metropolitan Airport    Lovell Field   ',
    'CHB  - Chilas Airport ',
    'CHC  - Christchurch International Airport ',
    'CHF  - Jinhae Airport ',
    'CHG  - Chaoyang Airport ',
    'CHH  - Chachapoyas Airport ',
    'CHI  - metropolitan area2 ',
    'CHJ  - Chipinge Airport ',
    'CHK  - Chickasha Municipal Airport ',
    'CHL  - Challis Airport    FAA  LLJ   ',
    'CHM  - Tnte  FAP Jaime Montreuil Morales Airport ',
    'CHN  - Jeonju Airport ',
    'CHO  - Charlottesville–Albemarle Airport ',
    'CHP  - Circle Hot Springs Airport ',
    'CHQ  - Chania International Airport    Ioannis Daskalogiannis   ',
    'CHR  - Châteauroux Centre Marcel Dassault Airport ',
    'CHS  - Charleston International Airport      Charleston Air Force Base ',
    'CHT  - Chatham Islands    Tuuta Airport ',
    'CHU  - Chuathbaluk Airport    FAA  9A3   ',
    'CHV  - Chaves Airport ',
    'CHX  - Changuinola Capitán Manuel Niño International Airport ',
    'CHY  - Choiseul Bay Airport ',
    'CHZ  - Chiloquin State Airport    FAA  2S7   ',
    'CIA  - Ciampino–G  B  Pastine International Airport ',
    'CIC  - Chico Municipal Airport ',
    'CID  - The Eastern Iowa Airport ',
    'CIE  - Collie Airport ',
    'CIF  - Chifeng Yulong Airport ',
    'CIG  - Craig–Moffat Airport    FAA  CAG   ',
    'CIH  - Changzhi Wangcun Airport ',
    'CII  - Aydın Airport  1      Çıldır Airport   ',
    'CIJ  - Captain Aníbal Arab Airport ',
    'CIK  - Chalkyitsik Airport ',
    'CIL  - Council Airport    FAA  K29   ',
    'CIM  - Cimitarra Airport ',
    'CIN  - Arthur N  Neu Airport ',
    'CIO  - Teniente Coronel Carmelo Peralta Airport ',
    'CIP  - Chipata Airport ',
    'CIQ  - Chiquimula Airport ',
    'CIR  - Cairo Regional Airport ',
    'CIS  - Canton Island Airport ',
    'CIT  - Shymkent International Airport ',
    'CIU  - Chippewa County International Airport ',
    'CIW  - Canouan Airport ',
    'CIX  - FAP Captain José Abelardo Quiñones González International Airport ',
    'CIY  - Comiso Airport ',
    'CIZ  - Coari Airport ',
    'CJA  - Mayor General FAP Armando Revoredo Iglesias Airport ',
    'CJB  - Coimbatore International Airport ',
    'CJC  - El Loa Airport ',
    'CJD  - Candilejas Airport ',
    'CJF  - Coondewanna Airport ',
    'CJH  - Chilko Lake   Tsylos Park Lodge   Aerodrome    TC  CAG3   ',
    'CJJ  - Cheongju International Airport ',
    'CJL  - Chitral Airport ',
    'CJM  - Chumphon Airport ',
    'CJN  - Cijulang Nusawiru Airport ',
    'CJS  - Abraham González International Airport ',
    'CJT  - Copalar Airport ',
    'CJU  - Jeju International Airport ',
    'CKA  - Kegelman Air Force Auxiliary Field ',
    'CKB  - North Central West Virginia Airport ',
    'CKC  - Cherkasy International Airport ',
    'CKD  - Crooked Creek Airport    FAA  CJX   ',
    'CKE  - Lampson Field  1      FAA  1O2   ',
    'CKG  - Chongqing Jiangbei International Airport ',
    'CKH  - Chokurdakh Airport ',
    'CKI  - Croker Island Airport ',
    'CKK  - Sharp County Regional Airport  1      FAA  CVK   ',
    'CKL  - Chkalovsky Airport ',
    'CKM  - Fletcher Field ',
    'CKN  - Crookston Municipal Airport    Kirkwood Field   ',
    'CKO  - Cornélio Procópio Airport ',
    'CKR  - Crane Island Airstrip ',
    'CKS  - Carajás Airport ',
    'CKT  - Sarakhs Airport ',
    'CKU  - Cordova Municipal Airport ',
    'CKV  - Clarksville–Montgomery County Regional Airport    Outlaw Field   ',
    'CKW  - Graeme Rowley Aerodrome ',
    'CKX  - Chicken Airport ',
    'CKY  - Conakry International Airport    Gbessia Int l Airport   ',
    'CKZ  - Çanakkale Airport ',
    'CLA  - Comilla Airport ',
    'CLD  - McClellan–Palomar Airport    FAA  CRQ   ',
    'CLE  - Cleveland Hopkins International Airport ',
    'CLG  - New Coalinga Municipal Airport    FAA  C80   ',
    'CLH  - Coolah Airport ',
    'CLI  - Clintonville Municipal Airport ',
    'CLJ  - Cluj Napoca International Airport ',
    'CLK  - Clinton Regional Airport ',
    'CLL  - Easterwood Airport    Easterwood Field   ',
    'CLM  - William R  Fairchild International Airport ',
    'CLN  - Carolina Airport ',
    'CLO  - Alfonso Bonilla Aragón International Airport ',
    'CLP  - Clarks Point Airport ',
    'CLQ  - Licenciado Miguel de la Madrid Airport ',
    'CLR  - Cliff Hatfield Memorial Airport ',
    'CLS  - Chehalis Centralia Airport ',
    'CLT  - Charlotte Douglas International Airport ',
    'CLU  - Columbus Municipal Airport    FAA  BAK   ',
    'CLV  - Caldas Novas Airport ',
    'CLW  - Clearwater Air Park ',
    'CLX  - Clorinda Airport ',
    'CLY  - Calvi – Sainte Catherine Airport ',
    'CLZ  - Calabozo Airport ',
    'CMA  - Cunnamulla Airport ',
    'CMB  - Bandaranaike International Airport ',
    'CMC  - Camocim Airport ',
    'CMD  - Cootamundra Airport ',
    'CME  - Ciudad del Carmen International Airport ',
    'CMF  - Chambéry Savoie Airport ',
    'CMG  - Corumbá International Airport ',
    'CMH  - John Glenn Columbus International Airport ',
    'CMI  - University of Illinois Willard Airport ',
    'CMJ  - Qimei Airport ',
    'CMK  - Club Makokola Airport ',
    'CML  - Camooweal Airport ',
    'CMM  - Carmelita Airport ',
    'CMN  - Mohammed V International Airport ',
    'CMO  - Obbia Airport ',
    'CMP  - Santana do Araguaia Airport ',
    'CMQ  - Clermont Airport ',
    'CMR  - Colmar – Houssen Airport ',
    'CMS  - Iskushuban Airport ',
    'CMT  - Cameta Airport ',
    'CMU  - Chimbu Airport ',
    'CMV  - Coromandel Aerodrome ',
    'CMW  - Ignacio Agramonte International Airport ',
    'CMX  - Houghton County Memorial Airport ',
    'CMY  - Sparta  Fort McCoy Airport ',
    'CMZ  - Caia Airport ',
    'CNA  - Cananea National Airport ',
    'CNB  - Coonamble Airport ',
    'CNC  - Coconut Island Airport ',
    'CND  - Mihail Kogălniceanu International Airport ',
    'CNE  - Fremont County Airport    FAA  1V6   ',
    'CNF  - Tancredo Neves International Airport    Confins Int l Airport   ',
    'CNG  - Cognac – Châteaubernard Air Base ',
    'CNH  - Claremont Municipal Airport ',
    'CNI  - Changhai Airport ',
    'CNJ  - Cloncurry Airport ',
    'CNK  - Blosser Municipal Airport ',
    'CNL  - Sindal Airport ',
    'CNM  - Cavern City Air Terminal ',
    'CNN  - Kannur International Airport ',
    'CNO  - Chino Airport ',
    'CNP  - Nerlerit Inaat Airport    Constable Pynt Airport   ',
    'CNQ  - Doctor Fernando Piragine Niveyro International Airport ',
    'CNR  - Chañaral Airport ',
    'CNS  - Cairns Airport ',
    'CNT  - Charata Airport ',
    'CNU  - Chanute Martin Johnson Airport ',
    'CNV  - Canavieiras Airport ',
    'CNW  - TSTC Waco Airport ',
    'CNX  - Chiang Mai International Airport ',
    'CNY  - Canyonlands Field ',
    'CNZ  - Cangamba Airport ',
    'COA  - Columbia Airport    FAA  O22   ',
    'COB  - Coolibah Airport ',
    'COC  - Comodoro Pierrestegui Airport ',
    'COD  - Yellowstone Regional Airport ',
    'COE  - Coeur d Alene Airport ',
    'COF  - Patrick Space Force Base ',
    'COG  - Mandinga Airport ',
    'COH  - Cooch Behar Airport ',
    'COI  - Merritt Island Airport ',
    'COJ  - Coonabarabran Airport ',
    'COK  - Cochin International Airport    Nedumbassery Airport   ',
    'COL  - Coll Airport ',
    'COM  - Coleman Municipal Airport ',
    'CON  - Concord Municipal Airport ',
    'COO  - Cadjehoun Airport ',
    'COP  - Cooperstown Westville Airport    FAA  K23   ',
    'COQ  - Choibalsan Airport ',
    'COR  - Ingeniero Aeronáutico Ambrosio L V  Taravella International Airport    Pajas Blancas   ',
    'COS  - Colorado Springs Airport ',
    'COT  - Cotulla–La Salle County Airport ',
    'COU  - Columbia Regional Airport ',
    'COW  - Coquimbo Airport ',
    'COY  - Coolawanyah Station Airport ',
    'COZ  - Constanza Airport ',
    'CPA  - Cape Palmas Airport ',
    'CPB  - Capurganá Airport ',
    'CPC  - Aviador Carlos Campos Airport ',
    'CPD  - Coober Pedy Airport ',
    'CPE  - Ing  Alberto Acuña Ongay International Airport ',
    'CPF  - Ngloram Airport ',
    'CPG  - Carmen de Patagones Airport ',
    'CPH  - Copenhagen Airport, Kastrup ',
    'CPI  - Cape Orford Airport ',
    'CPL  - General Navas Pardo Airport ',
    'CPM  - Compton  Woodley Airport ',
    'CPN  - Cape Rodney Airport ',
    'CPO  - Desierto de Atacama Airport ',
    'CPP  - Coposa Airport ',
    'CPR  - Casper–Natrona County International Airport ',
    'CPS  - St  Louis Downtown Airport ',
    'CPT  - Cape Town International Airport ',
    'CPU  - Cururupu Airport ',
    'CPV  - Campina Grande Airport    Presidente João Suassuna Airport   ',
    'CPX  - Benjamín Rivera Noriega Airport ',
    'CQA  - Canarana Airport ',
    'CQD  - Shahrekord Airport ',
    'CQF  - Calais–Dunkerque Airport ',
    'CQM  - Ciudad Real Central Airport ',
    'CQP  - Cape Flattery Airport ',
    'CQS  - Costa Marques Airport ',
    'CQT  - Caquetania Airport ',
    'CQW  - Wulong Xiannüshan Airport ',
    'CRA  - Craiova International Airport ',
    'CRB  - Collarenebri Airport ',
    'CRC  - Santa Ana Airport ',
    'CRD  - General Enrique Mosconi International Airport ',
    'CRE  - Grand Strand Airport ',
    'CRF  - Carnot Airport ',
    'CRG  - Jacksonville Executive at Craig Airport ',
    'CRH  - Cherrabah Airport ',
    'CRI  - Colonel Hill Airport    Crooked Island Airport   ',
    'CRJ  - Coorabie Airport ',
    'CRK  - Clark International Airport ',
    'CRL  - Brussels South Charleroi Airport ',
    'CRM  - Catarman National Airport ',
    'CRO  - Corcoran Airport ',
    'CRP  - Corpus Christi International Airport ',
    'CRQ  - Caravelas Airport ',
    'CRR  - Ceres Airport ',
    'CRS  - C  David Campbell Field    Corsicana Municipal Airport   ',
    'CRT  - Z  M  Jack Stell Field ',
    'CRU  - Lauriston Airport    Carriacou Island Airport   ',
    'CRV  - Crotone Airport    Sant Anna Airport   ',
    'CRW  - Yeager Airport ',
    'CRX  - Roscoe Turner Airport ',
    'CRY  - Carlton Hill Airport ',
    'CRZ  - Turkmenabat Airport ',
    'CSA  - Colonsay Airport ',
    'CSB  - Caransebeș Airport ',
    'CSC  - Cañas Mojica Airport ',
    'CSD  - Cresswell Downs Airport ',
    'CSE  - Buckhorn Ranch Airport    FAA  0CO2   ',
    'CSF  - Creil Air Base ',
    'CSG  - Columbus Airport ',
    'CSH  - Solovki Airport ',
    'CSI  - Casino Airport ',
    'CSK  - Cap Skirring Airport ',
    'CSM  - Clinton Sherman Airport ',
    'CSN  - Carson Airport    FAA  CXP   ',
    'CSO  - Magdeburg–Cochstedt Airport ',
    'CSQ  - Creston Municipal Airport ',
    'CSR  - Casuarito Airport ',
    'CSS  - Cassilândia Airport ',
    'CST  - Castaway Island Seaplane Base ',
    'CSU  - Santa Cruz do Sul Airport ',
    'CSV  - Crossville Memorial Whitson Field ',
    'CSX  - Changsha Huanghua International Airport ',
    'CSY  - Cheboksary Airport ',
    'CSZ  - Brigadier Hector Eduardo Ruiz Airport ',
    'CTA  - Catania–Fontanarossa Airport ',
    'CTB  - Cut Bank Municipal Airport ',
    'CTC  - Coronel Felipe Varela International Airport ',
    'CTD  - Chitré Alonso Valderrama Airport ',
    'CTE  - Cartí Airport ',
    'CTF  - Coatepeque Airport ',
    'CTG  - Rafael Núñez International Airport ',
    'CTH  - Chester County G  O  Carlson Airport    FAA  MQS   ',
    'CTI  - Cuito Cuanavale Airport ',
    'CTK  - Canton Municipal Airport    FAA  7G9   ',
    'CTL  - Charleville Airport ',
    'CTM  - Chetumal International Airport ',
    'CTN  - Cooktown Airport ',
    'CTO  - Calverton Executive Airpark    FAA  3C8   ',
    'CTP  - Carutapera Airport ',
    'CTQ  - Do Palmar Airport ',
    'CTR  - Cattle Creek Airport ',
    'CTS  - New Chitose Airport ',
    'CTT  - Le Castellet Airport ',
    'CTU  - Chengdu Shuangliu International Airport ',
    'CTW  - Cottonwood Airport    FAA  P52   ',
    'CTX  - Cortland County Airport    Chase Field, FAA  N03   ',
    'CTY  - Cross City Airport ',
    'CTZ  - Clinton–Sampson County Airport ',
    'CUA  - Ciudad Constitución Airport ',
    'CUB  - Jim Hamilton–L B  Owens Airport ',
    'CUC  - Camilo Daza International Airport ',
    'CUD  - Caloundra Airport ',
    'CUE  - Mariscal Lamar International Airport ',
    'CUF  - Cuneo International Airport ',
    'CUG  - Cudal Airport ',
    'CUH  - Cushing Municipal Airport ',
    'CUI  - Curillo Airport ',
    'CUJ  - Culion Airport ',
    'CUK  - Caye Caulker Airport ',
    'CUL  - Bachigualato Federal International Airport ',
    'CUM  - Antonio José de Sucre Airport ',
    'CUN  - Cancún International Airport ',
    'CUO  - Caruru Airport ',
    'CUP  - General José Francisco Bermúdez Airport ',
    'CUQ  - Coen Airport ',
    'CUR  - Curaçao International Airport    Hato Int l Airport   ',
    'CUS  - Columbus Municipal Airport    FAA  0NM0   ',
    'CUT  - Cutral Có Airport ',
    'CUU  - General Roberto Fierro Villalobos International Airport ',
    'CUV  - Casigua El Cubo Airport ',
    'CUX  - Cuddihy Field    FAA  07TE   ',
    'CUY  - Cue Airport ',
    'CUZ  - Alejandro Velasco Astete International Airport ',
    'CVB  - Chungrebu Airport ',
    'CVC  - Cleve Airport ',
    'CVE  - Coveñas Airport ',
    'CVF  - Courchevel Altiport ',
    'CVG  - Cincinnati  Northern Kentucky International Airport ',
    'CVH  - Caviahue Airport ',
    'CVI  - Caleta Olivia Airport ',
    'CVJ  - General Mariano Matamoros Airport ',
    'CVL  - Cape Vogel Airport ',
    'CVM  - General Pedro J  Méndez International Airport ',
    'CVN  - Clovis Municipal Airport ',
    'CVO  - Corvallis Municipal Airport ',
    'CVQ  - Carnarvon Airport ',
    'CVS  - Cannon Air Force Base ',
    'CVT  - Coventry Airport ',
    'CVU  - Corvo Airport ',
    'CWA  - Central Wisconsin Airport ',
    'CWB  - Afonso Pena International Airport ',
    'CWC  - Chernivtsi International Airport ',
    'CWF  - Chennault International Airport ',
    'CWI  - Clinton Municipal Airport ',
    'CWJ  - Cangyuan Washan Airport ',
    'CWL  - Cardiff Airport ',
    'CWR  - Cowarie Airport ',
    'CWS  - Center Island Airport    FAA  78WA   ',
    'CWT  - Cowra Airport ',
    'CWW  - Corowa Airport ',
    'CWX  - Cochise County Airport  1      FAA  P33   ',
    'CXA  - Caicara del Orinoco Airport ',
    'CXB  - Cox s Bazar Airport ',
    'CXC  - Chitina Airport ',
    'CXF  - Coldfoot Airport ',
    'CXH  - Vancouver Harbour Flight Centre    Coal Harbour Seaplane Base   ',
    'CXI  - Cassidy International Airport ',
    'CXJ  - Caxias do Sul Airport    Hugo Cantergiani Regional Airport   ',
    'CXL  - Calexico International Airport ',
    'CXN  - Candala Airport ',
    'CXO  - Lone Star Executive Airport ',
    'CXP  - Tunggul Wulung Airport ',
    'CXQ  - Christmas Creek Airport ',
    'CXR  - Cam Ranh International Airport ',
    'CXT  - Charters Towers Airport ',
    'CXY  - Cat Cay Airport ',
    'CYA  - Antoine Simon Airport ',
    'CYB  - Charles Kirkconnell International Airport ',
    'CYC  - Caye Chapel Airport ',
    'CYD  - San Ignacio Town Airstrip ',
    'CYF  - Chefornak Airport    FAA  CFK   ',
    'CYG  - Corryong Airport ',
    'CYI  - Chiayi Airport ',
    'CYL  - Coyoles Airport ',
    'CYM  - Chatham Seaplane Base ',
    'CYO  - Vilo Acuña Airport ',
    'CYP  - Calbayog Airport ',
    'CYR  - Colonia Airport    Laguna de los Patos Intn l Airport   ',
    'CYS  - Cheyenne Regional Airport    Jerry Olson Field   ',
    'CYT  - Yakataga Airport ',
    'CYU  - Cuyo Airport ',
    'CYW  - Captain Rogelio Castillo National Airport ',
    'CYX  - Chersky Airport ',
    'CYZ  - Cauayan Airport ',
    'CZA  - Chichen Itza International Airport ',
    'CZB  - Carlos Ruhl Airport ',
    'CZC  - Copper Center 2 Airport    FAA  Z93   ',
    'CZE  - José Leonardo Chirino Airport ',
    'CZF  - Cape Romanzof LRRS Airport ',
    'CZH  - Corozal Airport ',
    'CZJ  - Corazón de Jesús Airport ',
    'CZK  - Cascade Locks State Airport ',
    'CZL  - Mohamed Boudiaf International Airport ',
    'CZM  - Cozumel International Airport ',
    'CZN  - Chisana Airport ',
    'CZO  - Chistochina Airport ',
    'CZP  - Cape Pole Seaplane Base    FAA  Z71   ',
    'CZS  - Cruzeiro do Sul International Airport ',
    'CZT  - Dimmit County Airport ',
    'CZU  - Las Brujas Airport ',
    'CZW  - Częstochowa Rudniki Airport ',
    'CZX  - Changzhou Benniu Airport ',
    'CZY  - Cluny Airport ',
    'DAA  - Davison Army Airfield ',
    'DAB  - Daytona Beach International Airport ',
    'DAC  - Shahjalal International Airport ',
    'DAD  - Da Nang International Airport ',
    'DAF  - Daup Airport ',
    'DAG  - Barstow Daggett Airport ',
    'DAH  - Dathina Airport ',
    'DAK  - Dakhla Oasis Airport ',
    'DAL  - Dallas Love Field ',
    'DAM  - Damascus International Airport ',
    'DAN  - Danville Regional Airport ',
    'DAO  - Dabo Airport ',
    'DAP  - Darchula Airport ',
    'DAR  - Julius Nyerere International Airport ',
    'DAS  - Great Bear Lake Airport  1      TC  CFF4   ',
    'DAT  - Datong Yungang Airport ',
    'DAU  - Daru Airport ',
    'DAV  - Enrique Malek International Airport ',
    'DAX  - Dazhou Heshi Airport ',
    'DAY  - Dayton International Airport ',
    'DAZ  - Darwaz Airport ',
    'DBA  - Dalbandin Airport ',
    'DBB  - Al Alamain International Airport ',
    'DBC  - Baicheng Chang an Airport ',
    'DBD  - Dhanbad Airport ',
    'DBK  - Dutch Bay Seaplane Base ',
    'DBM  - Debre Marqos Airport ',
    'DBN  - W  H   Bud  Barron Airport ',
    'DBO  - Dubbo City Regional Airport ',
    'DBP  - Debepare Airport ',
    'DBQ  - Dubuque Regional Airport ',
    'DBR  - Darbhanga Airport ',
    'DBS  - Dubois Municipal Airport    FAA  U41   ',
    'DBT  - Debre Tabor Airport ',
    'DBU  - Dambulu Oya Tank Seaplane Base ',
    'DBV  - Dubrovnik Airport ',
    'DBY  - Dalby Airport ',
    'DCA  - Ronald Reagan Washington National Airport ',
    'DCF  - Canefield Airport ',
    'DCG  - Dubai Creek Seaplane Base ',
    'DCI  - Decimomannu Air Base ',
    'DCK  - Dahl Creek Airport ',
    'DCM  - Castres–Mazamet Airport ',
    'DCN  - RAAF Base Curtin ',
    'DCT  - Duncan Town Airport ',
    'DCU  - Pryor Field Regional Airport ',
    'DCY  - Daocheng Yading Airport ',
    'DDC  - Dodge City Regional Airport ',
    'DDD  - Dhaalu Airport ',
    'DDG  - Dandong Langtou Airport ',
    'DDM  - Dodoima Airport ',
    'DDN  - Delta Downs Airport ',
    'DDU  - Dadu Airport ',
    'DEA  - Dera Ghazi Khan International Airport ',
    'DEB  - Debrecen International Airport ',
    'DEC  - Decatur Airport ',
    'DED  - Jolly Grant Airport ',
    'DEE  - Yuzhno Kurilsk Mendeleyevo Airport ',
    'DEF  - Dezful Airport ',
    'DEH  - Decorah Municipal Airport ',
    'DEI  - Denis Island Airport ',
    'DEL  - Indira Gandhi International Airport ',
    'DEM  - Dembidolo Airport ',
    'DEN  - Denver International Airport ',
    'DEP  - Daporijo Airport ',
    'DEQ  - Deqing Moganshan Airport ',
    'DER  - Derim Airport ',
    'DES  - Desroches Airport ',
    'DET  - Coleman A  Young International Airport ',
    'DEX  - Nop Goliat Dekai Airport ',
    'DEZ  - Deir ez Zor Airport ',
    'DFI  - Defiance Memorial Airport ',
    'DFP  - Drumduff Airport ',
    'DFW  - Dallas  Fort Worth International Airport ',
    'DGA  - Dangriga Airport ',
    'DGC  - Degeh Bur Airport ',
    'DGD  - Dalgaranga Airport ',
    'DGE  - Mudgee Airport ',
    'DGF  - Douglas Lake Airport ',
    'DGH  - Deoghar Airport  3   ',
    'DGK  - Dugong Beach Lodge Airstrip ',
    'DGL  - Douglas Municipal Airport ',
    'DGM  - Dandugama Seaplane Base ',
    'DGN  - Naval Surface Warfare Center Dahlgren Division    FAA  NDY   ',
    'DGO  - General Guadalupe Victoria International Airport ',
    'DGP  - Daugavpils International Airport ',
    'DGR  - Dargaville Aerodrome ',
    'DGT  - Sibulan Airport    Dumaguete Airport   ',
    'DGU  - Dédougou Airport ',
    'DGW  - Converse County Airport ',
    'DHA  - King Abdulaziz Air Base ',
    'DHB  - Deer Harbor Sea Plane Base ',
    'DHD  - Durham Downs Airport ',
    'DHF  - Al Dhafra Air Base ',
    'DHI  - Dhangadhi Airport ',
    'DHL  - Dhala Airport ',
    'DHM  - Gaggal Airport ',
    'DHN  - Dothan Regional Airport ',
    'DHR  - De Kooy Airfield ',
    'DHT  - Dalhart Municipal Airport ',
    'DIA  - Doha International Airport ',
    'DIB  - Dibrugarh Airport    Mohanbari Airport   ',
    'DIE  - Arrachart Airport ',
    'DIG  - Diqing Shangri La Airport ',
    'DIJ  - Dijon Air Base ',
    'DIK  - Dickinson Theodore Roosevelt Regional Airport ',
    'DIL  - Presidente Nicolau Lobato International Airport ',
    'DIM  - Dimbokro Airport ',
    'DIN  - Dien Bien Phu Airport ',
    'DIO  - Diomede Heliport ',
    'DIP  - Diapaga Airport ',
    'DIQ  - Divinópolis Airport    Brigadeiro Cabral Airport   ',
    'DIR  - Aba Tenna Dejazmach Yilma International Airport ',
    'DIS  - Dolisie Airport ',
    'DIU  - Diu Airport ',
    'DIV  - Divo Airport ',
    'DIW  - Mawella Lagoon Seaplane Base ',
    'DIY  - Diyarbakır Airport ',
    'DJA  - Djougou Airport ',
    'DJB  - Sultan Thaha Airport ',
    'DJE  - Djerba–Zarzis International Airport ',
    'DJG  - Tiska Djanet Airport ',
    'DJH  - Jebel Ali Seaplane Base ',
    'DJJ  - Sentani Airport ',
    'DJM  - Djambala Airport ',
    'DJN  - Delta Junction Airport    FAA  D66   ',
    'DJO  - Daloa Airport ',
    'DJU  - Djúpivogur Airport ',
    'DKA  - Katsina Airport ',
    'DKI  - Dunk Island Airport ',
    'DKK  - Chautauqua County  Dunkirk Airport ',
    'DKR  - Léopold Sédar Senghor International Airport ',
    'DKS  - Dikson Airport ',
    'DKV  - Docker River Airport ',
    'DLA  - Douala International Airport ',
    'DLB  - Dalbertis Airport ',
    'DLC  - Dalian Zhoushuizi International Airport ',
    'DLE  - Dole–Jura Airport ',
    'DLF  - Laughlin Air Force Base ',
    'DLG  - Dillingham Airport ',
    'DLH  - Duluth International Airport ',
    'DLI  - Lien Khuong Airport ',
    'DLK  - Dulkaninna Airport ',
    'DLL  - Dillon County Airport    FAA  DLC   ',
    'DLM  - Dalaman Airport ',
    'DLN  - Dillon Airport ',
    'DLS  - Columbia Gorge Regional Airport    The Dalles Municipal Airport   ',
    'DLU  - Dali Airport ',
    'DLV  - Delissaville Airport ',
    'DLY  - Dillon s Bay Airport ',
    'DLZ  - Dalanzadgad Airport ',
    'DMA  - Davis–Monthan Air Force Base ',
    'DMB  - Taraz Airport    Jambyl Airport   ',
    'DMD  - Doomadgee Airport ',
    'DME  - Domodedovo International Airport ',
    'DMK  - Don Mueang International Airport ',
    'DMM  - King Fahd International Airport ',
    'DMN  - Deming Municipal Airport ',
    'DMO  - Sedalia Regional Airport ',
    'DMR  - Dhamar Airport ',
    'DMT  - Diamantino Airport ',
    'DMU  - Dimapur Airport ',
    'DNA  - Kadena Air Base ',
    'DNB  - Dunbar Airport ',
    'DND  - Dundee Airport ',
    'DNF  - Martuba Air Base ',
    'DNG  - Doongan Airport ',
    'DNH  - Dunhuang Airport ',
    'DNI  - Wad Medani Airport ',
    'DNK  - Dnipro International Airport ',
    'DNL  - Daniel Field ',
    'DNM  - Denham Airport ',
    'DNN  - Dalton Municipal Airport ',
    'DNO  - Dianópolis Airport ',
    'DNP  - Tribhuvannagar Airport    Dang Airport   ',
    'DNQ  - Deniliquin Airport ',
    'DNR  - Dinard–Pleurtuit–Saint Malo Airport ',
    'DNS  - Denison Municipal Airport ',
    'DNU  - Dinangat Airport ',
    'DNV  - Vermilion Regional Airport ',
    'DNX  - Galegu Airport ',
    'DNZ  - Denizli Çardak Airport ',
    'DOA  - Doany Airport ',
    'DOB  - Dobo Airport ',
    'DOC  - Dornoch Airfield ',
    'DOD  - Dodoma Airport ',
    'DOE  - Djoemoe Airstrip ',
    'DOG  - Dongola Airport ',
    'DOH  - Hamad International Airport ',
    'DOI  - Doini Island Airport ',
    'DOK  - Donetsk International Airport ',
    'DOL  - Deauville – Saint Gatien Airport ',
    'DOM  - Douglas–Charles Airport ',
    'DON  - Dos Lagunas Airport ',
    'DOO  - Dorobisoro Airstrip ',
    'DOP  - Dolpa Airport ',
    'DOR  - Dori Airport ',
    'DOS  - Dios Airport ',
    'DOU  - Dourados Airport    Francisco de Matos Pereira Airport   ',
    'DOV  - Dover Air Force Base ',
    'DOX  - Dongara Airport ',
    'DOY  - Dongying Shengli Airport ',
    'DPA  - DuPage Airport ',
    'DPB  - Pampa Guanaco Airport ',
    'DPE  - Dieppe   Saint Aubin Airport ',
    'DPG  - Michael Army Airfield ',
    'DPL  - Dipolog Airport ',
    'DPO  - Devonport Airport ',
    'DPS  - Ngurah Rai International Airport ',
    'DPT  - Deputatsky Airport ',
    'DPU  - Dumpu Airport ',
    'DQA  - Daqing Sartu Airport ',
    'DQM  - Duqm International Airport ',
    'DRA  - Desert Rock Airport ',
    'DRB  - Derby Airport ',
    'DRC  - Dirico Airport ',
    'DRD  - Dorunda Airport ',
    'DRE  - Drummond Island Airport    FAA  DRM   ',
    'DRF  - Drift River Airport ',
    'DRG  - Deering Airport    FAA  DEE   ',
    'DRH  - Dabra Airport ',
    'DRI  - Beauregard Regional Airport ',
    'DRJ  - Drietabbetje Airstrip ',
    'DRK  - Drake Bay Airport ',
    'DRN  - Dirranbandi Airport ',
    'DRO  - Durango–La Plata County Airport ',
    'DRP  - Bicol International Airport ',
    'DRR  - Durrie Airport ',
    'DRS  - Dresden Airport ',
    'DRT  - Del Rio International Airport ',
    'DRU  - Drummond Airport    FAA  M26   ',
    'DRV  - Dharavandhoo Airport ',
    'DRW  - Darwin International Airport ',
    'DRY  - Drysdale River Airport ',
    'DSA  - Robin Hood Airport Doncaster Sheffield ',
    'DSC  - Dschang Airport ',
    'DSD  - La Désirade Airport    Grande Anse Airport   ',
    'DSE  - Combolcha Airport ',
    'DSG  - Dilasag Airport ',
    'DSI  - Destin Executive Airport    FAA  DTS   ',
    'DSK  - Dera Ismail Khan Airport ',
    'DSM  - Des Moines International Airport ',
    'DSN  - Ordos Ejin Horo Airport ',
    'DSO  - Sondok Airport ',
    'DSS  - Blaise Diagne International Airport ',
    'DSV  - Dansville Municipal Airport ',
    'DSX  - Dongsha Island Airport ',
    'DTA  - Delta Municipal Airport ',
    'DTB  - Silangit Airport ',
    'DTD  - Datadawai Airport ',
    'DTE  - Bagasbas Airport ',
    'DTH  - Furnace Creek Airport    FAA  L06   ',
    'DTI  - Diamantina Airport ',
    'DTL  - Detroit Lakes Airport    Wething Field   ',
    'DTM  - Dortmund Airport ',
    'DTN  - Shreveport Downtown Airport ',
    'DTR  - Decatur Shores Airport ',
    'DTT  - metropolitan area1 ',
    'DTU  - Wudalianchi Airport ',
    'DTW  - Detroit Metropolitan Wayne County Airport ',
    'DUA  - Durant Regional Airport–Eaker Field ',
    'DUB  - Dublin Airport ',
    'DUC  - Halliburton Field ',
    'DUD  - Dunedin International Airport ',
    'DUE  - Dundo Airport ',
    'DUF  - Pine Island Airport    FAA  7NC2   ',
    'DUG  - Bisbee Douglas International Airport ',
    'DUJ  - DuBois Regional Airport ',
    'DUK  - Dukuduku Airport ',
    'DUM  - Pinang Kampai Airport ',
    'DUQ  - Duncan Airport ',
    'DUR  - King Shaka International Airport ',
    'DUS  - Düsseldorf Airport ',
    'DUT  - Unalaska Airport ',
    'DVD  - Andavadoaka Airport ',
    'DVK  - Diavik Airport ',
    'DVL  - Devils Lake Regional Airport    Devils Lake Municipal Airport   ',
    'DVN  - Davenport Municipal Airport ',
    'DVO  - Francisco Bangoy International Airport ',
    'DVP  - Davenport Downs Airport ',
    'DVR  - Daly River Airport ',
    'DVT  - Phoenix Deer Valley Airport ',
    'DWA  - Dwanga Airport    Dwangwa Airport   ',
    'DWB  - Soalala Airport ',
    'DWC  - Al Maktoum International Airport ',
    'DWD  - Dawadmi Domestic Airport    King Salman bin Abdul Aziz Domestic Airport   ',
    'DWH  - David Wayne Hooks Memorial Airport ',
    'DWO  - Diyawanna Oya Seaplane Base ',
    'DWR  - Dwyer Airport ',
    'DXB  - Dubai International Airport ',
    'DXD  - Dixie Airport ',
    'DXE  - Bruce Campbell Field  1      FAA  MBO   ',
    'DXR  - Danbury Municipal Airport ',
    'DYA  - Dysart Airport ',
    'DYG  - Zhangjiajie Hehua Airport ',
    'DYL  - Doylestown Airport ',
    'DYM  - Diamantina Lakes Airport ',
    'DYR  - Ugolny Airport ',
    'DYS  - Dyess Air Force Base ',
    'DYU  - Dushanbe International Airport ',
    'DYW  - Daly Waters Airport ',
    'DZA  - Dzaoudzi–Pamandzi International Airport ',
    'DZH  - Dazhou Jinya Airport ',
    'DZI  - Codazzi Airport ',
    'DZN  - Zhezkazgan Airport ',
    'DZO  - Santa Bernardina International Airport ',
    'DZU  - Dazu Air Base ',
    'EAA  - Eagle Airport ',
    'EAB  - Abbs Airport ',
    'EAE  - Siwo Airport ',
    'EAL  - Elenak Airport ',
    'EAM  - Najran Domestic Airport ',
    'EAN  - Phifer Airfield ',
    'EAP  - metropolitan area2 ',
    'EAR  - Kearney Regional Airport ',
    'EAS  - San Sebastián Airport ',
    'EAT  - Pangborn Memorial Airport ',
    'EAU  - Chippewa Valley Regional Airport ',
    'EBA  - Marina di Campo Airport ',
    'EBB  - Entebbe International Airport ',
    'EBD  - El Obeid Airport ',
    'EBG  - El Bagre Airport    El Tomin Airport   ',
    'EBH  - El Bayadh Airport ',
    'EBJ  - Esbjerg Airport ',
    'EBL  - Erbil International Airport ',
    'EBM  - El Borma Airport ',
    'EBN  - Ebadon Airstrip ',
    'EBO  - Ebon Airport ',
    'EBS  - Webster City Municipal Airport ',
    'EBU  - Saint Étienne–Bouthéon Airport ',
    'EBW  - Ebolowa Airport ',
    'ECA  - Iosco County Airport    FAA  6D9   ',
    'ECG  - Elizabeth City Regional Airport ',
    'ECH  - Echuca Airport ',
    'ECI  - Costa Esmeralda Airport ',
    'ECN  - Ercan International Airport ',
    'ECO  - El Encanto Airport ',
    'ECP  - Northwest Florida Beaches International Airport ',
    'ECR  - El Charco Airport ',
    'ECS  - Mondell Field ',
    'EDA  - Edna Bay Seaplane Base ',
    'EDB  - El Debba Airport ',
    'EDD  - Erldunda Airport ',
    'EDE  - Northeastern Regional Airport ',
    'EDF  - Elmendorf Air Force Base ',
    'EDI  - Edinburgh Airport ',
    'EDK  - Captain Jack Thomas  El Dorado Airport    FAA  EQA   ',
    'EDL  - Eldoret International Airport ',
    'EDM  - La Roche sur Yon Aerodrome ',
    'EDO  - Balıkesir Koca Seyit Airport ',
    'EDQ  - Erandique Airport ',
    'EDR  - Edward River Airport ',
    'EDW  - Edwards Air Force Base ',
    'EEA  - Planalto Serrano Regional Airport ',
    'EED  - Needles Airport ',
    'EEK  - Eek Airport ',
    'EEN  - Dillant–Hopkins Airport ',
    'EFD  - Ellington Field ',
    'EFG  - Efogi Airport ',
    'EFK  - Newport State Airport ',
    'EFL  - Cephalonia International Airport ',
    'EFW  - Jefferson Municipal Airport ',
    'EGA  - Engati Airport ',
    'EGC  - Bergerac Dordogne Périgord Airport ',
    'EGE  - Eagle County Regional Airport ',
    'EGI  - Duke Field    Eglin Auxiliary Field 3   ',
    'EGL  - Neghelle Airport ',
    'EGM  - Seghe Airport ',
    'EGN  - Geneina Airport ',
    'EGO  - Belgorod International Airport ',
    'EGP  - Maverick County Memorial International Airport ',
    'EGS  - Egilsstaðir Airport ',
    'EGV  - Eagle River Union Airport ',
    'EGX  - Egegik Airport    FAA  EII   ',
    'EHL  - El Bolsón Airport ',
    'EHM  - Cape Newenham LRRS Airport ',
    'EHU  - Ezhou Huahu Airport ',
    'EIA  - Popondetta Airport ',
    'EIB  - Eisenach Kindel Airport ',
    'EIE  - Yeniseysk Airport ',
    'EIH  - Einasleigh Airport ',
    'EIK  - Yeysk Airport ',
    'EIL  - Eielson Air Force Base ',
    'EIN  - Eindhoven Airport ',
    'EIS  - Terrance B  Lettsome International Airport ',
    'EIY  - Ein Yahav Airfield ',
    'EJA  - Yariguíes Airport ',
    'EJH  - Al Wajh Domestic Airport ',
    'EJN  - Ejin Banner Taolai Airport ',
    'EJT  - Enejit Airport ',
    'EKA  - Murray Field ',
    'EKB  - Ekibastuz Airport ',
    'EKD  - Elkedra Airport ',
    'EKE  - Ekereku Airport ',
    'EKI  - Elkhart Municipal Airport    FAA  EKM   ',
    'EKN  - Elkins Randolph County Airport ',
    'EKO  - Elko Regional Airport ',
    'EKS  - Shakhtyorsk Airport ',
    'EKT  - Eskilstuna Airport ',
    'EKX  - Elizabethtown Regional Airport    Addington Field   ',
    'ELA  - Eagle Lake Airport ',
    'ELB  - Las Flores Airport ',
    'ELC  - Elcho Island Airport ',
    'ELD  - South Arkansas Regional Airport at Goodwin Field ',
    'ELE  - El Real Airport ',
    'ELF  - El Fasher Airport ',
    'ELG  - El Golea Airport ',
    'ELH  - North Eleuthera Airport ',
    'ELI  - Elim Airport ',
    'ELJ  - El Recreo Airport ',
    'ELK  - Elk City Regional Business Airport ',
    'ELL  - Ellisras Airport ',
    'ELM  - Elmira  Corning Regional Airport ',
    'ELN  - Bowers Field ',
    'ELO  - Eldorado Airport ',
    'ELP  - El Paso International Airport ',
    'ELQ  - Prince Nayef bin Abdulaziz Regional Airport ',
    'ELR  - Elelim Airport ',
    'ELS  - East London Airport ',
    'ELT  - El Tor Airport ',
    'ELU  - Guemar Airport ',
    'ELV  - Elfin Cove Seaplane Base ',
    'ELW  - Ellamar Seaplane Base    FAA  1Z9   ',
    'ELX  - El Tigre Airport ',
    'ELY  - Ely Airport ',
    'ELZ  - Wellsville Municipal Airport    Tarantine Field   ',
    'EMA  - East Midlands Airport ',
    'EMD  - Emerald Airport ',
    'EME  - Emden Airport ',
    'EMG  - Empangeni Airport ',
    'EMI  - Emirau Airport ',
    'EMK  - Emmonak Airport    FAA  ENM   ',
    'EML  - Emmen Air Base ',
    'EMM  - Kemmerer Municipal Airport ',
    'EMN  - Néma Airport ',
    'EMO  - Emo Airport ',
    'EMP  - Emporia Municipal Airport ',
    'EMS  - Embessa Airport ',
    'EMT  - San Gabriel Valley Airport ',
    'EMX  - El Maitén Airport ',
    'EMY  - El Minya Airport ',
    'ENA  - Kenai Municipal Airport ',
    'ENB  - Eneabba Airport ',
    'ENC  - Nancy Essey Airport ',
    'END  - Vance Air Force Base ',
    'ENE  - H  Hasan Aroeboesman Airport ',
    'ENF  - Enontekiö Airport ',
    'ENH  - Enshi Xujiaping Airport ',
    'ENI  - El Nido Airport ',
    'ENJ  - El Naranjo Airport ',
    'ENK  - Enniskillen  St Angelo Airport ',
    'ENL  - Centralia Municipal Airport ',
    'ENN  - Nenana Municipal Airport ',
    'ENO  - Teniente Amin Ayub Gonzalez Airport ',
    'ENS  - Enschede Airport Twente ',
    'ENT  - Enewetak Auxiliary Airfield ',
    'ENU  - Akanu Ibiam International Airport ',
    'ENV  - Wendover Airport ',
    'ENW  - Kenosha Regional Airport ',
    'ENY  - Yan an Nanniwan Airport ',
    'EOH  - Olaya Herrera Airport ',
    'EOI  - Eday Airport ',
    'EOK  - Keokuk Municipal Airport ',
    'EOR  - El Dorado Airport ',
    'EOS  - Neosho Hugh Robinson Airport ',
    'EOZ  - Elorza Airport ',
    'EPA  - El Palomar Airport ',
    'EPG  - Browns Airport    FAA  NE69   ',
    'EPH  - Ephrata Municipal Airport ',
    'EPL  - Épinal – Mirecourt Airport ',
    'EPN  - Epéna Airport ',
    'EPR  - Esperance Airport ',
    'EPS  - Arroyo Barril Airport ',
    'EPT  - Eliptamin Airport ',
    'EPU  - Pärnu Airport ',
    'EQS  - Esquel Airport ',
    'ERA  - Erigavo Airport ',
    'ERB  - Pukatja Airport    Ernabella Airport   ',
    'ERC  - Erzincan Airport ',
    'ERD  - Berdiansk Airport ',
    'ERE  - Erave Airport ',
    'ERF  - Erfurt–Weimar Airport ',
    'ERG  - Erbogachen Airport ',
    'ERH  - Moulay Ali Cherif Airport ',
    'ERI  - Erie International Airport    Tom Ridge Field   ',
    'ERL  - Erenhot Saiwusu International Airport ',
    'ERM  - Erechim Airport ',
    'ERN  - Eirunepé Airport    Amaury Feitosa Tomaz Airport   ',
    'ERQ  - Elrose Airport ',
    'ERR  - Errol Airport ',
    'ERS  - Eros Airport ',
    'ERT  - Erdenet Airport ',
    'ERU  - Erume Airport ',
    'ERV  - Kerrville Municipal Airport    Louis Schreiner Field   ',
    'ERZ  - Erzurum Airport ',
    'ESA  - Esa ala Airport ',
    'ESB  - Esenboğa International Airport ',
    'ESC  - Delta County Airport ',
    'ESD  - Orcas Island Airport    FAA  ORS   ',
    'ESE  - Ensenada Airport ',
    'ESF  - Esler Airfield    Esler Regional Airport   ',
    'ESG  - Dr  Luis María Argaña International Airport ',
    'ESH  - Shoreham Airport    Brighton City Airport   ',
    'ESI  - Espinosa Airport ',
    'ESK  - Eskişehir Airport ',
    'ESL  - Elista Airport ',
    'ESM  - Carlos Concha Torres International Airport ',
    'ESN  - Easton Airport    Newnam Field   ',
    'ESO  - Ohkay Owingeh Airport    FAA  E14   ',
    'ESP  - Stroudsburg–Pocono Airport  1      FAA  N53   ',
    'ESR  - Ricardo García Posada Airport ',
    'ESS  - Essen  Mülheim Airport ',
    'EST  - Estherville Municipal Airport ',
    'ESU  - Essaouira Mogador Airport ',
    'ESW  - Easton State Airport ',
    'ETB  - West Bend Municipal Airport ',
    'ETD  - Etadunna Airport ',
    'ETE  - Genda Wuha Airport ',
    'ETH  - J  Hozman Airport ',
    'ETM  - Ramon Airport ',
    'ETN  - Eastland Municipal Airport ',
    'ETR  - Santa Rosa International Airport ',
    'ETS  - Enterprise Municipal Airport    FAA  EDN   ',
    'ETZ  - Metz–Nancy–Lorraine Airport ',
    'EUA  - Eua Airport    Kaufana Airport   ',
    'EUC  - Eucla Airport ',
    'EUE  - Eureka Airport    FAA  05U   ',
    'EUF  - Weedon Field ',
    'EUG  - Eugene Airport    Mahlon Sweet Airport   ',
    'EUM  - Neumünster Airport ',
    'EUN  - Hassan I Airport ',
    'EUO  - Paratebueno Airport ',
    'EUQ  - Evelio Javier Airport    Antique Airport   ',
    'EUX  - F  D  Roosevelt Airport ',
    'EVA  - Ben Bruce Memorial Airpark    FAA  4TE8   ',
    'EVD  - Eva Downs Airport ',
    'EVE  - Harstad  Narvik Airport, Evenes ',
    'EVG  - Sveg Airport ',
    'EVH  - Evans Head Memorial Aerodrome ',
    'EVM  - Eveleth Virginia Municipal Airport ',
    'EVN  - Zvartnots International Airport ',
    'EVV  - Evansville Regional Airport ',
    'EVW  - Evanston Uinta County Burns Field ',
    'EVX  - Évreux Fauville Air Base ',
    'EWB  - New Bedford Regional Airport ',
    'EWE  - Ewer Airport ',
    'EWI  - Enarotali Airport ',
    'EWK  - Newton City  County Airport ',
    'EWN  - Coastal Carolina Regional Airport ',
    'EWO  - Ewo Airport ',
    'EWR  - Newark Liberty International Airport ',
    'EXI  - Excursion Inlet Seaplane Base ',
    'EXM  - Exmouth Airport ',
    'EXT  - Exeter International Airport ',
    'EYK  - Beloyarsk Airport ',
    'EYL  - Yélimané Airport ',
    'EYP  - El Alcaraván Airport ',
    'EYR  - Yerington Municipal Airport  1      FAA  O43   ',
    'EYS  - Eliye Springs Airport ',
    'EYW  - Key West International Airport ',
    'EZE  - Ministro Pistarini International Airport ',
    'EZS  - Elazığ Airport ',
    'EZV  - Beryozovo Airport ',
    'FAA  - Faranah Airport ',
    'FAB  - Farnborough Airport ',
    'FAC  - Faaite Airport ',
    'FAE  - Vágar Airport ',
    'FAF  - Felker Army Airfield ',
    'FAG  - Fagurhólsmýri Airport ',
    'FAH  - Farah Airport ',
    'FAI  - Fairbanks International Airport ',
    'FAJ  - Diego Jiménez Torres Airport    FAA  X95   ',
    'FAK  - False Island Seaplane Base    FAA  2Z6   ',
    'FAM  - Farmington Regional Airport ',
    'FAO  - Faro Airport ',
    'FAQ  - Frieda River Airport ',
    'FAR  - Hector International Airport ',
    'FAS  - Fáskrúðsfjörður Airport ',
    'FAT  - Fresno Yosemite International Airport ',
    'FAU  - Fahud Airport ',
    'FAV  - Fakarava Airport ',
    'FAY  - Fayetteville Regional Airport    Grannis Field   ',
    'FAZ  - Fasa Airport ',
    'FBA  - Fonte Boa Airport ',
    'FBD  - Fayzabad Airport ',
    'FBE  - Francisco Beltrão Airport    Paulo Abdala Airport   ',
    'FBG  - Simmons Army Airfield ',
    'FBK  - Ladd Army Airfield ',
    'FBL  - Faribault Municipal Airport ',
    'FBM  - Lubumbashi International Airport ',
    'FBR  - Fort Bridger Airport ',
    'FBS  - Friday Harbor Seaplane Base    FAA  W33   ',
    'FBY  - Fairbury Municipal Airport ',
    'FCA  - Glacier Park International Airport    FAA  GPI   ',
    'FCB  - Ficksburg Airport ',
    'FCH  - Fresno Chandler Executive Airport ',
    'FCM  - Flying Cloud Airport ',
    'FCN  - Sea Airport Cuxhaven  Nordholz ',
    'FCO  - Leonardo da Vinci–Fiumicino Airport ',
    'FCS  - Butts Army Airfield    Fort Carson   ',
    'FCY  - Forrest City Municipal Airport ',
    'FDE  - Førde Airport, Bringeland ',
    'FDF  - Martinique Aimé Césaire International Airport ',
    'FDH  - Friedrichshafen Airport    Bodensee Airport   ',
    'FDK  - Frederick Municipal Airport ',
    'FDR  - Frederick Regional Airport ',
    'FDU  - Bandundu Airport ',
    'FDY  - Findlay Airport ',
    'FEA  - Fetlar Airport ',
    'FEB  - Sanfebagar Airport ',
    'FEC  - Feira de Santana Airport    Gov  João Durval Carneiro Airport   ',
    'FEG  - Fergana International Airport ',
    'FEJ  - Feijó Airport ',
    'FEK  - Ferkessédougou Airport ',
    'FEL  - Fürstenfeldbruck Air Base ',
    'FEN  - Fernando de Noronha Airport    Gov  Carlos Wilson Airport   ',
    'FEP  - Albertus Airport ',
    'FET  - Fremont Municipal Airport ',
    'FEZ  - Fes–Saïss Airport ',
    'FFA  - First Flight Airport ',
    'FFD  - RAF Fairford ',
    'FFL  - Fairfield Municipal Airport ',
    'FFM  - Fergus Falls Municipal Airport    Einar Mickelson Field   ',
    'FFO  - Wright Patterson Air Force Base    First Flight Office   ',
    'FFT  - Capital City Airport ',
    'FFU  - Futaleufú Airfield ',
    'FGD  - Fderik Airport ',
    'FGI  - Fagali i Airport ',
    'FGU  - Fangatau Airport ',
    'FHU  - Sierra Vista Municipal Airport    Libby Army Airfield   ',
    'FHZ  - Fakahina Airport ',
    'FID  - Elizabeth Field    FAA  0B8   ',
    'FIE  - Fair Isle Airport ',
    'FIG  - Fria Airport ',
    'FIH  - N djili Airport    Kinshasa Int l Airport   ',
    'FIK  - Finke Airport ',
    'FIL  - Fillmore Municipal Airport    FAA  FOM   ',
    'FIN  - Finschhafen Airport ',
    'FIZ  - Fitzroy Crossing Airport ',
    'FJR  - Fujairah International Airport ',
    'FKB  - Karlsruhe  Baden Baden Airport ',
    'FKI  - Bangoka International Airport ',
    'FKJ  - Fukui Airport ',
    'FKL  - Venango Regional Airport    Chess Lamberton Field   ',
    'FKN  - Franklin Municipal–John Beverly Rose Airport ',
    'FKQ  - Fakfak Torea Airport ',
    'FKS  - Fukushima Airport ',
    'FLA  - Gustavo Artunduaga Paredes Airport ',
    'FLB  - Cangapara Airport ',
    'FLD  - Fond du Lac County Airport ',
    'FLF  - Flensburg Schäferhaus Airport ',
    'FLG  - Flagstaff Pulliam Airport ',
    'FLH  - Flotta Airport ',
    'FLI  - Holt Airport ',
    'FLL  - Fort Lauderdale–Hollywood International Airport ',
    'FLM  - Filadelfia Airport ',
    'FLN  - Hercílio Luz International Airport ',
    'FLO  - Florence Regional Airport ',
    'FLP  - Marion County Regional Airport ',
    'FLR  - Florence Airport, Peretola    Amerigo Vespucci Airport   ',
    'FLS  - Flinders Island Airport ',
    'FLT  - Flat Airport ',
    'FLV  - Sherman Army Airfield ',
    'FLW  - Flores Airport ',
    'FLX  - Fallon Municipal Airport ',
    'FLY  - Finley Airport ',
    'FLZ  - Ferdinand Lumban Tobing Airport ',
    'FMA  - Formosa International Airport    El Pucú Airport   ',
    'FME  - Tipton Airport ',
    'FMH  - Otis Air National Guard Base ',
    'FMI  - Kalemie Airport ',
    'FMM  - Memmingen Airport ',
    'FMN  - Four Corners Regional Airport ',
    'FMO  - Münster Osnabrück Airport ',
    'FMS  - Fort Madison Municipal Airport    FAA  FSW   ',
    'FMU  - Florence Municipal Airport  1      FAA  6S2   ',
    'FMY  - Page Field ',
    'FNA  - Lungi International Airport ',
    'FNB  - Neubrandenburg Airport ',
    'FNC  - Madeira Airport ',
    'FND  - Funadhoo Airport ',
    'FNE  - Fane Airport ',
    'FNG  - Fada N gourma Airport ',
    'FNH  - Fincha Airport ',
    'FNI  - Nîmes–Alès–Camargue–Cévennes Airport    Garons Airport   ',
    'FNJ  - Pyongyang Sunan International Airport ',
    'FNL  - Fort Collins–Loveland Municipal Airport ',
    'FNR  - Funter Bay Seaplane Base ',
    'FNT  - Bishop International Airport ',
    'FNU  - Oristano Fenosu Airport ',
    'FOA  - Foula Airfield ',
    'FOB  - Fort Bragg Airport    FAA  82CL   ',
    'FOC  - Fuzhou Changle International Airport ',
    'FOD  - Fort Dodge Regional Airport ',
    'FOE  - Topeka Regional Airport ',
    'FOG  - Foggia Gino Lisa Airport ',
    'FOK  - Francis S  Gabreski Airport ',
    'FOM  - Foumban Nkounja Airport ',
    'FON  - La Fortuna Arenal Airport ',
    'FOO  - Kornasoren Airport    Numfoor Airport   ',
    'FOR  - Pinto Martins – Fortaleza International Airport ',
    'FOS  - Forrest Airport ',
    'FOT  - Forster   Wallis Island   Airport  1   ',
    'FOU  - Fougamou Airport ',
    'FOY  - Foya Airport ',
    'FPO  - Grand Bahama International Airport ',
    'FPR  - St  Lucie County International Airport ',
    'FPY  - Perry Foley Airport    FAA  40J   ',
    'FRA  - Frankfurt Airport ',
    'FRB  - Forbes Airport ',
    'FRC  - Franca Airport    Ten  Lund Presotto–Franca State Airport   ',
    'FRD  - Friday Harbor Airport    FAA  FHR   ',
    'FRE  - Fera Airport    Fera  Maringe Airport   ',
    'FRG  - Republic Airport ',
    'FRH  - French Lick Municipal Airport ',
    'FRI  - Marshall Army Airfield ',
    'FRK  - Frégate Island Airport ',
    'FRL  - Forlì International Airport    Luigi Ridolfi Airport   ',
    'FRM  - Fairmont Municipal Airport ',
    'FRN  - Bryant Army Airport ',
    'FRO  - Florø Airport ',
    'FRQ  - Feramin Airport ',
    'FRR  - Front Royal–Warren County Airport ',
    'FRS  - Mundo Maya International Airport ',
    'FRT  - Frutillar Airport ',
    'FRU  - Manas International Airport ',
    'FRW  - Francistown Airport ',
    'FRY  - Eastern Slopes Regional Airport    FAA  IZG   ',
    'FRZ  - Fritzlar Air Base ',
    'FSC  - Figari–Sud Corse Airport ',
    'FSD  - Sioux Falls Regional Airport    Joe Foss Field   ',
    'FSI  - Henry Post Army Airfield    Fort Sill   ',
    'FSK  - Fort Scott Municipal Airport ',
    'FSL  - Fossil Downs Airport ',
    'FSM  - Fort Smith Regional Airport ',
    'FSP  - Saint Pierre Airport ',
    'FSS  - RAF Kinloss ',
    'FST  - Fort Stockton–Pecos County Airport ',
    'FSU  - Fort Sumner Municipal Airport ',
    'FSZ  - Shizuoka Airport    Mt  Fuji Shizuoka Airport   ',
    'FTA  - Futuna Airport ',
    'FTE  - Comandante Armando Tola International Airport ',
    'FTI  - Fitiuta Airport    FAA  FAQ   ',
    'FTK  - Godman Army Airfield ',
    'FTU  - Tôlanaro Airport    Marillac Airport   ',
    'FTW  - Fort Worth Meacham International Airport ',
    'FTX  - Owando Airport ',
    'FTY  - Fulton County Airport    Charlie Brown Field   ',
    'FUB  - Fulleborn Airport ',
    'FUE  - Fuerteventura Airport ',
    'FUG  - Fuyang Xiguan Airport ',
    'FUJ  - Fukue Airport    Gotō Fukue Airport   ',
    'FUK  - Fukuoka Airport    Itazuke Air Base   ',
    'FUL  - Fullerton Municipal Airport ',
    'FUM  - Fuma Airport ',
    'FUN  - Funafuti International Airport ',
    'FUO  - Foshan Shadi Airport ',
    'FUT  - Pointe Vele Airport ',
    'FVL  - Flora Valley Airport ',
    'FVM  - Fuvahmulah Airport ',
    'FVR  - Forrest River Airport ',
    'FWA  - Fort Wayne International Airport ',
    'FWH  - NAS Fort Worth JRB     Carswell Field   FAA  NFW   ',
    'FWL  - Farewell Airport ',
    'FXE  - Fort Lauderdale Executive Airport ',
    'FXO  - Cuamba Airport ',
    'FXY  - Forest City Municipal Airport ',
    'FYJ  - Fuyuan Dongji Airport ',
    'FYM  - Fayetteville Municipal Airport ',
    'FYN  - Fuyun Koktokay Airport ',
    'FYT  - Faya Largeau Airport ',
    'FYU  - Fort Yukon Airport ',
    'FYV  - Drake Field    Fayetteville Executive Airport   ',
    'FZO  - Bristol Filton Airport ',
    'FZL  - Fuzuli International Airport ',
    'GAA  - Guamal Airport ',
    'GAB  - Gabbs Airport ',
    'GAC  - Gracias Airport ',
    'GAD  - Northeast Alabama Regional Airport ',
    'GAE  - Gabès – Matmata International Airport ',
    'GAF  - Gafsa – Ksar International Airport ',
    'GAG  - Gage Airport ',
    'GAH  - Gayndah Airport ',
    'GAI  - Montgomery County Airpark ',
    'GAJ  - Yamagata Airport    Junmachi Airport   ',
    'GAL  - Edward G  Pitka Sr  Airport ',
    'GAM  - Gambell Airport ',
    'GAN  - Gan International Airport ',
    'GAO  - Mariana Grajales Airport ',
    'GAP  - Gusap Airport ',
    'GAQ  - Gao International Airport    Korogoussou Airport   ',
    'GAR  - Garaina Airport ',
    'GAS  - Garissa Airport ',
    'GAT  - Gap–Tallard Airport ',
    'GAU  - Lokpriya Gopinath Bordoloi International Airport ',
    'GAV  - Gag Island Airport ',
    'GAW  - Gangaw Airport ',
    'GAX  - Gamba Airport ',
    'GAY  - Gaya Airport    Bodhgaya Airport   ',
    'GAZ  - Guasopa Airport ',
    'GBA  - Cotswold Airport ',
    'GBB  - Qabala International Airport ',
    'GBC  - Gasuke Airport ',
    'GBD  - Great Bend Municipal Airport ',
    'GBE  - Sir Seretse Khama International Airport ',
    'GBF  - Negarbo Airport ',
    'GBG  - Galesburg Municipal Airport ',
    'GBH  - Galbraith Lake Airport ',
    'GBI  - Kalaburagi Airport ',
    'GBJ  - Marie Galante Airport    Les Bases   ',
    'GBK  - Gbangbatok Airport ',
    'GBL  - South Goulburn Island Airport ',
    'GBM  - Garbaharey Airport ',
    'GBP  - Gamboola Airport ',
    'GBR  - Walter J  Koladza Airport ',
    'GBT  - Gorgan Airport ',
    'GBU  - Khashm el Girba Airport ',
    'GBV  - Gibb River Airport ',
    'GBW  - Ginbata Airport ',
    'GBZ  - Great Barrier Aerodrome ',
    'GCA  - Guacamayas Airport ',
    'GCC  - Gillette–Campbell County Airport ',
    'GCD  - Grand Coulee Dam Airport  2      FAA  3W7   ',
    'GCH  - Gachsaran Airport ',
    'GCI  - Guernsey Airport ',
    'GCJ  - Grand Central Airport ',
    'GCK  - Garden City Regional Airport ',
    'GCM  - Owen Roberts International Airport ',
    'GCN  - Grand Canyon National Park Airport ',
    'GCT  - Grand Canyon Bar 10 Airport ',
    'GCW  - Grand Canyon West Airport  2      FAA  1G4   ',
    'GCY  - Greeneville–Greene County Municipal Airport ',
    'GDA  - Gounda Airport ',
    'GDC  - Donaldson Center Airport    FAA  GYH   ',
    'GDD  - Gordon Downs Airport ',
    'GDE  - Gode Airport ',
    'GDG  - Magdagachi Airport ',
    'GDH  - Golden Horn Lodge Seaplane Base    FAA  3Z8   ',
    'GDI  - Gordil Airport ',
    'GDJ  - Gandajika Airport ',
    'GDL  - Guadalajara International Airport    Miguel Hidalgo y Costilla Int l   ',
    'GDM  - Gardner Municipal Airport ',
    'GDN  - Gdańsk Lech Wałęsa Airport ',
    'GDO  - Guasdualito Airport    Vare Maria Airport   ',
    'GDP  - Guadalupe Airport ',
    'GDQ  - Gondar Airport    Atse Tewodros Airport   ',
    'GDT  - JAGS McCartney International Airport    Grand Turk Int l   ',
    'GDV  - Dawson Community Airport ',
    'GDW  - Gladwin Zettel Memorial Airport ',
    'GDX  - Sokol Airport ',
    'GDZ  - Gelendzhik Airport ',
    'GEA  - Nouméa Magenta Airport ',
    'GEB  - Gebe Airport ',
    'GEC  - Geçitkale Air Base ',
    'GED  - Delaware Coastal Airport ',
    'GEE  - George Town Aerodrome ',
    'GEF  - Geva Airport ',
    'GEG  - Spokane International Airport ',
    'GEL  - Sepé Tiaraju Airport ',
    'GEM  - President Obiang Nguema International Airport ',
    'GEO  - Cheddi Jagan International Airport ',
    'GER  - Rafael Cabrera Mustelier Airport ',
    'GES  - General Santos International Airport    Tambler Airport   ',
    'GET  - Geraldton Airport ',
    'GEV  - Gällivare Airport ',
    'GEW  - Gewoia Airport ',
    'GEX  - Geelong Airport ',
    'GEY  - South Big Horn County Airport ',
    'GFD  - Pope Field ',
    'GFE  - Grenfell Airport ',
    'GFF  - Griffith Airport ',
    'GFK  - Grand Forks International Airport ',
    'GFL  - Floyd Bennett Memorial Airport ',
    'GFN  - Clarence Valley Regional Airport ',
    'GFO  - Bartica Airport ',
    'GFR  - Granville–Mont Saint Michel Aerodrome    fr   ',
    'GFY  - Grootfontein Air Base ',
    'GGB  - Água Boa Airport ',
    'GGC  - Lumbala N guimbo Airport ',
    'GGD  - Gregory Downs Airport ',
    'GGE  - Georgetown County Airport ',
    'GGF  - Almeirim Airport ',
    'GGG  - East Texas Regional Airport ',
    'GGH  - Gastão Mesquita Airport ',
    'GGJ  - Guaíra Airport ',
    'GGL  - Gilgal Airport ',
    'GGM  - Kakamega Airport ',
    'GGN  - Gagnoa Airport ',
    'GGO  - Guiglo Airport ',
    'GGR  - Garowe International Airport ',
    'GGS  - Gobernador Gregores Airport ',
    'GGT  - Exuma International Airport ',
    'GGW  - Glasgow Airport    Wokal Field   ',
    'GHA  - Noumérat – Moufdi Zakaria Airport ',
    'GHB  - Governor s Harbour Airport ',
    'GHC  - Great Harbour Cay Airport ',
    'GHE  - Garachiné Airport ',
    'GHF  - Giebelstadt Airport ',
    'GHK  - Gahcho Kue Aerodrome  2      TC  CGK2   ',
    'GHM  - Centerville Municipal Airport ',
    'GHN  - Guanghan Airport ',
    'GHS  - West Kutai Melalan Airport ',
    'GHT  - Ghat Airport ',
    'GHU  - Gualeguaychú Airport ',
    'GIB  - Gibraltar International Airport    North Front Airport   ',
    'GIC  - Boigu Island Airport ',
    'GID  - Gitega Airport ',
    'GIF  - Winter Haven s Gilbert Airport ',
    'GIG  - Rio de Janeiro–Galeão International Airport ',
    'GII  - Siguiri Airport ',
    'GIL  - Gilgit Airport ',
    'GIM  - Miele Mimbale Airport ',
    'GIR  - Santiago Vila Airport ',
    'GIS  - Gisborne Airport ',
    'GIT  - Geita Airport ',
    'GIU  - Sigiriya Airport ',
    'GIY  - Giyani Airport ',
    'GIZ  - Jizan Regional Airport    King Abdullah bin Abdulaziz Airport   ',
    'GJA  - Guanaja Airport ',
    'GJL  - Jijel Ferhat Abbas Airport ',
    'GJM  - Guajará Mirim Airport ',
    'GJR  - Gjögur Airport ',
    'GJT  - Grand Junction Regional Airport    Walker Field   ',
    'GKA  - Goroka Airport ',
    'GKD  - Gökçeada Airport ',
    'GKE  - NATO Air Base Geilenkirchen ',
    'GKH  - Palungtar Airport ',
    'GKK  - Kooddoo Airport ',
    'GKL  - Great Keppel Island Airport ',
    'GKN  - Gulkana Airport ',
    'GKO  - Kongo Boumba Airport ',
    'GKT  - Gatlinburg–Pigeon Forge Airport ',
    'GLA  - Glasgow Airport ',
    'GLB  - San Carlos Apache Airport  2      FAA  P13   ',
    'GLC  - Geladi Airport ',
    'GLD  - Goodland Municipal Airport    Renner Field   ',
    'GLE  - Gainesville Municipal Airport ',
    'GLF  - Golfito Airport ',
    'GLG  - Glengyle Airport ',
    'GLH  - Mid Delta Regional Airport ',
    'GLI  - Glen Innes Airport ',
    'GLK  - Abdullahi Yusuf International Airport ',
    'GLL  - Gol Airport, Klanten ',
    'GLM  - Glenormiston Airport ',
    'GLN  - Guelmim Airport ',
    'GLO  - Gloucestershire Airport ',
    'GLP  - Gulgubip Airport ',
    'GLR  - Gaylord Regional Airport ',
    'GLS  - Scholes International Airport at Galveston ',
    'GLT  - Gladstone Airport ',
    'GLU  - Gelephu Airport ',
    'GLV  - Golovin Airport ',
    'GLW  - Glasgow Municipal Airport ',
    'GLX  - Gamar Malamo Airport ',
    'GLY  - Goldsworthy Airport ',
    'GLZ  - Gilze Rijen Air Base ',
    'GMA  - Gemena Airport ',
    'GMB  - Gambela Airport ',
    'GMC  - Guerima Airport ',
    'GMD  - Ben Slimane Airport ',
    'GME  - Gomel Airport ',
    'GMI  - Gasmata Airport ',
    'GMM  - Gamboma Airport ',
    'GMN  - Greymouth Airport ',
    'GMO  - Gombe Lawanti International Airport ',
    'GMP  - Gimpo International Airport ',
    'GMQ  - Golog Maqin Airport ',
    'GMR  - Totegegie Airport    Gambier Island Airport   ',
    'GMS  - Guimarães Airport ',
    'GMT  - Granite Mountain Air Station    FAA  GSZ   ',
    'GMU  - Greenville Downtown Airport ',
    'GMV  - Monument Valley Airport    FAA  UT25   ',
    'GMZ  - La Gomera Airport ',
    'GNA  - Grodno Airport ',
    'GNB  - Grenoble–Isère Airport ',
    'GND  - Maurice Bishop International Airport ',
    'GNF  - Gansner Field  2      FAA  2O1   ',
    'GNG  - Gooding Municipal Airport ',
    'GNI  - Lyudao Airport    Green Island Airport   ',
    'GNJ  - Ganja International Airport ',
    'GNM  - Guanambi Airport ',
    'GNN  - Ginir Airport ',
    'GNR  - Dr  Arturo Umberto Illia Airport ',
    'GNS  - Binaka Airport ',
    'GNT  - Grants Milan Municipal Airport ',
    'GNU  - Goodnews Airport ',
    'GNV  - Gainesville Regional Airport ',
    'GNY  - Şanlıurfa GAP Airport ',
    'GNZ  - Ghanzi Airport ',
    'GOA  - Genoa Cristoforo Colombo Airport ',
    'GOB  - Robe Airport ',
    'GOC  - Gora Airport ',
    'GOE  - Gonaili Airstrip ',
    'GOG  - Gobabis Airport ',
    'GOH  - Nuuk Airport ',
    'GOI  - Dabolim Airport ',
    'GOJ  - Nizhny Novgorod International Airport    Strigino Airport   ',
    'GOK  - Guthrie–Edmond Regional Airport ',
    'GOL  - Gold Beach Municipal Airport    FAA  4S1   ',
    'GOM  - Goma International Airport ',
    'GON  - Groton–New London Airport ',
    'GOO  - Goondiwindi Airport ',
    'GOP  - Gorakhpur Airport ',
    'GOQ  - Golmud Airport ',
    'GOR  - Gore Airport ',
    'GOS  - Gosford Airport ',
    'GOT  - Göteborg Landvetter Airport ',
    'GOU  - Garoua International Airport ',
    'GOV  - Gove Airport ',
    'GOX  - Manohar International Airport ',
    'GOZ  - Gorna Oryahovitsa Airport ',
    'GPA  - Araxos Airport ',
    'GPB  - Tancredo Thomas de Faria Airport ',
    'GPD  - Mount Gordon Airport ',
    'GPI  - Guapi Airport    Juan Casiano Airport   ',
    'GPL  - Guápiles Airport ',
    'GPN  - Garden Point Airport ',
    'GPO  - General Pico Airport ',
    'GPS  - Seymour Airport ',
    'GPT  - Gulfport–Biloxi International Airport ',
    'GPZ  - Grand Rapids–Itasca County Airport    Gordon Newstrom Field   ',
    'GQQ  - Galion Municipal Airport ',
    'GRA  - Gamarra Airport ',
    'GRB  - Austin Straubel International Airport ',
    'GRC  - Grand Cess Airport ',
    'GRD  - Greenwood County Airport ',
    'GRE  - Greenville Airport ',
    'GRF  - Gray Army Airfield ',
    'GRG  - Gardez Airport ',
    'GRH  - Garuahi Airport ',
    'GRI  - Central Nebraska Regional Airport ',
    'GRJ  - George Airport ',
    'GRK  - Killeen–Fort Hood Regional Airport      Robert Gray Army Airfield ',
    'GRL  - Garasa Airport ',
    'GRM  - Grand Marais  Cook County Airport    FAA  CKC   ',
    'GRN  - Gordon Municipal Airport ',
    'GRO  - Girona–Costa Brava Airport ',
    'GRP  - Gurupi Airport ',
    'GRQ  - Groningen Airport Eelde ',
    'GRR  - Gerald R  Ford International Airport ',
    'GRS  - Grosseto Airport ',
    'GRT  - Gujrat Airport ',
    'GRU  - São Paulo  Guarulhos–Governador André Franco Montoro International Airport ',
    'GRV  - Grozny Airport ',
    'GRW  - Graciosa Airport ',
    'GRX  - Federico García Lorca Airport    Granada Jaén Airport   ',
    'GRY  - Grímsey Airport ',
    'GRZ  - Graz Airport ',
    'GSA  - Long Pasia Airport ',
    'GSB  - Seymour Johnson Air Force Base ',
    'GSC  - Gascoyne Junction Airport ',
    'GSE  - Göteborg City Airport ',
    'GSH  - Goshen Municipal Airport ',
    'GSI  - Grand Santi Airport ',
    'GSJ  - San José Airport ',
    'GSL  - Taltheilei Narrows Airport  2      TC  CFA7   ',
    'GSM  - Dayrestan Airport    Qeshm International Airport   ',
    'GSN  - Mount Gunson Airport ',
    'GSO  - Piedmont Triad International Airport ',
    'GSP  - Greenville–Spartanburg International Airport ',
    'GSQ  - Sharq Al Owainat Airport ',
    'GSR  - Qardho Airport ',
    'GSS  - Sabi Sabi Airport ',
    'GST  - Gustavus Airport ',
    'GSU  - Azaza Airport ',
    'GSV  - Saratov Gagarin Airport ',
    'GTA  - Gatokae Aerodrome ',
    'GTE  - Groote Eylandt Airport ',
    'GTF  - Great Falls International Airport ',
    'GTG  - Grantsburg Municipal Airport ',
    'GTI  - Rügen Airport    Güttin Airfield   ',
    'GTN  - Glentanner Aerodrome ',
    'GTO  - Jalaluddin Airport ',
    'GTP  - Grants Pass Airport  2      FAA  3S8   ',
    'GTR  - Golden Triangle Regional Airport ',
    'GTS  - The Granites Airport ',
    'GTT  - Georgetown Airport ',
    'GTW  - Holešov Airport ',
    'GTY  - Gettysburg Regional Airport    FAA  W05   ',
    'GTZ  - Kirawira B Airstrip ',
    'GUA  - La Aurora International Airport ',
    'GUB  - Guerrero Negro Airport ',
    'GUC  - Gunnison–Crested Butte Regional Airport ',
    'GUD  - Goundam Airport ',
    'GUE  - Guriaso Airport ',
    'GUF  - Jack Edwards Airport    FAA  JKA   ',
    'GUG  - Guari Airport ',
    'GUH  - Gunnedah Airport ',
    'GUI  - Güiria Airport ',
    'GUJ  - Guaratinguetá Airport ',
    'GUL  - Goulburn Airport ',
    'GUM  - Antonio B  Won Pat International Airport    Guam Int l   ',
    'GUP  - Gallup Municipal Airport ',
    'GUQ  - Guanare Airport ',
    'GUR  - Gurney Airport ',
    'GUS  - Grissom Air Reserve Base ',
    'GUT  - RAF Gütersloh ',
    'GUU  - Grundarfjörður Airport ',
    'GUV  - Mougulu Airport ',
    'GUW  - Atyrau Airport ',
    'GUX  - Guna Airport ',
    'GUY  - Guymon Municipal Airport ',
    'GUZ  - Guarapari Airport ',
    'GVA  - Geneva Airport ',
    'GVE  - Gordonsville Municipal Airport ',
    'GVI  - Green River Airport ',
    'GVL  - Lee Gilmer Memorial Airport ',
    'GVN  - Sovetskaya Gavan Airport ',
    'GVP  - Greenvale Airport ',
    'GVR  - Coronel Altino Machado de Oliveira Airport ',
    'GVT  - Majors Airport ',
    'GVX  - Gävle Sandviken Airport ',
    'GWA  - Gwa Airport ',
    'GWD  - Gwadar International Airport ',
    'GWE  - Thornhill Air Base ',
    'GWL  - Rajmata Vijaya Raje Scindia Airport    Gwalior Airport   ',
    'GWN  - Gnarowein Airport ',
    'GWO  - Greenwood–Leflore Airport ',
    'GWS  - Glenwood Springs Municipal Airport ',
    'GWT  - Sylt Airport    Westerland Airport   ',
    'GWV  - Glendale Fokker Field    FAA  WV66   ',
    'GWY  - Galway Airport ',
    'GXA  - Beringin Airport ',
    'GXF  - Sayun Airport ',
    'GXG  - Negage Airport ',
    'GXH  - Gannan Xiahe Airport ',
    'GXQ  - Teniente Vidal Airfield ',
    'GXX  - Yagoua Airport ',
    'GXY  - Greeley–Weld County Airport ',
    'GYA  - Guayaramerín Airport ',
    'GYD  - Heydar Aliyev International Airport ',
    'GYE  - José Joaquín de Olmedo International Airport ',
    'GYG  - Magan Airport ',
    'GYI  - Gisenyi Airport ',
    'GYL  - Argyle Airport ',
    'GYM  - General José María Yáñez International Airport ',
    'GYN  - Santa Genoveva Airport ',
    'GYP  - Gympie Airport ',
    'GYR  - Phoenix Goodyear Airport ',
    'GYS  - Guangyuan Panlong Airport ',
    'GYU  - Guyuan Liupanshan Airport ',
    'GZA  - Yasser Arafat International Airport ',
    'GZG  - Garze Gesar Airport ',
    'GZI  - Ghazni Airport ',
    'GZO  - Nusatupe Airport ',
    'GZP  - Gazipaşa–Alanya Airport ',
    'GZT  - Gaziantep Oğuzeli International Airport ',
    'GZW  - Qazvin Airport ',
    'HAA  - Hasvik Airport ',
    'HAB  - Marion County – Rankin Fite Airport ',
    'HAC  - Hachijojima Airport ',
    'HAD  - Halmstad Airport    Halmstad City Airport   ',
    'HAF  - Half Moon Bay Airport ',
    'HAH  - Prince Said Ibrahim International Airport ',
    'HAI  - Three Rivers Municipal Airport    Dr  Haines Flying Field   ',
    'HAJ  - Hannover Airport ',
    'HAK  - Haikou Meilan International Airport ',
    'HAL  - Halali Airport ',
    'HAM  - Hamburg Airport ',
    'HAN  - Noi Bai International Airport ',
    'HAO  - Butler County Regional Airport ',
    'HAQ  - Hanimaadhoo International Airport ',
    'HAR  - Capital City Airport    FAA  CXY   ',
    'HAS  - Ha il Regional Airport ',
    'HAT  - Heathlands Airport ',
    'HAU  - Haugesund Airport, Karmøy ',
    'HAV  - José Martí International Airport ',
    'HAW  - Haverfordwest Aerodrome ',
    'HAY  - Hacaritama Airport ',
    'HAZ  - Hatzfeldthaven Airport ',
    'HBA  - Hobart International Airport ',
    'HBB  - Hobbs Industrial Airpark ',
    'HBD  - Habi Airport ',
    'HBE  - Borg El Arab Airport ',
    'HBG  - Hattiesburg Bobby L  Chain Municipal Airport ',
    'HBH  - Entrance Island Seaplane Base    Hobart Bay     FAA  2Z1   ',
    'HBK  - Holbrook Municipal Airport  1      FAA  P14   ',
    'HBQ  - Qilian Airport ',
    'HBR  - Hobart Regional Airport ',
    'HBT  - Hambantota Sea Plane Base ',
    'HBU  - Bulgan Airport, Khovd ',
    'HBX  - Hubli Airport ',
    'HCA  - Big Spring McMahon–Wrinkle Airport  1      FAA  BPG   ',
    'HCC  - Columbia County Airport    FAA  1B1   ',
    'HCJ  - Hechi Jinchengjiang Airport ',
    'HCM  - Eyl Airport ',
    'HCN  - Hengchun Airport ',
    'HCQ  - Halls Creek Airport ',
    'HCR  - Holy Cross Airport    FAA  HCA   ',
    'HCW  - Cheraw Municipal Airport    Lynch Bellinger Field, FAA  CQW   ',
    'HCZ  - Chenzhou Beihu Airport ',
    'HDB  - Heidelberg Airport ',
    'HDD  - Hyderabad Airport ',
    'HDE  - Brewster Field ',
    'HDF  - Heringsdorf Airport ',
    'HDG  - Handan Airport ',
    'HDH  - Dillingham Airfield ',
    'HDK  - Kulhudhuffushi Airport ',
    'HDM  - Hamadan Airport ',
    'HDN  - Yampa Valley Airport ',
    'HDR  - Havadarya Airport ',
    'HDS  - Air Force Base Hoedspruit ',
    'HDY  - Hat Yai International Airport ',
    'HEA  - Herat International Airport ',
    'HEB  - Hinthada Airport ',
    'HED  - Herendeen Bay Airport    FAA  AK33   ',
    'HEE  - Thompson–Robbins Airport ',
    'HEH  - Heho Airport ',
    'HEI  - Heide–Büsum Airport ',
    'HEK  - Heihe Airport ',
    'HEL  - Helsinki Airport    Helsinki Vantaa Airport   ',
    'HEM  - Helsinki Malmi Airport ',
    'HEO  - Haelogo Airport ',
    'HER  - Heraklion International Airport    Nikos Kazantzakis Airport   ',
    'HES  - Hermiston Municipal Airport    FAA  HRI   ',
    'HET  - Hohhot Baita International Airport ',
    'HEZ  - Natchez–Adams County Airport    Hardy–Anders Field   ',
    'HFA  - Haifa Airport    Uri Michaeli Airport   ',
    'HFD  - Hartford–Brainard Airport ',
    'HFE  - Hefei Xinqiao International Airport    formerly  Hefei Luogang International Airport   ',
    'HFF  - Mackall Army Airfield ',
    'HFN  - Hornafjörður Airport ',
    'HFS  - Hagfors Airport ',
    'HFT  - Hammerfest Airport ',
    'HGA  - Hargeisa International Airport    Egal Int l   ',
    'HGD  - Hughenden Airport ',
    'HGE  - Higuerote Airport ',
    'HGH  - Hangzhou Xiaoshan International Airport ',
    'HGI  - Paloich Airport ',
    'HGL  - Heligoland Airfield ',
    'HGN  - Mae Hong Son Airport ',
    'HGO  - Korhogo Airport ',
    'HGR  - Hagerstown Regional Airport    Richard A  Henson Field   ',
    'HGS  - Hastings Airport ',
    'HGU  - Mount Hagen Airport ',
    'HGZ  - Hog River Airport    FAA  2AK6   ',
    'HHE  - JMSDF Hachinohe Air Base ',
    'HHH  - Hilton Head Airport    FAA  HXD   ',
    'HHI  - Wheeler Army Airfield ',
    'HHN  - Frankfurt–Hahn Airport ',
    'HHQ  - Hua Hin Airport ',
    'HHR  - Hawthorne Municipal Airport    Jack Northrop Field   ',
    'HHZ  - Hikueru Airport ',
    'HIA  - Huai an Lianshui Airport ',
    'HIB  - Range Regional Airport ',
    'HID  - Horn Island Airport ',
    'HIE  - Mount Washington Regional Airport ',
    'HIF  - Hill Air Force Base ',
    'HIG  - Highbury Airport ',
    'HII  - Lake Havasu City Airport ',
    'HIJ  - Hiroshima Airport ',
    'HIL  - Shilavo Airport ',
    'HIM  - Hingurakgoda Airport    Minneriya Airport   ',
    'HIN  - Sacheon Airport ',
    'HIO  - Hillsboro Airport    Portland–Hillsboro Airport   ',
    'HIP  - Headingly Airport ',
    'HIR  - Honiara International Airport ',
    'HIT  - Haivaro Airport ',
    'HIW  - Hiroshima–Nishi Airport ',
    'HJJ  - Huaihua Zhijiang Airport ',
    'HJR  - Civil Aerodrome Khajuraho ',
    'HJT  - Khujirt Airport ',
    'HKA  - Blytheville Municipal Airport ',
    'HKB  - Healy Lake Airport ',
    'HKD  - Hakodate Airport ',
    'HKG  - Hong Kong International Airport    Chek Lap Kok Airport   ',
    'HKK  - Hokitika Airport ',
    'HKN  - Hoskins Airport ',
    'HKS  - Hawkins Field ',
    'HKT  - Phuket International Airport ',
    'HKV  - Haskovo Malevo Airport ',
    'HKY  - Hickory Regional Airport ',
    'HLA  - Lanseria International Airport ',
    'HLB  - Hillenbrand Industries Airport ',
    'HLC  - Hill City Municipal Airport ',
    'HLD  - Hulunbuir Hailar Airport ',
    'HLE  - Saint Helena Airport ',
    'HLF  - Hultsfred Vimmerby Airport ',
    'HLG  - Wheeling Ohio County Airport ',
    'HLH  - Ulanhot Airport ',
    'HLI  - Hollister Municipal Airport    FAA  CVH   ',
    'HLL  - Hillside Airport ',
    'HLM  - Park Township Airport ',
    'HLN  - Helena Regional Airport ',
    'HLP  - Halim Perdanakusuma International Airport ',
    'HLR  - Hood Army Airfield ',
    'HLS  - St Helens Airport ',
    'HLT  - Hamilton Airport ',
    'HLV  - Helenvale Airport ',
    'HLW  - Hluhluwe Airport ',
    'HLZ  - Hamilton Airport ',
    'HMA  - Khanty Mansiysk Airport ',
    'HMB  - Sohag International Airport ',
    'HME  - Oued Irara–Krim Belkacem Airport ',
    'HMG  - Hermannsburg Airport ',
    'HMI  - Hami Airport    Kumul Airport   ',
    'HMJ  - Khmelnytskyi Airport ',
    'HMN  - Holloman Air Force Base ',
    'HMO  - General Ignacio Pesqueira García International Airport ',
    'HMR  - Hamar Airport, Stafsberg ',
    'HMT  - Hemet Ryan Airport ',
    'HMV  - Hemavan Tärnaby Airport ',
    'HMY  - Seosan Air Base ',
    'HNA  - Hanamaki Airport ',
    'HNB  - Huntingburg Airport ',
    'HNC  - Billy Mitchell Airport    FAA  HSE   ',
    'HND  - Haneda Airport ',
    'HNH  - Hoonah Airport ',
    'HNI  - Heiweni Airport ',
    'HNL  - Honolulu International Airport ',
    'HNM  - Hana Airport ',
    'HNN  - Honinabi Airport ',
    'HNS  - Haines Airport ',
    'HNY  - Hengyang Nanyue Airport ',
    'HOA  - Hola Airport ',
    'HOB  - Lea County Regional Airport ',
    'HOC  - Komako Airport ',
    'HOD  - Hodeida International Airport ',
    'HOE  - Ban Huoeisay Airport ',
    'HOF  - Al Ahsa International Airport ',
    'HOG  - Frank País Airport ',
    'HOH  - Hohenems Dornbirn Airport ',
    'HOI  - Hao Airport ',
    'HOK  - Hooker Creek Airport ',
    'HOM  - Homer Airport ',
    'HON  - Huron Regional Airport ',
    'HOO  - Nhon Co Airport ',
    'HOP  - Campbell Army Airfield ',
    'HOQ  - Hof–Plauen Airport ',
    'HOR  - Horta Airport ',
    'HOS  - Chos Malal Airport ',
    'HOT  - Memorial Field Airport ',
    'HOU  - William P  Hobby Airport ',
    'HOV  - Ørsta–Volda Airport, Hovden ',
    'HOX  - Homalin Airport ',
    'HOY  - Hoy Island Airport ',
    'HPA  - Lifuka Island Airport    Salote Pilolevu Airport   ',
    'HPB  - Hooper Bay Airport ',
    'HPE  - Hopevale Airport ',
    'HPG  - Shennongjia Hongping Airport ',
    'HPH  - Cat Bi International Airport ',
    'HPN  - Westchester County Airport ',
    'HPT  - Hampton Municipal Airport ',
    'HPV  - Princeville Airport    FAA  HI01   ',
    'HPY  - Baytown Airport ',
    'HQM  - Bowerman Airport ',
    'HRB  - Harbin Taiping International Airport ',
    'HRC  - Zhayrem Airport ',
    'HRE  - Harare International Airport ',
    'HRG  - Hurghada International Airport ',
    'HRI  - Mattala Rajapaksa International Airport ',
    'HRK  - Kharkiv International Airport    Osnova Airport   ',
    'HRL  - Valley International Airport ',
    'HRM  - Hassi R Mel Airport    Tilrempt Airport   ',
    'HRO  - Boone County Airport ',
    'HRR  - La Herrera Airport ',
    'HRS  - Harrismith Airport ',
    'HRT  - RAF Linton on Ouse ',
    'HRY  - Henbury Airport ',
    'HRZ  - Horizontina Airport ',
    'HSA  - Hazret Sultan International Airport ',
    'HSB  - Harrisburg Raleigh Airport ',
    'HSC  - Shaoguan Guitou Airport ',
    'HSG  - Saga Airport ',
    'HSH  - Henderson Executive Airport    FAA  HND   ',
    'HSI  - Hastings Municipal Airport ',
    'HSJ  - Zhengzhou Shangjie Airport ',
    'HSK  - Huesca–Pirineos Airport ',
    'HSL  - Huslia Airport    FAA  HLA   ',
    'HSM  - Horsham Airport ',
    'HSN  - Zhoushan Putuoshan Airport ',
    'HSP  - Ingalls Field ',
    'HSS  - Hisar Airport ',
    'HST  - Homestead Air Reserve Base ',
    'HSV  - Huntsville International Airport    Carl T  Jones Field   ',
    'HSZ  - Hsinchu Air Base ',
    'HTA  - Chita International Airport    Kadala Airport   ',
    'HTG  - Khatanga Airport ',
    'HTH  - Hawthorne Industrial Airport ',
    'HTI  - Great Barrier Reef Airport ',
    'HTL  - Roscommon County–Blodgett Memorial Airport ',
    'HTM  - Khatgal Airport ',
    'HTN  - Hotan Airport ',
    'HTO  - East Hampton Airport ',
    'HTR  - Hateruma Airport ',
    'HTS  - Tri State Airport    Milton J  Ferguson Field   ',
    'HTT  - Huatugou Airport ',
    'HTU  - Hopetoun Airport ',
    'HTV  - Huntsville Municipal Airport    Bruce Brothers Regional   ',
    'HTW  - Lawrence County Airpark ',
    'HTY  - Hatay Airport ',
    'HTZ  - Hato Corozal Airport ',
    'HUA  - Redstone Army Airfield ',
    'HUB  - Humbert River Airport ',
    'HUC  - Humacao Airport ',
    'HUD  - Humboldt Municipal Airport    FAA  0K7   ',
    'HUE  - Humera Airport ',
    'HUF  - Terre Haute International Airport    Hulman Field   ',
    'HUG  - Huehuetenango Airport ',
    'HUH  - Huahine – Fare Airport ',
    'HUI  - Phu Bai International Airport ',
    'HUJ  - Stan Stamper Municipal Airport    FAA  HHW   ',
    'HUK  - Hukuntsi Airport ',
    'HUL  - Houlton International Airport ',
    'HUM  - Houma–Terrebonne Airport ',
    'HUN  - Hualien Airport ',
    'HUO  - Holingol Huolinhe Airport ',
    'HUQ  - Hun Airport ',
    'HUS  - Hughes Airport ',
    'HUT  - Hutchinson Municipal Airport ',
    'HUU  - Alférez FAP David Figueroa Fernandini Airport ',
    'HUV  - Hudiksvall Airport ',
    'HUW  - Francisco Correa da Cruz Airport ',
    'HUX  - Bahías de Huatulco International Airport ',
    'HUY  - Humberside Airport ',
    'HUZ  - Huizhou Pingtan Airport ',
    'HVA  - Analalava Airport ',
    'HVB  - Hervey Bay Airport ',
    'HVD  - Khovd Airport ',
    'HVE  - Hanksville Airport ',
    'HVG  - Honningsvåg Airport, Valan ',
    'HVK  - Hólmavík Airport ',
    'HVN  - Tweed New Haven Airport ',
    'HVR  - Havre City–County Airport ',
    'HVS  - Hartsville Regional Airport ',
    'HWA  - Hawabango Airport ',
    'HWD  - Hayward Executive Airport ',
    'HWI  - Hawk Inlet Seaplane Base ',
    'HWK  - Wilpena Pound Airport ',
    'HWN  - Hwange National Park Airport ',
    'HWO  - North Perry Airport ',
    'HXD  - Delingha Airport ',
    'HXX  - Hay Airport ',
    'HYA  - Barnstable Municipal Airport    Boardman  Polando Field   ',
    'HYC  - Wycombe Air Park ',
    'HYD  - Rajiv Gandhi International Airport ',
    'HYF  - Hayfields Airport ',
    'HYG  - Hydaburg Seaplane Base ',
    'HYL  - Hollis Clark Bay Seaplane Base ',
    'HYN  - Taizhou Luqiao Airport ',
    'HYR  - Sawyer County Airport ',
    'HYS  - Hays Regional Airport ',
    'HYV  - Hyvinkää Airfield ',
    'HZA  - Heze Mudan Airport ',
    'HZB  - Merville–Calonne Airport ',
    'HZG  - Hanzhong Chenggu Airport ',
    'HZH  - Liping Airport ',
    'HZK  - Húsavík Airport ',
    'HZL  - Hazleton Municipal Airport ',
    'HZP  - Fort MacKay  Horizon Airport ',
    'HZU  - Chengdu Huaizhou Airport ',
    'HZV  - Hazyview Airport ',
    'IAA  - Igarka Airport ',
    'IAB  - McConnell Air Force Base ',
    'IAD  - Washington Dulles International Airport ',
    'IAG  - Niagara Falls International Airport ',
    'IAH  - George Bush Intercontinental Airport ',
    'IAM  - In Amenas Airport    Zarzaitine Airport   ',
    'IAN  - Bob Baker Memorial Airport ',
    'IAO  - Sayak Airport  1      Siargao Airport   ',
    'IAQ  - Bahregan Airport ',
    'IAR  - Tunoshna Airport ',
    'IAS  - Iași International Airport ',
    'IAU  - Iaura Airport ',
    'IBA  - Ibadan Airport ',
    'IBB  - General Villamil Airport ',
    'IBE  - Perales Airport ',
    'IBI  - Iboki Airport ',
    'IBL  - Indigo Bay Lodge Airport ',
    'IBO  - Ibo Island Airport ',
    'IBP  - Iberia Airport ',
    'IBR  - Ibaraki Airport ',
    'IBZ  - Ibiza Airport ',
    'ICA  - Icabarú Airport ',
    'ICC  - Andrés Miguel Salazar Marcano Airport ',
    'ICI  - Cicia Airport ',
    'ICK  - Majoor Henk Fernandes Airport    Nieuw Nickerie Airport   ',
    'ICL  - Schenck Field ',
    'ICN  - Incheon International Airport ',
    'ICO  - Sicogon Airport ',
    'ICS  - Cascade Airport  1      FAA  KU70   ',
    'ICT  - Wichita Dwight D  Eisenhower National Airport ',
    'ICY  - Icy Bay Airport    FAA  19AK   ',
    'IDA  - Idaho Falls Regional Airport ',
    'IDB  - Idre Airport ',
    'IDC  - Chilonzuine Island Airstrip ',
    'IDF  - Idiofa Airport ',
    'IDG  - Ida Grove Municipal Airport ',
    'IDH  - Idaho County Airport  1      FAA  GIC   ',
    'IDI  - Indiana County–Jimmy Stewart Airport ',
    'IDK  - Indulkana Airport ',
    'IDN  - Indagen Airport ',
    'IDO  - Santa Isabel do Morro Airport ',
    'IDP  - Independence Municipal Airport ',
    'IDR  - Devi Ahilyabai Holkar International Airport ',
    'IDY  - Île d Yeu Aerodrome ',
    'IEG  - Zielona Góra Airport ',
    'IEJ  - Iejima Airport ',
    'IES  - Riesa–Göhlis Airfield ',
    'IEV  - Kyiv International Airport   Zhuliany   ',
    'IFA  - Iowa Falls Municipal Airport ',
    'IFF  - Iffley Airport ',
    'IFH  - Hesa Air Base ',
    'IFJ  - Ísafjörður Airport ',
    'IFL  - Innisfail Airport ',
    'IFN  - Isfahan International Airport    Shahid Beheshti Int l   ',
    'IFO  - Ivano Frankivsk International Airport ',
    'IFP  - Laughlin  Bullhead International Airport ',
    'IFU  - Ifuru Airport ',
    'IGA  - Inagua Airport    Matthew Town Airport   ',
    'IGB  - Ingeniero Jacobacci Airport    Capitán FAA H  R  Borden Airport   ',
    'IGD  - Iğdır Airport ',
    'IGE  - Tchongorove Airport ',
    'IGG  - Igiugig Airport ',
    'IGH  - Ingham Airport ',
    'IGL  - Çiğli Air Base ',
    'IGM  - Kingman Airport ',
    'IGN  - Maria Cristina Airport    Iligan Airport   ',
    'IGO  - Jaime Ortíz Betancur Airport ',
    'IGR  - Cataratas del Iguazú International Airport ',
    'IGS  - Ingolstadt Manching Airport ',
    'IGT  - Magas Airport    Sleptsovskaya Airport   ',
    'IGU  - Foz do Iguaçu International Airport ',
    'IHC  - Inhaca Airport ',
    'IHN  - Qishn Airport ',
    'IHO  - Ihosy Airport ',
    'IHR  - Iranshahr Airport ',
    'IHU  - Ihu Airport ',
    'IIA  - Inishmaan Aerodrome ',
    'IIL  - Ilam Airport ',
    'IIS  - Nissan Island Airport ',
    'IJK  - Izhevsk Airport ',
    'IJU  - João Batista Bos Filho Airport ',
    'IJX  - Jacksonville Municipal Airport ',
    'IKA  - Tehran Imam Khomeini International Airport ',
    'IKB  - Wilkes County Airport    FAA  UKF   ',
    'IKI  - Iki Airport ',
    'IKK  - Greater Kankakee Airport ',
    'IKL  - Ikela Airport ',
    'IKO  - Nikolski Air Station ',
    'IKP  - Inkerman Airport ',
    'IKS  - Tiksi Airport ',
    'IKT  - International Airport Irkutsk ',
    'IKU  - Issyk Kul International Airport ',
    'ILA  - Illaga Airport ',
    'ILD  - Lleida–Alguaire Airport ',
    'ILE  - Skylark Field ',
    'ILF  - Ilford Airport ',
    'ILG  - Wilmington Airport    New Castle Airport   ',
    'ILH  - Storck Barracks ',
    'ILI  - Iliamna Airport ',
    'ILK  - Ilaka Est Airport    Atsinanana Airport   ',
    'ILL  - Willmar Municipal Airport    John L  Rice Field   ',
    'ILM  - Wilmington International Airport ',
    'ILN  - Wilmington Air Park ',
    'ILO  - Iloilo International Airport ',
    'ILP  - Île des Pins Airport ',
    'ILQ  - Ilo Airport ',
    'ILR  - Ilorin International Airport ',
    'ILS  - Ilopango International Airport ',
    'ILU  - Kilaguni Airport ',
    'ILX  - Ileg Airport ',
    'ILY  - Islay Airport    Glenegedale Airport   ',
    'ILZ  - Žilina Airport ',
    'IMA  - Iamalele Airport ',
    'IMB  - Imbaimadai Airport ',
    'IMD  - Imonda Airport ',
    'IMF  - Imphal International Airport    Tulihal Airport   ',
    'IMG  - Inhaminga Airport ',
    'IMI  - Ine Airport    FAA  N20   ',
    'IMK  - Simikot Airport ',
    'IML  - Imperial Municipal Airport ',
    'IMM  - Immokalee Regional Airport ',
    'IMN  - Imane Airport ',
    'IMO  - Zemio Airport ',
    'IMP  - Imperatriz Airport    Prefeito Renato Moreira Airport   ',
    'IMQ  - Maku International Airport ',
    'IMT  - Ford Airport ',
    'INA  - Inta Airport ',
    'INB  - Independence Airport ',
    'INC  - Yinchuan Hedong International Airport ',
    'IND  - Indianapolis International Airport ',
    'INE  - Chinde Airport ',
    'INF  - In Guezzam Airport ',
    'INH  - Inhambane Airport ',
    'INI  - Niš Constantine the Great Airport ',
    'INJ  - Injune Airport ',
    'INK  - Winkler County Airport ',
    'INL  - Falls International Airport ',
    'INM  - Innamincka Airport ',
    'INN  - Innsbruck Airport    Kranebitten Airport   ',
    'INO  - Inongo Airport ',
    'INQ  - Inisheer Aerodrome ',
    'INS  - Creech Air Force Base ',
    'INT  - Smith Reynolds Airport ',
    'INU  - Nauru International Airport ',
    'INV  - Inverness Airport ',
    'INW  - Winslow–Lindbergh Regional Airport ',
    'INX  - Inanwatan Airport ',
    'INY  - Inyati Airport ',
    'INZ  - In Salah Airport ',
    'IOA  - Ioannina National Airport ',
    'IOK  - Iokea Airport ',
    'IOM  - Isle of Man Airport    Ronaldsway Airport   ',
    'ION  - Impfondo Airport ',
    'IOP  - Ioma Airport ',
    'IOR  - Inishmore Aerodrome    Kilronan Airport   ',
    'IOS  - Ilhéus Jorge Amado Airport ',
    'IOT  - Illorsuit Heliport ',
    'IOW  - Iowa City Municipal Airport ',
    'IPA  - Ipota Airport ',
    'IPC  - Mataveri International Airport    Isla de Pascua Airport   ',
    'IPE  - Ipil Airport ',
    'IPG  - Ipiranga Airport ',
    'IPH  - Sultan Azlan Shah Airport ',
    'IPI  - San Luis Airport ',
    'IPL  - Imperial County Airport    Boley Field   ',
    'IPN  - Usiminas Airport ',
    'IPT  - Williamsport Regional Airport ',
    'IPU  - Ipiaú Airport ',
    'IPZ  - San Isidro de El General Airport ',
    'IQA  - Al Asad Airbase ',
    'IQM  - Qiemo Airport ',
    'IQN  - Qingyang Airport ',
    'IQQ  - Diego Aracena International Airport ',
    'IQT  - Coronel FAP Francisco Secada Vignetta International Airport ',
    'IRA  - Kirakira Airport    Ngorangora Airstrip   ',
    'IRB  - Iraan Municipal Airport    FAA  2F0   ',
    'IRC  - Circle City Airport    FAA  CRC   ',
    'IRD  - Ishwardi Airport ',
    'IRE  - Irecê Airport ',
    'IRG  - Lockhart River Airport ',
    'IRI  - Iringa Airport    Nduli Airport   ',
    'IRJ  - Capitán Vicente Almandos Almonacid Airport ',
    'IRK  - Kirksville Regional Airport ',
    'IRM  - Igrim Airport ',
    'IRN  - Iriona Airport ',
    'IRO  - Birao Airport ',
    'IRP  - Matari Airport ',
    'IRS  - Kirsch Municipal Airport ',
    'IRU  - Iranamadu Waterdrome ',
    'IRZ  - Tapuruquara Airport ',
    'ISA  - Mount Isa Airport ',
    'ISB  - Islamabad International Airport ',
    'ISC  - St Mary s Airport ',
    'ISD  - Iscuande Airport ',
    'ISE  - Isparta Süleyman Demirel Airport ',
    'ISG  - New Ishigaki Airport ',
    'ISI  - Isisford Airport ',
    'ISJ  - Isla Mujeres Airport ',
    'ISK  - Ozar Airport ',
    'ISL  - Istanbul Atatürk Airport ',
    'ISM  - Kissimmee Gateway Airport ',
    'ISN  - Sloulin Field International Airport ',
    'ISO  - Kinston Regional Jetport    Stallings Field   ',
    'ISP  - Long Island MacArthur Airport ',
    'ISQ  - Schoolcraft County Airport ',
    'ISS  - Wiscasset Airport    FAA  IWI   ',
    'IST  - Istanbul Airport ',
    'ISU  - Sulaimaniyah International Airport ',
    'ISW  - South Wood County Airport    Alexander Field   ',
    'ITA  - Itacoatiara Airport ',
    'ITB  - Itaituba Airport ',
    'ITE  - Ituberá Airport ',
    'ITH  - Ithaca Tompkins Regional Airport ',
    'ITI  - Itambacuri Airport ',
    'ITK  - Itokama Airport ',
    'ITM  - Osaka International Airport    Itami Int l   ',
    'ITN  - Itabuna Airport ',
    'ITO  - Hilo International Airport ',
    'ITP  - Itaperuna Airport ',
    'ITQ  - Itaqui Airport ',
    'ITR  - Itumbiara Airport ',
    'ITU  - Iturup Airport ',
    'IUE  - Niue International Airport    Hanan Int l   ',
    'IUL  - Ilu Airport ',
    'IUS  - Inus Airport ',
    'IVA  - Ambanja Airport ',
    'IVC  - Invercargill Airport ',
    'IVG  - Dolac Airport ',
    'IVI  - Viveros Island Airport ',
    'IVL  - Ivalo Airport ',
    'IVO  - Chibolo Airport ',
    'IVR  - Inverell Airport ',
    'IVW  - Inverway Airport ',
    'IWA  - Ivanovo Yuzhny Airport ',
    'IWD  - Gogebic–Iron County Airport ',
    'IWJ  - Iwami Airport    Hagi Iwami Airport   ',
    'IWK  - Marine Corps Air Station Iwakuni ',
    'IWO  - Iwo Jima Air Base ',
    'IWS  - West Houston Airport ',
    'IXA  - Agartala Airport    Singerbhil Airport   ',
    'IXB  - Bagdogra Airport ',
    'IXC  - Chandigarh Airport ',
    'IXD  - Allahabad Airport    Bamrauli Air Force Base   ',
    'IXE  - Mangalore Airport ',
    'IXG  - Belgaum Airport ',
    'IXH  - Kailashahar Airport ',
    'IXI  - Lilabari Airport ',
    'IXJ  - Jammu Airport    Satwari Airport   ',
    'IXK  - Keshod Airport ',
    'IXL  - Kushok Bakula Rimpochee Airport ',
    'IXM  - Madurai Airport ',
    'IXN  - Khowai Airport ',
    'IXP  - Pathankot Airport ',
    'IXQ  - Kamalpur Airport ',
    'IXR  - Birsa Munda Airport ',
    'IXS  - Silchar Airport    Kumbhirgram Air Force Base   ',
    'IXT  - Pasighat Airport ',
    'IXU  - Aurangabad Airport    Chikkalthana Airport   ',
    'IXV  - Along Airport ',
    'IXW  - Sonari Airport ',
    'IXX  - Bidar Airport ',
    'IXY  - Kandla Airport    Gandhidham Airport   ',
    'IXZ  - Veer Savarkar International Airport    Port Blair Airport   ',
    'IYK  - Inyokern Airport ',
    'IZA  - Presidente Itamar Franco Airport    Zona da Mata Regional Airport   ',
    'IZM  - metropolitan area2 ',
    'IZO  - Izumo Airport ',
    'IZT  - Ixtepec Airport ',
    'JAA  - Jalalabad Airport ',
    'JAB  - Jabiru Airport ',
    'JAC  - Jackson Hole Airport ',
    'JAD  - Jandakot Airport ',
    'JAE  - Jaén Airport ',
    'JAF  - Jaffna International Airport ',
    'JAG  - PAF Base Shahbaz ',
    'JAI  - Jaipur International Airport ',
    'JAK  - Jacmel Airport ',
    'JAL  - El Lencero Airport ',
    'JAM  - Bezmer Air Base ',
    'JAN  - Jackson–Evers International Airport ',
    'JAP  - Chacarita Airport ',
    'JAQ  - Jacquinot Bay Airport ',
    'JAR  - Jahrom Airport ',
    'JAS  - Jasper County Airport    Bell Field   ',
    'JAT  - Jabot Airport ',
    'JAU  - Francisco Carle Airport ',
    'JAV  - Ilulissat Airport ',
    'JAX  - Jacksonville International Airport ',
    'JBB  - Notohadinegoro Airport ',
    'JBQ  - La Isabela International Airport    Dr  Joaquin Balaguer Int l   ',
    'JBR  - Jonesboro Municipal Airport ',
    'JBS  - São Borja Airport ',
    'JBT  - Bethel Seaplane Base    FAA  Z59   ',
    'JCB  - Santa Terezinha Municipal Airport ',
    'JCI  - New Century AirCenter    FAA  IXD   ',
    'JCK  - Julia Creek Airport ',
    'JCM  - Jacobina Airport ',
    'JCR  - Jacareacanga Airport ',
    'JCT  - Kimble County Airport ',
    'JCY  - LBJ Ranch Airport    FAA  0TE7   ',
    'JDA  - Grant County Regional Airport    Ogilvie Field     FAA  GCD   ',
    'JDF  - Francisco Álvares de Assis Airport ',
    'JDG  - Jeongseok Airport ',
    'JDH  - Jodhpur Airport ',
    'JDN  - Jordan Airport ',
    'JDO  - Juazeiro do Norte Airport ',
    'JDR  - Prefeito Octávio de Almeida Neves Airport ',
    'JDZ  - Jingdezhen Luojia Airport ',
    'JED  - King Abdulaziz International Airport ',
    'JEE  - Jérémie Airport ',
    'JEF  - Jefferson City Memorial Airport ',
    'JEG  - Aasiaat Airport ',
    'JEJ  - Jeh Airport ',
    'JEK  - Jeki Airstrip ',
    'JEQ  - Jequié Airport ',
    'JER  - Jersey Airport ',
    'JFK  - John F  Kennedy International Airport ',
    'JFN  - Northeast Ohio Regional Airport    FAA  HZY   ',
    'JFR  - Paamiut Airport ',
    'JGA  - Jamnagar Airport    Govardhanpur Airport   ',
    'JGB  - Jagdalpur Airport ',
    'JGD  - Jiagedaqi Airport ',
    'JGN  - Jiayuguan Airport ',
    'JGS  - Jinggangshan Airport ',
    'JHB  - Senai International Airport ',
    'JHG  - Xishuangbanna Gasa Airport ',
    'JHL  - Fort MacKay  Albian Aerodrome  1      TC  CAL4   ',
    'JHM  - Kapalua Airport ',
    'JHS  - Sisimiut Airport ',
    'JHW  - Chautauqua County Jamestown Airport ',
    'JIA  - Juína Airport ',
    'JIB  - Djibouti–Ambouli International Airport ',
    'JIC  - Jinchang Jinchuan Airport ',
    'JIJ  - Wilwal International Airport ',
    'JIK  - Ikaria Island National Airport ',
    'JIL  - Jilin Ertaizi Airport ',
    'JIM  - Aba Segud Airport ',
    'JIN  - Jinja Airport ',
    'JIO  - Jos Orno Imsula Airport ',
    'JIP  - Jipijapa Airport ',
    'JIQ  - Qianjiang Wulingshan Airport ',
    'JIR  - Jiri Airport ',
    'JIU  - Jiujiang Lushan Airport ',
    'JIW  - Jiwani Airport ',
    'JJA  - Jajao Airport ',
    'JJD  - Comte  Ariston Pessoa Regional Airport ',
    'JJG  - Humberto Ghizzo Bortoluzzi Regional Airport ',
    'JJI  - Juanjuí Airport ',
    'JJM  - Mulika Lodge Airport ',
    'JJN  - Quanzhou Jinjiang International Airport ',
    'JKG  - Jönköping Airport ',
    'JKH  - Chios Island National Airport ',
    'JKL  - Kalymnos Island National Airport ',
    'JKR  - Janakpur Airport ',
    'JKT  - metropolitan area2 ',
    'JKV  - Cherokee County Airport    FAA  JSO   ',
    'JLA  - Quartz Creek Airport ',
    'JLN  - Joplin Regional Airport ',
    'JLR  - Jabalpur Airport    Dumna Airport   ',
    'JLS  - Jales Airport ',
    'JMB  - Jamba Airport ',
    'JMJ  - Lancang Jingmai Airport ',
    'JMK  - Mykonos Island National Airport ',
    'JMO  - Jomsom Airport ',
    'JMS  - Jamestown Regional Airport ',
    'JMU  - Jiamusi Dongjiao Airport ',
    'JNA  - Januária Airport ',
    'JNB  - O  R  Tambo International Airport ',
    'JNG  - Jining Qufu Airport ',
    'JNI  - Junín Airport ',
    'JNJ  - Ja Aluni Airport ',
    'JNU  - Juneau International Airport ',
    'JNX  - Naxos Island National Airport ',
    'JNZ  - Jinzhou Bay Airport ',
    'JOE  - Joensuu Airport ',
    'JOG  - Adisucipto International Airport ',
    'JOH  - Port St  Johns Airport ',
    'JOI  - Joinville Lauro Carneiro de Loyola Airport ',
    'JOJ  - Doris Lake Aerodrome  1      TC  CDL7   ',
    'JOK  - Yoshkar Ola Airport ',
    'JOL  - Jolo Airport ',
    'JOM  - Njombe Airport ',
    'JOP  - Josephstaal Airport ',
    'JOS  - Yakubu Gowon Airport ',
    'JOT  - Joliet Regional Airport ',
    'JPA  - Presidente Castro Pinto International Airport ',
    'JPR  - José Coleto Airport ',
    'JQA  - Qaarsut Airport    Uummannaq  Qaarsut Airport   ',
    'JQE  - Jaqué Airport ',
    'JRF  - Kalaeloa Airport  1      John Rodgers Field   ',
    'JRG  - Veer Surendra Sai Airport ',
    'JRH  - Jorhat Airport    Rowriah Airport   ',
    'JRN  - Juruena Airport ',
    'JRO  - Kilimanjaro International Airport ',
    'JRS  - Atarot Airport    Jerusalem International Airport   ',
    'JSA  - Jaisalmer Airport ',
    'JSH  - Sitia Public Airport ',
    'JSI  - Skiathos Island National Airport ',
    'JSJ  - Jiansanjiang Airport ',
    'JSK  - Jask Airport ',
    'JSM  - José de San Martín Airport ',
    'JSR  - Jessore Airport ',
    'JST  - John Murtha Johnstown–Cambria County Airport ',
    'JSU  - Maniitsoq Airport ',
    'JSY  - Syros Island National Airport ',
    'JTC  - Moussa Nakhl Tobias–Bauru  Arealva State Airport ',
    'JTI  - Jataí Airport ',
    'JTR  - Santorini   Thira   National Airport ',
    'JTY  - Astypalaia Island National Airport ',
    'JUA  - Inácio Luís do Nascimento Airport ',
    'JUB  - Juba International Airport ',
    'JUH  - Chizhou Jiuhuashan Airport ',
    'JUI  - Juist Airport ',
    'JUJ  - Gobernador Horacio Guzmán International Airport ',
    'JUL  - Inca Manco Cápac International Airport ',
    'JUM  - Jumla Airport ',
    'JUN  - Jundah Airport ',
    'JUO  - Juradó Airport ',
    'JUR  - Jurien Bay Airport ',
    'JUT  - Juticalpa Airport ',
    'JUV  - Upernavik Airport ',
    'JUZ  - Quzhou Airport ',
    'JVA  - Ankavandra Airport ',
    'JVI  - Central Jersey Regional Airport    FAA  47N   ',
    'JVL  - Southern Wisconsin Regional Airport ',
    'JWA  - Jwaneng Airport ',
    'JWN  - Zanjan Airport ',
    'JWO  - Jungwon Air Base ',
    'JXA  - Jixi Xingkaihu Airport ',
    'JXN  - Jackson County Airport    Reynolds Field   ',
    'JYR  - Jiroft Airport ',
    'JYV  - Jyväskylä Airport ',
    'JZH  - Jiuzhai Huanglong Airport ',
    'KAA  - Kasama Airport ',
    'KAB  - Kariba Airport ',
    'KAC  - Kamishly Airport ',
    'KAD  - Kaduna Airport ',
    'KAE  - Kake Seaplane Base ',
    'KAF  - Karato Airport ',
    'KAG  - Gangneung Air Base ',
    'KAI  - Kaieteur International Airport ',
    'KAJ  - Kajaani Airport ',
    'KAK  - Kar Airport ',
    'KAL  - Kaltag Airport ',
    'KAM  - Kamaran Airport ',
    'KAN  - Mallam Aminu Kano International Airport ',
    'KAO  - Kuusamo Airport ',
    'KAP  - Kapanga Airport ',
    'KAQ  - Kamulai Airport ',
    'KAR  - Kamarang Airport ',
    'KAS  - Karasburg Airport ',
    'KAT  - Kaitaia Airport ',
    'KAU  - Kauhava Airfield ',
    'KAV  - Kavanayén Airport ',
    'KAW  - Kawthaung Airport ',
    'KAX  - Kalbarri Airport ',
    'KAY  - Wakaya Airport ',
    'KAZ  - Kuabang Airport ',
    'KBA  - Kabala Airport ',
    'KBB  - Kirkimbie Airport ',
    'KBC  - Birch Creek Airport    FAA  Z91   ',
    'KBD  - Kimberley Downs Airport ',
    'KBE  - Bell Island Hot Springs Seaplane Base ',
    'KBF  - Karubaga Airport ',
    'KBG  - Kabalega Falls Airport ',
    'KBI  - Kribi Airport ',
    'KBJ  - Kings Canyon Airport ',
    'KBL  - Kabul International Airport ',
    'KBM  - Kabwum Airport ',
    'KBN  - Tunta Airport ',
    'KBO  - Kabalo Airport ',
    'KBP  - Boryspil International Airport ',
    'KBQ  - Kasungu Airport ',
    'KBR  - Sultan Ismail Petra Airport ',
    'KBS  - Bo Airport ',
    'KBT  - Kaben Airport ',
    'KBU  - Gusti Syamsir Alam Airport    Stagen Airport   ',
    'KBV  - Krabi Airport ',
    'KBW  - Chignik Bay Seaplane Base    FAA  Z78   ',
    'KBX  - Kambuaya Airport ',
    'KBY  - Streaky Bay Airport ',
    'KBZ  - Kaikoura Aerodrome ',
    'KCA  - Kuqa Qiuci Airport ',
    'KCB  - Tepoe Airstrip ',
    'KCC  - Coffman Cove Seaplane Base ',
    'KCD  - Kamur Airport ',
    'KCE  - Collinsville Airport ',
    'KCF  - Kadanwari Airport ',
    'KCG  - Chignik Fisheries Airport    closed   ',
    'KCH  - Kuching International Airport ',
    'KCJ  - Komaio Airport ',
    'KCK  - Kirensk Airport ',
    'KCL  - Chignik Lagoon Airport    Chignik Flats Airport   ',
    'KCM  - Kahramanmaraş Airport ',
    'KCN  - Chernofski Harbor Seaplane Base ',
    'KCO  - Cengiz Topel Naval Air Station ',
    'KCP  - Kamianets Podilskyi Airport ',
    'KCQ  - Chignik Lake Airport    FAA  A79   ',
    'KCR  - Colorado Creek Airport ',
    'KCS  - Kings Creek Station Airport ',
    'KCT  - Koggala Airport ',
    'KCU  - Masindi Airport ',
    'KCZ  - Kōchi Ryōma Airport ',
    'KDA  - Kolda North Airport ',
    'KDB  - Kambalda Airport ',
    'KDC  - Kandi Airport ',
    'KDD  - Khuzdar Airport ',
    'KDE  - Koroba Airport ',
    'KDH  - Kandahar International Airport ',
    'KDI  - Haluoleo Airport ',
    'KDJ  - Ndjolé Ville Airport ',
    'KDK  - Kodiak Municipal Airport ',
    'KDL  - Kärdla Airport ',
    'KDM  - Kaadedhdhoo Airport ',
    'KDN  - Ndendé Airport ',
    'KDO  - Kadhdhoo Airport ',
    'KDP  - Kandep Airport ',
    'KDQ  - Kamberatoro Airport ',
    'KDR  - Kandrian Airport ',
    'KDS  - Kamaran Downs Airport ',
    'KDT  - Kamphaeng Saen Airport ',
    'KDU  - Skardu Airport ',
    'KDV  - Vunisea Airport ',
    'KDW  - Victoria Reservoir Seaplane Base ',
    'KDX  - Kadugli Airport ',
    'KDY  - Teply Klyuch Airport ',
    'KDZ  - Polgolla Reservoir Seaplane Base ',
    'KEA  - Kerki Airport ',
    'KEB  - Nanwalek Airport ',
    'KEC  - Kasenga Airport ',
    'KED  - Kaédi Airport ',
    'KEE  - Kelle Airport ',
    'KEF  - Keflavík International Airport ',
    'KEG  - Keglsugl Airport ',
    'KEH  - Kenmore Air Harbor    FAA  S60   ',
    'KEI  - Kepi Airport ',
    'KEJ  - Kemerovo International Airport ',
    'KEK  - Ekwok Airport ',
    'KEL  - Kiel Airport ',
    'KEM  - Kemi Tornio Airport ',
    'KEN  - Kenema Airport ',
    'KEO  - Odienné Airport ',
    'KEP  - Nepalgunj Airport ',
    'KEQ  - Kebar Airport ',
    'KER  - Kerman Airport ',
    'KES  - Kelsey Airport ',
    'KET  - Kengtung Airport ',
    'KEU  - Keekorok Airport ',
    'KEV  - Halli Airport ',
    'KEW  - Keewaywin Airport    TC LID  CPV8   ',
    'KEX  - Kanabea Airport ',
    'KEY  - Kericho Airport ',
    'KEZ  - Kelani River Peliyagoda Waterdrome ',
    'KFA  - Kiffa Airport ',
    'KFE  - Fortescue Dave Forrest Airport ',
    'KFG  - Kalkgurung Airport ',
    'KFM  - Kirby Lake Airport ',
    'KFP  - False Pass Airport ',
    'KFS  - Kastamonu Airport ',
    'KFZ  - Kukës International Airport ',
    'KGA  - Kananga Airport ',
    'KGB  - Konge Airport ',
    'KGC  - Kingscote Airport ',
    'KGD  - Khrabrovo Airport ',
    'KGE  - Kaghau Airport ',
    'KGF  - Sary Arka Airport ',
    'KGG  - Kédougou Airport ',
    'KGH  - Yongai Airport ',
    'KGI  - Kalgoorlie Boulder Airport ',
    'KGJ  - Karonga Airport ',
    'KGK  - Koliganek Airport    FAA  JZZ   ',
    'KGL  - Kigali International Airport    Kanombe Airport   ',
    'KGM  - Kungim Airport ',
    'KGN  - Kasongo Lunda Airport ',
    'KGO  - Kirovohrad Airport ',
    'KGP  - Kogalym International Airport ',
    'KGR  - Kulgera Airport ',
    'KGS  - Kos Island International Airport ',
    'KGT  - Kangding Airport ',
    'KGU  - Keningau Airport ',
    'KGW  - Kagi Airport ',
    'KGX  - Grayling Airport ',
    'KGY  - Kingaroy Airport ',
    'KGZ  - Glacier Creek Airport ',
    'KHA  - Khaneh Airport    Piranshahr Airport   ',
    'KHC  - Kerch Airport    Voykovo Airport   ',
    'KHD  - Khorramabad Airport ',
    'KHE  - Kherson International Airport ',
    'KHG  - Kashgar Airport    Kashi Airport   ',
    'KHH  - Kaohsiung International Airport ',
    'KHI  - Jinnah International Airport ',
    'KHJ  - Kauhajoki Airfield ',
    'KHK  - Kharg Airport ',
    'KHM  - Khamti Airport ',
    'KHN  - Nanchang Changbei International Airport ',
    'KHO  - Khoka Moya Airport ',
    'KHR  - Kharkhorin Airport ',
    'KHS  - Khasab Airport ',
    'KHT  - Khost Airfield ',
    'KHU  - Kremenchuk Airport ',
    'KHV  - Khabarovsk Novy Airport ',
    'KHW  - Khwai River Airport ',
    'KHX  - Kihihi Airstrip ',
    'KHY  - Khoy Airport ',
    'KHZ  - Kauehi Aerodrome ',
    'KIA  - Kaiapit Airport ',
    'KIB  - Ivanof Bay Seaplane Base ',
    'KIC  - Mesa Del Rey Airport ',
    'KID  - Kristianstad Airport ',
    'KIE  - Aropa Airport ',
    'KIF  - Kingfisher Lake Airport ',
    'KIG  - Koingnaas Airport ',
    'KIH  - Kish International Airport ',
    'KII  - Kibuli Airport ',
    'KIJ  - Niigata Airport ',
    'KIK  - Kirkuk Airport ',
    'KIL  - Kilwa Airport ',
    'KIM  - Kimberley Airport ',
    'KIN  - Norman Manley International Airport ',
    'KIO  - Kili Airport    FAA  Q51   ',
    'KIP  - Kickapoo Downtown Airport    FAA  CWC   ',
    'KIQ  - Kira Airport ',
    'KIR  - Kerry Airport    Farranfore Airport   ',
    'KIS  - Kisumu International Airport ',
    'KIT  - Kithira Island National Airport ',
    'KIU  - Kiunga Airport ',
    'KIV  - Chișinău International Airport ',
    'KIW  - Southdowns Airport ',
    'KIX  - Kansai International Airport ',
    'KIY  - Kilwa Masoko Airport ',
    'KIZ  - Kikinonda Airport ',
    'KJA  - Yemelyanovo International Airport ',
    'KJB  - Kurnool Airport ',
    'KJH  - Kaili Huangping Airport ',
    'KJI  - Kanas Airport ',
    'KJK  - Kortrijk–Wevelgem International Airport ',
    'KJP  - Kerama Airport ',
    'KJT  - Kertajati International Airport ',
    'KJU  - Kamiraba Airport ',
    'KJX  - Blangpidie Airport ',
    'KKA  - Koyuk Alfred Adams Airport ',
    'KKB  - Kitoi Bay Seaplane Base ',
    'KKC  - Khon Kaen Airport ',
    'KKD  - Kokoda Airport ',
    'KKE  - Kerikeri Airport    Bay of Islands Airport   ',
    'KKG  - Konawaruk Airport ',
    'KKH  - Kongiganak Airport    FAA  DUY   ',
    'KKI  - Akiachak Airport    FAA  Z13   ',
    'KKJ  - Kitakyushu Airport ',
    'KKK  - Kalakaket Creek Air Station    FAA  1KC   ',
    'KKL  - Karluk Lake Seaplane Base ',
    'KKM  - Khok Kathiam Air Force Base ',
    'KKN  - Kirkenes Airport, Høybuktmoen ',
    'KKO  - Kaikohe Aerodrome ',
    'KKP  - Koolburra Airport ',
    'KKQ  - Krasnoselkup Airport ',
    'KKR  - Kaukura Airport ',
    'KKS  - Kashan Airport ',
    'KKT  - Kentland Municipal Airport    FAA  50I   ',
    'KKU  - Ekuk Airport ',
    'KKW  - Kikwit Airport ',
    'KKX  - Kikai Airport    Kikaiga Shima Airport   ',
    'KKY  - Kilkenny Airport ',
    'KKZ  - Koh Kong Airport ',
    'KLB  - Kalabo Airport ',
    'KLC  - Kaolack Airport ',
    'KLD  - Migalovo Air Base ',
    'KLE  - Kaélé Airport ',
    'KLF  - Grabtsevo Airport ',
    'KLG  - Kalskag Airport ',
    'KLH  - Kolhapur Airport ',
    'KLI  - Kotakoli Air Base ',
    'KLK  - Kalokol Airport    Fergusons Gulf Airport   ',
    'KLL  - Levelock Airport    FAA  9Z8   ',
    'KLM  - Kalaleh Airport ',
    'KLN  - Larsen Bay Airport    FAA  2A3   ',
    'KLO  - Kalibo International Airport ',
    'KLQ  - Keluang Airport ',
    'KLR  - Kalmar Airport ',
    'KLS  - Southwest Washington Regional Airport ',
    'KLU  - Klagenfurt Airport ',
    'KLV  - Karlovy Vary Airport ',
    'KLW  - Klawock Airport    FAA  AKW   ',
    'KLX  - Kalamata International Airport ',
    'KLY  - Kamisuku Airport ',
    'KLZ  - Kleinzee Airport ',
    'KMA  - Kerema Airport ',
    'KMB  - Koinambe Airport ',
    'KMC  - King Khaled Military City Airport ',
    'KME  - Kamembe Airport ',
    'KMF  - Kamina Airport ',
    'KMG  - Kunming Changshui International Airport ',
    'KMH  - Johan Pienaar Airport ',
    'KMI  - Miyazaki Airport ',
    'KMJ  - Kumamoto Airport ',
    'KMK  - Makabana Airport ',
    'KML  - Kamileroi Airport ',
    'KMM  - Kimam Airport ',
    'KMN  - Kamina Airport  1      Kamina Ville Airport   ',
    'KMO  - Manokotak Airport    FAA  MBA   ',
    'KMP  - Keetmanshoop Airport ',
    'KMQ  - Komatsu Airport    Kanazawa Airport   ',
    'KMR  - Karimui Airport ',
    'KMS  - Kumasi Airport ',
    'KMT  - Kampot Airport ',
    'KMU  - Kismayo Airport    Kisimayu Airport   ',
    'KMV  - Kalaymyo Airport    Kalemyo Airport   ',
    'KMW  - Kostroma Airport ',
    'KMX  - King Khalid Air Base ',
    'KMY  - Moser Bay Seaplane Base ',
    'KMZ  - Kaoma Airport ',
    'KNA  - Viña del Mar Airport ',
    'KNB  - Kanab Municipal Airport ',
    'KND  - Kindu Airport ',
    'KNE  - Kanainj Airport ',
    'KNF  - RAF Marham ',
    'KNG  - Kaimana Airport ',
    'KNH  - Kinmen Airport    Shang Yi Airport   ',
    'KNI  - Katanning Airport ',
    'KNJ  - Kindamba Airport ',
    'KNK  - Kokhanok Airport    FAA  9K2   ',
    'KNL  - Kelanoa Airport ',
    'KNM  - Kaniama Airport ',
    'KNN  - Kankan Airport    Diankana Airport   ',
    'KNO  - Kualanamu International Airport ',
    'KNP  - Kapanda Airport ',
    'KNQ  - Koné Airport ',
    'KNR  - Jam Airport ',
    'KNS  - King Island Airport ',
    'KNT  - Kennett Memorial Airport    FAA  TKX   ',
    'KNU  - Kanpur Airport    Chakeri Air Force Station   ',
    'KNW  - New Stuyahok Airport ',
    'KNX  - East Kimberley Regional Airport ',
    'KNZ  - Kéniéba Airport ',
    'KOA  - Kona International Airport at Keāhole ',
    'KOC  - Koumac Airport ',
    'KOD  - Kotabangun Airport ',
    'KOE  - El Tari Airport ',
    'KOF  - Komatipoort Airport ',
    'KOG  - Khong Island Airport ',
    'KOH  - Koolatah Airport ',
    'KOI  - Kirkwall Airport ',
    'KOJ  - Kagoshima Airport ',
    'KOK  - Kokkola Pietarsaari Airport ',
    'KOL  - Koumala Airport ',
    'KOM  - Komo Manda Airport ',
    'KON  - Kontum Airport ',
    'KOO  - Kongolo Airport ',
    'KOP  - Nakhon Phanom Airport ',
    'KOQ  - Köthen Airport ',
    'KOR  - Kakoro Airport ',
    'KOS  - Sihanoukville International Airport    Kaong Kang Airport   ',
    'KOT  - Kotlik Airport    FAA  2A9   ',
    'KOU  - Koulamoutou Airport ',
    'KOV  - Kokshetau Airport ',
    'KOW  - Ganzhou Huangjin Airport ',
    'KOX  - Kokonao Airport ',
    'KOY  - Olga Bay Seaplane Base ',
    'KOZ  - Ouzinkie Airport    FAA  4K5   ',
    'KPA  - Kopiago Airport ',
    'KPB  - Point Baker Seaplane Base ',
    'KPC  - Port Clarence Coast Guard Station ',
    'KPE  - Yapsiei Airport ',
    'KPF  - Kondubol Airport ',
    'KPG  - Kurupung Airport ',
    'KPI  - Kapit Airport ',
    'KPL  - Kapal Airport ',
    'KPM  - Kompiam Airport ',
    'KPN  - Kipnuk Airport    FAA  IIK   ',
    'KPO  - Pohang Airport ',
    'KPP  - Kalpowar Airport ',
    'KPR  - Port Williams Seaplane Base ',
    'KPS  - Kempsey Airport ',
    'KPT  - Jackpot Airport    Hayden Field     FAA  06U   ',
    'KPV  - Perryville Airport    FAA  PEV   ',
    'KPW  - Keperveyem Airport ',
    'KPY  - Port Bailey Seaplane Base ',
    'KQA  - Akutan Airport ',
    'KQH  - Ajmer Kishangarh Airport ',
    'KQL  - Kol Airport ',
    'KQR  - Karara Airport ',
    'KQT  - Bokhtar International Airport ',
    'KRA  - Kerang Airport ',
    'KRB  - Karumba Airport ',
    'KRC  - Depati Parbo Airport ',
    'KRD  - Kurundi Airport ',
    'KRE  - Kirundo Airport ',
    'KRF  - Höga Kusten Airport    Kramfors Sollefteå Airport   ',
    'KRG  - Karasabai Airport ',
    'KRI  - Kikori Airport ',
    'KRJ  - Karawari Airport ',
    'KRK  - John Paul II International Airport Kraków–Balice ',
    'KRL  - Korla Airport ',
    'KRM  - Karanambo Airport ',
    'KRN  - Kiruna Airport ',
    'KRO  - Kurgan Airport ',
    'KRP  - Karup Airport ',
    'KRQ  - Kramatorsk Airport ',
    'KRR  - Krasnodar International Airport    Pashkovsky Airport   ',
    'KRS  - Kristiansand Airport, Kjevik ',
    'KRT  - Khartoum International Airport ',
    'KRU  - Kerau Airport ',
    'KRV  - Kimwarer Airport    Kerio Valley Airport   ',
    'KRW  - Turkmenbashi International Airport ',
    'KRX  - Karkar Airport ',
    'KRY  - Karamay Airport ',
    'KRZ  - Basango Mboliasa Airport ',
    'KSA  - Kosrae International Airport    FAA  TTK   ',
    'KSB  - Kasanombe Airport ',
    'KSC  - Košice International Airport ',
    'KSD  - Karlstad Airport ',
    'KSE  - Kasese Airport ',
    'KSF  - Kassel Airport ',
    'KSG  - Kisengam Airport ',
    'KSH  - Shahid Ashrafi Esfahani Airport    Kermanshah Airport   ',
    'KSI  - Kissidougou Airport ',
    'KSJ  - Kasos Island Public Airport ',
    'KSK  - Karlskoga Airport ',
    'KSL  - Kassala Airport ',
    'KSM  - St  Mary s Airport ',
    'KSN  - Kostanay Airport ',
    'KSO  - Kastoria National Airport    Aristotelis Airport   ',
    'KSP  - Kosipe Airport ',
    'KSQ  - Karshi Airport ',
    'KSR  - H  Aroeppala Airport ',
    'KSS  - Sikasso Airport ',
    'KST  - Rabak Airport ',
    'KSU  - Kristiansund Airport, Kvernberget ',
    'KSV  - Springvale Airport ',
    'KSW  - Kiryat Shmona Airport ',
    'KSX  - Yasuru Airport ',
    'KSY  - Kars Harakani Airport ',
    'KSZ  - Kotlas Airport ',
    'KTA  - Karratha Airport ',
    'KTB  - Thorne Bay Seaplane Base ',
    'KTC  - Katiola Airport ',
    'KTD  - Kitadaito Airport ',
    'KTE  - Kerteh Airport ',
    'KTF  - Takaka Aerodrome ',
    'KTG  - Rahadi Osman Airport    Ketapang Airport   ',
    'KTH  - Tikchik Lodge Seaplane Base    FAA  AK56   ',
    'KTI  - Kratié Airport ',
    'KTJ  - Kichwa Tembo Airport ',
    'KTK  - Kunua Airport ',
    'KTL  - Kitale Airport ',
    'KTM  - Tribhuvan International Airport ',
    'KTN  - Ketchikan International Airport ',
    'KTO  - Kato Airport ',
    'KTP  - Tinson Pen Aerodrome ',
    'KTQ  - Kitee Airfield ',
    'KTR  - RAAF Base Tindal ',
    'KTS  - Brevig Mission Airport ',
    'KTT  - Kittilä Airport ',
    'KTU  - Kota Airport ',
    'KTV  - Kamarata Airport ',
    'KTW  - Katowice International Airport ',
    'KTX  - Koutiala Airport ',
    'KTY  - Katukurunda Airport ',
    'KUA  - Sultan Haji Ahmad Shah Airport    RMAF Kuantan   ',
    'KUC  - Kuria Airport ',
    'KUD  - Kudat Airport ',
    'KUE  - Kukundu Airport ',
    'KUF  - Kurumoch International Airport ',
    'KUG  - Kubin Airport ',
    'KUH  - Kushiro Airport ',
    'KUK  - Kasigluk Airport    FAA  Z09   ',
    'KUL  - Kuala Lumpur International Airport ',
    'KUM  - Yakushima Airport ',
    'KUN  - Kaunas International Airport ',
    'KUO  - Kuopio Airport ',
    'KUP  - Kupiano Airport ',
    'KUQ  - Kuri Airport ',
    'KUR  - Razer Airport ',
    'KUS  - Kulusuk Airport ',
    'KUT  - David the Builder Kutaisi International Airport ',
    'KUU  - Bhuntar Airport    Kullu Manali Airport   ',
    'KUV  - Gunsan Airport ',
    'KUX  - Kuyol Airport ',
    'KUY  - Kamusi Airport ',
    'KVA  - Kavala International Airport    Alexander the Great Airport   ',
    'KVB  - Skövde Airport ',
    'KVC  - King Cove Airport ',
    'KVD  - Ganja International Airport ',
    'KVE  - Kitava Island Airport ',
    'KVG  - Kavieng Airport ',
    'KVK  - Kirovsk Apatity Airport    Khibiny Airport   ',
    'KVL  - Kivalina Airport ',
    'KVM  - Markovo Airport ',
    'KVO  - Morava Airport ',
    'KVR  - Kavalerovo Airport ',
    'KVU  - Korolevu Seaplane Base ',
    'KVX  - Pobedilovo Airport ',
    'KWA  - Bucholz Army Airfield ',
    'KWB  - Dewadaru Airport ',
    'KWD  - Kavadja Airport ',
    'KWE  - Guiyang Longdongbao International Airport ',
    'KWF  - Waterfall Seaplane Base ',
    'KWG  - Kryvyi Rih International Airport ',
    'KWH  - Khwahan Airport ',
    'KWI  - Kuwait International Airport ',
    'KWJ  - Gwangju Airport ',
    'KWK  - Kwigillingok Airport    FAA  GGV   ',
    'KWL  - Guilin Liangjiang International Airport ',
    'KWM  - Kowanyama Airport ',
    'KWN  - Quinhagak Airport    FAA  AQH   ',
    'KWO  - Kawito Airport ',
    'KWP  - West Point Village Seaplane Base ',
    'KWR  - Kwai Harbour Airport ',
    'KWS  - Kwailabesi Airport ',
    'KWT  - Kwethluk Airport ',
    'KWV  - Kurwina Airport ',
    'KWX  - Kiwai Island Airport ',
    'KWY  - Kiwayu Airport ',
    'KWZ  - Kolwezi Airport ',
    'KXA  - Kasaan Seaplane Base ',
    'KXD  - Kondinskoye Airport ',
    'KXE  - Klerksdorp Airport    P C  Pelser Airport   ',
    'KXF  - Koro Airport ',
    'KXK  - Komsomolsk on Amur Airport ',
    'KXR  - Karoola Airport ',
    'KXU  - Katiu Airport ',
    'KYA  - Konya Airport ',
    'KYD  - Lanyu Airport ',
    'KYE  - Rene Mouawad Air Base    Kleyate Airport   ',
    'KYF  - Yeelirrie Airport ',
    'KYI  - Yalata Airport ',
    'KYK  - Karluk Airport ',
    'KYO  - Tampa North Aero Park  1      FAA  X39   ',
    'KYP  - Kyaukpyu Airport ',
    'KYS  - Kayes Airport    Dag Dag Airport   ',
    'KYT  - Kyauktu Airport ',
    'KYU  - Koyukuk Airport ',
    'KYX  - Yalumet Airport ',
    'KYZ  - Kyzyl Airport ',
    'KZB  - Zachar Bay Seaplane Base ',
    'KZC  - Kampong Chhnang Airport ',
    'KZD  - Krakor Airport ',
    'KZF  - Kaintiba Airport ',
    'KZG  - Kitzingen Airport ',
    'KZI  - Kozani National Airport    Filippos Airport   ',
    'KZN  - Kazan International Airport ',
    'KZO  - Kyzylorda Airport ',
    'KZR  - Zafer Airport ',
    'KZS  - Kastellorizo Island Public Airport ',
    'LAA  - Lamar Municipal Airport ',
    'LAB  - Lab Lab Airport ',
    'LAC  - Layang Layang Airport    Swallow Reef Airport   ',
    'LAD  - Quatro de Fevereiro Airport ',
    'LAE  - Lae Nadzab Airport ',
    'LAF  - Purdue University Airport ',
    'LAH  - Oesman Sadik Airport ',
    'LAI  - Lannion – Côte de Granit Airport ',
    'LAJ  - Antônio Correia Pinto de Macedo Airport ',
    'LAK  - Aklavik  Freddie Carmichael Airport ',
    'LAL  - Lakeland Linder International Airport ',
    'LAM  - Los Alamos County Airport ',
    'LAN  - Capital Region International Airport ',
    'LAO  - Laoag International Airport ',
    'LAP  - Manuel Márquez de León International Airport ',
    'LAQ  - Al Abraq International Airport ',
    'LAR  - Laramie Regional Airport ',
    'LAS  - Harry Reid International Airport ',
    'LAU  - Manda Airport ',
    'LAW  - Lawton–Fort Sill Regional Airport ',
    'LAX  - Los Angeles International Airport ',
    'LAY  - Ladysmith Airport ',
    'LAZ  - Bom Jesus da Lapa Airport ',
    'LBA  - Leeds Bradford Airport ',
    'LBB  - Lubbock Preston Smith International Airport ',
    'LBC  - Lübeck Airport ',
    'LBD  - Khujand Airport ',
    'LBE  - Arnold Palmer Regional Airport ',
    'LBF  - North Platte Regional Airport    Lee Bird Field   ',
    'LBG  - Paris–Le Bourget Airport ',
    'LBH  - Palm Beach Water Airport ',
    'LBI  - Le Sequestre Airport ',
    'LBJ  - Komodo Airport ',
    'LBK  - Liboi Airport ',
    'LBL  - Liberal Mid America Regional Airport ',
    'LBM  - Luabo Airport ',
    'LBN  - Lake Baringo Airport ',
    'LBO  - Lusambo Airport ',
    'LBP  - Long Banga Airport ',
    'LBQ  - Lambaréné Airport ',
    'LBR  - Lábrea Airport ',
    'LBS  - Labasa Airport ',
    'LBT  - Lumberton Municipal Airport ',
    'LBU  - Labuan Airport ',
    'LBV  - Libreville International Airport ',
    'LBW  - Juvai Semaring Airport    Long Bawan Airport   ',
    'LBX  - Lubang Airport ',
    'LBY  - La Baule Escoublac Airport ',
    'LBZ  - Lucapa Airport ',
    'LCA  - Larnaca International Airport ',
    'LCB  - Pontes e Lacerda Airport ',
    'LCC  - Galatina Air Base ',
    'LCD  - Louis Trichardt Airport ',
    'LCE  - Golosón International Airport ',
    'LCF  - Río Dulce Airport    Las Vegas Airport   ',
    'LCG  - A Coruña Airport ',
    'LCH  - Lake Charles Regional Airport ',
    'LCI  - Laconia Municipal Airport ',
    'LCJ  - Łódź Władysław Reymont Airport ',
    'LCK  - Rickenbacker International Airport ',
    'LCL  - La Coloma Airport ',
    'LCM  - La Cumbre Airport ',
    'LCN  - Balcanoona Airport ',
    'LCO  - Lague Airport ',
    'LCP  - Loncopué Airport ',
    'LCQ  - Lake City Gateway Airport ',
    'LCR  - La Chorrera Airport ',
    'LCT  - Shijiazhuang Luancheng Airport ',
    'LCV  - Lucca Tassignano Airport ',
    'LCX  - Longyan Guanzhishan Airport ',
    'LCY  - London City Airport ',
    'LDA  - Malda Airport ',
    'LDB  - Londrina–Governador José Richa Airport ',
    'LDC  - Lindeman Island Airport ',
    'LDE  - Tarbes–Lourdes–Pyrénées Airport ',
    'LDG  - Leshukonskoye Airport ',
    'LDH  - Lord Howe Island Airport ',
    'LDI  - Lindi Airport    Kikwetu Airport   ',
    'LDJ  - Linden Airport ',
    'LDK  - Lidköping Hovby Airport ',
    'LDM  - Mason County Airport ',
    'LDN  - Lamidanda Airport ',
    'LDO  - Laduani Airstrip ',
    'LDR  - Lawdar Airport ',
    'LDS  - Yichun Lindu Airport ',
    'LDU  - Lahad Datu Airport ',
    'LDV  - Landivisiau Air Base ',
    'LDW  - Lansdowne Airport ',
    'LDX  - Saint Laurent du Maroni Airport ',
    'LDY  - City of Derry Airport ',
    'LDZ  - Londolozi Airport ',
    'LEA  - Learmonth Airport ',
    'LEB  - Lebanon Municipal Airport ',
    'LEC  - Coronel Horácio de Mattos Airport ',
    'LED  - Pulkovo Airport ',
    'LEE  - Leesburg International Airport ',
    'LEF  - Lebakeng Airport ',
    'LEG  - Aleg Airport ',
    'LEH  - Le Havre – Octeville Airport ',
    'LEI  - Almería Airport ',
    'LEJ  - Leipzig  Halle Airport ',
    'LEK  - Tata Airport ',
    'LEL  - Lake Evella Airport ',
    'LEM  - Lemmon Municipal Airport ',
    'LEN  - León Airport ',
    'LEO  - Lékoni Airport ',
    'LEP  - Leopoldina Airport ',
    'LEQ  - Land s End Airport ',
    'LER  - Leinster Airport ',
    'LES  - Lesobeng Airport ',
    'LET  - Alfredo Vásquez Cobo International Airport ',
    'LEU  - La Seu d Urgell Airport ',
    'LEV  - Levuka Airfield    Bureta Airport   ',
    'LEW  - Auburn  Lewiston Municipal Airport ',
    'LEX  - Blue Grass Airport ',
    'LEY  - Lelystad Airport ',
    'LEZ  - La Esperanza Airport ',
    'LFB  - Lumbo Airport ',
    'LFI  - Langley Air Force Base ',
    'LFK  - Angelina County Airport ',
    'LFM  - Lamerd Airport ',
    'LFN  - Triangle North Executive Airport    Franklin County Airport     FAA  LHZ   ',
    'LFO  - Kelafo Airport ',
    'LFP  - Lakefield Airport ',
    'LFQ  - Linfen Qiaoli Airport ',
    'LFR  - La Fría Airport ',
    'LFT  - Lafayette Regional Airport ',
    'LFW  - Lomé–Tokoin Airport    Gnassingbé Eyadéma Int l   ',
    'LGA  - LaGuardia Airport ',
    'LGB  - Long Beach Airport ',
    'LGC  - LaGrange Callaway Airport ',
    'LGD  - La Grande  Union County Airport ',
    'LGE  - Lake Gregory Airport ',
    'LGF  - Laguna Army Airfield ',
    'LGG  - Liège Airport ',
    'LGH  - Leigh Creek Airport ',
    'LGI  - Deadman s Cay Airport ',
    'LGK  - Langkawi International Airport ',
    'LGL  - Long Lellang Airport ',
    'LGM  - Laiagam Airport ',
    'LGN  - Linga Linga Airport ',
    'LGO  - Langeoog Airport ',
    'LGQ  - Lago Agrio Airport ',
    'LGR  - Cochrane Airfield ',
    'LGS  - Comodoro D  Ricardo Salomón Airport ',
    'LGT  - Las Gaviotas Airport ',
    'LGU  - Logan Cache Airport ',
    'LGW  - Gatwick Airport ',
    'LGX  - Lugh Ganane Airport ',
    'LGY  - Lagunillas Airport ',
    'LHA  - Flughafen Lahr    Lahr Airport   ',
    'LHE  - Allama Iqbal International Airport ',
    'LHG  - Lightning Ridge Airport ',
    'LHI  - Lereh Airport ',
    'LHK  - Laohekou Airport ',
    'LHP  - Lehu Airport ',
    'LHR  - Heathrow Airport ',
    'LHS  - Las Heras Airport ',
    'LHU  - Lianshulu Airport ',
    'LHV  - William T  Piper Memorial Airport ',
    'LHW  - Lanzhou Zhongchuan International Airport ',
    'LIA  - Liangping Airport ',
    'LIB  - Limbunya Airport ',
    'LIC  - Limon Municipal Airport ',
    'LIE  - Libenge Airport ',
    'LIF  - Ouanaham Airport ',
    'LIG  - Limoges – Bellegarde Airport ',
    'LIH  - Lihue Airport ',
    'LII  - Mulia Airport ',
    'LIK  - Likiep Airport ',
    'LIL  - Lille Airport    Lille–Lesquin Airport   ',
    'LIM  - Jorge Chávez International Airport ',
    'LIN  - Linate Airport ',
    'LIO  - Limón International Airport ',
    'LIP  - Lins Airport ',
    'LIQ  - Lisala Airport ',
    'LIR  - Daniel Oduber Quirós International Airport ',
    'LIS  - Lisbon Portela Airport ',
    'LIT  - Clinton National Airport    Adams Field   ',
    'LIV  - Livengood Camp Airport    FAA  4AK   ',
    'LIW  - Loikaw Airport ',
    'LIX  - Likoma Airport ',
    'LIY  - MidCoast Regional Airport at Wright Army Airfield    FAA  LHW   ',
    'LIZ  - Loring International Airport    FAA  ME16   ',
    'LJA  - Lodja Airport ',
    'LJG  - Lijiang Sanyi Airport ',
    'LJN  - Texas Gulf Coast Regional Airport    FAA  LBX   ',
    'LJU  - Ljubljana Jože Pučnik Airport    Brnik Airport   ',
    'LKA  - Gewayantana Airport ',
    'LKB  - Lakeba Airport ',
    'LKC  - Lekana Airport ',
    'LKD  - Lakeland Downs Airport ',
    'LKE  - Kenmore Air Harbor Seaplane Base    Seattle Lake Union SPB     FAA  W55   ',
    'LKG  - Lokichogio Airport ',
    'LKH  - Long Akah Airport ',
    'LKK  - Kulik Lake Airport ',
    'LKL  - Lakselv Airport, Banak ',
    'LKN  - Leknes Airport ',
    'LKO  - Chaudhary Charan Singh International Airport ',
    'LKP  - Lake Placid Airport ',
    'LKR  - Las Khorey Airport ',
    'LKU  - Lake Turkana Airport ',
    'LKV  - Lake County Airport ',
    'LKW  - Lekhwair Airport ',
    'LKY  - Lake Manyara Airport ',
    'LKZ  - RAF Lakenheath ',
    'LLA  - Luleå Airport    Kallax Airport   ',
    'LLB  - Libo Airport    Qiannan Airport   ',
    'LLC  - Cagayan North International Airport    Lal lo Airport   ',
    'LLE  - Malelane Airport ',
    'LLF  - Yongzhou Lingling Airport ',
    'LLG  - Chillagoe Airport ',
    'LLH  - Las Limas Airport ',
    'LLI  - Lalibela Airport ',
    'LLJ  - Silampari Airport ',
    'LLK  - Lankaran International Airport ',
    'LLL  - Lissadell Airport ',
    'LLM  - Lomlom Airport ',
    'LLN  - Kelila Airport ',
    'LLO  - Palopo Lagaligo Airport ',
    'LLP  - Linda Downs Airport ',
    'LLS  - Alférez Armando Rodríguez Airport ',
    'LLT  - Lobito Airport ',
    'LLV  - Lüliang Airport ',
    'LLW  - Lilongwe International Airport    Kamuzu Int l   ',
    'LLX  - Caledonia County Airport    FAA  CDA   ',
    'LLY  - South Jersey Regional Airport    FAA  VAY   ',
    'LMA  - Lake Minchumina Airport    FAA  MHM   ',
    'LMB  - Salima Airport ',
    'LMC  - La Macarena Airport ',
    'LMD  - Los Menucos Airport ',
    'LME  - Le Mans Arnage Airport ',
    'LMG  - Lamassa Airport ',
    'LMH  - Limón Airport ',
    'LMI  - Lumi Airport ',
    'LML  - Lae Airport ',
    'LMM  - Fort Valley Federal International Airport ',
    'LMN  - Limbang Airport ',
    'LMO  - RAF Lossiemouth ',
    'LMP  - Lampedusa Airport ',
    'LMQ  - Marsa Brega Airport ',
    'LMR  - Finsch Mine Airport ',
    'LMS  - Louisville Winston County Airport ',
    'LMT  - Klamath Falls Airport ',
    'LMU  - Letung Airport ',
    'LMX  - Lopéz de Micay Airport ',
    'LMY  - Lake Murray Airport ',
    'LMZ  - Palma Airport ',
    'LNA  - Palm Beach County Park Airport ',
    'LNB  - Lamen Bay Airport ',
    'LNC  - Lengbati Airport ',
    'LND  - Hunt Field ',
    'LNE  - Lonorore Airport ',
    'LNF  - Munbil Airport ',
    'LNG  - Lese Airport ',
    'LNH  - Lake Nash Airport ',
    'LNI  - Point Lonely Short Range Radar Site    FAA  AK71   ',
    'LNJ  - Lincang Airport ',
    'LNK  - Lincoln Airport ',
    'LNL  - Longnan Chengzhou Airport ',
    'LNM  - Langimar Airport ',
    'LNN  - Lost Nation Airport ',
    'LNO  - Leonora Airport ',
    'LNP  - Lonesome Pine Airport ',
    'LNQ  - Loani Airport ',
    'LNR  - Tri County Regional Airport ',
    'LNS  - Lancaster Airport ',
    'LNU  - Robert Atty Bessing Airport ',
    'LNV  - Lihir Island Airport ',
    'LNX  - Smolensk South Airport ',
    'LNY  - Lanai Airport ',
    'LNZ  - Linz Airport    Blue Danube Airport   ',
    'LOA  - Lorraine Airport ',
    'LOB  - San Rafael Airport ',
    'LOC  - Lock Airport ',
    'LOD  - Longana Airport ',
    'LOE  - Loei Airport ',
    'LOF  - Loen Airport ',
    'LOH  - Ciudad de Catamayo Airport ',
    'LOI  - Helmuth Baungartem Airport ',
    'LOK  - Lodwar Airport ',
    'LOL  - Derby Field ',
    'LOM  - Francisco Primo de Verdad National Airport ',
    'LON  - metropolitan area3 ',
    'LOO  - L Mekrareg Airport    Laghouat Airport   ',
    'LOP  - Lombok International Airport ',
    'LOQ  - Lobatse Airport ',
    'LOS  - Murtala Muhammed International Airport ',
    'LOT  - Lewis University Airport ',
    'LOU  - Bowman Field ',
    'LOV  - Venustiano Carranza International Airport ',
    'LOW  - Louisa County Airport    Freeman Field     FAA  LKU   ',
    'LOX  - Los Tablones Airport ',
    'LOY  - Loiyangalani Airport ',
    'LOZ  - London Corbin Airport    Magee Field   ',
    'LPA  - Gran Canaria Airport ',
    'LPB  - El Alto International Airport ',
    'LPC  - Lompoc Airport ',
    'LPD  - La Pedrera Airport ',
    'LPE  - La Primavera Airport ',
    'LPF  - Liupanshui Yuezhao Airport ',
    'LPG  - La Plata Airport ',
    'LPI  - Linköping  Saab Airport ',
    'LPJ  - Armando Schwarck Airport ',
    'LPK  - Lipetsk Airport ',
    'LPL  - Liverpool John Lennon Airport ',
    'LPM  - Malekoula Airport    Lamap Airport   ',
    'LPN  - Leron Plains Airport ',
    'LPO  - La Porte Municipal Airport    FAA  PPO   ',
    'LPP  - Lappeenranta Airport ',
    'LPQ  - Luang Prabang International Airport ',
    'LPS  - Fishermans Bay  LPS Seaplane Base ',
    'LPT  - Lampang Airport ',
    'LPU  - Long Apung Airport ',
    'LPX  - Liepāja International Airport ',
    'LPY  - Le Puy – Loudes Airport ',
    'LQK  - Pickens County Airport ',
    'LQM  - Caucayá Airport ',
    'LQN  - Qala i Naw Airport ',
    'LRA  - Larissa National Airport ',
    'LRB  - Leribe Airport ',
    'LRD  - Laredo International Airport ',
    'LRE  - Longreach Airport ',
    'LRF  - Little Rock Air Force Base ',
    'LRG  - Loralai Airport ',
    'LRH  - La Rochelle – Île de Ré Airport ',
    'LRI  - Lorica Airport ',
    'LRJ  - Le Mars Municipal Airport ',
    'LRL  - Niamtougou International Airport ',
    'LRM  - La Romana International Airport    Casa de Campo International Airport   ',
    'LRQ  - Laurie River Airport ',
    'LRR  - Larestan International Airport ',
    'LRS  - Leros Municipal Airport ',
    'LRT  - Lorient South Brittany Airport    Lann Bihoué Airport   ',
    'LRU  - Las Cruces International Airport ',
    'LRV  - Los Roques Airport ',
    'LSA  - Losuia Airport ',
    'LSB  - Lordsburg Municipal Airport ',
    'LSC  - La Florida Airport ',
    'LSE  - La Crosse Regional Airport ',
    'LSF  - Lawson Army Airfield ',
    'LSH  - Lashio Airport ',
    'LSI  - Sumburgh Airport ',
    'LSJ  - Long Island Airport ',
    'LSK  - Lusk Municipal Airport ',
    'LSL  - Los Chiles Airport ',
    'LSM  - Long Semado Airport ',
    'LSN  - Los Banos Municipal Airport ',
    'LSO  - Les Sables d Olonne – Talmont Airport ',
    'LSP  - Josefa Camejo International Airport ',
    'LSQ  - Maria Dolores Airport ',
    'LSS  - Les Saintes Airport ',
    'LST  - Launceston Airport ',
    'LSU  - Long Sukang Airport ',
    'LSV  - Nellis Air Force Base ',
    'LSW  - Malikus Saleh Airport ',
    'LSX  - Lhok Sukon Airport ',
    'LSY  - Lismore Airport ',
    'LSZ  - Lošinj Airport ',
    'LTA  - Tzaneen Airport    Letaba Airport   ',
    'LTB  - Latrobe Airport ',
    'LTC  - Laï Airport ',
    'LTD  - Ghadames Airport ',
    'LTF  - Leitre Airport ',
    'LTG  - Langtang Airport ',
    'LTI  - Altai Airport ',
    'LTK  - Bassel Al Assad International Airport ',
    'LTL  - Lastourville Airport ',
    'LTM  - Lethem Airport ',
    'LTN  - Luton Airport ',
    'LTO  - Loreto International Airport ',
    'LTP  - Lyndhurst Airport ',
    'LTQ  - Le Touquet – Côte d Opale Airport ',
    'LTR  - Letterkenny Airfield ',
    'LTS  - Altus Air Force Base ',
    'LTT  - La Môle – Saint Tropez Airport ',
    'LTU  - Latur Airport ',
    'LTV  - Lotus Vale Station Airport ',
    'LTW  - St  Mary s County Regional Airport    FAA  2W6   ',
    'LTX  - Cotopaxi International Airport ',
    'LUA  - Tenzing Hillary Airport ',
    'LUB  - Lumid Pau Airport ',
    'LUC  - Laucala Airport ',
    'LUD  - Lüderitz Airport ',
    'LUE  - Boľkovce Airport ',
    'LUF  - Luke Air Force Base ',
    'LUG  - Lugano Airport ',
    'LUH  - Sahnewal Airport    Ludhiana Airport   ',
    'LUI  - La Unión Airport ',
    'LUK  - Cincinnati Municipal Airport    Lunken Field   ',
    'LUL  - Hesler Noble Field ',
    'LUM  - Dehong Mangshi Airport ',
    'LUN  - Kenneth Kaunda International Airport ',
    'LUO  - Luena Airport ',
    'LUP  - Kalaupapa Airport ',
    'LUQ  - Brigadier Mayor César Raúl Ojeda Airport ',
    'LUR  - Cape Lisburne LRRS Airport ',
    'LUS  - Lusanga Airport ',
    'LUT  - New Laura Airport ',
    'LUU  - Laura Airport ',
    'LUV  - Karel Sadsuitubun Airport ',
    'LUW  - Syukuran Aminuddin Amir Airport ',
    'LUX  - Luxembourg Findel Airport ',
    'LUZ  - Lublin Airport ',
    'LVA  - Laval Entrammes Airport ',
    'LVB  - Galpões Airport ',
    'LVD  - Lime Village Airport    FAA  2AK   ',
    'LVI  - Harry Mwanga Nkumbula International Airport ',
    'LVK  - Livermore Municipal Airport ',
    'LVL  - Lawrenceville  Brunswick Municipal Airport ',
    'LVM  - Mission Field ',
    'LVO  - Laverton Airport ',
    'LVP  - Lavan Airport ',
    'LVR  - Bom Futuro Municipal Airport ',
    'LVS  - Las Vegas Municipal Airport ',
    'LWA  - Lebak Airport ',
    'LWB  - Greenbrier Valley Airport ',
    'LWC  - Lawrence Municipal Airport ',
    'LWE  - Wonopito Airport ',
    'LWH  - Lawn Hill Airport ',
    'LWI  - Lowai Airport ',
    'LWK  - Tingwall Airport    Lerwick  Tingwall Airport   ',
    'LWL  - Wells Municipal Airport    Harriet Field   ',
    'LWM  - Lawrence Municipal Airport ',
    'LWN  - Shirak Airport ',
    'LWO  - Lviv Danylo Halytskyi International Airport ',
    'LWR  - Leeuwarden Air Base ',
    'LWS  - Lewiston–Nez Perce County Airport ',
    'LWT  - Lewistown Municipal Airport ',
    'LWV  - Lawrenceville–Vincennes International Airport ',
    'LWY  - Lawas Airport ',
    'LXA  - Lhasa Gonggar Airport ',
    'LXG  - Louang Namtha Airport ',
    'LXN  - Jim Kelly Field ',
    'LXR  - Luxor International Airport ',
    'LXS  - Lemnos International Airport ',
    'LXU  - Lukulu Airport ',
    'LXV  - Lake County Airport ',
    'LYA  - Luoyang Beijiao Airport ',
    'LYB  - Edward Bodden Airfield ',
    'LYC  - Lycksele Airport ',
    'LYE  - RAF Lyneham ',
    'LYG  - Lianyungang Baitabu Airport ',
    'LYH  - Lynchburg Regional Airport    Preston Glenn Field   ',
    'LYI  - Linyi Qiyang Airport   formerly  Linyi Shubuling Airport   ',
    'LYK  - Lunyuk Airport ',
    'LYN  - Lyon–Bron Airport ',
    'LYO  - Lyons–Rice County Municipal Airport ',
    'LYP  - Faisalabad International Airport ',
    'LYR  - Svalbard Airport, Longyear ',
    'LYS  - Lyon–Saint Exupéry Airport ',
    'LYT  - Lady Elliot Island Airport ',
    'LYU  - Ely Municipal Airport    FAA  ELO   ',
    'LYX  - Lydd Airport    London Ashford Airport   ',
    'LZA  - Luiza Airport ',
    'LZC  - Lázaro Cárdenas Airport ',
    'LZH  - Liuzhou Bailian Airport ',
    'LZI  - Luozi Airport ',
    'LZM  - Cuango Luzamba Airport ',
    'LZN  - Matsu Nangan Airport ',
    'LZO  - Luzhou Yunlong Airport ',
    'LZR  - Lizard Island Airport ',
    'LZU  - Gwinnett County Airport  2      Briscoe Field   ',
    'LZY  - Nyingchi Mainling Airport ',
    'MAA  - Chennai International Airport ',
    'MAB  - João Correa da Rocha Airport ',
    'MAC  - Macon Downtown Airport ',
    'MAD  - Adolfo Suárez Madrid–Barajas Airport ',
    'MAE  - Madera Municipal Airport ',
    'MAF  - Midland International Air and Space Port ',
    'MAG  - Madang Airport ',
    'MAH  - Menorca Airport    Mahón Airport   ',
    'MAI  - Mangochi Airport ',
    'MAJ  - Marshall Islands International Airport    Amata Kabua Int l   ',
    'MAK  - Malakal Airport ',
    'MAL  - Mangole Airport ',
    'MAM  - General Servando Canales International Airport ',
    'MAN  - Manchester Airport ',
    'MAO  - Eduardo Gomes International Airport ',
    'MAP  - Mamai Airport ',
    'MAQ  - Mae Sot Airport ',
    'MAR  - La Chinita International Airport ',
    'MAS  - Momote Airport ',
    'MAT  - Matadi Tshimpi Airport ',
    'MAU  - Maupiti Airport ',
    'MAV  - Maloelap Airport    FAA  3N1   ',
    'MAW  - Malden Regional Airport ',
    'MAX  - Ouro Sogui Airport ',
    'MAY  - Clarence A  Bain Airport ',
    'MAZ  - Eugenio María de Hostos Airport ',
    'MBA  - Moi International Airport ',
    'MBB  - Marble Bar Airport ',
    'MBC  - Mbigou Airport ',
    'MBD  - Mahikeng Airport    Mmabatho Airport   ',
    'MBE  - Monbetsu Airport ',
    'MBF  - Porepunkah Airfield ',
    'MBG  - Mobridge Municipal Airport ',
    'MBH  - Maryborough Airport ',
    'MBI  - Mbeya Airport ',
    'MBJ  - Sangster International Airport ',
    'MBK  - Orlando Villas Bôas Regional Airport ',
    'MBL  - Manistee County Blacker Airport ',
    'MBM  - Mkambati Airport ',
    'MBN  - Mount Barnett Airport ',
    'MBO  - Mamburao Airport ',
    'MBP  - Moyobamba Airport ',
    'MBQ  - Mbarara Airport ',
    'MBR  - M Bout Airport ',
    'MBS  - MBS International Airport ',
    'MBT  - Moises R  Espinosa Airport    Masbate Airport   ',
    'MBU  - Mbambanakira Airport ',
    'MBV  - Masa Airport ',
    'MBW  - Moorabbin Airport ',
    'MBX  - Maribor Edvard Rusjan Airport ',
    'MBY  - Omar N  Bradley Airport ',
    'MBZ  - Maués Airport ',
    'MCA  - Macenta Airport ',
    'MCB  - McComb–Pike County Airport    John E  Lewis Field   ',
    'MCC  - McClellan Airfield ',
    'MCD  - Mackinac Island Airport ',
    'MCE  - Merced Regional Airport    MacReady Field   ',
    'MCF  - MacDill Air Force Base ',
    'MCG  - McGrath Airport ',
    'MCH  - General Manuel Serrano Airport ',
    'MCI  - Kansas City International Airport ',
    'MCJ  - Jorge Isaacs Airport    La Mina Airport   ',
    'MCK  - McCook Ben Nelson Regional Airport ',
    'MCL  - McKinley National Park Airport    FAA  INR   ',
    'MCN  - Middle Georgia Regional Airport ',
    'MCO  - Orlando International Airport ',
    'MCP  - Alberto Alcolumbre International Airport ',
    'MCQ  - Miskolc Airport ',
    'MCS  - Monte Caseros Airport ',
    'MCT  - Muscat International Airport ',
    'MCU  - Montluçon – Guéret Airport ',
    'MCV  - McArthur River Mine Airport ',
    'MCW  - Mason City Municipal Airport ',
    'MCX  - Uytash Airport ',
    'MCY  - Sunshine Coast Airport ',
    'MCZ  - Zumbi dos Palmares International Airport ',
    'MDB  - Melinda Airport ',
    'MDC  - Sam Ratulangi International Airport ',
    'MDD  - Midland Airpark ',
    'MDE  - José María Córdova International Airport ',
    'MDF  - Taylor County Airport ',
    'MDG  - Mudanjiang Hailang Airport ',
    'MDH  - Southern Illinois Airport ',
    'MDI  - Makurdi Airport ',
    'MDJ  - Madras Municipal Airport    FAA  S33   ',
    'MDK  - Mbandaka Airport ',
    'MDL  - Mandalay International Airport ',
    'MDM  - Munduku Airport ',
    'MDN  - Madison Municipal Airport ',
    'MDO  - Middleton Island Airport ',
    'MDP  - Mindiptana Airport ',
    'MDQ  - Astor Piazzolla International Airport ',
    'MDS  - Middle Caicos Airport ',
    'MDT  - Harrisburg International Airport ',
    'MDU  - Mendi Airport ',
    'MDV  - Médouneu Airport ',
    'MDW  - Midway International Airport Chicago',
    'MDX  - Mercedes Airport ',
    'MDY  - Henderson Field ',
    'MDZ  - Gov  Francisco Gabrielli International Airport    El Plumerillo   ',
    'MEA  - Benedito Lacerda Airport ',
    'MEB  - Essendon Airport ',
    'MEC  - Eloy Alfaro International Airport ',
    'MED  - Prince Mohammad bin Abdulaziz Airport ',
    'MEE  - Maré Airport ',
    'MEF  - Melfi Airport ',
    'MEG  - Malanje Airport ',
    'MEH  - Mehamn Airport ',
    'MEI  - Meridian Regional Airport    Key Field   ',
    'MEJ  - Port Meadville Airport    FAA  GKJ   ',
    'MEK  - Bassatine Air Base ',
    'MEL  - Melbourne Airport ',
    'MEM  - Memphis International Airport ',
    'MEN  - Brenoux Airport ',
    'MEO  - Dare County Regional Airport    FAA  MQI   ',
    'MEP  - Mersing Airport ',
    'MEQ  - Cut Nyak Dhien Airport ',
    'MER  - Castle Airport ',
    'MES  - Soewondo Air Force Base ',
    'MET  - Moreton Airport ',
    'MEU  - Serra do Areão Airport ',
    'MEV  - Minden–Tahoe Airport ',
    'MEW  - Mweka Airport ',
    'MEX  - Mexico City International Airport ',
    'MEY  - Meghauli Airport ',
    'MEZ  - Messina Airport ',
    'MFA  - Mafia Airport ',
    'MFB  - Monfort Airport ',
    'MFC  - Mafeteng Airport ',
    'MFD  - Mansfield Lahm Regional Airport ',
    'MFE  - McAllen Miller International Airport ',
    'MFF  - Moanda Airport ',
    'MFG  - Muzaffarabad Airport ',
    'MFH  - Mesquite Airport    FAA  67L   ',
    'MFI  - Marshfield Municipal Airport ',
    'MFJ  - Moala Airport ',
    'MFK  - Matsu Beigan Airport ',
    'MFL  - Mount Full Stop Airport ',
    'MFM  - Macau International Airport ',
    'MFN  - Milford Sound Airport ',
    'MFO  - Manguna Airport ',
    'MFP  - Manners Creek Airport ',
    'MFQ  - Maradi Airport ',
    'MFR  - Rogue Valley International–Medford Airport ',
    'MFS  - Miraflores Airport ',
    'MFU  - Mfuwe Airport ',
    'MFV  - Accomack County Airport ',
    'MFW  - Magaruque Airport ',
    'MFX  - Méribel Altiport ',
    'MFY  - Mayfa ah Airport ',
    'MFZ  - Meselia Airport ',
    'MGA  - Augusto C  Sandino International Airport ',
    'MGB  - Mount Gambier Airport ',
    'MGC  - Michigan City Municipal Airport ',
    'MGD  - Magdalena Airport ',
    'MGE  - Dobbins Air Reserve Base ',
    'MGF  - Sílvio Name Júnior Regional Airport ',
    'MGG  - Margarima Airport ',
    'MGH  - Margate Airport ',
    'MGI  - Aransas National Wildlife Refuge Airport  1      FAA  XS10   ',
    'MGJ  - Orange County Airport ',
    'MGK  - Mong Ton Airport ',
    'MGL  - Düsseldorf Mönchengladbach Airport ',
    'MGM  - Montgomery Regional Airport    Dannelly Field   ',
    'MGN  - Baracoa Regional Airport ',
    'MGO  - Manega Airport ',
    'MGP  - Manga Airport ',
    'MGQ  - Aden Adde International Airport ',
    'MGR  - Moultrie Municipal Airport ',
    'MGS  - Mangaia Airport ',
    'MGT  - Milingimbi Airport ',
    'MGU  - Manaung Airport ',
    'MGV  - Margaret River Station Airport ',
    'MGW  - Morgantown Municipal Airport    Walter L  Bill Hart Field   ',
    'MGX  - Moabi Airport ',
    'MGY  - Dayton–Wright Brothers Airport ',
    'MGZ  - Myeik Airport ',
    'MHA  - Mahdia Airport ',
    'MHC  - Mocopulli Airport ',
    'MHD  - Mashhad International Airport    Shahid Hashemi Nejad Airport   ',
    'MHE  - Mitchell Municipal Airport ',
    'MHF  - Morichal Airport ',
    'MHG  - Mannheim City Airport ',
    'MHH  - Marsh Harbour Airport ',
    'MHI  - Moucha Airport ',
    'MHK  - Manhattan Regional Airport ',
    'MHL  - Marshall Memorial Municipal Airport ',
    'MHN  - Hooker County Airport ',
    'MHO  - Mount House Airport ',
    'MHP  - Minsk 1 Airport ',
    'MHQ  - Mariehamn Airport ',
    'MHR  - Sacramento Mather Airport ',
    'MHS  - Dunsmuir Municipal Mott Airport  1      FAA  1O6   ',
    'MHT  - Manchester–Boston Regional Airport ',
    'MHU  - Mount Hotham Airport ',
    'MHV  - Mojave Air and Space Port ',
    'MHW  - Monteagudo Airport ',
    'MHX  - Manihiki Island Airport ',
    'MHY  - Morehead Airport ',
    'MHZ  - RAF Mildenhall ',
    'MIA  - Miami International Airport ',
    'MIB  - Minot Air Force Base ',
    'MIC  - Crystal Airport ',
    'MID  - Manuel Crescencio Rejón International Airport ',
    'MIE  - Delaware County Regional Airport ',
    'MIF  - Roy Hurd Memorial Airport    FAA  E01   ',
    'MIG  - Mianyang Nanjiao Airport ',
    'MIH  - Mitchell Plateau Airport ',
    'MII  - Frank Miloye Milenkowichi–Marília State Airport ',
    'MIJ  - Mili Airport    FAA  1Q9   ',
    'MIK  - Mikkeli Airport ',
    'MIL  - metropolitan area1 ',
    'MIM  - Merimbula Airport ',
    'MIN  - Minnipa Airport ',
    'MIO  - Miami Municipal Airport ',
    'MIP  - Mitzpe Ramon Airport ',
    'MIQ  - Millard Airport    FAA  MLE   ',
    'MIR  - Monastir Habib Bourguiba International Airport ',
    'MIS  - Misima Island Airport ',
    'MIT  - Shafter Airport    Minter Field   ',
    'MIU  - Maiduguri International Airport ',
    'MIV  - Millville Municipal Airport ',
    'MIW  - Marshalltown Municipal Airport ',
    'MIX  - Mirití Paraná Airport ',
    'MIY  - Mittiebah Airport ',
    'MIZ  - Mainoru Airport ',
    'MJA  - Manja Airport ',
    'MJB  - Mejit Airport    FAA  Q30   ',
    'MJC  - Man Airport ',
    'MJD  - Moenjodaro Airport ',
    'MJE  - Majkin Airport ',
    'MJF  - Mosjøen Airport, Kjærstad ',
    'MJI  - Mitiga International Airport ',
    'MJJ  - Moki Airport ',
    'MJK  - Shark Bay Airport ',
    'MJL  - Mouila Airport ',
    'MJM  - Mbuji Mayi Airport ',
    'MJN  - Amborovy Airport    Philibert Tsiranana Airport   ',
    'MJO  - Mount Etjo Airport ',
    'MJP  - Manjimup Airport ',
    'MJQ  - Jackson Municipal Airport ',
    'MJR  - Miramar Airport ',
    'MJS  - Maganja da Costa Airport ',
    'MJT  - Mytilene International Airport ',
    'MJU  - Tampa Padang Airport ',
    'MJV  - Murcia–San Javier Airport ',
    'MJW  - Mahenye Airport ',
    'MJX  - Robert J  Miller Air Park    Ocean County Airport   ',
    'MJZ  - Mirny Airport ',
    'MKA  - Mariánské Lázně Airport ',
    'MKB  - Mékambo Airport ',
    'MKC  - Charles B  Wheeler Downtown Airport ',
    'MKE  - Milwaukee Mitchell International Airport ',
    'MKG  - Muskegon County Airport ',
    'MKH  - Mokhotlong Airport ',
    'MKI  - M Boki Airport ',
    'MKJ  - Makoua Airport ',
    'MKK  - Molokai Airport ',
    'MKL  - McKellar–Sipes Regional Airport ',
    'MKM  - Mukah Airport ',
    'MKN  - Malekolon Airport ',
    'MKO  - Davis Field ',
    'MKP  - Makemo Airport ',
    'MKQ  - Mopah Airport ',
    'MKR  - Meekatharra Airport ',
    'MKS  - Mekane Selam Airport ',
    'MKT  - Mankato Regional Airport ',
    'MKU  - Makokou Airport ',
    'MKV  - Mount Cavenagh Airport ',
    'MKW  - Rendani Airport ',
    'MKY  - Mackay Airport ',
    'MKZ  - Malacca International Airport ',
    'MLA  - Malta International Airport    Luqa Airport   ',
    'MLB  - Melbourne Orlando International Airport ',
    'MLC  - McAlester Regional Airport ',
    'MLD  - Malad City Airport ',
    'MLE  - Ibrahim Nasir International Airport ',
    'MLF  - Milford Municipal Airport    Ben and Judy Briscoe Field   ',
    'MLG  - Abdul Rachman Saleh Airport ',
    'MLH  - EuroAirport Basel Mulhouse Freiburg ',
    'MLI  - Quad Cities International Airport ',
    'MLJ  - Baldwin County Airport ',
    'MLK  - Malta Airport  1      FAA  M75   ',
    'MLL  - Marshall Don Hunter Sr  Airport    FAA  MDM   ',
    'MLM  - General Francisco J  Mujica International Airport ',
    'MLN  - Melilla Airport ',
    'MLO  - Milos Island National Airport ',
    'MLP  - Malabang Airport ',
    'MLQ  - Malalaua Airport ',
    'MLR  - Millicent Airport ',
    'MLS  - Miles City Airport    Frank Wiley Field   ',
    'MLT  - Millinocket Municipal Airport ',
    'MLU  - Monroe Regional Airport ',
    'MLV  - Merluna Airport ',
    'MLW  - Spriggs Payne Airport ',
    'MLX  - Malatya Erhaç Airport ',
    'MLY  - Manley Hot Springs Airport ',
    'MLZ  - Cerro Largo International Airport ',
    'MMA  - metropolitan area2 ',
    'MMB  - Memanbetsu Airport ',
    'MMC  - Ciudad Mante National Airport ',
    'MMD  - Minami Daito Airport ',
    'MME  - Teesside International Airport ',
    'MMF  - Mamfe Airport ',
    'MMG  - Mount Magnet Airport ',
    'MMH  - Mammoth Yosemite Airport ',
    'MMI  - McMinn County Airport ',
    'MMJ  - Matsumoto Airport ',
    'MMK  - Murmansk Airport ',
    'MML  - Southwest Minnesota Regional Airport    Marshall  Ryan Field   ',
    'MMM  - Middlemount Airport ',
    'MMN  - Minute Man Air Field  1      FAA  6B6   ',
    'MMO  - Maio Airport ',
    'MMP  - San Bernardo Airport ',
    'MMQ  - Mbala Airport ',
    'MMS  - Selfs Airport ',
    'MMT  - McEntire Joint National Guard Base ',
    'MMU  - Morristown Municipal Airport ',
    'MMV  - Mal Island Airport ',
    'MMW  - Moma Airport ',
    'MMX  - Malmö Airport ',
    'MMY  - Miyako Airport ',
    'MMZ  - Maymana Airport ',
    'MNA  - Melangguane Airport ',
    'MNB  - Muanda Airport    Moanda Airport   ',
    'MNC  - Nacala Airport ',
    'MND  - Medina Airport ',
    'MNE  - Mungeranie Airport ',
    'MNF  - Mana Island Airport ',
    'MNG  - Maningrida Airport ',
    'MNH  - Al Musannah Airport ',
    'MNI  - John A  Osborne Airport ',
    'MNJ  - Mananjary Airport ',
    'MNK  - Maiana Airport ',
    'MNL  - Ninoy Aquino International Airport      Villamor Air Base ',
    'MNM  - Menominee Marinette Twin County Airport ',
    'MNN  - Marion Municipal Airport ',
    'MNO  - Manono Airport ',
    'MNP  - Maron Island Airport ',
    'MNQ  - Monto Airport ',
    'MNR  - Mongu Airport ',
    'MNS  - Mansa Airport ',
    'MNT  - Minto Al Wright Airport    FAA  51Z   ',
    'MNU  - Mawlamyaing Airport ',
    'MNV  - Mount Valley Airport ',
    'MNW  - MacDonald Downs Airport ',
    'MNX  - Manicoré Airport ',
    'MNY  - Mono Airport ',
    'MNZ  - Manassas Regional Airport    FAA  HEF   ',
    'MOA  - Orestes Acosta Airport ',
    'MOB  - Mobile Regional Airport ',
    'MOC  - Montes Claros  Mário Ribeiro Airport ',
    'MOD  - Modesto City–County Airport    Harry Sham Field   ',
    'MOE  - Momeik Airport ',
    'MOF  - Frans Seda Airport    Wai Oti Airport   ',
    'MOG  - Monghsat Airport ',
    'MOH  - Maleo Airport ',
    'MOI  - Mitiaro Airport    Nukuroa Airport   ',
    'MOJ  - Moengo Airstrip ',
    'MOK  - Muynak Airport ',
    'MOL  - Molde Airport, Årø ',
    'MOM  - Letfotar Airport ',
    'MON  - Mount Cook Aerodrome ',
    'MOO  - Moomba Airport ',
    'MOP  - Mount Pleasant Municipal Airport ',
    'MOQ  - Morondava Airport ',
    'MOR  - Morristown Regional Airport    Moore–Murrell Airport   ',
    'MOS  - Moses Point Airport ',
    'MOT  - Minot International Airport ',
    'MOU  - Mountain Village Airport ',
    'MOV  - Moranbah Airport ',
    'MOW  - metropolitan area3 ',
    'MOX  - Morris Municipal Airport ',
    'MOY  - Monterrey Airport ',
    'MOZ  - Moorea Airport    Temae Airport   ',
    'MPA  - Katima Mulilo Airport    Mpacha Airport   ',
    'MPB  - Miami Seaplane Base    FAA  X44   ',
    'MPC  - Mukomuko Airport ',
    'MPD  - Sindhri Airport ',
    'MPF  - Mapoda Airport ',
    'MPG  - Makini Airport ',
    'MPH  - Godofredo P  Ramos Airport    Caticlan Airport   ',
    'MPI  - Mamitupu Airport ',
    'MPJ  - Petit Jean Park Airport ',
    'MPL  - Montpellier–Méditerranée Airport    Fréjorgues Airport   ',
    'MPM  - Maputo International Airport ',
    'MPN  - RAF Mount Pleasant ',
    'MPO  - Pocono Mountains Municipal Airport ',
    'MPP  - Mulatupo Airport ',
    'MPQ  - Ma an Airport ',
    'MPR  - McPherson Airport ',
    'MPS  - Mount Pleasant Regional Airport  1      FAA  OSA   ',
    'MPT  - Maliana Airport ',
    'MPU  - Mabua Airstrip ',
    'MPV  - Edward F  Knapp State Airport ',
    'MPW  - Mariupol International Airport ',
    'MPX  - Miyanmin Airport ',
    'MPY  - Maripasoula Airport ',
    'MPZ  - Mount Pleasant Municipal Airport ',
    'MQA  - Mandora Station Airport ',
    'MQB  - Macomb Municipal Airport ',
    'MQC  - Miquelon Airport ',
    'MQD  - Maquinchao Airport ',
    'MQE  - Marqua Airport ',
    'MQF  - Magnitogorsk International Airport ',
    'MQG  - Midgard Airport ',
    'MQH  - Minaçu Airport ',
    'MQJ  - Moma Airport ',
    'MQK  - San Matías Airport ',
    'MQL  - Mildura Airport ',
    'MQM  - Mardin Airport ',
    'MQN  - Mo i Rana Airport, Røssvoll ',
    'MQO  - Malam Airport ',
    'MQP  - Kruger Mpumalanga International Airport ',
    'MQQ  - Moundou Airport ',
    'MQR  - Mosquera Airport ',
    'MQS  - Mustique Airport ',
    'MQT  - Sawyer International Airport    FAA  SAW   ',
    'MQU  - Mariquita Airport ',
    'MQV  - Mostaganem Airport ',
    'MQW  - Telfair–Wheeler Airport ',
    'MQX  - Alula Aba Nega Airport ',
    'MQY  - Smyrna Airport ',
    'MQZ  - Margaret River Airport ',
    'MRA  - Misrata Airport ',
    'MRB  - Eastern WV Regional Airport    Shepherd Field   ',
    'MRC  - Maury County Airport ',
    'MRD  - Alberto Carnevalli Airport ',
    'MRE  - Mara Serena Airport ',
    'MRF  - Marfa Municipal Airport ',
    'MRG  - Mareeba Airfield ',
    'MRH  - May River Airport ',
    'MRI  - Merrill Field ',
    'MRJ  - Marcala Airport ',
    'MRK  - Marco Island Airport    FAA  MKY   ',
    'MRL  - Miners Lake Airport ',
    'MRM  - Manari Airport ',
    'MRN  - Foothills Regional Airport    was Morganton Lenoir Airport   ',
    'MRO  - Hood Aerodrome ',
    'MRP  - Marla Airport ',
    'MRQ  - Marinduque Airport ',
    'MRR  - José María Velasco Ibarra Airport ',
    'MRS  - Marseille Provence Airport ',
    'MRT  - Moroak Airport ',
    'MRU  - Sir Seewoosagur Ramgoolam International Airport ',
    'MRV  - Mineralnye Vody Airport ',
    'MRW  - Lolland Falster Airport ',
    'MRX  - Mahshahr Airport ',
    'MRY  - Monterey Regional Airport ',
    'MRZ  - Moree Airport ',
    'MSA  - Muskrat Dam Airport ',
    'MSC  - Falcon Field Airport    FAA  FFZ   ',
    'MSD  - Mount Pleasant Airport    FAA  43U   ',
    'MSE  - Manston Airport    Kent International Airport   ',
    'MSF  - Mount Swan Airport ',
    'MSG  - Matsaile Airport ',
    'MSH  - Masirah Air Base ',
    'MSI  - Masalembo Airport ',
    'MSJ  - Misawa Airport ',
    'MSL  - Northwest Alabama Regional Airport ',
    'MSM  - Masi Manimba Airport ',
    'MSN  - Dane County Regional Airport    Truax Field   ',
    'MSO  - Missoula International Airport ',
    'MSP  - Minneapolis–Saint Paul International Airport    Wold–Chamberlain Field   ',
    'MSQ  - Minsk National Airport ',
    'MSR  - Muş Airport ',
    'MSS  - Massena International Airport    Richards Field   ',
    'MST  - Maastricht Aachen Airport ',
    'MSU  - Moshoeshoe I International Airport ',
    'MSV  - Sullivan County International Airport ',
    'MSW  - Massawa International Airport ',
    'MSX  - Mossendjo Airport ',
    'MSY  - Louis Armstrong New Orleans International Airport ',
    'MSZ  - Moçâmedes Airport    Yuri Gagarin Airport   ',
    'MTA  - Matamata Airport ',
    'MTB  - Montelíbano Airport ',
    'MTC  - Selfridge Air National Guard Base ',
    'MTD  - Mount Sandford Station Airport ',
    'MTE  - Monte Alegre Airport ',
    'MTF  - Mizan Teferi Airport ',
    'MTG  - Mato Grosso Airport ',
    'MTH  - Florida Keys Marathon Airport ',
    'MTI  - Mosteiros Airport ',
    'MTJ  - Montrose Regional Airport ',
    'MTK  - Makin Airport ',
    'MTL  - Maitland Airport ',
    'MTM  - Metlakatla Seaplane Base ',
    'MTN  - Martin State Airport ',
    'MTO  - Coles County Memorial Airport ',
    'MTP  - Montauk Airport ',
    'MTQ  - Mitchell Airport ',
    'MTR  - Los Garzones Airport ',
    'MTS  - Matsapha Airport ',
    'MTT  - Minatitlán  Coatzacoalcos International Airport ',
    'MTU  - Montepuez Airport ',
    'MTV  - Mota Lava Airport ',
    'MTW  - Manitowoc County Airport ',
    'MTX  - Metro Field    FAA  MTF   ',
    'MTY  - General Mariano Escobedo International Airport ',
    'MTZ  - Bar Yehuda Airfield    Masada Airfield   ',
    'MUA  - Munda Airport ',
    'MUB  - Maun Airport ',
    'MUC  - Munich Airport ',
    'MUD  - Mueda Airport ',
    'MUE  - Waimea Kohala Airport ',
    'MUF  - Muting Airport ',
    'MUG  - Mulegé Airstrip ',
    'MUH  - Marsa Matruh International Airport ',
    'MUI  - Muir Army Airfield ',
    'MUJ  - Mui Airport ',
    'MUK  - Mauke Airport ',
    'MUL  - Spence Airport ',
    'MUN  - José Tadeo Monagas International Airport ',
    'MUO  - Mountain Home Air Force Base ',
    'MUP  - Mulga Park Airport ',
    'MUQ  - Muccan Station Airport ',
    'MUR  - Marudi Airport ',
    'MUS  - Minami Torishima Airport ',
    'MUT  - Muscatine Municipal Airport ',
    'MUW  - Ghriss Airport ',
    'MUX  - Multan International Airport ',
    'MUY  - Mouyondzi Airport ',
    'MUZ  - Musoma Airport ',
    'MVA  - Mývatn Airport ',
    'MVB  - M Vengue El Hadj Omar Bongo Ondimba International Airport ',
    'MVC  - Monroe County Airport ',
    'MVD  - Carrasco Gral  Cesáreo L  Berisso International Airport ',
    'MVE  - Montevideo–Chippewa County Airport ',
    'MVF  - Gov  Dix Sept Rosado Airport ',
    'MVG  - Mevang Airport ',
    'MVH  - Macksville Airport ',
    'MVI  - Manetai Airport ',
    'MVK  - Mulka Airport ',
    'MVL  - Morrisville–Stowe State Airport ',
    'MVM  - Kayenta Airport    FAA  0V7   ',
    'MVN  - Mount Vernon Airport ',
    'MVO  - Mongo Airport ',
    'MVP  - Fabio Alberto León Bentley Airport ',
    'MVQ  - Mogilev Airport ',
    'MVR  - Salak Airport ',
    'MVS  - Mucuri Airport ',
    'MVT  - Mataiva Airport ',
    'MVU  - Musgrave Airport ',
    'MVV  - Megève Altiport ',
    'MVW  - Skagit Regional Airport    FAA  BVS   ',
    'MVX  - Minvoul Airport ',
    'MVY  - Martha s Vineyard Airport ',
    'MVZ  - Masvingo Airport ',
    'MWA  - Williamson County Regional Airport ',
    'MWB  - Morawa Airport ',
    'MWC  - Lawrence J  Timmerman Airport ',
    'MWD  - PAF Base M M  Alam ',
    'MWE  - Merowe Airport ',
    'MWF  - Maewo Naone Airport ',
    'MWG  - Marawaka Airport ',
    'MWH  - Grant County International Airport ',
    'MWI  - Maramuni Airport ',
    'MWJ  - Matthews Ridge Airport ',
    'MWK  - Matak Airport    Tarempa Airport   ',
    'MWL  - Mineral Wells Airport ',
    'MWM  - Windom Municipal Airport ',
    'MWN  - Mwadui Airport ',
    'MWO  - Middletown Regional Airport    Hook Field   ',
    'MWQ  - Magway Airport ',
    'MWR  - Motswari Airstrip ',
    'MWT  - Moolawatana Airport ',
    'MWU  - Mussau Island Airport ',
    'MWV  - Mondulkiri Airport ',
    'MWX  - Muan International Airport ',
    'MWY  - Miranda Downs Airport ',
    'MWZ  - Mwanza Airport ',
    'MXA  - Manila Municipal Airport ',
    'MXB  - Andi Jemma Airport ',
    'MXC  - Monticello Airport    FAA  U43   ',
    'MXD  - Marion Downs Airport ',
    'MXE  - Laurinburg–Maxton Airport    FAA  MEB   ',
    'MXF  - Maxwell Air Force Base ',
    'MXG  - Marlboro Airport  1      FAA  9B1   ',
    'MXH  - Moro Airport ',
    'MXI  - Mati Airport    Imelda R  Marcos Airport   ',
    'MXJ  - Minna Airport ',
    'MXK  - Mindik Airport ',
    'MXL  - General Rodolfo Sánchez Taboada International Airport ',
    'MXM  - Morombe Airport ',
    'MXN  - Morlaix – Ploujean Airport ',
    'MXO  - Monticello Regional Airport ',
    'MXP  - Milan–Malpensa Airport ',
    'MXR  - Myrhorod Air Base ',
    'MXS  - Maota Airport ',
    'MXT  - Maintirano Airport ',
    'MXU  - Mullewa Airport ',
    'MXV  - Mörön Airport ',
    'MXW  - Mandalgovi Airport ',
    'MXX  - Mora–Siljan Airport ',
    'MXY  - McCarthy Airport    FAA  15Z   ',
    'MXZ  - Meixian Airport ',
    'MYA  - Moruya Airport ',
    'MYB  - Mayumba Airport ',
    'MYC  - Mariscal Sucre Airport ',
    'MYD  - Malindi Airport ',
    'MYE  - Miyakejima Airport ',
    'MYF  - Montgomery Field Airport ',
    'MYG  - Mayaguana Airport    Abraham s Bay Airport   ',
    'MYH  - Marble Canyon Airport  1      FAA  L41   ',
    'MYI  - Murray Island Airport ',
    'MYJ  - Matsuyama Airport ',
    'MYK  - May Creek Airport ',
    'MYL  - McCall Municipal Airport ',
    'MYM  - Monkey Mountain Airport ',
    'MYN  - Marib Airport ',
    'MYO  - Myroodah Airport ',
    'MYP  - Mary International Airport ',
    'MYQ  - Mysore Airport    Mandakalli Airport   ',
    'MYR  - Myrtle Beach International Airport ',
    'MYT  - Myitkyina Airport ',
    'MYU  - Mekoryuk Airport ',
    'MYV  - Yuba County Airport ',
    'MYW  - Mtwara Airport ',
    'MYX  - Menyamya Airport ',
    'MYY  - Miri Airport ',
    'MYZ  - Monkey Bay Airport ',
    'MZA  - Manuel Prado Ugarteche Airport ',
    'MZB  - Mocímboa da Praia Airport ',
    'MZC  - Mitzic Airport ',
    'MZD  - Méndez Airport ',
    'MZE  - Manatee Airport ',
    'MZF  - Wild Coast Sun Airport ',
    'MZG  - Magong Airport ',
    'MZH  - Amasya Merzifon Airport ',
    'MZI  - Mopti Airport    Ambodedjo Airport   ',
    'MZJ  - Pinal Airpark ',
    'MZK  - Marakei Airport ',
    'MZL  - La Nubia Airport    Santaguida Airport   ',
    'MZM  - Metz Frescaty Air Base ',
    'MZN  - Minj Airport ',
    'MZO  - Sierra Maestra Airport ',
    'MZP  - Motueka Aerodrome ',
    'MZQ  - Mkuze Airport ',
    'MZR  - Mazar e Sharif International Airport ',
    'MZT  - General Rafael Buelna International Airport ',
    'MZU  - Muzaffarpur Airport ',
    'MZV  - Mulu Airport ',
    'MZW  - Mécheria Airport ',
    'MZX  - Mena Airport ',
    'MZY  - Mossel Bay Airport ',
    'MZZ  - Marion Municipal Airport ',
    'NAA  - Narrabri Airport ',
    'NAC  - Naracoorte Airport ',
    'NAD  - Macanal Airport ',
    'NAE  - Boundétingou Airport ',
    'NAF  - Banaina Airport ',
    'NAG  - Dr  Babasaheb Ambedkar International Airport ',
    'NAH  - Naha Airport ',
    'NAI  - Annai Airport ',
    'NAJ  - Nakhchivan International Airport ',
    'NAK  - Nakhon Ratchasima Airport ',
    'NAL  - Nalchik Airport ',
    'NAM  - Namlea Airport ',
    'NAN  - Nadi International Airport ',
    'NAO  - Nanchong Gaoping Airport ',
    'NAP  - Naples International Airport ',
    'NAQ  - Qaanaaq Airport ',
    'NAR  - Puerto Nare Airport ',
    'NAS  - Lynden Pindling International Airport ',
    'NAT  - São Gonçalo do Amarante–Governador Aluízio Alves International Airport ',
    'NAU  - Napuka Airport ',
    'NAV  - Nevşehir Kapadokya Airport ',
    'NAW  - Narathiwat Airport ',
    'NAY  - Beijing Nanyuan Airport ',
    'NAZ  - Nana Airport ',
    'NBA  - Nambaiyufa Airport ',
    'NBB  - Barranco Minas Airport ',
    'NBC  - Begishevo Airport ',
    'NBE  - Enfidha Hammamet International Airport ',
    'NBG  - NAS JRB New Orleans    Alvin Callender Field   ',
    'NBH  - Nambucca Heads Airport ',
    'NBL  - Wannukandi Airport ',
    'NBN  - Annobón Airport ',
    'NBO  - Jomo Kenyatta International Airport ',
    'NBS  - Changbaishan Airport ',
    'NBW  - Leeward Point Field ',
    'NBX  - Nabire Airport ',
    'NCA  - North Caicos Airport ',
    'NCE  - Nice Côte d Azur Airport ',
    'NCG  - Nuevo Casas Grandes Municipal Airport ',
    'NCH  - Nachingwea Airport ',
    'NCI  - Antioquia Airport ',
    'NCJ  - Sunchales Aeroclub Airport ',
    'NCL  - Newcastle Airport ',
    'NCN  - Chenega Bay Airport    FAA  C05   ',
    'NCO  - Quonset State Airport  1      FAA  OQU   ',
    'NCR  - San Carlos Airport ',
    'NCS  - Newcastle Airport ',
    'NCT  - Nicoya Guanacaste Airport ',
    'NCU  - Nukus Airport ',
    'NCY  - Annecy – Haute Savoie – Mont Blanc Airport ',
    'NDA  - Bandanaira Airport ',
    'NDB  - Nouadhibou International Airport ',
    'NDC  - Shri Guru Gobind Singh Ji Airport ',
    'NDD  - Sumbe Airport ',
    'NDE  - Mandera Airport ',
    'NDF  - N dalatando Airport ',
    'NDG  - Qiqihar Sanjiazi Airport ',
    'NDI  - Namudi Airport ',
    'NDJ  - N Djamena International Airport ',
    'NDK  - Namorik Airport    Namdrik Airport     FAA  3N0   ',
    'NDL  - N Délé Airport ',
    'NDM  - Mendi Airport ',
    'NDN  - Nadunumu Airport ',
    'NDP  - Naval Air Station Ellyson Field  2      now retired   ',
    'NDR  - Nador International Airport ',
    'NDS  - Sandstone Airport ',
    'NDU  - Rundu Airport ',
    'NDY  - Sanday Airport ',
    'NEC  - Necochea Airport ',
    'NEF  - Neftekamsk Airport ',
    'NEG  - Negril Aerodrome ',
    'NEJ  - Nejjo Airport ',
    'NEK  - Nekemte Airport ',
    'NEL  - NAES Lakehurst    Maxfield Field   ',
    'NEN  - NOLF Whitehouse ',
    'NER  - Chulman Neryungri Airport ',
    'NEU  - Nathong Airport ',
    'NEV  - Vance W  Amory International Airport ',
    'NEW  - Lakefront Airport ',
    'NFG  - Nefteyugansk Airport ',
    'NFL  - NAS Fallon    Van Voorhis Field   ',
    'NFO  - Niuafo ou Airport ',
    'NFR  - Nafoora Airport ',
    'NGA  - Young Airport ',
    'NGB  - Ningbo Lishe International Airport ',
    'NGD  - Auguste George Airport ',
    'NGE  - Ngaoundéré Airport ',
    'NGF  - MCAS Kaneohe Bay ',
    'NGI  - Gau Airport ',
    'NGK  - Nogliki Airport ',
    'NGL  - Ngala Airfield ',
    'NGN  - Narganá Airport ',
    'NGO  - Chūbu Centrair International Airport ',
    'NGP  - NAS Corpus Christi    Truax Field   ',
    'NGQ  - Ngari Gunsa Airport ',
    'NGR  - Ningerum Airport ',
    'NGS  - Nagasaki Airport ',
    'NGU  - NS Norfolk    Chambers Field   ',
    'NGW  - NOLF Cabaniss Field ',
    'NGX  - Manang Airport ',
    'NHA  - Nha Trang Air Base ',
    'NHD  - Al Minhad Air Base ',
    'NHF  - New Halfa Airport ',
    'NHK  - NAS Patuxent River    Trapnell Field   ',
    'NHS  - Nushki Airport ',
    'NHT  - RAF Northolt ',
    'NHV  - Nuku Hiva Airport ',
    'NHX  - NOLF Barin    FAA  NBJ   ',
    'NHZ  - Brunswick Executive Airport ',
    'NIA  - Nimba Airport ',
    'NIB  - Nikolai Airport    FAA  FSP   ',
    'NIC  - Nicosia International Airport1 ',
    'NIF  - Nifty Airport ',
    'NIG  - Nikunau Airport ',
    'NIK  - Niokolo Koba Airport ',
    'NIM  - Diori Hamani International Airport ',
    'NIN  - Ninilchik Airport ',
    'NIO  - Nioki Airport ',
    'NIP  - NAS Jacksonville    Towers Field   ',
    'NIR  - Chase Field Industrial Complex  1      FAA  1XA2   ',
    'NIS  - Simberi Airport ',
    'NIT  - Niort   Souché Airport ',
    'NIU  - Niau Airport ',
    'NIX  - Nioro Airport ',
    'NJA  - Naval Air Facility Atsugi ',
    'NJC  - Nizhnevartovsk Airport ',
    'NJF  - Al Najaf International Airport ',
    'NJK  - NAF El Centro ',
    'NKA  - Nkan Airport ',
    'NKB  - Noonkanbah Airport ',
    'NKC  - Nouakchott–Oumtounsy International Airport ',
    'NKD  - Sinak Airport ',
    'NKG  - Nanjing Lukou International Airport ',
    'NKI  - Naukati Bay Seaplane Base  1      FAA  AK62   ',
    'NKL  - Nkolo Fuma Airport ',
    'NKM  - Nagoya Airfield    Komaki Airport   ',
    'NKN  - Nankina Airport ',
    'NKO  - Ankokoambo Airport ',
    'NKP  - Nukutepipi Airport ',
    'NKS  - Nkongsamba Airport ',
    'NKT  - Şırnak Airport ',
    'NKU  - Nkaus Airport ',
    'NKX  - MCAS Miramar ',
    'NKY  - Yokangassi Airport ',
    'NLA  - Simon Mwansa Kapwepwe International Airport ',
    'NLC  - NAS Lemoore    Reeves Field   ',
    'NLD  - Quetzalcóatl International Airport ',
    'NLE  - Jerry Tyler Memorial Airport    FAA  3TR   ',
    'NLF  - Darnley Island Airport ',
    'NLG  - Nelson Lagoon Airport    FAA  OUL   ',
    'NLH  - Ninglang Luguhu Airport ',
    'NLI  - Nikolayevsk on Amur Airport ',
    'NLK  - Norfolk Island Airport ',
    'NLL  - Nullagine Airport ',
    'NLN  - Kneeland Airport  1      FAA  O19   ',
    'NLO  - N Dolo Airport ',
    'NLP  - Nelspruit Airport ',
    'NLS  - Nicholson Airport ',
    'NLT  - Xinyuan Nalati Airport ',
    'NLU  - Santa Lucía Air Force Base Num 1 ',
    'NLV  - Mykolaiv International Airport ',
    'NMA  - Namangan Airport ',
    'NMB  - Daman Airport ',
    'NMC  - Norman s Cay Airport ',
    'NME  - Nightmute Airport    FAA  IGT   ',
    'NMF  - Maafaru International Airport ',
    'NMG  - San Miguel Airport ',
    'NML  - Fort McMurray  Mildred Lake Airport  1      TC  CAJ3   ',
    'NMN  - Nomane Airport ',
    'NMP  - New Moon Airport ',
    'NMR  - Nappa Merrie Airport ',
    'NMS  - Nansang Airport ',
    'NMT  - Namtu Airport ',
    'NNA  - Kenitra Air Base ',
    'NNB  - Santa Ana Airport ',
    'NND  - Nangade Airport ',
    'NNG  - Nanning Wuxu International Airport ',
    'NNI  - Namutoni Airport ',
    'NNK  - Naknek Airport    FAA  5NK   ',
    'NNL  - Nondalton Airport    FAA  5NN   ',
    'NNM  - Naryan Mar Airport ',
    'NNR  - Connemara Airport ',
    'NNT  - Nan Nakhon Airport ',
    'NNU  - Nanuque Airport ',
    'NNX  - Nunukan Airport ',
    'NNY  - Nanyang Jiangying Airport ',
    'NOA  - NAS Nowra ',
    'NOB  - Nosara Airport ',
    'NOC  - Ireland West Airport Knock ',
    'NOD  - Norddeich Airport ',
    'NOG  - Nogales International Airport ',
    'NOI  - Novorossiysk Airport ',
    'NOJ  - Noyabrsk Airport ',
    'NOK  - Nova Xavantina Airport ',
    'NOM  - Nomad River Airport ',
    'NON  - Nonouti Airport ',
    'NOO  - Naoro Airport ',
    'NOP  - Sinop Airport ',
    'NOR  - Norðfjörður Airport ',
    'NOS  - Fascene Airport ',
    'NOT  - Marin County Airport    Gnoss Field     FAA  DVO   ',
    'NOU  - La Tontouta International Airport ',
    'NOV  - Albano Machado Airport ',
    'NOZ  - Spichenkovo Airport ',
    'NPA  - NAS Pensacola    Forrest Sherman Field   ',
    'NPE  - Hawke s Bay Airport ',
    'NPG  - Nipa Airport ',
    'NPH  - Nephi Municipal Airport    FAA  U14   ',
    'NPL  - New Plymouth Airport ',
    'NPO  - Nanga Pinoh Airport ',
    'NPP  - Napperby Airport ',
    'NPR  - Novo Progresso Airport ',
    'NPT  - Newport State Airport    FAA  UUU   ',
    'NPU  - San Pedro de Urabá Airport ',
    'NPY  - Mpanda Airport ',
    'NQA  - Millington Regional Jetport ',
    'NQI  - NAS Kingsville ',
    'NQL  - Niquelândia Airport ',
    'NQN  - Presidente Perón International Airport ',
    'NQT  - Nottingham Airport ',
    'NQU  - Reyes Murillo Airport ',
    'NQX  - NAS Key West    Boca Chica Field   ',
    'NQY  - Newquay Airport ',
    'NQZ  - Nursultan Nazarbayev International Airport    since June 8, 2020   ',
    'NRA  - Narrandera Airport ',
    'NRB  - NS Mayport    Admiral David L  McDonald Field   ',
    'NRC  - NASA Crows Landing Airport ',
    'NRD  - Norderney Airfield ',
    'NRE  - Namrole Airport ',
    'NRG  - Narrogin Airport ',
    'NRI  - Grand Lake Regional Airport  1      FAA  3O9   ',
    'NRK  - Norrköping Airport ',
    'NRL  - North Ronaldsay Airport ',
    'NRM  - Keibane Airport ',
    'NRN  - Weeze Airport    Niederrhein Airport   ',
    'NRR  - José Aponte de la Torre Airport  1      FAA  RVR   ',
    'NRS  - NOLF Imperial Beach    Ream Field   ',
    'NRT  - Narita International Airport ',
    'NRY  - Newry Airport ',
    'NSB  - North Seaplane Base ',
    'NSE  - NAS Whiting Field – North ',
    'NSH  - Noshahr Airport ',
    'NSI  - Yaoundé Nsimalen International Airport ',
    'NSK  - Alykel Airport ',
    'NSL  - Slayton Municipal Airport  1      FAA  DVP   ',
    'NSM  - Norseman Airport ',
    'NSN  - Nelson Airport ',
    'NSO  - Scone Airport ',
    'NST  - Nakhon Si Thammarat Airport ',
    'NSV  - Noosa Airport ',
    'NSY  - Naval Air Station Sigonella ',
    'NTA  - Natadola Seaplane Base ',
    'NTB  - Notodden Airport, Tuven ',
    'NTC  - Santa Carolina Airport ',
    'NTD  - NAS Point Mugu    Naval Base Ventura County   ',
    'NTE  - Nantes Atlantique Airport ',
    'NTG  - Nantong Xingdong Airport ',
    'NTI  - Stenkool Airport ',
    'NTJ  - Manti Ephraim Airport    FAA  41U   ',
    'NTL  - Newcastle Airport      RAAF Base Williamtown ',
    'NTM  - Miracema do Tocantins Airport ',
    'NTN  - Normanton Airport ',
    'NTO  - Agostinho Neto Airport ',
    'NTQ  - Noto Airport ',
    'NTR  - Del Norte International Airport ',
    'NTT  - Niuatoputapu Airport ',
    'NTU  - NAS Oceana    Apollo Soucek Field   ',
    'NTX  - Ranai Airport ',
    'NTY  - Pilanesberg International Airport ',
    'NUA  - Gregory Lake Seaplane Base ',
    'NUB  - Numbulwar Airport ',
    'NUD  - En Nahud Airport ',
    'NUE  - Nuremberg Airport ',
    'NUF  - Castlereigh Reservoir Seaplane Base ',
    'NUG  - Nuguria Airstrip ',
    'NUH  - Nunchía Airport ',
    'NUI  - Nuiqsut Airport    FAA  AQT   ',
    'NUJ  - Hamedan Air Base  1      Nogeh Airport   ',
    'NUK  - Nukutavake Airport ',
    'NUL  - Nulato Airport ',
    'NUM  - Neom Bay Airport ',
    'NUN  - NOLF Saufley Field ',
    'NUP  - Nunapitchuk Airport    FAA  16A   ',
    'NUQ  - Moffett Federal Airfield ',
    'NUR  - Nullabor Motel Airport ',
    'NUS  - Norsup Airport ',
    'NUT  - Nutuve Airport ',
    'NUU  - Nakuru Airport ',
    'NUW  - NAS Whidbey Island    Ault Field   ',
    'NUX  - Novy Urengoy Airport ',
    'NVA  - Benito Salas Airport ',
    'NVD  - Nevada Municipal Airport ',
    'NVG  - Nueva Guinea Airport ',
    'NVI  - Navoi International Airport ',
    'NVK  - Narvik Airport, Framnes ',
    'NVN  - Nervino Airport    FAA  O02   ',
    'NVP  - Novo Aripuanã Airport ',
    'NVR  - Novgorod Airport ',
    'NVS  - Nevers   Fourchambault Airport ',
    'NVT  - Navegantes–Ministro Victor Konder International Airport ',
    'NVY  - Neyveli Airport ',
    'NWA  - Mohéli Bandar Es Eslam Airport ',
    'NWH  - Parlin Field    FAA  2B3   ',
    'NWI  - Norwich International Airport ',
    'NWT  - Nowata Airport ',
    'NYA  - Nyagan Airport ',
    'NYC  - metropolitan area2 ',
    'NYE  - Nyeri Airport ',
    'NYG  - MCAF Quantico    Turner Field   ',
    'NYI  - Sunyani Airport ',
    'NYK  - Nanyuki Airport ',
    'NYM  - Nadym Airport ',
    'NYN  - Nyngan Airport ',
    'NYO  - Stockholm Skavsta Airport ',
    'NYR  - Nyurba Airport ',
    'NYS  - New York Skyports Seaplane Base ',
    'NYT  - Naypyidaw Airport ',
    'NYU  - Nyaung U Airport ',
    'NYW  - Monywa Airport ',
    'NZA  - Nzagi Airport ',
    'NZC  - Maria Reiche Neuman Airport ',
    'NZE  - Nzérékoré Airport ',
    'NZH  - Manzhouli Xijiao Airport ',
    'NZL  - Zhalantun Chengjisihan Airport ',
    'NZY  - NAS North Island    Halsey Field   ',
    'OAA  - Forward Operating Base Shank ',
    'OAG  - Orange Airport ',
    'OAH  - Shindand Air Base ',
    'OAI  - Bagram Airfield ',
    'OAJ  - Albert J  Ellis Airport ',
    'OAK  - Oakland International Airport ',
    'OAL  - Capital do Café Airport ',
    'OAM  - Oamaru Airport ',
    'OAN  - El Arrayán Airport ',
    'OAR  - Marina Municipal Airport ',
    'OAS  - Sharana Airstrip      Forward Operating Base Sharana ',
    'OAX  - Xoxocotlán International Airport ',
    'OAZ  - Camp Bastion Air Base ',
    'OBA  - Oban Airport ',
    'OBC  - Obock Airport ',
    'OBD  - Obano Airport ',
    'OBE  - Okeechobee County Airport ',
    'OBF  - Oberpfaffenhofen Airport ',
    'OBI  - Óbidos Airport ',
    'OBL  - Oostmalle Airfield ',
    'OBM  - Morobe Airport ',
    'OBN  - Oban Airport  1      North Connel Airport   ',
    'OBO  - Tokachi–Obihiro Airport ',
    'OBS  - Aubenas Aerodrome  1      Ardèche Méridionale Aerodrome   ',
    'OBU  - Kobuk Airport ',
    'OBX  - Obo Airport ',
    'OCA  - Ocean Reef Club Airport    FAA  07FA   ',
    'OCC  - Francisco de Orellana Airport ',
    'OCE  - Ocean City Municipal Airport ',
    'OCF  - Ocala International Airport    Jim Taylor Field   ',
    'OCH  - A L  Mangham Jr  Regional Airport ',
    'OCJ  - Ian Fleming International Airport ',
    'OCM  - Boolgeeda Airport ',
    'OCN  - Oceanside Municipal Airport    FAA  OKB   ',
    'OCS  - Corisco International Airport ',
    'OCV  - Aguas Claras Airport ',
    'OCW  - Washington–Warren Airport    Warren Field   ',
    'ODA  - Ouadda Airport ',
    'ODB  - Córdoba Airport ',
    'ODC  - Oakdale Airport  1      FAA  O27   ',
    'ODD  - Oodnadatta Airport ',
    'ODE  - Hans Christian Andersen Airport ',
    'ODH  - RAF Odiham ',
    'ODJ  - Ouanda Djallé Airport ',
    'ODL  - Cordillo Downs Airport ',
    'ODM  - Garrett County Airport  1      FAA  2G4   ',
    'ODN  - Long Seridan Airport ',
    'ODO  - Bodaybo Airport ',
    'ODR  - Ord River Airport ',
    'ODS  - Odesa International Airport ',
    'ODW  - A J  Eisenberg Airport    FAA  OKH   ',
    'ODY  - Oudomsay Airport ',
    'OEC  - Oecusse Airport ',
    'OEL  - Oryol Yuzhny Airport ',
    'OEM  - Vincent Fayks Airport ',
    'OEO  - L O  Simenstad Municipal Airport ',
    'OER  - Örnsköldsvik Airport ',
    'OES  - Antoine de Saint Exupéry Airport ',
    'OFF  - Offutt Air Force Base ',
    'OFI  - Ouango Fitini Airport ',
    'OFJ  - Ólafsfjörður Airport ',
    'OFK  - Norfolk Regional Airport    Karl Stefan Memorial Field   ',
    'OFU  - Ofu Airport    FAA  Z08   ',
    'OGA  - Searle Field ',
    'OGB  - Orangeburg Municipal Airport ',
    'OGD  - Ogden Hinckley Airport ',
    'OGE  - Ogeramnang Airport ',
    'OGG  - Kahului Airport ',
    'OGL  - Ogle Airport ',
    'OGM  - Ustupu Ogobsucum Airport ',
    'OGN  - Yonaguni Airport ',
    'OGO  - Abengourou Airport ',
    'OGR  - Bongor Airport ',
    'OGS  - Ogdensburg International Airport ',
    'OGU  - Ordu–Giresun Airport ',
    'OGV  - Ongava Airstrip ',
    'OGX  - Ain Beida Airport ',
    'OGZ  - Beslan Airport ',
    'OHA  - RNZAF Base Ohakea ',
    'OHB  - Ambohibary Airport ',
    'OHD  - Ohrid St  Paul the Apostle Airport ',
    'OHE  - Mohe Gulian Airport ',
    'OHH  - Okha Airport    Novostroyka Airport   ',
    'OHI  - Oshakati Airport ',
    'OHO  - Okhotsk Airport ',
    'OHR  - Wyk auf Föhr Airport ',
    'OHS  - Sohar Airport ',
    'OHT  - PAF Base Kohat ',
    'OIA  - Ourilândia do Norte Airport ',
    'OIC  - Lt  Warren Eaton Airport ',
    'OIM  - Oshima Airport ',
    'OIR  - Okushiri Airport ',
    'OIT  - Oita Airport ',
    'OJC  - Johnson County Executive Airport ',
    'OJU  - Tanjung Api Airport ',
    'OKA  - Naha Airport ',
    'OKB  - Orchid Beach Airport ',
    'OKC  - Will Rogers World Airport ',
    'OKD  - Okadama Airport ',
    'OKE  - Okinoerabu Airport ',
    'OKF  - Okaukuejo Airport ',
    'OKG  - Okoyo Airport ',
    'OKH  - RAF Cottesmore ',
    'OKI  - Oki Airport ',
    'OKJ  - Okayama Airport ',
    'OKK  - Kokomo Municipal Airport ',
    'OKL  - Gunung Bintang Airport ',
    'OKM  - Okmulgee Regional Airport ',
    'OKN  - Okondja Airport ',
    'OKO  - Yokota Air Base ',
    'OKP  - Oksapmin Airport ',
    'OKQ  - Okaba Airport ',
    'OKR  - Yorke Island Airport ',
    'OKS  - Garden County Airport ',
    'OKT  - Oktyabrsky Airport ',
    'OKU  - Mokuti Lodge Airport ',
    'OKV  - Okao Airport ',
    'OKY  - Oakey Army Aviation Centre ',
    'OLA  - Ørland Airport ',
    'OLB  - Olbia Costa Smeralda Airport ',
    'OLC  - Senadora Eunice Michiles Airport ',
    'OLD  - Old Town Municipal Airport and Seaplane Base    Dewitt Field   ',
    'OLE  - Cattaraugus County Olean Airport ',
    'OLF  - L  M  Clayton Airport ',
    'OLH  - Old Harbor Airport    FAA  6R7   ',
    'OLI  - Rif Airport ',
    'OLJ  - Olpoi Airport  1      North West Santo Airport   ',
    'OLK  - Fuerte Olimpo Airport ',
    'OLL  - Oyo Ollombo Airport ',
    'OLM  - Olympia Regional Airport ',
    'OLN  - Lago Musters Airport ',
    'OLO  - Olomouc Airport    Neředín Airport   ',
    'OLP  - Olympic Dam Airport ',
    'OLQ  - Olsobip Airport ',
    'OLR  - Forward Operating Base Salerno ',
    'OLS  - Nogales International Airport ',
    'OLU  - Columbus Municipal Airport ',
    'OLV  - Olive Branch Airport ',
    'OLX  - Olkiombo Airstrip ',
    'OLY  - Olney Noble Airport ',
    'OLZ  - Olyokminsk Airport ',
    'OMA  - Eppley Airfield ',
    'OMB  - Omboué Hospital Airport ',
    'OMC  - Ormoc Airport ',
    'OMD  - Oranjemund Airport ',
    'OME  - Nome Airport ',
    'OMF  - King Hussein Air Base ',
    'OMG  - Omega Airport ',
    'OMH  - Urmia Airport ',
    'OMI  - Omidiyeh Air Base ',
    'OMJ  - Ōmura Airport ',
    'OMK  - Omak Airport ',
    'OML  - Omkalai Airport ',
    'OMM  - Marmul Airport ',
    'OMO  - Mostar Airport ',
    'OMR  - Oradea International Airport ',
    'OMS  - Omsk Tsentralny Airport ',
    'OMY  - Thbeng Meanchey Airport  1      Preah Vinhear Airport   ',
    'ONA  - Winona Municipal Airport    Max Conrad Field   ',
    'ONB  - Ononge Airport ',
    'OND  - Ondangwa Airport ',
    'ONE  - Onepusu Airport ',
    'ONG  - Mornington Island Airport ',
    'ONH  - Oneonta Municipal Airport    FAA  N66   ',
    'ONI  - Moanamani Airport ',
    'ONJ  - Odate–Noshiro Airport ',
    'ONK  - Olenyok Airport ',
    'ONL  - O Neill Municipal Airport    John L  Baker Field   ',
    'ONM  - Socorro Municipal Airport ',
    'ONO  - Ontario Municipal Airport ',
    'ONP  - Newport Municipal Airport ',
    'ONQ  - Zonguldak Airport ',
    'ONR  - Monkira Airport ',
    'ONS  - Onslow Airport ',
    'ONT  - Ontario International Airport ',
    'ONU  - Ono i Lau Airport ',
    'ONX  - Enrique Adolfo Jiménez Airport ',
    'ONY  - Olney Municipal Airport ',
    'OOA  - Oskaloosa Municipal Airport ',
    'OOK  - Toksook Bay Airport ',
    'OOL  - Gold Coast Airport    Coolangatta Airport   ',
    'OOM  - Cooma–Snowy Mountains Airport ',
    'OOR  - Mooraberree Airport ',
    'OOT  - Onotoa Airport ',
    'OPA  - Kópasker Airport ',
    'OPB  - Open Bay Airport ',
    'OPF  - Miami Opa Locka Executive Airport ',
    'OPI  - Oenpelli Airport ',
    'OPL  - St  Landry Parish Airport    Ahart Field   ',
    'OPO  - Francisco de Sá Carneiro Airport ',
    'OPS  - Presidente João Figueiredo Airport ',
    'OPU  - Balimo Airport ',
    'OPW  - Opuwa Airport ',
    'ORA  - Orán Airport ',
    'ORB  - Örebro Airport ',
    'ORC  - Orocue Airport ',
    'ORD  - O Hare International Airport Chicago ',
    'ORE  - Orléans – Saint Denis de l Hôtel Airport ',
    'ORF  - Norfolk International Airport ',
    'ORG  - Zorg en Hoop Airport ',
    'ORH  - Worcester Regional Airport ',
    'ORI  - Port Lions Airport ',
    'ORJ  - Orinduik Airport ',
    'ORK  - Cork Airport ',
    'ORL  - Orlando Executive Airport ',
    'ORM  - Sywell Aerodrome ',
    'ORN  - Oran Es Sénia Airport ',
    'ORO  - Yoro Airport ',
    'ORP  - Orapa Airport ',
    'ORR  - Yorketown Airport ',
    'ORS  - Orpheus Island Resort Waterport ',
    'ORT  - Northway Airport ',
    'ORU  - Juan Mendoza Airport ',
    'ORV  - Robert   Bob   Curtis Memorial Airport    FAA  D76   ',
    'ORW  - Ormara Airport ',
    'ORX  - Oriximiná Airport ',
    'ORY  - Orly Airport ',
    'ORZ  - Orange Walk Airport ',
    'OSA  - metropolitan area1 ',
    'OSB  - Grand Glaize Osage Beach Airport    FAA  K15   ',
    'OSC  - Oscoda–Wurtsmith Airport ',
    'OSD  - Åre Östersund Airport ',
    'OSE  - Omora Airport ',
    'OSF  - Ostafyevo International Airport ',
    'OSG  - Ossima Airport ',
    'OSH  - Wittman Regional Airport ',
    'OSI  - Osijek Airport ',
    'OSK  - Oskarshamn Airport ',
    'OSL  - Oslo Airport, Gardermoen ',
    'OSM  - Mosul International Airport ',
    'OSN  - Osan Air Base ',
    'OSO  - Osborne Mine Airport ',
    'OSR  - Leoš Janáček Airport Ostrava ',
    'OSS  - Osh Airport ',
    'OST  - Ostend–Bruges International Airport ',
    'OSU  - Ohio State University Airport ',
    'OSW  - Orsk Airport ',
    'OSX  - Kosciusko Attala County Airport ',
    'OSY  - Namsos Airport, Høknesøra ',
    'OSZ  - Koszalin Zegrze Pomorskie Airport ',
    'OTA  - Mota Airport ',
    'OTC  - Bol Bérim Airport ',
    'OTD  - Contadora Airport ',
    'OTG  - Worthington Municipal Airport ',
    'OTH  - Southwest Oregon Regional Airport ',
    'OTI  - Pitu Airport ',
    'OTJ  - Otjiwarongo Airport ',
    'OTK  - Tillamook Airport  1      FAA  TMK   ',
    'OTL  - Boutilimit Airport ',
    'OTM  - Ottumwa Regional Airport ',
    'OTN  - Ed Air Airport    FAA  2IG4   ',
    'OTP  - Henri Coandă International Airport ',
    'OTR  - Coto 47 Airport ',
    'OTS  - Anacortes Airport    FAA  74S   ',
    'OTT  - Andre Maggi Airport ',
    'OTU  - Otú Airport ',
    'OTV  - Ontong Java Airport ',
    'OTY  - Oria Airport ',
    'OTZ  - Ralph Wien Memorial Airport ',
    'OUA  - Ouagadougou Airport ',
    'OUD  - Angads Airport ',
    'OUE  - Ouésso Airport ',
    'OUG  - Ouahigouya Airport ',
    'OUH  - Oudtshoorn Airport ',
    'OUK  - Out Skerries Airport ',
    'OUL  - Oulu Airport ',
    'OUM  - Oum Hadjer Airport ',
    'OUN  - University of Oklahoma Westheimer Airport    Max Westheimer Airport   ',
    'OUR  - Batouri Airport ',
    'OUS  - Jornalista Benedito Pimentel–Ourinhos State Airport ',
    'OUT  - Bousso Airport ',
    'OUU  - Ouanga Airport ',
    'OUZ  - Tazadit Airport ',
    'OVA  - Bekily Airport ',
    'OVB  - Tolmachevo Airport ',
    'OVD  - Asturias Airport    Oviedo Airport   ',
    'OVE  - Oroville Municipal Airport ',
    'OVG  - Air Force Base Overberg ',
    'OVL  - El Tuqui Airport ',
    'OVR  - Olavarría Airport ',
    'OVS  - Sovetsky Airport ',
    'OWA  - Owatonna Degner Regional Airport ',
    'OWB  - Owensboro–Daviess County Airport ',
    'OWD  - Norwood Memorial Airport ',
    'OWE  - Owendo Airport ',
    'OWK  - Central Maine Airport of Norridgewock ',
    'OXB  - Osvaldo Vieira International Airport ',
    'OXC  - Waterbury–Oxford Airport ',
    'OXD  - Miami University Airport ',
    'OXF  - Oxford Airport    London Oxford Airport   ',
    'OXO  - Orientos Airport ',
    'OXR  - Oxnard Airport ',
    'OXY  - Morney Airport ',
    'OYA  - Goya Airport ',
    'OYE  - Oyem Airport ',
    'OYG  - Moyo Airport ',
    'OYK  - Oiapoque Airport ',
    'OYL  - Moyale Airport ',
    'OYN  - Ouyen Airport ',
    'OYO  - Tres Arroyos Airport ',
    'OYP  - Saint Georges de l Oyapock Airport ',
    'OZA  - Ozona Municipal Airport ',
    'OZC  - Labo Airport ',
    'OZG  - Zagora Airport ',
    'OZH  - Zaporizhzhia International Airport ',
    'OZP  - Morón Air Base ',
    'OZR  - Cairns Army Airfield ',
    'OZZ  - Ouarzazate Airport ',
    'PAA  - Hpa An Airport ',
    'PAB  - Bilaspur Airport ',
    'PAC  - Albrook Marcos A  Gelabert International Airport ',
    'PAD  - Paderborn Lippstadt Airport ',
    'PAE  - Paine Field    Snohomish County Airport   ',
    'PAF  - Pakuba Airfield ',
    'PAG  - Pagadian Airport ',
    'PAH  - Barkley Regional Airport ',
    'PAJ  - Parachinar Airport ',
    'PAK  - Port Allen Airport ',
    'PAL  - Captain Germán Olano Moreno Air Base ',
    'PAM  - Tyndall Air Force Base ',
    'PAN  - Pattani Airport ',
    'PAO  - Palo Alto Airport    Santa Clara County Airport   ',
    'PAP  - Toussaint L Ouverture International Airport ',
    'PAQ  - Palmer Municipal Airport ',
    'PAR  - metropolitan area1 ',
    'PAS  - Paros National Airport ',
    'PAT  - Lok Nayak Jayaprakash Airport ',
    'PAU  - Pauk Airport ',
    'PAV  - Paulo Afonso Airport ',
    'PAW  - Pambwa Airport ',
    'PAX  - Port de Paix Airport ',
    'PAY  - Pamol Airport ',
    'PAZ  - El Tajín National Airport ',
    'PBB  - Paranaíba Airport ',
    'PBC  - Hermanos Serdán International Airport ',
    'PBD  - Porbandar Airport ',
    'PBE  - Morela Airport  1      Puerto Berrío Airport   ',
    'PBF  - Grider Field ',
    'PBG  - Plattsburgh International Airport ',
    'PBH  - Paro International Airport ',
    'PBI  - Palm Beach International Airport ',
    'PBJ  - Paama Airport ',
    'PBL  - Bartolomé Salom Airport ',
    'PBM  - Johan Adolf Pengel International Airport ',
    'PBN  - Porto Amboim Airport ',
    'PBO  - Paraburdoo Airport ',
    'PBP  - Punta Islita Airport ',
    'PBQ  - Pimenta Bueno Airport ',
    'PBR  - Puerto Barrios Airport ',
    'PBT  - Puerto Leda Airport ',
    'PBU  - Putao Airport ',
    'PBV  - Porto dos Gaúchos Airport ',
    'PBX  - Fazenda Piraguassu Airport ',
    'PBZ  - Plettenberg Bay Airport ',
    'PCA  - Portage Creek Airport    FAA  A14   ',
    'PCB  - Pondok Cabe Airport ',
    'PCC  - Puerto Rico Airport ',
    'PCD  - Prairie du Chien Municipal Airport    FAA  PDC   ',
    'PCF  - Potchefstroom Airport ',
    'PCH  - Palacios Airport ',
    'PCJ  - Puerto La Victoria Airport ',
    'PCL  - FAP Captain David Abensur Rengifo International Airport ',
    'PCM  - Playa del Carmen Airport ',
    'PCN  - Picton Aerodrome ',
    'PCO  - Punta Colorada Airstrip ',
    'PCP  - Príncipe Airport ',
    'PCQ  - Boun Neua Airport ',
    'PCR  - Germán Olano Airport ',
    'PCS  - Picos Airport ',
    'PCT  - Princeton Airport    FAA  39N   ',
    'PCU  - Poplarville Pearl River County Airport  1      FAA  M13   ',
    'PCV  - Punta Chivato Airstrip ',
    'PDA  - Obando Airport ',
    'PDB  - Pedro Bay Airport    FAA  4K0   ',
    'PDC  - Mueo  Nickel Airport ',
    'PDD  - Ponta do Ouro Airport ',
    'PDE  - Pandie Pandie Airport ',
    'PDF  - Faz Taua Airport ',
    'PDG  - Minangkabau International Airport ',
    'PDI  - Pindiu Airport ',
    'PDK  - DeKalb–Peachtree Airport ',
    'PDL  - João Paulo II Airport ',
    'PDM  - Pedasí Airport  1      Capt  J  Montenegro Airport   ',
    'PDN  - Parndana Airport ',
    'PDO  - Pendopo Airport ',
    'PDP  - Capitán de Corbeta Carlos A  Curbelo International Airport ',
    'PDR  - Presidente Dutra Airport ',
    'PDS  - Piedras Negras International Airport ',
    'PDT  - Eastern Oregon Regional Airport ',
    'PDU  - Tydeo Larre Borges International Airport ',
    'PDV  - Plovdiv Airport ',
    'PDX  - Portland International Airport ',
    'PDZ  - Pedernales Airport ',
    'PEA  - Penneshaw Airport ',
    'PEB  - Pebane Airport ',
    'PEC  - Pelican Seaplane Base ',
    'PED  - Pardubice Airport ',
    'PEE  - Perm International Airport    Bolshoye Savino Airport   ',
    'PEF  - Peenemünde Airfield ',
    'PEG  - Perugia San Francesco d Assisi – Umbria International Airport ',
    'PEH  - Comodoro Pedro Zanni Airport ',
    'PEI  - Matecaña International Airport ',
    'PEK  - Beijing Capital International Airport ',
    'PEL  - Pelaneng Airport ',
    'PEM  - Padre Aldamiz International Airport ',
    'PEN  - Penang International Airport ',
    'PEP  - Peppimenarti Airport ',
    'PEQ  - Pecos Municipal Airport ',
    'PER  - Perth Airport ',
    'PES  - Petrozavodsk Airport    Besovets Airport   ',
    'PET  - João Simões Lopes Neto International Airport ',
    'PEU  - Puerto Lempira Airport ',
    'PEV  - Pécs Pogány International Airport ',
    'PEW  - Bacha Khan International Airport ',
    'PEX  - Pechora Airport ',
    'PEY  - Penong Airport ',
    'PEZ  - Penza Airport    Ternovka Airport   ',
    'PFB  - Lauro Kurtz Airport ',
    'PFC  - Pacific City State Airport ',
    'PFJ  - Patreksfjörður Airport ',
    'PFM  - Primrose Aerodrome ',
    'PFO  - Paphos International Airport ',
    'PFQ  - Parsabad Moghan Airport ',
    'PFR  - Ilebo Airport ',
    'PGA  - Page Municipal Airport ',
    'PGB  - Pangoa Airport ',
    'PGC  - Grant County Airport    FAA  W99   ',
    'PGD  - Punta Gorda Airport ',
    'PGE  - Yegepa Airport ',
    'PGF  - Perpignan–Rivesaltes Airport ',
    'PGH  - Pantnagar Airport ',
    'PGI  - Chitato Airport ',
    'PGK  - Depati Amir Airport ',
    'PGL  - Trent Lott International Airport    FAA  PQL   ',
    'PGM  - Port Graham Airport ',
    'PGN  - Pangia Airport ',
    'PGO  - Stevens Field    FAA  PSO   ',
    'PGQ  - Buli Airport ',
    'PGR  - Kirk Field ',
    'PGS  - Grand Canyon Caverns Airport    FAA  L37   ',
    'PGU  - Persian Gulf Airport ',
    'PGV  - Pitt–Greenville Airport ',
    'PGX  - Périgueux Bassillac Airport ',
    'PGZ  - Comte  Antonio Amilton Beraldo Airport ',
    'PHA  - Phan Rang Air Base ',
    'PHB  - Parnaíba Prefeito Dr  João Silva Filho International Airport ',
    'PHC  - Port Harcourt International Airport ',
    'PHD  - Harry Clever Field ',
    'PHE  - Port Hedland International Airport ',
    'PHF  - Newport News  Williamsburg International Airport ',
    'PHG  - Port Harcourt City Airport ',
    'PHI  - Pinheiro Airport ',
    'PHK  - Palm Beach County Glades Airport ',
    'PHL  - Philadelphia International Airport ',
    'PHN  - St  Clair County International Airport ',
    'PHO  - Point Hope Airport ',
    'PHP  - Philip Airport ',
    'PHQ  - The Monument Airport ',
    'PHR  - Pacific Harbour Airport ',
    'PHS  - Phitsanulok Airport ',
    'PHT  - Henry County Airport ',
    'PHW  - Hendrik Van Eck Airport ',
    'PHX  - Phoenix Sky Harbor International Airport ',
    'PHY  - Phetchabun Airport ',
    'PIA  - General Wayne A  Downing Peoria International Airport ',
    'PIB  - Hattiesburg–Laurel Regional Airport ',
    'PIC  - Pine Cay Airport ',
    'PIE  - St  Pete–Clearwater International Airport ',
    'PIF  - Pingtung South Airport ',
    'PIH  - Pocatello Regional Airport ',
    'PIK  - Glasgow Prestwick Airport ',
    'PIL  - Carlos Miguel Jiménez Airport ',
    'PIM  - Harris County Airport ',
    'PIN  - Júlio Belém Airport ',
    'PIO  - Capitán FAP Renán Elías Olivera Airport ',
    'PIP  - Pilot Point Airport    FAA  PNP   ',
    'PIQ  - Pipillipai Airport ',
    'PIR  - Pierre Regional Airport ',
    'PIS  - Poitiers–Biard Airport ',
    'PIT  - Pittsburgh International Airport ',
    'PIU  - FAP Captain Guillermo Concha Iberico International Airport ',
    'PIV  - Pirapora Airport ',
    'PIW  - Pikwitonei Airport ',
    'PIX  - Pico Airport ',
    'PIZ  - Point Lay LRRS Airport ',
    'PJA  - Pajala Airport ',
    'PJB  - Payson Airport    FAA  PAN   ',
    'PJC  - Dr  Augusto Roberto Fuster International Airport ',
    'PJG  - Panjgur Airport ',
    'PJM  - Puerto Jiménez Airport ',
    'PKA  - Napaskiak Airport ',
    'PKB  - Mid Ohio Valley Regional Airport ',
    'PKC  - Petropavlovsk Kamchatsky Airport    Yelizovo Airport   ',
    'PKD  - Park Rapids Municipal Airport    Konshok Field   ',
    'PKE  - Parkes Airport ',
    'PKF  - Park Falls Municipal Airport ',
    'PKG  - Pangkor Airport ',
    'PKH  - Porto Cheli Airport ',
    'PKJ  - Playa Grande Airport ',
    'PKK  - Pakokku Airport ',
    'PKM  - Port Kaituma Airport ',
    'PKN  - Iskandar Airport ',
    'PKO  - Parakou Airport ',
    'PKP  - Puka Puka Airport ',
    'PKR  - Pokhara Airport ',
    'PKT  - Port Keats Airfield ',
    'PKU  - Sultan Syarif Kasim II International Airport ',
    'PKV  - Pskov Airport ',
    'PKW  - Selebi Phikwe Airport ',
    'PKX  - Beijing Daxing International Airport ',
    'PKY  - Tjilik Riwut Airport ',
    'PKZ  - Pakse International Airport ',
    'PLA  - Planadas Airport ',
    'PLC  - Planeta Rica Airport ',
    'PLD  - Carrillo Airport  1      Playa Samara  Carrillo Airport   ',
    'PLE  - Paiela Airport ',
    'PLF  - Pala Airport ',
    'PLH  - Plymouth City Airport ',
    'PLI  - Palm Island Airport ',
    'PLJ  - Placencia Airport ',
    'PLK  - M  Graham Clark Downtown Airport ',
    'PLL  - Manaus Air Force Base ',
    'PLM  - Sultan Mahmud Badaruddin II International Airport ',
    'PLN  - Pellston Regional Airport    Emmet County   ',
    'PLO  - Port Lincoln Airport ',
    'PLP  - Captain Ramon Xatruch Airport ',
    'PLQ  - Palanga International Airport ',
    'PLR  - St  Clair County Airport ',
    'PLS  - Providenciales International Airport ',
    'PLT  - Plato Airport ',
    'PLU  - Belo Horizonte  Pampulha – Carlos Drummond de Andrade Airport ',
    'PLV  - Poltava Airport ',
    'PLW  - Mutiara Airport ',
    'PLX  - Semey Airport ',
    'PLY  - Plymouth Municipal Airport    FAA  C65   ',
    'PLZ  - Port Elizabeth Airport ',
    'PMA  - Pemba Airport ',
    'PMB  - Pembina Municipal Airport ',
    'PMC  - El Tepual Airport ',
    'PMD  - Palmdale Regional Airport ',
    'PMF  - Parma Airport    Giuseppe Verdi Airport   ',
    'PMG  - Ponta Porã International Airport ',
    'PMH  - Greater Portsmouth Regional Airport ',
    'PMI  - Palma de Mallorca Airport ',
    'PMK  - Palm Island Airport ',
    'PML  - Port Moller Airport    FAA  1AK3   ',
    'PMN  - Pumani Airport ',
    'PMO  - Falcone–Borsellino Airport    Punta Raisi Airport   ',
    'PMP  - Pimaga Airport ',
    'PMQ  - Perito Moreno Airport ',
    'PMR  - Palmerston North Airport ',
    'PMS  - Palmyra Airport ',
    'PMT  - Paramakatoi Airport ',
    'PMV  - Santiago Mariño Caribbean International Airport ',
    'PMW  - Palmas–Brigadeiro Lysias Rodrigues Airport ',
    'PMX  - Metropolitan Airport  1      FAA  13MA   ',
    'PMY  - El Tehuelche Airport ',
    'PMZ  - Palmar Sur Airport ',
    'PNA  - Pamplona Airport ',
    'PNB  - Porto Nacional Airport ',
    'PNC  - Ponca City Regional Airport ',
    'PND  - Punta Gorda Airport ',
    'PNE  - Northeast Philadelphia Airport ',
    'PNG  - Santos Dumont Airport ',
    'PNH  - Phnom Penh International Airport ',
    'PNI  - Pohnpei International Airport ',
    'PNJ  - Penglai Shahekou Airport ',
    'PNK  - Supadio Airport ',
    'PNL  - Pantelleria Airport ',
    'PNN  - Princeton Municipal Airport ',
    'PNP  - Girua Airport ',
    'PNQ  - Pune Airport ',
    'PNR  - Pointe Noire Airport ',
    'PNS  - Pensacola International Airport ',
    'PNT  - Teniente Julio Gallardo Airport ',
    'PNU  - Panguitch Municipal Airport    FAA  U55   ',
    'PNV  - Panevėžys Air Base  1      Pajuostis Airport   ',
    'PNX  - North Texas Regional Airport    FAA  GYI   ',
    'PNY  - Puducherry Airport ',
    'PNZ  - Senador Nilo Coelho Airport ',
    'POA  - Salgado Filho International Airport ',
    'POB  - Pope Field    Pope AFB   ',
    'POC  - Brackett Field ',
    'POD  - Podor Airport ',
    'POE  - Polk Army Airfield ',
    'POF  - Poplar Bluff Municipal Airport ',
    'POG  - Port Gentil International Airport ',
    'POH  - Pocahontas Municipal Airport ',
    'POI  - Captain Nicolas Rojas Airport ',
    'POJ  - Pedro Pereira dos Santos Airport ',
    'POL  - Pemba Airport ',
    'POM  - Jacksons International Airport ',
    'PON  - Poptún Airport ',
    'POO  - Poços de Caldas Airport ',
    'POP  - Gregorio Luperón International Airport ',
    'POR  - Pori Airport ',
    'POS  - Piarco International Airport ',
    'POT  - Ken Jones Aerodrome ',
    'POU  - Dutchess County Airport ',
    'POV  - Prešov Air Base ',
    'POW  - Portorož Airport ',
    'POX  - Pontoise – Cormeilles Aerodrome ',
    'POY  - Powell Municipal Airport ',
    'POZ  - Poznań–Ławica Airport ',
    'PPA  - Perry Lefors Field ',
    'PPB  - Presidente Prudente Airport ',
    'PPC  - Prospect Creek Airport ',
    'PPE  - Puerto Peñasco International Airport ',
    'PPF  - Tri City Airport ',
    'PPG  - Pago Pago International Airport ',
    'PPH  - Parai tepuí Airport ',
    'PPI  - Port Pirie Airport ',
    'PPJ  - Panjang Island Airport ',
    'PPK  - Petropavl Airport ',
    'PPL  - Phaplu Airport ',
    'PPM  - Pompano Beach Airpark    FAA  PMP   ',
    'PPN  - Guillermo León Valencia Airport ',
    'PPP  - Whitsunday Coast Airport ',
    'PPQ  - Kapiti Coast Airport ',
    'PPR  - Tuanku Tambusai Airport ',
    'PPS  - Puerto Princesa International Airport ',
    'PPT  - Fa a ā International Airport ',
    'PPU  - Papun Airport ',
    'PPV  - Port Protection Seaplane Base    FAA  19P   ',
    'PPW  - Papa Westray Airport ',
    'PPX  - Param Airport ',
    'PPY  - Pouso Alegre Airport ',
    'PPZ  - Puerto Páez Airport ',
    'PQC  - Phu Quoc International Airport ',
    'PQD  - Batticaloa Seaplane Base ',
    'PQI  - Northern Maine Regional Airport at Presque Isle ',
    'PQM  - Palenque International Airport ',
    'PQQ  - Port Macquarie Airport ',
    'PQS  - Pilot Station Airport    FAA  0AK   ',
    'PRA  - General Justo José de Urquiza Airport ',
    'PRB  - Paso Robles Municipal Airport ',
    'PRC  - Ernest A  Love Field ',
    'PRD  - Pardoo Airport ',
    'PRE  - Pore Airport ',
    'PRG  - Václav Havel Airport Prague ',
    'PRH  - Phrae Airport ',
    'PRI  - Praslin Island Airport ',
    'PRK  - Prieska Airport ',
    'PRM  - Portimão Airport ',
    'PRN  - Pristina International Airport Adem Jashari ',
    'PRO  - Perry Municipal Airport ',
    'PRP  - Propriano Airport ',
    'PRQ  - Presidencia Roque Sáenz Peña Airport ',
    'PRR  - Paruima Airport ',
    'PRS  - Parasi Airport ',
    'PRU  - Pyay Airport ',
    'PRV  - Přerov Airport ',
    'PRW  - Prentice Airport    FAA  5N2   ',
    'PRX  - Cox Field ',
    'PRY  - Wonderboom Airport ',
    'PRZ  - Prineville Airport    FAA  S39   ',
    'PSA  - Pisa International Airport    Galileo Galilei Airport   ',
    'PSB  - Mid State Regional Airport ',
    'PSC  - Tri Cities Airport ',
    'PSD  - Port Said Airport ',
    'PSE  - Mercedita Airport ',
    'PSF  - Pittsfield Municipal Airport ',
    'PSG  - Petersburg James A  Johnson Airport ',
    'PSH  - Sankt Peter Ording Airport ',
    'PSI  - Pasni Airport ',
    'PSJ  - Kasiguncu Airport ',
    'PSK  - New River Valley Airport ',
    'PSL  - Perth Airport    Scone Airport   ',
    'PSM  - Portsmouth International Airport at Pease ',
    'PSN  - Palestine Municipal Airport ',
    'PSO  - Antonio Nariño Airport ',
    'PSP  - Palm Springs International Airport ',
    'PSQ  - Philadelphia Seaplane Base    FAA  9N2   ',
    'PSR  - Abruzzo Airport ',
    'PSS  - Libertador General José de San Martín Airport ',
    'PSU  - Pangsuma Airport ',
    'PSV  - Papa Stour Airstrip ',
    'PSW  - Passos Airport ',
    'PSX  - Palacios Municipal Airport ',
    'PSY  - Port Stanley Airport ',
    'PSZ  - Puerto Suárez International Airport ',
    'PTA  - Port Alsworth Airport    FAA  TPO   ',
    'PTB  - Dinwiddie County Airport ',
    'PTC  - Port Alice Seaplane Base    FAA  16K   ',
    'PTD  - Port Alexander Seaplane Base    FAA  AHP   ',
    'PTF  - Malolo Lailai Airport ',
    'PTG  - Polokwane International Airport ',
    'PTH  - Port Heiden Airport ',
    'PTJ  - Portland Airport ',
    'PTK  - Oakland County International Airport ',
    'PTM  - Palmarito Airport ',
    'PTN  - Harry P  Williams Memorial Airport ',
    'PTO  - Juvenal Loureiro Cardoso Airport ',
    'PTP  - Pointe à Pitre International Airport    Le Raizet Airport   ',
    'PTQ  - Porto de Moz Airport ',
    'PTS  - Atkinson Municipal Airport ',
    'PTT  - Pratt Regional Airport ',
    'PTU  - Platinum Airport ',
    'PTV  - Porterville Municipal Airport ',
    'PTW  - Heritage Field ',
    'PTX  - Contador Airport ',
    'PTY  - Tocumen International Airport ',
    'PTZ  - Río Amazonas Airport ',
    'PUA  - Puas Airport ',
    'PUB  - Pueblo Memorial Airport ',
    'PUC  - Carbon County Regional Airport ',
    'PUD  - Puerto Deseado Airport ',
    'PUE  - Puerto Obaldia Airport ',
    'PUF  - Pau Pyrénées Airport ',
    'PUG  - Port Augusta Airport ',
    'PUI  - Pureni Airport ',
    'PUJ  - Punta Cana International Airport ',
    'PUK  - Pukarua Airport ',
    'PUL  - Port of Poulsbo Marina Moorage Seaplane Base    FAA  83Q   ',
    'PUM  - Sangia Nibandera Airport ',
    'PUN  - Punia Airport ',
    'PUP  - Pô Airport ',
    'PUQ  - Presidente Carlos Ibáñez del Campo International Airport ',
    'PUR  - Puerto Rico Airport ',
    'PUS  - Gimhae International Airport ',
    'PUT  - Sri Sathya Sai Airport ',
    'PUU  - Tres de Mayo Airport ',
    'PUV  - Malabou Airport ',
    'PUW  - Pullman–Moscow Regional Airport ',
    'PUX  - El Mirador Airport ',
    'PUY  - Pula Airport ',
    'PUZ  - Puerto Cabezas Airport ',
    'PVA  - El Embrujo Airport ',
    'PVC  - Provincetown Municipal Airport ',
    'PVD  - Theodore Francis Green State Airport ',
    'PVE  - El Porvenir Airport ',
    'PVF  - Placerville Airport ',
    'PVG  - Shanghai Pudong International Airport ',
    'PVH  - Governador Jorge Teixeira de Oliveira International Airport ',
    'PVI  - Edu Chaves Airport ',
    'PVK  - Aktion National Airport ',
    'PVL  - Pike County Airport  1      FAA  PBX   ',
    'PVO  - Reales Tamarindos Airport ',
    'PVR  - Licenciado Gustavo Díaz Ordaz International Airport ',
    'PVS  - Provideniya Bay Airport ',
    'PVU  - Provo Municipal Airport ',
    'PVW  - Hale County Airport ',
    'PWA  - Wiley Post Airport ',
    'PWD  - Sher Wood Airport ',
    'PWE  - Pevek Airport ',
    'PWI  - Beles Airport ',
    'PWK  - Chicago Executive Airport ',
    'PWL  - Wirasaba Airport ',
    'PWM  - Portland International Jetport ',
    'PWN  - Pitts Town Airport ',
    'PWO  - Pweto Airport ',
    'PWQ  - Pavlodar Airport ',
    'PWR  - Port Walter Seaplane Base ',
    'PWT  - Bremerton National Airport ',
    'PWY  - Ralph Wenz Field    FAA  PNA   ',
    'PXA  - Atung Bungsu Airport ',
    'PXH  - Prominent Hill Airport ',
    'PXL  - Polacca Airport    FAA  P10   ',
    'PXM  - Puerto Escondido International Airport ',
    'PXO  - Porto Santo Airport ',
    'PXR  - Surin Airport ',
    'PXU  - Pleiku Airport ',
    'PYA  - Velasquez Airport ',
    'PYB  - Jeypore Airport ',
    'PYC  - Playón Chico Airport ',
    'PYE  - Tongareva Airport ',
    'PYG  - Pakyong Airport ',
    'PYH  - Cacique Aramare Airport ',
    'PYJ  - Polyarny Airport ',
    'PYK  - Payam International Airport ',
    'PYL  - Perry Island Seaplane Base ',
    'PYM  - Plymouth Municipal Airport ',
    'PYN  - Payán Airport ',
    'PYO  - Putumayo Airport ',
    'PYR  - Andravida Air Base ',
    'PYS  - Paradise Skypark  1      FAA  CA92   ',
    'PYV  - Yaviza Airport ',
    'PYY  - Pai Airport ',
    'PZA  - Paz de Ariporo Airport ',
    'PZB  - Pietermaritzburg Airport ',
    'PZH  - Zhob Airport ',
    'PZI  - Panzhihua Bao anying Airport ',
    'PZK  - Pukapuka Island Airfield ',
    'PZL  - Zulu Inyala Airport ',
    'PZO  - Manuel Carlos Piar Guayana Airport ',
    'PZS  - Maquehue Airport ',
    'PZU  - Port Sudan New International Airport ',
    'PZY  - Piešťany Airport ',
    'QAH  - Hindon Airport ',
    'QAQ  - L Aquila–Preturo Airport ',
    'QBC  - Bella Coola Airport ',
    'QCY  - RAF Coningsby ',
    'QHU  - Husum Schwesing Airport ',
    'QLR  - Monte Real Air Base ',
    'QOW  - Sam Mbakwe Airport ',
    'QPG  - Paya Lebar Air Base ',
    'QRA  - Rand Airport ',
    'QRC  - Rancagua de la Independencia Airport ',
    'QRO  - Querétaro Intercontinental Airport ',
    'QRW  - Warri Airport    Osubi Airport   ',
    'QSC  - Mário Pereira Lopes Airport ',
    'QSF  - Ain Arnat Airport ',
    'QSR  - Salerno Costa d Amalfi Airport    Pontecagnano Airport   ',
    'QSZ  - Shache Airport ',
    'QUB  - Ubari Airport ',
    'QUG  - Chichester  Goodwood Airport ',
    'QUO  - Akwa Ibom International Airport ',
    'QUY  - RAF Wyton ',
    'QWG  - Wilgrove Air Park    FAA  8A6     closed June 2020   ',
    'QXB  - Aix en Provence Aerodrome ',
    'RAA  - Rakanda Airport ',
    'RAB  - Rabaul Airport    Tokua Airport   ',
    'RAC  - John H  Batten Airport ',
    'RAD  - Road Town Seaplane Base ',
    'RAE  - Arar Domestic Airport ',
    'RAF  - Rafaela Airport ',
    'RAG  - Raglan Aerodrome ',
    'RAH  - Rafha Domestic Airport ',
    'RAI  - Nelson Mandela International Airport ',
    'RAJ  - Rajkot Airport ',
    'RAK  - Marrakesh Menara Airport ',
    'RAL  - Riverside Municipal Airport ',
    'RAM  - Ramingining Airport ',
    'RAN  - Ravenna Airport ',
    'RAO  - Leite Lopes Airport ',
    'RAP  - Rapid City Regional Airport ',
    'RAQ  - Sugimanuru Airport ',
    'RAR  - Rarotonga International Airport ',
    'RAS  - Rasht Airport ',
    'RAT  - Raduzhny Airport ',
    'RAV  - Cravo Norte Airport ',
    'RAW  - Arawa Airport ',
    'RAX  - Oram Airport ',
    'RAZ  - Rawalakot Airport ',
    'RBA  - Rabat–Salé Airport ',
    'RBB  - Borba Airport ',
    'RBC  - Robinvale Airport ',
    'RBD  - Dallas Executive Airport ',
    'RBE  - Ratanakiri Airport ',
    'RBF  - Big Bear City Airport  1      FAA  L35   ',
    'RBG  - Roseburg Regional Airport ',
    'RBI  - Rabi Airport ',
    'RBJ  - Rebun Airport ',
    'RBK  - French Valley Airport  1      FAA  F70   ',
    'RBL  - Red Bluff Municipal Airport ',
    'RBM  - Straubing Wallmühle Airport ',
    'RBO  - Roboré Airport ',
    'RBP  - Rabaraba Airport ',
    'RBQ  - Rurrenabaque Airport ',
    'RBR  - Plácido de Castro International Airport ',
    'RBS  - Orbost Airport ',
    'RBT  - Marsabit Airport ',
    'RBU  - Roebourne Airport ',
    'RBV  - Ramata Airport ',
    'RBW  - Lowcountry Regional Airport ',
    'RBX  - Rumbek Airport ',
    'RBY  - Ruby Airport ',
    'RCA  - Ellsworth Air Force Base ',
    'RCB  - Richards Bay Airport ',
    'RCE  - Roche Harbor Seaplane Base ',
    'RCH  - Almirante Padilla Airport ',
    'RCK  - H  H  Coffield Regional Airport ',
    'RCL  - Redcliffe Airport ',
    'RCM  - Richmond Airport ',
    'RCN  - American River Airport ',
    'RCO  - Rochefort – Saint Agnant Airport ',
    'RCQ  - Reconquista Airport ',
    'RCR  - Fulton County Airport ',
    'RCS  - Rochester Airport ',
    'RCT  - Nartron Field ',
    'RCU  - Las Higueras Airport ',
    'RCY  - Port Nelson Airport ',
    'RDA  - Rockhampton Downs Airport ',
    'RDB  - Red Dog Airport ',
    'RDC  - Redenção Airport ',
    'RDD  - Redding Municipal Airport ',
    'RDE  - Merdey Airport  1      Jahabra Airport   ',
    'RDG  - Reading Regional Airport    Carl A  Spaatz Field   ',
    'RDM  - Roberts Field ',
    'RDN  - Redang Airport ',
    'RDO  - Radom Airport ',
    'RDP  - Kazi Nazrul Islam Airport ',
    'RDR  - Grand Forks Air Force Base ',
    'RDS  - Rincón de los Sauces Airport ',
    'RDT  - Richard Toll Airport ',
    'RDU  - Raleigh–Durham International Airport ',
    'RDV  - Red Devil Airport ',
    'RDZ  - Rodez–Marcillac Airport ',
    'REA  - Reao Airport ',
    'REB  - Rechlin–Lärz Airfield ',
    'REC  - Recife  Guararapes–Gilberto Freyre International Airport ',
    'RED  - Mifflin County Airport    FAA  RVL   ',
    'REE  - Reese Airpark  1      FAA  8XS8   ',
    'REG  - Reggio di Calabria Airport ',
    'REI  - Régina Airport ',
    'REK  - metropolitan area1 ',
    'REL  - Almirante Marcos A  Zar Airport ',
    'REN  - Orenburg Tsentralny Airport ',
    'REO  - Rome State Airport ',
    'REP  - Siem Reap International Airport    Angkor Int l   ',
    'REQ  - Reko Diq Airport ',
    'RER  - Retalhuleu Airport ',
    'RES  - Resistencia International Airport ',
    'RET  - Røst Airport ',
    'REU  - Reus Airport ',
    'REW  - Churhata Airport ',
    'REX  - General Lucio Blanco International Airport ',
    'REY  - Reyes Airport ',
    'REZ  - Resende Airport ',
    'RFA  - Rafaï Airport ',
    'RFD  - Chicago Rockford International Airport    Greater Rockford Airport   ',
    'RFG  - Rooke Field ',
    'RFK  - Rollang Field  1      FAA  5MS1   ',
    'RFN  - Raufarhöfn Airport ',
    'RFP  - Raiatea Airport  1      Uturoa Airport   ',
    'RFR  - Río Frío Airport ',
    'RFS  - Rosita Airport ',
    'RGA  - Hermes Quijada International Airport ',
    'RGE  - Porgera Airport ',
    'RGH  - Balurghat Airport ',
    'RGI  - Rangiroa Airport ',
    'RGK  - Gorno Altaysk Airport ',
    'RGL  - Piloto Civil Norberto Fernández International Airport ',
    'RGN  - Yangon International Airport ',
    'RGO  - Chongjin Airport ',
    'RGR  - Ranger Municipal Airport  1      FAA  F23   ',
    'RGS  - Burgos Airport ',
    'RGT  - Japura Airport ',
    'RHA  - Reykhólar Airport ',
    'RHD  - Termas de Río Hondo Airport ',
    'RHE  - Reims – Champagne Air Base ',
    'RHG  - Ruhengeri Airport ',
    'RHI  - Rhinelander–Oneida County Airport ',
    'RHL  - Roy Hill Station Airport ',
    'RHN  - Skorpion Mine Airport ',
    'RHO  - Rhodes International Airport ',
    'RHP  - Ramechhap Airport ',
    'RHR  - Al Hamra Seaplane Base ',
    'RHT  - Alxa Right Banner Badanjilin Airport ',
    'RHV  - Reid–Hillview Airport of Santa Clara County ',
    'RIA  - Santa Maria Airport ',
    'RIB  - Riberalta Airport ',
    'RIC  - Richmond International Airport ',
    'RID  - Richmond Municipal Airport ',
    'RIE  - Rice Lake Regional Airport    Carl s Field     FAA  RPD   ',
    'RIF  - Richfield Municipal Airport ',
    'RIG  - Rio Grande Regional Airport ',
    'RIH  - Scarlett Martinez International Airport ',
    'RIJ  - Juan Simons Vela Airport ',
    'RIL  - Garfield County Regional Airport ',
    'RIM  - San Nicolas Airport ',
    'RIN  - Ringgi Cove Airport ',
    'RIO  - metropolitan area2 ',
    'RIR  - Flabob Airport ',
    'RIS  - Rishiri Airport ',
    'RIV  - March Air Reserve Base ',
    'RIW  - Central Wyoming Regional Airport ',
    'RIX  - Riga International Airport ',
    'RIY  - Riyan Airport ',
    'RIZ  - Rizhao Shanzihe Airport ',
    'RJA  - Rajahmundry Airport ',
    'RJB  - Rajbiraj Airport ',
    'RJH  - Shah Makhdum Airport ',
    'RJI  - Rajouri Airport ',
    'RJK  - Rijeka Airport ',
    'RJL  - Logroño–Agoncillo Airport ',
    'RJM  - Marinda Airport ',
    'RJN  - Rafsanjan Airport ',
    'RKA  - Aratika Nord Airport ',
    'RKD  - Knox County Regional Airport ',
    'RKE  - Roskilde Airport ',
    'RKH  - Rock Hill  York County Airport  1      FAA  UZA   ',
    'RKI  - Rokot Airport ',
    'RKP  - Aransas County Airport ',
    'RKR  - Robert S  Kerr Airport ',
    'RKS  - Rock Springs–Sweetwater County Airport ',
    'RKT  - Ras Al Khaimah International Airport ',
    'RKU  - Kairuku Airport ',
    'RKV  - Reykjavík Airport ',
    'RKW  - Rockwood Municipal Airport ',
    'RKY  - Rokeby Airport ',
    'RKZ  - Shigatse Peace Airport ',
    'RLD  - Richland Airport ',
    'RLG  - Rostock–Laage Airport ',
    'RLK  - Bayannur Tianjitai Airport ',
    'RLO  - Valle del Conlara Airport ',
    'RLP  - Rosella Plains Airport ',
    'RLR  - Relais de la Reine Airport ',
    'RLT  - Arlit Airport ',
    'RMA  - Roma Airport ',
    'RMB  - Buraimi Airport ',
    'RMD  - Ramagundam Airport    Basanth Nagar Airport   ',
    'RME  - Griffiss International Airport ',
    'RMF  - Marsa Alam International Airport ',
    'RMG  - Richard B  Russell Airport ',
    'RMI  - Federico Fellini International Airport ',
    'RMK  - Renmark Airport ',
    'RML  - Colombo International Airport, Ratmalana ',
    'RMN  - Rumginae Airport ',
    'RMP  - Rampart Airport ',
    'RMQ  - Taichung Airport    Taichung Ching Chuan Kang Airport   ',
    'RMS  - Ramstein Air Base ',
    'RMT  - Rimatara Airport ',
    'RMU  - Región de Murcia International Airport ',
    'RMY  - Mariposa Yosemite Airport  1      FAA  MPI   ',
    'RNA  - Ulawa Airport ',
    'RNB  - Ronneby Airport ',
    'RNC  - Warren County Memorial Airport ',
    'RND  - Randolph Air Force Base ',
    'RNE  - Roanne Renaison Airport ',
    'RNG  - Rangely Airport  1      FAA  4V0   ',
    'RNH  - New Richmond Regional Airport ',
    'RNI  - Corn Island Airport ',
    'RNJ  - Yoron Airport ',
    'RNL  - Rennell  Tingoa Airport ',
    'RNM  - Qarn Alam Airport ',
    'RNN  - Bornholm Airport ',
    'RNO  - Reno–Tahoe International Airport ',
    'RNP  - Rongelap Airport ',
    'RNR  - Robinson River Airport ',
    'RNS  - Rennes–Saint Jacques Airport ',
    'RNT  - Renton Municipal Airport ',
    'RNU  - Ranau Airport ',
    'RNZ  - Jasper County Airport    FAA  RZL   ',
    'ROA  - Roanoke–Blacksburg Regional Airport    Woodrum Field   ',
    'ROB  - Roberts International Airport ',
    'ROC  - Greater Rochester International Airport ',
    'ROD  - Robertson Airfield ',
    'ROF  - Montague Airport  1      Yreka Rohrer Field     FAA  1O5   ',
    'ROG  - Rogers Municipal Airport    Carter Field   ',
    'ROH  - Robinhood Airport ',
    'ROI  - Roi Et Airport ',
    'ROK  - Rockhampton Airport ',
    'ROL  - Roosevelt Municipal Airport ',
    'ROM  - metropolitan area3 ',
    'RON  - Juan José Rondón Airport ',
    'ROO  - Maestro Marinho Franco Airport ',
    'ROP  - Rota International Airport    FAA  GRO   ',
    'ROR  - Roman Tmetuchl International Airport ',
    'ROS  - Rosario – Islas Malvinas International Airport ',
    'ROT  - Rotorua Regional Airport ',
    'ROU  - Ruse Airport ',
    'ROV  - Platov International Airport ',
    'ROW  - Roswell International Air Center ',
    'ROX  - Roseau Municipal Airport    Rudy Billberg Field   ',
    'ROY  - Río Mayo Airport ',
    'ROZ  - US Naval Station Rota ',
    'RPA  - Rolpa Airport ',
    'RPB  - Roper Bar Airport ',
    'RPI  - Rampi Airport ',
    'RPM  - Ngukurr Airport ',
    'RPN  - Rosh Pina Airport ',
    'RPR  - Swami Vivekananda Airport ',
    'RPV  - Roper Valley Airport ',
    'RPX  - Roundup Airport ',
    'RQA  - Ruoqiang Loulan Airport ',
    'RQW  - Qayyarah Airfield West ',
    'RRE  - Marree Airport ',
    'RRG  - Sir Gaëtan Duval Airport ',
    'RRI  - Barora Airport ',
    'RRJ  - Jacarepaguá Airport ',
    'RRK  - Rourkela Airport ',
    'RRL  - Merrill Municipal Airport ',
    'RRM  - Marromeu Airport ',
    'RRR  - Raroia Airport ',
    'RRS  - Røros Airport ',
    'RRT  - Warroad International Memorial Airport    Swede Carlson Field   ',
    'RRV  - Robinson River Airport ',
    'RSA  - Santa Rosa Airport ',
    'RSB  - Roseberth Airport ',
    'RSD  - Rock Sound International Airport ',
    'RSE  - Rose Bay Water Airport ',
    'RSH  - Russian Mission Airport ',
    'RSI  - Red Sea International Airport ',
    'RSJ  - Rosario Seaplane Base    FAA  W49   ',
    'RSK  - Abresso Airport ',
    'RSL  - Russell Municipal Airport ',
    'RSN  - Ruston Regional Airport ',
    'RSS  - Damazin Airport ',
    'RST  - Rochester International Airport ',
    'RSU  - Yeosu  Suncheon Airport ',
    'RSW  - Southwest Florida International Airport ',
    'RSX  - Rouses Point Seaplane Base  1      FAA  K21   ',
    'RTA  - Rotuma Airport ',
    'RTB  - Juan Manuel Gálvez International Airport ',
    'RTC  - Ratnagiri Airport ',
    'RTG  - Frans Sales Lega Airport ',
    'RTI  - David Constantijn Saudale Airport ',
    'RTL  - Spirit Lake Municipal Airport  1      FAA  0F3   ',
    'RTM  - Rotterdam The Hague Airport ',
    'RTN  - Raton Municipal Airport    Crews Field   ',
    'RTP  - Rutland Plains Airport ',
    'RTS  - Rottnest Island Airport ',
    'RTW  - Saratov Tsentralny Airport ',
    'RTY  - Merty Merty Airport ',
    'RUA  - Arua Airport ',
    'RUD  - Shahroud Airport ',
    'RUE  - Butembo Airport ',
    'RUF  - Yuruf Airport ',
    'RUG  - Rugao Air Base ',
    'RUH  - King Khalid International Airport ',
    'RUI  - Sierra Blanca Regional Airport  1      FAA  SRR   ',
    'RUK  - Chaurjahari Airport ',
    'RUL  - Maavarulu Airport ',
    'RUM  - Rumjatar Airport ',
    'RUN  - Roland Garros Airport ',
    'RUP  - Rupsi Airport ',
    'RUR  - Rurutu Airport ',
    'RUS  - Marau Airport ',
    'RUT  - Rutland – Southern Vermont Regional Airport ',
    'RUU  - Ruti Airport ',
    'RUV  - Rubelsanto Airport ',
    'RUY  - Copán Ruinas Airport ',
    'RVA  - Farafangana Airport ',
    'RVC  - River Cess Airport ',
    'RVD  - General Leite de Castro Airport ',
    'RVE  - Los Colonizadores Airport ',
    'RVH  - Rzhevka Airport ',
    'RVI  - Rostov on Don Airport ',
    'RVK  - Rørvik Airport, Ryum ',
    'RVN  - Rovaniemi Airport ',
    'RVO  - Reivilo Airport ',
    'RVR  - Green River Municipal Airport  1      FAA  U34   ',
    'RVS  - Richard Lloyd Jones Jr  Airport ',
    'RVT  - Ravensthorpe Airport ',
    'RVV  - Raivavae Airport ',
    'RVY  - Pres  Gral  Óscar D  Gestido International Airport ',
    'RWF  - Redwood Falls Municipal Airport ',
    'RWI  - Rocky Mount–Wilson Regional Airport ',
    'RWL  - Rawlins Municipal Airport    Harvey Field   ',
    'RWN  - Rivne International Airport ',
    'RXA  - Ar Rawdah Airport ',
    'RXE  - Rexburg–Madison County Airport ',
    'RXS  - Roxas Airport ',
    'RYB  - Staroselye Airport ',
    'RYG  - Moss Airport, Rygge      Rygge Air Station ',
    'RYK  - Shaikh Zayed International Airport ',
    'RYL  - Royal Airstrip ',
    'RYN  - Royan – Médis Aerodrome ',
    'RYO  - Rio Turbio Airport ',
    'RZA  - Santa Cruz Airport ',
    'RZE  - Rzeszów–Jasionka Airport ',
    'RZN  - Turlatovo Airport ',
    'RZP  - Cesar Lim Rodriguez Airport ',
    'RZR  - Ramsar International Airport ',
    'RZS  - Sawan Airport ',
    'RZV  - Rize–Artvin Airport ',
    'RZZ  - Halifax County Airport ',
    'SAA  - Shively Field ',
    'SAB  - Juancho E  Yrausquin Airport ',
    'SAC  - Sacramento Executive Airport ',
    'SAD  - Safford Regional Airport ',
    'SAF  - Santa Fe Municipal Airport ',
    'SAG  - Shirdi Airport ',
    'SAH  - Sana a International Airport    El Rahaba Airport   ',
    'SAK  - Sauðárkrókur Airport ',
    'SAL  - Monseñor Óscar Arnulfo Romero International Airport ',
    'SAM  - Salamo Airport ',
    'SAN  - San Diego International Airport ',
    'SAO  - metropolitan area1 ',
    'SAP  - Ramón Villeda Morales International Airport ',
    'SAQ  - San Andros Airport ',
    'SAR  - Sparta Community Airport    Hunter Field   ',
    'SAS  - Salton Sea Airport ',
    'SAT  - San Antonio International Airport ',
    'SAU  - Tardamu Airport ',
    'SAV  - Savannah  Hilton Head International Airport ',
    'SAW  - Sabiha Gökçen International Airport ',
    'SAX  - Sambú Airport ',
    'SAY  - Siena–Ampugnano Airport ',
    'SAZ  - Sasstown Airport ',
    'SBA  - Santa Barbara Municipal Airport ',
    'SBB  - Santa Bárbara de Barinas Airport ',
    'SBC  - Selbang Airport ',
    'SBD  - San Bernardino International Airport ',
    'SBE  - Suabi Airport ',
    'SBF  - Sardeh Band Airport ',
    'SBG  - Maimun Saleh Airport ',
    'SBH  - Gustaf III Airport ',
    'SBI  - Sambailo Airport ',
    'SBJ  - São Mateus Airport ',
    'SBK  - Saint Brieuc – Armor Airport ',
    'SBL  - Santa Ana del Yacuma Airport ',
    'SBM  - Sheboygan County Memorial Airport ',
    'SBN  - South Bend International Airport ',
    'SBO  - Salina Gunnison Airport  1      FAA  44U   ',
    'SBP  - San Luis Obispo County Regional Airport    McChesney Field   ',
    'SBQ  - Sibi Airport ',
    'SBR  - Saibai Island Airport ',
    'SBS  - Steamboat Springs Airport    Bob Adams Field   ',
    'SBT  - Sabetta International Airport ',
    'SBU  - Springbok Airport ',
    'SBV  - Sabah Airport ',
    'SBW  - Sibu Airport ',
    'SBX  - Shelby Airport ',
    'SBY  - Salisbury–Ocean City–Wicomico Regional Airport ',
    'SBZ  - Sibiu International Airport ',
    'SCA  - Santa Catalina Airport ',
    'SCB  - Scribner State Airport ',
    'SCC  - Deadhorse Airport ',
    'SCD  - Sulaco Airport ',
    'SCE  - University Park Airport    FAA  UNV   ',
    'SCF  - Scottsdale Airport    FAA  SDL   ',
    'SCG  - Spring Creek Airport ',
    'SCH  - Schenectady County Airport ',
    'SCI  - Paramillo Airport ',
    'SCK  - Stockton Metropolitan Airport ',
    'SCL  - Comodoro Arturo Merino Benítez International Airport ',
    'SCM  - Scammon Bay Airport ',
    'SCN  - Saarbrücken Airport ',
    'SCO  - Aktau Airport ',
    'SCP  - Mont Dauphin   Saint Crépin Airport ',
    'SCQ  - Santiago de Compostela Airport ',
    'SCR  - Scandinavian Mountains Airport ',
    'SCS  - Scatsta Airport ',
    'SCT  - Socotra Airport ',
    'SCU  - Antonio Maceo International Airport ',
    'SCV  - Suceava International Airport    Ștefan cel Mare Int l   ',
    'SCW  - Syktyvkar Airport ',
    'SCX  - Salina Cruz Airport ',
    'SCY  - San Cristóbal Airport ',
    'SCZ  - Santa Cruz  Graciosa Bay  Luova Airport ',
    'SDB  - Air Force Base Langebaanweg ',
    'SDC  - Sand Creek Airport ',
    'SDD  - Lubango Mukanka Airport ',
    'SDE  - Vicecomodoro Ángel de la Paz Aragonés Airport ',
    'SDF  - Louisville International Airport    Standiford Field   ',
    'SDG  - Sanandaj Airport ',
    'SDH  - Santa Rosa de Copán Airport ',
    'SDI  - Saidor Airport ',
    'SDJ  - Sendai Airport ',
    'SDK  - Sandakan Airport ',
    'SDL  - Sundsvall Timrå Airport    Midlanda Airport   ',
    'SDM  - Brown Field Municipal Airport ',
    'SDN  - Sandane Airport, Anda ',
    'SDP  - Sand Point Airport ',
    'SDQ  - Las Américas International Airport ',
    'SDR  - Santander Airport ',
    'SDS  - Sado Airport ',
    'SDT  - Saidu Sharif Airport ',
    'SDU  - Santos Dumont Airport ',
    'SDV  - Sde Dov Airport ',
    'SDX  - Sedona Airport    FAA  SEZ   ',
    'SDY  - Sidney–Richland Municipal Airport ',
    'SDZ  - metropolitan area2 ',
    'SEA  - Seattle–Tacoma International Airport ',
    'SEB  - Sabha Airport ',
    'SED  - Sdom Airfield  1      Hashnayim Airfield   ',
    'SEE  - Gillespie Field ',
    'SEF  - Sebring Regional Airport ',
    'SEG  - Penn Valley Airport ',
    'SEH  - Senggeh Airport ',
    'SEK  - Srednekolymsk Airport ',
    'SEL  - metropolitan area3 ',
    'SEM  - Craig Field ',
    'SEN  - London Southend Airport ',
    'SEO  - Séguéla Airport ',
    'SEP  - Stephenville Clark Regional Airport ',
    'SEQ  - Sei Pakning Airport ',
    'SER  - Freeman Municipal Airport ',
    'SEU  - Seronera Airstrip ',
    'SEV  - Severodonetsk Airport ',
    'SEW  - Siwa Oasis North Airport ',
    'SEY  - Sélibaby Airport ',
    'SEZ  - Seychelles International Airport ',
    'SFA  - Sfax–Thyna International Airport ',
    'SFB  - Orlando Sanford International Airport ',
    'SFC  - Saint François Airport ',
    'SFD  - Las Flecheras Airport ',
    'SFE  - San Fernando Airport ',
    'SFF  - Felts Field ',
    'SFG  - L Espérance Airport  1      Grand Case Airport   ',
    'SFH  - San Felipe International Airport ',
    'SFI  - Safi Airport ',
    'SFJ  - Kangerlussuaq Airport ',
    'SFK  - Soure Airport ',
    'SFL  - São Filipe Airport ',
    'SFM  - Sanford Seacoast Regional Airport ',
    'SFN  - Sauce Viejo Airport ',
    'SFO  - San Francisco International Airport ',
    'SFQ  - Şanlıurfa Airport ',
    'SFS  - Subic Bay International Airport ',
    'SFT  - Skellefteå Airport ',
    'SFU  - Safia Airport ',
    'SFV  - Santa Fé do Sul Airport ',
    'SFX  - Macagua Airport ',
    'SFY  - metropolitan area4 ',
    'SFZ  - North Central State Airport ',
    'SGA  - Sheghnan Airport ',
    'SGB  - Singaua Airport ',
    'SGC  - Surgut International Airport ',
    'SGD  - Sønderborg Airport ',
    'SGE  - Siegerland Airport ',
    'SGF  - Springfield–Branson National Airport ',
    'SGG  - Simanggang Airport ',
    'SGH  - Springfield–Beckley Municipal Airport ',
    'SGI  - PAF Base Mushaf ',
    'SGJ  - Sagarai Airport ',
    'SGK  - Sangapi Airport ',
    'SGL  - Sangley Point Airport ',
    'SGM  - San Ignacio Airfield ',
    'SGN  - Tan Son Nhat International Airport ',
    'SGO  - St George Airport ',
    'SGP  - Shay Gap Airport ',
    'SGQ  - Sangkimah Airport ',
    'SGR  - Sugar Land Regional Airport ',
    'SGT  - Stuttgart Municipal Airport ',
    'SGU  - St  George Regional Airport ',
    'SGV  - Sierra Grande Airport ',
    'SGW  - Saginaw Seaplane Base  1      FAA  A23   ',
    'SGX  - Songea Airport ',
    'SGY  - Skagway Airport ',
    'SGZ  - Songkhla Airport ',
    'SHA  - Shanghai Hongqiao International Airport ',
    'SHB  - Nakashibetsu Airport ',
    'SHC  - Shire Airport ',
    'SHD  - Shenandoah Valley Regional Airport ',
    'SHE  - Shenyang Taoxian International Airport ',
    'SHF  - Shihezi Huayuan Airport ',
    'SHG  - Shungnak Airport ',
    'SHH  - Shishmaref Airport ',
    'SHI  - Shimojishima Airport ',
    'SHJ  - Sharjah International Airport ',
    'SHK  - Sehonghong Airport ',
    'SHL  - Shillong Airport    Barapani Airport   ',
    'SHM  - Nanki–Shirahama Airport ',
    'SHN  - Sanderson Field ',
    'SHO  - King Mswati III International Airport  1      Sikhuphe Int l   ',
    'SHQ  - Southport Airport ',
    'SHR  - Sheridan County Airport ',
    'SHS  - Shashi Airport ',
    'SHT  - Shepparton Airport ',
    'SHU  - Smith Point Airport ',
    'SHV  - Shreveport Regional Airport ',
    'SHW  - Sharurah Domestic Airport ',
    'SHX  - Shageluk Airport ',
    'SHY  - Shinyanga Airport ',
    'SHZ  - Seshutes Airport ',
    'SIA  - Xi an Xiguan Airport ',
    'SIB  - Sibiti Airport ',
    'SIC  - San José Airport ',
    'SID  - Amílcar Cabral International Airport ',
    'SIE  - Sines Airport ',
    'SIF  - Simara Airport ',
    'SIG  - Fernando Luis Ribas Dominicci Airport ',
    'SIH  - Silgadhi Airport ',
    'SII  - Sidi Ifni Airport    Sania Ramel Airport   ',
    'SIJ  - Siglufjörður Airport ',
    'SIK  - Sikeston Memorial Municipal Airport ',
    'SIL  - Sila Airport ',
    'SIM  - Simbai Airport ',
    'SIN  - Singapore Changi Airport ',
    'SIO  - Smithton Airport ',
    'SIP  - Simferopol International Airport ',
    'SIQ  - Dabo Singkep Airport ',
    'SIR  - Sion Airport ',
    'SIS  - Sishen Airport ',
    'SIT  - Sitka Rocky Gutierrez Airport ',
    'SIU  - Siuna Airport ',
    'SIV  - Sullivan County Airport ',
    'SIW  - Sibisa Airport ',
    'SIX  - Singleton Airport ',
    'SIY  - Siskiyou County Airport ',
    'SIZ  - Sissano Airport ',
    'SJA  - San Juan de Marcona Airport ',
    'SJB  - San Joaquín Airport ',
    'SJC  - San Jose International Airport ',
    'SJD  - Los Cabos International Airport ',
    'SJE  - Jorge Enrique González Torres Airport ',
    'SJF  - Cruz Bay Seaplane Base ',
    'SJG  - San Pedro de Jagua Airport ',
    'SJH  - San Juan del Cesar Airport ',
    'SJI  - San Jose Airport ',
    'SJJ  - Sarajevo International Airport ',
    'SJK  - Professor Urbano Ernesto Stumpf Airport ',
    'SJL  - São Gabriel da Cachoeira Airport ',
    'SJM  - San Juan de la Maguana Airport ',
    'SJN  - St  Johns Industrial Air Park ',
    'SJO  - Juan Santamaría International Airport ',
    'SJP  - Prof  Eribelto Manoel Reino State Airport ',
    'SJQ  - Sesheke Airport ',
    'SJR  - San Juan de Urabá Airport ',
    'SJS  - San José de Chiquitos Airport ',
    'SJT  - San Angelo Regional Airport    Mathis Field   ',
    'SJU  - Luis Muñoz Marín International Airport ',
    'SJV  - San Javier Airport ',
    'SJW  - Shijiazhuang Zhengding International Airport ',
    'SJX  - Sarteneja Airport ',
    'SJY  - Seinäjoki Airport ',
    'SJZ  - São Jorge Airport ',
    'SKA  - Fairchild Air Force Base ',
    'SKB  - Robert L  Bradshaw International Airport ',
    'SKC  - Suki Airport ',
    'SKD  - Samarkand International Airport ',
    'SKE  - Skien Airport, Geiteryggen ',
    'SKF  - Kelly Field Annex      Lackland Air Force Base ',
    'SKG  - Thessaloniki Airport Macedonia ',
    'SKH  - Surkhet Airport ',
    'SKI  - Skikda Airport ',
    'SKK  - Shaktoolik Airport    FAA  2C7   ',
    'SKL  - Broadford Airfield ',
    'SKM  - Skeldon Airport ',
    'SKN  - Stokmarknes Airport, Skagen ',
    'SKO  - Sadiq Abubakar III International Airport ',
    'SKP  - Skopje Alexander the Great Airport ',
    'SKQ  - Sekake Airport ',
    'SKR  - Shakiso Airport ',
    'SKS  - Vojens Airport    Skrydstrup Airport   ',
    'SKT  - Sialkot International Airport ',
    'SKU  - Skyros Island National Airport ',
    'SKV  - St  Catherine International Airport ',
    'SKW  - Skwentna Airport ',
    'SKX  - Saransk Airport ',
    'SKY  - Griffing Sandusky Airport ',
    'SKZ  - Sukkur Airport ',
    'SLA  - Martín Miguel de Güemes International Airport ',
    'SLB  - Storm Lake Municipal Airport ',
    'SLC  - Salt Lake City International Airport ',
    'SLD  - Sliač Airport ',
    'SLE  - McNary Field ',
    'SLF  - Sulayel Airport ',
    'SLG  - Smith Field ',
    'SLH  - Vanua Lava Airport ',
    'SLI  - Solwezi Airport ',
    'SLJ  - Solomon Airport ',
    'SLK  - Adirondack Regional Airport ',
    'SLL  - Salalah International Airport ',
    'SLM  - Salamanca Airport ',
    'SLN  - Salina Regional Airport ',
    'SLO  - Salem–Leckrone Airport ',
    'SLP  - Ponciano Arriaga International Airport ',
    'SLQ  - Sleetmute Airport ',
    'SLR  - Sulphur Springs Municipal Airport ',
    'SLS  - Silistra Airfield ',
    'SLT  - Harriet Alexander Field    FAA  ANK   ',
    'SLU  - George F  L  Charles Airport ',
    'SLV  - Shimla Airport ',
    'SLW  - Plan de Guadalupe International Airport ',
    'SLX  - Salt Cay Airport ',
    'SLY  - Salekhard Airport ',
    'SLZ  - Marechal Cunha Machado International Airport ',
    'SMA  - Santa Maria Airport ',
    'SMB  - Franco Bianco Airport ',
    'SMC  - Santa María Airport ',
    'SMD  - Smith Field ',
    'SME  - Lake Cumberland Regional Airport ',
    'SMF  - Sacramento International Airport ',
    'SMG  - Santa María Airport ',
    'SMH  - Sapmanga Airport ',
    'SMI  - Samos International Airport  Aristarchos of Samos ',
    'SMJ  - Sim Airport ',
    'SMK  - St  Michael Airport ',
    'SML  - Stella Maris Airport ',
    'SMM  - Semporna Airport ',
    'SMN  - Lemhi County Airport ',
    'SMO  - Santa Monica Municipal Airport ',
    'SMP  - Stockholm Airport ',
    'SMQ  - H  Asan Airport ',
    'SMR  - Simón Bolívar International Airport ',
    'SMS  - Sainte Marie Airport ',
    'SMU  - Sheep Mountain Airport ',
    'SMV  - Samedan Airport    Engadin Airport   ',
    'SMW  - Smara Airport ',
    'SMX  - Santa Maria Public Airport    Capt  G  Allan Hancock Field   ',
    'SMY  - Simenti Airport ',
    'SMZ  - Stoelmans Eiland Airstrip ',
    'SNA  - John Wayne Airport    Orange County Airport   ',
    'SNB  - Snake Bay Airport ',
    'SNC  - General Ulpiano Paez Airport ',
    'SNE  - Preguiça Airport ',
    'SNF  - Sub Teniente Nestor Arias Airport ',
    'SNG  - Capitán Av  Juan Cochamanidis Airport ',
    'SNH  - Stanthorpe Airport ',
    'SNI  - Greenville  Sinoe Airport    R E  Murray Airport   ',
    'SNJ  - San Julián Air Base ',
    'SNK  - Winston Field Airport ',
    'SNL  - Shawnee Regional Airport ',
    'SNM  - San Ignacio de Moxos Airport ',
    'SNN  - Shannon Airport ',
    'SNO  - Sakon Nakhon Airport ',
    'SNP  - St  Paul Island Airport ',
    'SNQ  - San Quintín Military Airstrip ',
    'SNR  - Saint Nazaire Montoir Airport ',
    'SNS  - Salinas Municipal Airport ',
    'SNT  - Las Cruces Airport ',
    'SNU  - Abel Santamaría Airport ',
    'SNV  - Santa Elena de Uairén Airport ',
    'SNW  - Thandwe Airport ',
    'SNX  - Semnan Municipal Airport ',
    'SNY  - Sidney Municipal Airport    Lloyd W  Carr Field   ',
    'SNZ  - Santa Cruz Air Force Base ',
    'SOA  - Sóc Trăng Airfield ',
    'SOB  - Hévíz–Balaton Airport ',
    'SOC  - Adisumarmo International Airport ',
    'SOD  - Bertram Luiz Leupolz–Sorocaba Airport ',
    'SOE  - Souanké Airport ',
    'SOF  - Sofia Airport ',
    'SOG  - Sogndal Airport, Haukåsen ',
    'SOJ  - Sørkjosen Airport ',
    'SOK  - Semonkong Airport ',
    'SOL  - Solomon State Field Airport  1      FAA  AK26   ',
    'SOM  - San Tomé Airport ',
    'SON  - Santo Pekoa International Airport ',
    'SOO  - Söderhamn Airport  1      Helsinge Airport   ',
    'SOP  - Moore County Airport ',
    'SOQ  - Dominique Edward Osok Airport ',
    'SOT  - Sodankylä Airfield ',
    'SOU  - Southampton Airport ',
    'SOV  - Seldovia Airport ',
    'SOW  - Show Low Regional Airport ',
    'SOX  - Alberto Lleras Camargo Airport ',
    'SOY  - Stronsay Airport ',
    'SOZ  - Solenzara Air Base ',
    'SPA  - Spartanburg Downtown Memorial Airport ',
    'SPB  - Charlotte Amalie Harbor Seaplane Base    St  Thomas Seaplane Base     FAA  VI22   ',
    'SPC  - La Palma Airport ',
    'SPD  - Saidpur Airport ',
    'SPE  - Sepulot Airport ',
    'SPF  - Black Hills Airport    Clyde Ice Field   ',
    'SPG  - Albert Whitted Airport ',
    'SPH  - Sopu Airport ',
    'SPI  - Abraham Lincoln Capital Airport ',
    'SPJ  - Sparti Airport ',
    'SPK  - metropolitan area5 ',
    'SPM  - Spangdahlem Air Base ',
    'SPN  - Saipan International Airport    Francisco C  Ada Int l     FAA  GSN   ',
    'SPP  - Menongue Airport ',
    'SPR  - John Greif II Airport ',
    'SPS  - Wichita Falls Municipal Airport ',
    'SPT  - Sipitang Airstrip ',
    'SPU  - Split Airport ',
    'SPV  - Sepik Plains Airport ',
    'SPW  - Spencer Municipal Airport ',
    'SPY  - San Pédro Airport ',
    'SPX  - Sphinx International Airport ',
    'SPZ  - Springdale Municipal Airport    FAA  ASG   ',
    'SQA  - Santa Ynez Airport    FAA  IZA   ',
    'SQB  - Santa Ana Airport ',
    'SQC  - Southern Cross Airport ',
    'SQD  - Shangrao Sanqingshan Airport ',
    'SQE  - San Luis de Palenque Airport ',
    'SQG  - Tebelian Airport  1      Susilo Airport   ',
    'SQH  - Nà Sản Airport ',
    'SQI  - Whiteside County Airport    Jos  H  Bittorf Field   ',
    'SQJ  - Sanming Shaxian Airport ',
    'SQK  - Sidi Barrani Airport ',
    'SQL  - San Carlos Airport ',
    'SQM  - São Miguel do Araguaia Airport ',
    'SQN  - Sanana Airport ',
    'SQO  - Storuman Airport ',
    'SQQ  - Šiauliai International Airport ',
    'SQR  - Soroako Airport ',
    'SQS  - Matthew Spain Airport ',
    'SQT  - China Strait Airstrip ',
    'SQU  - Saposoa Airport ',
    'SQV  - Sequim Valley Airport    FAA  W28   ',
    'SQW  - Skive Airport ',
    'SQX  - Hélio Wasum Airport ',
    'SQY  - São Lourenço do Sul Airport ',
    'SQZ  - RAF Scampton ',
    'SRA  - Santa Rosa Airport ',
    'SRB  - Santa Rosa Airport ',
    'SRC  - Searcy Municipal Airport ',
    'SRD  - San Ramón Airport ',
    'SRE  - Alcantarí Airport ',
    'SRF  - San Rafael Airport    Hamilton Field   ',
    'SRG  - Achmad Yani International Airport ',
    'SRH  - Sarh Airport ',
    'SRI  - Temindung Airport ',
    'SRJ  - Capitán Germán Quiroga Guardia Airport ',
    'SRL  - Palo Verde Airport ',
    'SRM  - Sandringham Station Airport ',
    'SRN  - Strahan Airport ',
    'SRO  - Santana Ramos Airport ',
    'SRP  - Stord Airport, Sørstokken ',
    'SRQ  - Sarasota–Bradenton International Airport ',
    'SRR  - Dunwich Airport ',
    'SRS  - San Marcos Airport ',
    'SRT  - Soroti Airport ',
    'SRV  - Stony River Airport ',
    'SRW  - Rowan County Airport    FAA  RUQ   ',
    'SRX  - Gardabya Airport ',
    'SRY  - Dasht e Naz Airport ',
    'SRZ  - El Trompillo Airport ',
    'SSA  - Deputado Luís Eduardo Magalhães International Airport ',
    'SSB  - Christiansted Harbor Seaplane Base    St  Croix Seaplane Base     FAA  VI32   ',
    'SSC  - Shaw Air Force Base ',
    'SSD  - San Felipe Airport ',
    'SSE  - Solapur Airport ',
    'SSF  - Stinson Municipal Airport ',
    'SSG  - Malabo International Airport    Saint Isabel Airport   ',
    'SSH  - Sharm el Sheikh International Airport ',
    'SSI  - Malcolm McKinnon Airport ',
    'SSJ  - Sandnessjøen Airport, Stokka ',
    'SSK  - Sturt Creek Airport ',
    'SSL  - Santa Rosalía Airport ',
    'SSN  - Seoul Air Base ',
    'SSO  - São Lourenço Airport ',
    'SSP  - Silver Plains Airport ',
    'SSQ  - La Sarre Airport    TC  CSR8   ',
    'SSR  - Sara Airport ',
    'SSS  - Siassi Airport ',
    'SST  - Santa Teresita Airport ',
    'SSV  - Siasi Airport ',
    'SSW  - Stuart Island Airpark  1      FAA  7WA5   ',
    'SSY  - Mbanza Congo Airport ',
    'SSZ  - Santos Air Force Base ',
    'STA  - Stauning Vestjylland Airport ',
    'STB  - Miguel Urdaneta Fernández Airport ',
    'STC  - St  Cloud Regional Airport ',
    'STD  - Mayor Buenaventura Vivas Airport ',
    'STE  - Stevens Point Municipal Airport ',
    'STF  - Stephens Island Airport ',
    'STG  - St  George Airport    FAA  PBV   ',
    'STH  - Strathmore Airport ',
    'STI  - Cibao International Airport ',
    'STJ  - Rosecrans Memorial Airport ',
    'STK  - Sterling Municipal Airport ',
    'STL  - Lambert–St  Louis International Airport ',
    'STM  - Santarém Maestro Wilson Fonseca Airport ',
    'STN  - London Stansted Airport ',
    'STO  - metropolitan area6 ',
    'STP  - St  Paul Downtown Airport    Holman Field   ',
    'STQ  - St  Marys Municipal Airport    FAA  OYM   ',
    'STR  - Stuttgart Airport ',
    'STS  - Charles M  Schulz–Sonoma County Airport ',
    'STT  - Cyril E  King Airport ',
    'STV  - Surat Airport ',
    'STW  - Stavropol Shpakovskoye Airport ',
    'STX  - Henry E  Rohlsen Airport ',
    'STY  - Nueva Hespérides International Airport ',
    'STZ  - Santa Terezinha Airport ',
    'SUA  - Witham Field ',
    'SUB  - Juanda International Airport ',
    'SUD  - Stroud Municipal Airport ',
    'SUE  - Door County Cherryland Airport ',
    'SUF  - Lamezia Terme International Airport ',
    'SUG  - Surigao Airport ',
    'SUH  - Sur Airport ',
    'SUI  - Sukhumi Babushara Airport    Dranda Airport   ',
    'SUJ  - Satu Mare International Airport ',
    'SUK  - Sakkyryr Airport ',
    'SUL  - Sui Airport ',
    'SUM  - Sumter Airport    FAA  SMS   ',
    'SUN  - Friedman Memorial Airport ',
    'SUO  - Sunriver Airport    FAA  S21   ',
    'SUP  - Trunojoyo Airport ',
    'SUQ  - Sucúa Airport ',
    'SUR  - Summer Beaver Airport  1      TC  CJV7   ',
    'SUS  - Spirit of St  Louis Airport ',
    'SUT  - Sumbawanga Airport ',
    'SUU  - Travis Air Force Base ',
    'SUV  - Nausori International Airport ',
    'SUW  - Richard I  Bong Airport ',
    'SUX  - Sioux Gateway Airport    Colonel Bud Day Field   ',
    'SUY  - Suntar Airport ',
    'SUZ  - Suria Airport ',
    'SVA  - Savoonga Airport ',
    'SVB  - Sambava Airport ',
    'SVC  - Grant County Airport ',
    'SVD  - Argyle International Airport ',
    'SVE  - Susanville Municipal Airport ',
    'SVF  - Savé Airport ',
    'SVG  - Stavanger Airport, Sola ',
    'SVH  - Statesville Regional Airport ',
    'SVI  - Eduardo Falla Solano Airport ',
    'SVJ  - Svolvær Airport, Helle ',
    'SVK  - Silver Creek Airport ',
    'SVL  - Savonlinna Airport ',
    'SVM  - St Pauls Airport ',
    'SVN  - Hunter Army Airfield ',
    'SVO  - Sheremetyevo International Airport ',
    'SVP  - Kuito Airport ',
    'SVQ  - Seville Airport ',
    'SVS  - Stevens Village Airport ',
    'SVT  - Savuti Airport ',
    'SVU  - Savusavu Airport ',
    'SVW  - Sparrevohn LRRS Airport ',
    'SVX  - Koltsovo International Airport ',
    'SVY  - Savo Airport ',
    'SVZ  - Juan Vicente Gómez International Airport ',
    'SWA  - Jieyang Chaoshan International Airport ',
    'SWB  - Shaw River Airport ',
    'SWC  - Stawell Airport ',
    'SWD  - Seward Airport ',
    'SWE  - Siwea Airport ',
    'SWF  - Stewart International Airport ',
    'SWG  - Satwag Airport ',
    'SWH  - Swan Hill Airport ',
    'SWJ  - South West Bay Airport ',
    'SWL  - San Vicente Airport ',
    'SWM  - Suia Missu Airport ',
    'SWN  - Sahiwal Airport ',
    'SWO  - Stillwater Regional Airport ',
    'SWP  - Swakopmund Airport ',
    'SWQ  - Sultan Muhammad Kaharuddin III Airport  1      Brangbiji Airport   ',
    'SWR  - Silur Airport ',
    'SWS  - Swansea Airport ',
    'SWT  - Strezhevoy Airport ',
    'SWU  - Suwon Air Base ',
    'SWV  - Severo Evensk Airport ',
    'SWW  - Avenger Field ',
    'SWX  - Shakawe Airport ',
    'SWY  - Sitiawan Airport ',
    'SXA  - Sialum Airport ',
    'SXB  - Strasbourg Airport ',
    'SXE  - West Sale Airport ',
    'SXG  - Senanga Airport ',
    'SXH  - Sehulea Airport ',
    'SXI  - Sirri Island Airport ',
    'SXJ  - Shanshan Airport ',
    'SXK  - Saumlaki Airport  1      Olilit Airport   ',
    'SXL  - Sligo Airport ',
    'SXM  - Princess Juliana International Airport ',
    'SXN  - Sua Pan Airport ',
    'SXO  - São Félix do Araguaia Airport ',
    'SXP  - Sheldon Point Airport ',
    'SXQ  - Soldotna Airport ',
    'SXR  - Sheikh ul Alam International Airport ',
    'SXS  - Sahabat Airport ',
    'SXT  - Sungai Tiang Airport ',
    'SXU  - Soddu Airport ',
    'SXV  - Salem Airport ',
    'SXW  - Sauren Airport ',
    'SXX  - São Félix do Xingu Airport ',
    'SXY  - Sidney Municipal Airport    FAA  N23   ',
    'SXZ  - Siirt Airport ',
    'SYA  - Eareckson Air Station ',
    'SYB  - Seal Bay Seaplane Base ',
    'SYC  - Shiringayoc Airport ',
    'SYD  - Sydney Airport    Kingsford Smith Airport   ',
    'SYE  - Saadah Airport ',
    'SYF  - Silva Bay Seaplane Base ',
    'SYI  - Shelbyville Municipal Airport    Bomar Field   ',
    'SYJ  - Sirjan Airport ',
    'SYK  - Stykkishólmur Airport ',
    'SYM  - Pu er Simao Airport ',
    'SYN  - Stanton Airfield ',
    'SYO  - Shonai Airport ',
    'SYP  - Ruben Cantu Airport ',
    'SYQ  - Tobías Bolaños International Airport ',
    'SYR  - Syracuse Hancock International Airport ',
    'SYS  - Saskylakh Airport ',
    'SYT  - Saint Yan Airport  1      Charolais Bourgogne Sud Airport   ',
    'SYU  - Warraber Island Airport ',
    'SYV  - Sylvester Airport ',
    'SYW  - Sehwan Sharif Airport ',
    'SYX  - Sanya Phoenix International Airport ',
    'SYY  - Stornoway Airport ',
    'SYZ  - Shiraz International Airport    Shahid Dastghaib Int l   ',
    'SZA  - Soyo Airport ',
    'SZB  - Sultan Abdul Aziz Shah Airport ',
    'SZE  - Semera Airport ',
    'SZF  - Samsun Çarşamba Airport ',
    'SZG  - Salzburg Airport ',
    'SZI  - Zaysan Airport ',
    'SZJ  - Siguanea Airport ',
    'SZK  - Skukuza Airport ',
    'SZL  - Whiteman Air Force Base ',
    'SZM  - Sesriem Airport ',
    'SZN  - Santa Cruz Island Airport ',
    'SZP  - Santa Paula Airport ',
    'SZR  - Stara Zagora Airport ',
    'SZS  - Ryan s Creek Aerodrome ',
    'SZU  - Ségou Airport ',
    'SZV  - Suzhou Guangfu Airport ',
    'SZW  - Schwerin Parchim International Airport ',
    'SZX  - Shenzhen Bao an International Airport ',
    'SZY  - Olsztyn Mazury Regional Airport ',
    'SZZ  - Solidarity Szczecin–Goleniów Airport ',
    'TAA  - Tarapaina Airport ',
    'TAB  - Arthur Napoleon Raymond Robinson International Airport ',
    'TAC  - Daniel Z  Romualdez Airport ',
    'TAD  - Perry Stokes Airport ',
    'TAE  - Daegu International Airport ',
    'TAF  - Oran Tafaraoui Airport ',
    'TAG  - Bohol–Panglao International Airport ',
    'TAH  - Whitegrass Airport ',
    'TAI  - Taiz International Airport ',
    'TAJ  - Tadji Airport ',
    'TAK  - Takamatsu Airport ',
    'TAL  - Ralph M  Calhoun Memorial Airport ',
    'TAM  - General Francisco Javier Mina International Airport ',
    'TAN  - Tangalooma Airport ',
    'TAO  - Qingdao Jiaodong International Airport ',
    'TAP  - Tapachula International Airport ',
    'TAQ  - Tarcoola Airport ',
    'TAR  - Taranto Grottaglie Airport ',
    'TAS  - Tashkent International Airport ',
    'TAT  - Poprad–Tatry Airport ',
    'TAU  - Tauramena Airport ',
    'TAV  - Tau Airport    FAA  HI36   ',
    'TAW  - Tacuarembó Airport ',
    'TAX  - Taliabu Airport ',
    'TAY  - Tartu Airport ',
    'TAZ  - Daşoguz Airport ',
    'TBA  - Tabibuga Airport ',
    'TBB  - Dong Tac Airport ',
    'TBC  - Tuba City Airport  1      FAA  T03   ',
    'TBD  - Timbiquí Airport ',
    'TBE  - Timbunke Airport ',
    'TBF  - Tabiteuea North Airport ',
    'TBG  - Tabubil Airport ',
    'TBH  - Tugdan Airport ',
    'TBI  - New Bight Airport ',
    'TBJ  - Tabarka–Aïn Draham International Airport ',
    'TBK  - Timber Creek Airport ',
    'TBL  - Tableland Homestead Airport ',
    'TBM  - Tumbang Samba Airport ',
    'TBN  - Waynesville St  Robert Regional Airport    Forney Field   ',
    'TBO  - Tabora Airport ',
    'TBP  - Cap  FAP Pedro Canga Rodríguez Airport ',
    'TBQ  - Tarabo Airport ',
    'TBR  - Statesboro–Bulloch County Airport ',
    'TBS  - Tbilisi International Airport ',
    'TBT  - Tabatinga International Airport ',
    'TBU  - Fuaʻamotu International Airport ',
    'TBV  - Tabal Airport ',
    'TBW  - Tambov Donskoye Airport ',
    'TBY  - Tshabong Airport ',
    'TBZ  - Tabriz International Airport ',
    'TCA  - Tennant Creek Airport ',
    'TCB  - Treasure Cay Airport ',
    'TCC  - Tucumcari Municipal Airport ',
    'TCD  - Tarapacá Airport ',
    'TCE  - Tulcea Danube Delta Airport ',
    'TCF  - Tocoa Airport ',
    'TCG  - Tacheng Airport ',
    'TCH  - Tchibanga Airport ',
    'TCI  - metropolitan area1 ',
    'TCJ  - Torembi Airport ',
    'TCK  - Tinboli Airport ',
    'TCL  - Tuscaloosa Regional Airport ',
    'TCM  - McChord Field ',
    'TCN  - Tehuacán Airport ',
    'TCO  - La Florida Airport ',
    'TCP  - Taba International Airport ',
    'TCQ  - Coronel FAP Carlos Ciriani Santa Rosa International Airport ',
    'TCR  - Tuticorin Airport ',
    'TCS  - Truth or Consequences Municipal Airport ',
    'TCT  - Takotna Airport ',
    'TCU  - Thaba Nchu Airport ',
    'TCV  - Tete Airport ',
    'TCW  - Tocumwal Airport ',
    'TCX  - Tabas Airport ',
    'TCY  - Terrace Bay Airport ',
    'TCZ  - Tengchong Tuofeng Airport ',
    'TDA  - Trinidad Airport ',
    'TDB  - Tetebedi Airport ',
    'TDD  - Teniente Jorge Henrich Arauz Airport ',
    'TDG  - Tandag Airport ',
    'TDJ  - Tadjoura Airport ',
    'TDK  - Taldykorgan Airport ',
    'TDL  - Tandil Airport ',
    'TDN  - Theda Station Airport ',
    'TDO  - South Lewis County Airport    Ed Carlson Memorial Field   ',
    'TDP  - Trompeteros Airport ',
    'TDR  - Theodore Airport ',
    'TDS  - Sasereme Airport ',
    'TDT  - Tanda Tula Airport ',
    'TDV  - Samangoky Airport ',
    'TDW  - Tradewind Airport ',
    'TDX  - Trat Airport ',
    'TDZ  - Toledo Executive Airport ',
    'TEA  - Tela Airport ',
    'TEB  - Teterboro Airport ',
    'TEC  - Telêmaco Borba Airport ',
    'TED  - Thisted Airport ',
    'TEE  - Cheikh Larbi Tébessi Airport ',
    'TEF  - Telfer Airport ',
    'TEG  - Tenkodogo Airport ',
    'TEH  - Tetlin Airport  1      FAA  3T4   ',
    'TEI  - Tezu Airport ',
    'TEK  - Tatitlek Airport    FAA  7KA   ',
    'TEL  - Telupid Airport ',
    'TEM  - Temora Airport ',
    'TEN  - Tongren Fenghuang Airport ',
    'TEO  - Terapo Airport ',
    'TEP  - Teptep Airport ',
    'TEQ  - Tekirdağ Çorlu Airport ',
    'TER  - Lajes Airport ',
    'TES  - Teseney Airport ',
    'TET  - Chingozi Airport ',
    'TEU  - Te Anau Airport  1      Manapouri Airport   ',
    'TEV  - Teruel Airport ',
    'TEX  - Telluride Regional Airport ',
    'TEY  - Thingeyri Airport ',
    'TEZ  - Tezpur Airport ',
    'TFB  - Tifalmin Airport ',
    'TFF  - Tefé Airport ',
    'TFI  - Tufi Airport ',
    'TFL  - Teófilo Otoni Airport  1      Juscelino Kubitscheck Airport   ',
    'TFM  - Telefomin Airport ',
    'TFN  - Tenerife–North Airport    Los Rodeos Airport   ',
    'TFR  - Wadi al Jandali Airport ',
    'TFS  - Tenerife–South Airport    Reina Sofia Airport   ',
    'TFT  - Taftan Airport ',
    'TFU  - Chengdu Tianfu International Airport ',
    'TGA  - Tengah Air Base ',
    'TGB  - Tagbita Airport ',
    'TGC  - Tanjung Manis Airport ',
    'TGD  - Podgorica Airport ',
    'TGE  - Sharpe Field    FAA  AL73   ',
    'TGG  - Sultan Mahmud Airport ',
    'TGH  - Tongoa Airport ',
    'TGI  - Tingo María Airport ',
    'TGJ  - Tiga Airport ',
    'TGK  - Taganrog Airport ',
    'TGL  - Tagula Airport ',
    'TGM  - Târgu Mureș International Airport ',
    'TGN  - Latrobe Regional Airport ',
    'TGO  - Tongliao Airport ',
    'TGP  - Podkamennaya Tunguska Airport ',
    'TGQ  - Tangará da Serra Airport ',
    'TGR  - Sidi Mahdi Airport ',
    'TGS  - Chokwe Airport ',
    'TGT  - Tanga Airport ',
    'TGU  - Toncontín International Airport ',
    'TGV  - Targovishte Airport  1      Buhovtsi Airfield   ',
    'TGZ  - Ángel Albino Corzo International Airport ',
    'THA  - Tullahoma Regional Airport    William Northern Field   ',
    'THB  - Thaba Tseka Airport ',
    'THC  - Tchien Airport ',
    'THD  - Tho Xuan Airport ',
    'THE  - Teresina–Senador Petrônio Portella Airport ',
    'THG  - Thangool Airport ',
    'THH  - Taharoa Aerodrome ',
    'THI  - Tichitt Airport ',
    'THK  - Thakhek Airport ',
    'THL  - Tachilek Airport ',
    'THM  - Thompson Falls Airport ',
    'THN  - Trollhättan–Vänersborg Airport ',
    'THO  - Thorshofn Airport ',
    'THP  - Hot Springs County–Thermopolis Municipal Airport ',
    'THQ  - Tianshui Maijishan Airport ',
    'THR  - Mehrabad International Airport ',
    'THS  - Sukhothai Airport ',
    'THT  - Tamchakett Airport ',
    'THU  - Pituffik Space Base ',
    'THV  - York Airport ',
    'THW  - Trincomalee Harbour Seaplane Base ',
    'THX  - Turukhansk Airport ',
    'THY  - P R  Mphephu Airport ',
    'THZ  - Tahoua Airport ',
    'TIA  - Tirana International Airport Nënë Tereza ',
    'TIB  - Tibú Airport ',
    'TIC  - Tinak Airport    FAA  N18   ',
    'TID  - Abdelhafid Boussouf Bou Chekif Airport ',
    'TIE  - Tippi Airport ',
    'TIF  - Ta if Regional Airport ',
    'TIG  - Tingwon Airport ',
    'TIH  - Tikehau Airport ',
    'TII  - Tarinkot Airport ',
    'TIJ  - Tijuana International Airport ',
    'TIK  - Tinker Air Force Base ',
    'TIL  - Cheadle Airport  1      TC  CFQ4   ',
    'TIM  - Mozes Kilangin Airport ',
    'TIN  - Tindouf Airport ',
    'TIO  - Tilin Airport ',
    'TIP  - Tripoli International Airport ',
    'TIQ  - Tinian International Airport    West Tinian Airport     FAA  TNI   ',
    'TIR  - Tirupati Airport ',
    'TIS  - Thursday Island Airport ',
    'TIU  - Richard Pearse Airport ',
    'TIV  - Tivat Airport ',
    'TIW  - Tacoma Narrows Airport ',
    'TIX  - Space Coast Regional Airport ',
    'TIY  - Tidjikja Airport ',
    'TIZ  - Tari Airport ',
    'TJA  - Capitán Oriel Lea Plaza Airport ',
    'TJB  - Sei Bati Airport ',
    'TJC  - Ticantiquí Airport ',
    'TJG  - Warukin Airport ',
    'TJH  - Tajima Airport ',
    'TJI  - Trujillo Airport  1      Capiro Airport   ',
    'TJK  - Tokat Airport ',
    'TJL  - Plínio Alarcom Airport ',
    'TJM  - Roshchino International Airport ',
    'TJN  - Takume Airport ',
    'TJQ  - H A S  Hanandjoeddin Airport    Buluh Tumbang Airport   ',
    'TJS  - Tanjung Harapan Airport ',
    'TJU  - Kulob Airport ',
    'TJV  - Thanjavur Air Force Station ',
    'TKA  - Talkeetna Airport ',
    'TKB  - Tekadu Airport ',
    'TKC  - Tiko Airport ',
    'TKD  - Takoradi Airport ',
    'TKE  - Tenakee Seaplane Base ',
    'TKF  - Truckee Tahoe Airport    FAA  TRK   ',
    'TKG  - Radin Inten II Airport ',
    'TKH  - Takhli Royal Thai Air Force Base ',
    'TKI  - Tokeen Seaplane Base    FAA  57A   ',
    'TKJ  - Tok Junction Airport  1      FAA  6K8   ',
    'TKK  - Chuuk International Airport ',
    'TKL  - Taku Lodge Seaplane Base ',
    'TKN  - Tokunoshima Airport ',
    'TKO  - Tlokoeng Airport ',
    'TKP  - Takapoto Airport ',
    'TKQ  - Kigoma Airport ',
    'TKR  - Thakurgaon Airport ',
    'TKS  - Tokushima Airport ',
    'TKT  - Tak Airport ',
    'TKU  - Turku Airport ',
    'TKV  - Tatakoto Airport ',
    'TKW  - Tekin Airport ',
    'TKX  - Takaroa Airport ',
    'TKY  - Turkey Creek Airport ',
    'TKZ  - Tokoroa Aerodrome ',
    'TLA  - Teller Airport    FAA  TER   ',
    'TLB  - Tarbela Dam Airport ',
    'TLC  - Licenciado Adolfo López Mateos International Airport ',
    'TLD  - Tuli Lodge Airport ',
    'TLE  - Toliara Airport ',
    'TLF  - Telida Airport    FAA  2K5   ',
    'TLG  - Tulaghi Heliport ',
    'TLH  - Tallahassee International Airport ',
    'TLI  - Sultan Bantilan Airport  1      Lalos Airport   ',
    'TLJ  - Tatalina LRRS Airport ',
    'TLK  - Talakan Airport ',
    'TLL  - Tallinn Airport    Lennart Meri Tallinn Airport   ',
    'TLM  - Zenata – Messali El Hadj Airport ',
    'TLN  - Toulon–Hyères Airport    Hyères Le Palyvestre Airport   ',
    'TLO  - Tol Airport ',
    'TLP  - Tumolbil Airport ',
    'TLQ  - Turpan Jiaohe Airport ',
    'TLR  - Mefford Field Airport ',
    'TLS  - Toulouse–Blagnac Airport ',
    'TLT  - Tuluksak Airport ',
    'TLU  - Golfo de Morrosquillo Airport ',
    'TLV  - Ben Gurion Airport ',
    'TLW  - Talasea Airport ',
    'TLX  - Panguilemo Airport ',
    'TLY  - Plastun Airport ',
    'TLZ  - Catalão Airport ',
    'TMA  - Henry Tift Myers Airport ',
    'TMB  - Miami Executive Airport ',
    'TMC  - Tambolaka Airport  1      Waikabubak Airport   ',
    'TMD  - Timbedra Airport ',
    'TME  - Gabriel Vargas Santos Airport ',
    'TMF  - Thimarafushi Airport ',
    'TMG  - Tommanggong Airport ',
    'TMH  - Tanah Merah Airport ',
    'TMI  - Tumlingtar Airport ',
    'TMJ  - Termez Airport ',
    'TMK  - Tam Kỳ Airport ',
    'TML  - Tamale Airport ',
    'TMM  - Toamasina Airport ',
    'TMN  - Tamana Airport ',
    'TMO  - Tumeremo Airport ',
    'TMP  - Tampere–Pirkkala Airport ',
    'TMQ  - Tambao Airport ',
    'TMR  - Aguenar – Hadj Bey Akhamok Airport ',
    'TMS  - São Tomé International Airport ',
    'TMT  - Porto Trombetas Airport ',
    'TMU  - Tambor Airport ',
    'TMW  - Tamworth Airport ',
    'TMX  - Timimoun Airport ',
    'TMY  - Tiom Airport ',
    'TMZ  - Thames Aerodrome ',
    'TNA  - Jinan Yaoqiang International Airport ',
    'TNB  - Tanah Grogot Airport ',
    'TNC  - Tin City LRRS Airport ',
    'TND  - Alberto Delgado Airport ',
    'TNE  - New Tanegashima Airport ',
    'TNF  - Toussus le Noble Airport ',
    'TNG  - Tangier Ibn Battouta Airport ',
    'TNH  - Tonghua Sanyuanpu Airport ',
    'TNI  - Satna Airport ',
    'TNJ  - Raja Haji Fisabilillah Airport ',
    'TNK  - Tununak Airport    FAA  4KA   ',
    'TNL  - Ternopil International Airport ',
    'TNM  - Teniente R  Marsh Airport ',
    'TNN  - Tainan Airport ',
    'TNO  - Tamarindo Airport ',
    'TNP  - Twentynine Palms Airport ',
    'TNQ  - Teraina Airport ',
    'TNR  - Ivato International Airport ',
    'TNS  - Tungsten   Cantung   Airport  1      TC  CBX5   ',
    'TNT  - Dade Collier Training and Transition Airport ',
    'TNU  - Newton Municipal Airport ',
    'TNV  - Tabuaeran Island Airport ',
    'TNW  - Jumandy Airport ',
    'TNX  - Steung Treng Airport ',
    'TNZ  - Tosontsengel Airport ',
    'TOA  - Zamperini Field ',
    'TOB  - Tobruk Airport ',
    'TOC  - Toccoa Airport    R G  LeTourneau Field   ',
    'TOD  - Tioman Airport ',
    'TOE  - Tozeur–Nefta International Airport ',
    'TOF  - Bogashevo Airport ',
    'TOG  - Togiak Airport ',
    'TOH  - Torres Airport ',
    'TOI  - Troy Municipal Airport ',
    'TOJ  - Madrid–Torrejón Airport ',
    'TOK  - Torokina Airport ',
    'TOL  - Toledo Express Airport ',
    'TOM  - Timbuktu Airport ',
    'TON  - Tonu Airport ',
    'TOO  - San Vito de Java Airport ',
    'TOP  - Philip Billard Municipal Airport ',
    'TOQ  - Barriles Airport ',
    'TOR  - Torrington Municipal Airport ',
    'TOS  - Tromsø Airport, Langnes ',
    'TOT  - Totness Airstrip ',
    'TOU  - Touho Airport ',
    'TOV  - West End Seaplane Base ',
    'TOW  - Luiz dal Canalle Filho Airport ',
    'TOX  - Tobolsk Airport ',
    'TOY  - Toyama Airport ',
    'TOZ  - Mahana Airport ',
    'TPA  - Tampa International Airport ',
    'TPC  - Tarapoa Airport ',
    'TPE  - Taiwan Taoyuan International Airport ',
    'TPF  - Peter O  Knight Airport ',
    'TPG  - Taiping Airport ',
    'TPH  - Tonopah Airport ',
    'TPI  - Tapini Airport ',
    'TPJ  - Taplejung Airport ',
    'TPK  - Teuku Cut Ali Airport ',
    'TPL  - Draughon–Miller Central Texas Regional Airport ',
    'TPN  - Tiputini Airport ',
    'TPP  - Cad  FAP Guillermo del Castillo Paredes Airport ',
    'TPQ  - Amado Nervo International Airport ',
    'TPR  - Tom Price Airport ',
    'TPS  - Vincenzo Florio Airport Trapani–Birgi ',
    'TPT  - Tapeta Airport ',
    'TPU  - Tikapur Airport ',
    'TPX  - Tupai Airport ',
    'TQD  - Al Taqaddum Air Base ',
    'TQL  - Tarko Sale Airport ',
    'TQN  - Taloqan Airport ',
    'TQP  - Trepell Airport ',
    'TQQ  - Maranggo Airport ',
    'TQS  - Captain Ernesto Esguerra Cubides Air Base ',
    'TRA  - Tarama Airport ',
    'TRB  - Gonzalo Mejía Airport ',
    'TRC  - Francisco Sarabia International Airport    Torreón Int l   ',
    'TRD  - Trondheim Airport, Værnes ',
    'TRE  - Tiree Airport ',
    'TRF  - Sandefjord Airport, Torp ',
    'TRG  - Tauranga Airport ',
    'TRH  - Trona Airport    FAA  L72   ',
    'TRI  - Tri Cities Regional Airport ',
    'TRJ  - Tarakbits Airport ',
    'TRK  - Juwata International Airport ',
    'TRL  - Terrell Municipal Airport ',
    'TRM  - Jacqueline Cochran Regional Airport ',
    'TRN  - Turin Airport    Caselle Airport   ',
    'TRO  - Taree Airport ',
    'TRQ  - José Galera dos Santos Airport ',
    'TRR  - China Bay Airport ',
    'TRS  - Trieste – Friuli Venezia Giulia Airport    Ronchi dei Legionari Airport   ',
    'TRU  - FAP Captain Carlos Martínez de Pinillos International Airport ',
    'TRV  - Trivandrum International Airport ',
    'TRW  - Bonriki International Airport ',
    'TRX  - Trenton Municipal Airport ',
    'TRY  - Tororo Airport ',
    'TRZ  - Tiruchirappalli International Airport ',
    'TSA  - Taipei Songshan Airport ',
    'TSB  - Tsumeb Airport ',
    'TSC  - Taisha Airport ',
    'TSD  - Tshipise Airport ',
    'TSE  - Astana International Airport ',
    'TSF  - Treviso Sant Angelo Airport ',
    'TSG  - Tanacross Airport ',
    'TSH  - Tshikapa Airport ',
    'TSI  - Tsile Tsile Airport ',
    'TSJ  - Tsushima Airport ',
    'TSK  - Taskul Airport ',
    'TSL  - Tamuín National Airport ',
    'TSM  - Taos Regional Airport    FAA  SKX   ',
    'TSN  - Tianjin Binhai International Airport ',
    'TSP  - Tehachapi Municipal Airport ',
    'TSQ  - Torres Airport ',
    'TSR  - Timișoara Traian Vuia International Airport ',
    'TST  - Trang Airport ',
    'TSU  - Tabiteuea South Airport ',
    'TSV  - Townsville Airport ',
    'TSW  - Tsewi Airport ',
    'TSY  - Tasikmalaya Airport  1      Wiriadinata Airport   ',
    'TSX  - Tanjung Santan Airport ',
    'TSZ  - Tsetserleg Airport ',
    'TTA  - Tan Tan Airport    Plage Blanche Airport   ',
    'TTB  - Tortolì Airport    Arbatax Airport   ',
    'TTC  - Las Breas Airport ',
    'TTD  - Portland–Troutdale Airport ',
    'TTE  - Sultan Babullah Airport ',
    'TTG  - Tartagal General Enrique Mosconi Airport ',
    'TTH  - RAFO Thumrait ',
    'TTI  - Tetiaroa Airport ',
    'TTJ  - Tottori Airport ',
    'TTL  - Turtle Island Seaplane Base ',
    'TTM  - Tablón de Tamará Airport ',
    'TTN  - Trenton–Mercer Airport ',
    'TTO  - Britton Municipal Airport    FAA  BTN   ',
    'TTQ  - Tortuguero Airport    Barra de Tortuguero Airport   ',
    'TTR  - Pongtiku Airport ',
    'TTS  - Tsaratanana Airport ',
    'TTT  - Taitung Airport    Taitung Fongnian Airport   ',
    'TTU  - Sania Ramel Airport ',
    'TTW  - Tissa Tank Waterdrome ',
    'TTX  - Truscott Mungalalu Airport ',
    'TUA  - Teniente Coronel Luis a Mantilla International Airport ',
    'TUB  - Tubuai – Mataura Airport ',
    'TUC  - Teniente General Benjamín Matienzo International Airport ',
    'TUD  - Tambacounda Airport ',
    'TUF  - Tours Val de Loire Airport ',
    'TUG  - Tuguegarao Airport ',
    'TUI  - Turaif Domestic Airport ',
    'TUJ  - Tum Airport ',
    'TUK  - Turbat International Airport ',
    'TUL  - Tulsa International Airport ',
    'TUM  - Tumut Airport ',
    'TUN  - Tunis–Carthage International Airport ',
    'TUO  - Taupo Airport ',
    'TUP  - Tupelo Regional Airport ',
    'TUQ  - Tougan Airport ',
    'TUR  - Tucuruí Airport ',
    'TUS  - Tucson International Airport ',
    'TUT  - Tauta Airport ',
    'TUU  - Tabuk Regional Airport ',
    'TUV  - San Rafael Airport ',
    'TUW  - Tubala Airport ',
    'TUX  - Tumbler Ridge Airport  1      TC  CBX7   ',
    'TUY  - Tulum Airport ',
    'TUZ  - Tucumã Airport ',
    'TVA  - Morafenobe Airport ',
    'TVC  - Cherry Capital Airport ',
    'TVF  - Thief River Falls Regional Airport ',
    'TVI  - Thomasville Regional Airport ',
    'TVL  - Lake Tahoe Airport ',
    'TVS  - Tangshan Sannühe Airport ',
    'TVU  - Matei Airport ',
    'TVY  - Dawei Airport ',
    'TWA  - Twin Hills Airport    FAA  A63   ',
    'TWB  - Toowoomba City Aerodrome ',
    'TWC  - Tumxuk Tangwangcheng Airport ',
    'TWD  - Jefferson County International Airport    FAA  0S9   ',
    'TWE  - Taylor Airport    FAA  AK49   ',
    'TWF  - Magic Valley Regional Airport    Joslin Field   ',
    'TWN  - Tewantin Airport ',
    'TWP  - Torwood Airport ',
    'TWT  - Sanga Sanga Airport ',
    'TWU  - Tawau Airport ',
    'TWY  - Tawa Airport ',
    'TWZ  - Pukaki Airport  1      Twizel Airport   ',
    'TXE  - Rembele Airport ',
    'TXF  - Teixeira de Freitas Airport  1      9 de maio Airport   ',
    'TXK  - Texarkana Regional Airport    Webb Field   ',
    'TXM  - Teminabuan Airport ',
    'TXN  - Huangshan Tunxi International Airport ',
    'TXR  - Tanbar Airport ',
    'TXU  - Tabou Airport ',
    'TYA  - Klokovo Airport ',
    'TYB  - Tibooburra Airport ',
    'TYD  - Tynda Airport ',
    'TYE  - Tyonek Airport ',
    'TYF  - Torsby Airport ',
    'TYG  - Thylungra Airport ',
    'TYL  - Capitán FAP Víctor Montes Arias International Airport ',
    'TYM  - Staniel Cay Airport ',
    'TYN  - Taiyuan Wusu International Airport ',
    'TYO  - metropolitan area2 ',
    'TYP  - Tobermorey Airport ',
    'TYR  - Tyler Pounds Regional Airport ',
    'TYS  - McGhee Tyson Airport ',
    'TYT  - Treinta y Tres Airport ',
    'TYZ  - Taylor Airport    FAA  TYL   ',
    'TZA  - Belize City Municipal Airport ',
    'TZC  - Tuscola Area Airport  1      FAA  CFS   ',
    'TZL  - Tuzla International Airport ',
    'TZM  - Cupul National Airport ',
    'TZN  - South Andros Airport    Congo Town Airport   ',
    'TZO  - Tsimiroro Airport ',
    'TZX  - Trabzon Airport ',
    'UAB  - Incirlik Air Base ',
    'UAC  - San Luis Río Colorado Airport ',
    'UAE  - Mount Aue Airport ',
    'UAH  - Ua Huka Airport ',
    'UAI  - Suai Airport ',
    'UAK  - Narsarsuaq Airport ',
    'UAL  - Luau Airport ',
    'UAM  - Andersen Air Force Base ',
    'UAP  - Ua Pou Airport ',
    'UAQ  - Domingo Faustino Sarmiento Airport ',
    'UAR  - Bouarfa Airport ',
    'UAS  - Samburu Airport  1      Buffalo Spring Airport   ',
    'UAX  - Uaxactun Airport ',
    'UBA  - Mário de Almeida Franco Airport ',
    'UBB  - Mabuiag Island Airport ',
    'UBI  - Buin Airport ',
    'UBJ  - Yamaguchi Ube Airport ',
    'UBN  - Chinggis Khaan International Airport ',
    'UBP  - Ubon Ratchathani Airport ',
    'UBR  - Ubrub Airport ',
    'UBS  - Columbus Lowndes County Airport ',
    'UBT  - Ubatuba Airport  1      Gastão Madeira Airport   ',
    'UBU  - Kalumburu Airport ',
    'UCB  - Ulanqab Airport ',
    'UCC  - Yucca Airstrip    FAA  NV11   ',
    'UCE  - Eunice Airport    FAA  4R7   ',
    'UCK  - Lutsk Airport ',
    'UCN  - Buchanan Airport ',
    'UCT  - Ukhta Airport ',
    'UCY  - Everett–Stewart Regional Airport ',
    'UDA  - Undara Airport ',
    'UDD  - Bermuda Dunes Airport ',
    'UDE  - Volkel Air Base ',
    'UDI  - Ten  Cel  Av  César Bombonato Airport ',
    'UDJ  - Uzhhorod International Airport ',
    'UDN  - Campoformido Airport ',
    'UDR  - Maharana Pratap Airport ',
    'UEE  - Queenstown Airport ',
    'UEL  - Quelimane Airport ',
    'UEN  - Urengoy Airport ',
    'UEO  - Kumejima Airport ',
    'UES  - Waukesha County Airport    Crites Field   ',
    'UET  - Quetta International Airport ',
    'UFA  - Ufa International Airport ',
    'UGA  - Bulgan Airport ',
    'UGB  - Ugashik Bay Airport ',
    'UGC  - Urgench International Airport ',
    'UGI  - San Juan   Uganik   Seaplane Base    FAA  WSJ   ',
    'UGL  - Union Glacier Blue Ice Runway ',
    'UGN  - Waukegan National Airport ',
    'UGO  - Uíge Airport ',
    'UGS  - Ugashik Airport    FAA  9A8   ',
    'UGT  - Bulagtai Airport ',
    'UGU  - Bilogai Airport ',
    'UHE  - Kunovice Airport ',
    'UIB  - El Caraño Airport ',
    'UIH  - Phu Cat Airport ',
    'UII  - Útila Airport ',
    'UIK  - Ust Ilimsk Airport ',
    'UIL  - Quillayute Airport ',
    'UIN  - Quincy Regional Airport    Baldwin Field   ',
    'UIO  - Mariscal Sucre International Airport ',
    'UIP  - Quimper–Cornouaille Airport    Pluguffan   ',
    'UIQ  - Quoin Hill Airfield ',
    'UIR  - Quirindi Airport ',
    'UIT  - Jaluit Airport    FAA  N55   ',
    'UJE  - Ujae Airport ',
    'UJN  - Uljin Airport ',
    'UJU  - Uiju Airfield ',
    'UKA  - Ukunda Airport  1      Diani Airport   ',
    'UKB  - Kobe Airport ',
    'UKG  - Ust Kuyga Airport ',
    'UKH  - Mukhaizna Airport ',
    'UKI  - Ukiah Municipal Airport ',
    'UKK  - Oskemen Airport    Ust Kamenogorsk Airport   ',
    'UKN  - Waukon Municipal Airport    FAA  Y01   ',
    'UKR  - Mukeiras Airport ',
    'UKS  - Sevastopol International Airport ',
    'UKT  - Quakertown Airport ',
    'UKU  - Nuku Airport ',
    'UKX  - Ust Kut Airport ',
    'ULA  - Capitán José Daniel Vazquez Airport ',
    'ULB  - Ulei Airport ',
    'ULD  - Ulundi Airport ',
    'ULE  - Sule Airport ',
    'ULG  - Ölgii Airport ',
    'ULH  - Prince Abdul Majeed bin Abdulaziz Domestic Airport ',
    'ULI  - Ulithi Airport    FAA  TT02   ',
    'ULK  - Lensk Airport ',
    'ULL  - Glenforsa Airfield ',
    'ULM  - New Ulm Municipal Airport ',
    'ULN  - Buyant Ukhaa International Airport ',
    'ULO  - Ulaangom Airport ',
    'ULP  - Quilpie Airport ',
    'ULQ  - Heriberto Gíl Martínez Airport ',
    'ULS  - Mulatos Airport ',
    'ULU  - Gulu Airport ',
    'ULV  - Ulyanovsk Baratayevka Airport ',
    'ULX  - Ulusaba Airstrip ',
    'ULY  - Ulyanovsk Vostochny Airport ',
    'ULZ  - Donoi Airport ',
    'UMA  - Punta de Maisí Airport ',
    'UMC  - Umba Airport ',
    'UME  - Umeå Airport ',
    'UMI  - Quince Mil Airport ',
    'UMM  - Summit Airport ',
    'UMR  - RAAF Woomera Airfield ',
    'UMS  - Ust Maya Airport ',
    'UMT  - Umiat Airport ',
    'UMU  - Orlando de Carvalho Airport ',
    'UMY  - Sumy Airport ',
    'UMZ  - Mena Intermountain Municipal Airport ',
    'UNA  - Una Comandatuba Airport ',
    'UNC  - Unguía Airport ',
    'UND  - Kunduz Airport ',
    'UNE  - Qacha s Nek Airport ',
    'UNG  - Kiunga Airport ',
    'UNI  - Union Island Airport ',
    'UNK  - Unalakleet Airport ',
    'UNN  - Ranong Airport ',
    'UNR  - Öndörkhaan Airport ',
    'UNT  - Unst Airport ',
    'UNU  - Dodge County Airport ',
    'UOA  - Mururoa Airport ',
    'UOL  - Pogogul Airport ',
    'UOS  - Franklin County Airport ',
    'UOX  - University Oxford Airport ',
    'UPB  - Playa Baracoa Airport ',
    'UPG  - Sultan Hasanuddin International Airport ',
    'UPL  - Upala Airport ',
    'UPN  - Ignacio López Rayón International Airport ',
    'UPP  - Upolu Airport ',
    'UPR  - Upiara Airport ',
    'UPV  - RAF Upavon ',
    'URA  - Oral Ak Zhol Airport ',
    'URB  - Castilho Airport  1      Urubupunga Airport   ',
    'URC  - Ürümqi Diwopu International Airport ',
    'URD  - Burg Feuerstein Airport ',
    'URE  - Kuressaare Airport ',
    'URG  - Rubem Berta International Airport ',
    'URI  - Uribe Airport ',
    'URJ  - Uray Airport ',
    'URM  - Uriman Airport ',
    'URN  - Urgun Airport ',
    'URO  - Rouen Airport    Vallée de Seine Airport   ',
    'URR  - Urrao Airport ',
    'URS  - Kursk Vostochny Airport ',
    'URT  - Surat Thani Airport ',
    'URU  - Uroubi Airport ',
    'URY  - Gurayat Domestic Airport ',
    'URZ  - Uruzgan Airport ',
    'USA  - Concord Regional Airport    FAA  JQF   ',
    'USC  - Union County Airport  Troy Shelton Field    FAA  35A   ',
    'USH  - Ushuaia – Malvinas Argentinas International Airport ',
    'USI  - Mabaruma Airport ',
    'USJ  - Usharal Airport ',
    'USK  - Usinsk Airport ',
    'USL  - Useless Loop Airport ',
    'USM  - Samui Airport ',
    'USN  - Ulsan Airport ',
    'USO  - Usino Airport ',
    'USQ  - Uşak Airport ',
    'USR  - Ust Nera Airport ',
    'USS  - Sancti Spíritus Airport ',
    'UST  - Northeast Florida Regional Airport    FAA  SGJ   ',
    'USU  - Francisco B  Reyes Airport ',
    'UTA  - Mutare Airport ',
    'UTB  - Muttaburra Airport ',
    'UTC  - Soesterberg Air Base ',
    'UTD  - Nutwood Downs Airport ',
    'UTG  - Quthing Airport ',
    'UTH  - Udon Thani International Airport ',
    'UTI  - Utti Airport ',
    'UTK  - Utirik Airport    FAA  03N   ',
    'UTM  - Tunica Municipal Airport    FAA  UTA   ',
    'UTN  - Upington Airport ',
    'UTO  - Indian Mountain LRRS Airport ',
    'UTP  - U Tapao International Airport ',
    'UTS  - Ust Tsilma Airport ',
    'UTT  - Mthatha Airport ',
    'UTU  - Ustupo Airport ',
    'UTW  - Queenstown Airport ',
    'UUA  - Bugulma Airport ',
    'UUD  - Baikal International Airport ',
    'UUK  - Ugnu Kuparuk Airport    FAA  UBW   ',
    'UUN  - Baruun Urt Airport ',
    'UUS  - Yuzhno Sakhalinsk Airport    Khomutovo Airport   ',
    'UUU  - Manumu Airport ',
    'UVA  - Garner Field ',
    'UVE  - Ouvéa Airport  1      Ouloup Airport   ',
    'UVF  - Hewanorra International Airport ',
    'UVI  - União da Vitória Airport ',
    'UVL  - El Kharga Airport ',
    'UVO  - Uvol Airport ',
    'UYL  - Nyala Airport ',
    'UYN  - Yulin Yuyang Airport ',
    'UYU  - Uyuni Airport    Joya Andina Airport   ',
    'UZC  - Užice Ponikve Airport ',
    'UZM  - Hope Bay Aerodrome  1      TC  CHB3   ',
    'UZR  - Urzhar Airport ',
    'UZU  - Curuzú Cuatiá Airport ',
    'VAA  - Vaasa Airport ',
    'VAB  - Yavarate Airport ',
    'VAC  - Varrelbusch Airport ',
    'VAD  - Moody Air Force Base ',
    'VAF  - Valence Chabeuil Airport ',
    'VAG  - Major Brigadeiro Trompowsky Airport ',
    'VAH  - Cap  Av  Vidal Villagomez Toledo Airport ',
    'VAI  - Vanimo Airport ',
    'VAK  - Chevak Airport ',
    'VAL  - Valença Airport ',
    'VAM  - Villa International Airport Maamigili ',
    'VAN  - Van Ferit Melen Airport ',
    'VAO  - Suavanao Airport ',
    'VAP  - Rodelillo Airfield ',
    'VAR  - Varna Airport ',
    'VAS  - Sivas Airport ',
    'VAT  - Vatomandry Airport ',
    'VAU  - Vatukoula Airport ',
    'VAV  - Vavaʻu International Airport ',
    'VAW  - Vardø Airport, Svartnes ',
    'VBA  - Ann Airport ',
    'VBC  - Mandalay Chanmyathazi Airport ',
    'VBG  - Vandenberg Air Force Base ',
    'VBP  - Bokpyin Airport ',
    'VBS  - Brescia Airport    Gabriele D Annunzio Airport   ',
    'VBV  - Vanuabalavu Airport ',
    'VBY  - Visby Airport ',
    'VCA  - Can Tho International Airport ',
    'VCD  - Victoria River Downs Airport ',
    'VCE  - Venice Marco Polo Airport ',
    'VCF  - Valcheta Airport ',
    'VCH  - Vichadero Airport ',
    'VCL  - Chu Lai International Airport ',
    'VCP  - Viracopos  Campinas International Airport ',
    'VCR  - Carora Airport ',
    'VCS  - Con Dao Airport    Cỏ Ống Airport   ',
    'VCT  - Victoria Regional Airport ',
    'VCV  - Southern California Logistics Airport ',
    'VDA  - Ovda Airport ',
    'VDB  - Fagernes Airport, Leirin ',
    'VDC  - Glauber Rocha Airport ',
    'VDE  - El Hierro Airport ',
    'VDH  - Dong Hoi Airport ',
    'VDI  - Vidalia Regional Airport ',
    'VDM  - Gobernador Edgardo Castello Airport ',
    'VDO  - Van Don International Airport ',
    'VDP  - Valle de la Pascua Airport ',
    'VDR  - Villa Dolores Airport ',
    'VDS  - Vadsø Airport ',
    'VDY  - Vidyanagar Airport  1      Jindal Airport   ',
    'VDZ  - Valdez Airport    Pioneer Field   ',
    'VEE  - Venetie Airport ',
    'VEG  - Maikwak Airport ',
    'VEL  - Vernal Regional Airport ',
    'VER  - General Heriberto Jara International Airport ',
    'VEV  - Barakoma Airport ',
    'VEX  - Tioga Municipal Airport    FAA  D60   ',
    'VEY  - Vestmannaeyjar Airport ',
    'VFA  - Victoria Falls Airport ',
    'VGA  - NTR Amaravati International Airport ',
    'VGD  - Vologda Airport ',
    'VGO  - Vigo–Peinador Airport ',
    'VGS  - General Villegas Airport ',
    'VGT  - North Las Vegas Airport ',
    'VGZ  - Villa Garzón Airport ',
    'VHC  - Henrique de Carvalho Airport ',
    'VHM  - Vilhelmina Airport ',
    'VHN  - Culberson County Airport ',
    'VHV  - Verkhnevilyuysk Airport ',
    'VHY  - Vichy — Charmeil Airport ',
    'VHZ  - Vahitahi Airport ',
    'VIA  - Ângelo Ponzoni Municipal Airport ',
    'VIB  - Villa Constitución Airport ',
    'VIC  - Vicenza Airport ',
    'VIE  - Vienna International Airport ',
    'VIG  - Juan Pablo Pérez Alfonzo Airport ',
    'VIH  - Rolla National Airport ',
    'VII  - Vinh International Airport ',
    'VIJ  - Virgin Gorda Airport ',
    'VIL  - Dakhla Airport ',
    'VIN  - Havryshivka Vinnytsia International Airport ',
    'VIQ  - Viqueque Airport ',
    'VIR  - Virginia Airport ',
    'VIS  - Visalia Municipal Airport ',
    'VIT  - Vitoria Airport ',
    'VIU  - Viru Harbour Airport ',
    'VIV  - Vivigani Airfield ',
    'VIX  - Eurico de Aguiar Salles Airport ',
    'VIY  - Vélizy – Villacoublay Air Base ',
    'VJB  - Xai Xai Airport ',
    'VJI  - Virginia Highlands Airport ',
    'VJQ  - Gurúé Airport ',
    'VKG  - Rach Gia Airport ',
    'VKO  - Vnukovo International Airport ',
    'VKS  - Vicksburg Municipal Airport ',
    'VKT  - Vorkuta Airport ',
    'VLA  - Vandalia Municipal Airport ',
    'VLC  - Valencia Airport ',
    'VLD  - Valdosta Regional Airport ',
    'VLE  - Valle Airport    FAA  40G   ',
    'VLG  - Villa Gesell Airport ',
    'VLI  - Bauerfield International Airport ',
    'VLK  - Volgodonsk Airport ',
    'VLL  - Valladolid Airport ',
    'VLM  - Lieutenant Colonel Rafael Pabón Airport ',
    'VLN  - Arturo Michelena International Airport ',
    'VLP  - Vila Rica Municipal Airport ',
    'VLR  - Vallenar Airport ',
    'VLS  - Valesdir Airport ',
    'VLU  - Velikiye Luki Airport ',
    'VLV  - Dr  Antonio Nicolás Briceño Airport ',
    'VLY  - Anglesey Airport      RAF Valley ',
    'VME  - Villa Reynolds Airport ',
    'VMI  - Dr  Juan Plate Airport ',
    'VMU  - Baimuru Airport ',
    'VNA  - Salavan Airport ',
    'VNC  - Venice Municipal Airport ',
    'VND  - Vangaindrano Airport ',
    'VNE  - Meucon Airport ',
    'VNO  - Vilnius Airport ',
    'VNR  - Vanrook Airport ',
    'VNS  - Lal Bahadur Shastri Airport ',
    'VNT  - Ventspils International Airport ',
    'VNX  - Vilankulo Airport ',
    'VNY  - Van Nuys Airport ',
    'VOD  - Vodochody Airport ',
    'VOG  - Volgograd International Airport ',
    'VOH  - Vohemar Airport ',
    'VOI  - Voinjama Airport    Tenebu Airport   ',
    'VOK  - Volk Field Air National Guard Base ',
    'VOL  - Nea Anchialos National Airport ',
    'VOT  - Votuporanga Airport  1      Domingos Pignatari Airport   ',
    'VOZ  - Voronezh International Airport    Chertovitskoye Airport   ',
    'VPE  - Ondjiva Pereira Airport ',
    'VPG  - Vipingo Airport ',
    'VPN  - Vopnafjörður Airport ',
    'VPS  - Destin–Fort Walton Beach Airport      Eglin Air Force Base ',
    'VPY  - Chimoio Airport ',
    'VPZ  - Porter County Regional Airport ',
    'VQQ  - Cecil Airport ',
    'VQS  - Antonio Rivera Rodríguez Airport ',
    'VRA  - Juan Gualberto Gómez Airport ',
    'VRB  - Vero Beach Regional Airport ',
    'VRC  - Virac Airport ',
    'VRE  - Vredendal Airport ',
    'VRI  - Varandey Airport ',
    'VRK  - Varkaus Airport ',
    'VRL  - Vila Real Airport ',
    'VRN  - Verona Villafranca Airport ',
    'VRO  - Kawama Airport ',
    'VRS  - Roy Otten Memorial Airfield    FAA  3VS   ',
    'VRU  - Vryburg Airport ',
    'VSA  - Carlos Rovirosa Pérez International Airport ',
    'VSE  - Viseu Airport ',
    'VSF  - Hartness State Airport ',
    'VSG  - Luhansk International Airport ',
    'VST  - Stockholm Västerås Airport ',
    'VTB  - Vitebsk Vostochny Airport ',
    'VTE  - Wattay International Airport ',
    'VTF  - Vatulele Airport ',
    'VTG  - Vung Tau Airport ',
    'VTL  - Vittel   Champ de Courses Airport ',
    'VTM  - Nevatim Airbase ',
    'VTN  - Miller Field ',
    'VTU  - Hermanos Ameijeiras Airport ',
    'VTZ  - Visakhapatnam Airport ',
    'VUP  - Alfonso López Pumarejo Airport ',
    'VUS  - Veliky Ustyug Airport ',
    'VUU  - Mvuu Camp Airport ',
    'VVB  - Mahanoro Airport ',
    'VVC  - La Vanguardia Airport ',
    'VVI  - Viru Viru International Airport ',
    'VVK  - Västervik Airport ',
    'VVN  - Las Malvinas Airport ',
    'VVO  - Vladivostok International Airport ',
    'VVZ  - Takhamalt Airport ',
    'VXC  - Lichinga Airport ',
    'VXE  - Cesária Évora Airport    São Pedro Airport   ',
    'VXO  - Växjö  Kronoberg Airport ',
    'VYD  - Vryheid Airport ',
    'VYI  - Vilyuysk Airport ',
    'VYS  - Illinois Valley Regional Airport    Walter A  Duncan Field   ',
    'WAA  - Wales Airport ',
    'WAC  - Wacca Airport ',
    'WAD  - Andriamena Airport ',
    'WAE  - Wadi al Dawasir Domestic Airport ',
    'WAF  - Wana Airport ',
    'WAG  - Whanganui Airport ',
    'WAH  - Harry Stern Airport    FAA  BWP   ',
    'WAI  - Ambalabe Airport ',
    'WAJ  - Wawoi Falls Airport ',
    'WAK  - Ankazoabo Airport ',
    'WAL  - Wallops Flight Facility ',
    'WAM  - Ambatondrazaka Airport ',
    'WAO  - Wabo Airport ',
    'WAP  - Alto Palena Airfield ',
    'WAQ  - Antsalova Airport ',
    'WAR  - Waris Airport ',
    'WAS  - metropolitan area1 ',
    'WAT  - Waterford Airport ',
    'WAU  - Wauchope Airport ',
    'WAV  - Wave Hill Airport ',
    'WAW  - Warsaw Chopin Airport ',
    'WAX  - Zuwarah Airport ',
    'WAY  - Greene County Airport ',
    'WAZ  - Warwick Airport ',
    'WBA  - Wahai Airport ',
    'WBB  - Stebbins Airport ',
    'WBC  - Wapolu Airport ',
    'WBD  - Befandriana Airport ',
    'WBE  - Ankaizina Airport ',
    'WBG  - Schleswig Air Base ',
    'WBK  - West Branch Community Airport  1      FAA  Y31   ',
    'WBM  - Wapenamanda Airport ',
    'WBO  - Antsoa Airport ',
    'WBQ  - Beaver Airport ',
    'WBR  - Roben–Hood Airport    FAA  RQB   ',
    'WBU  - Boulder Municipal Airport    FAA  BDU   ',
    'WBW  - Wilkes Barre Wyoming Valley Airport ',
    'WCA  - Gamboa Airport ',
    'WCH  - Nuevo Chaitén Airport ',
    'WCR  - Chandalar Lake Airport ',
    'WDA  - Wadi Ain Airport ',
    'WDG  - Enid Woodring Regional Airport ',
    'WDH  - Hosea Kutako International Airport ',
    'WDI  - Wondai Airport ',
    'WDN  - Waldronaire Airport  1      FAA  90WA   ',
    'WDR  - Barrow County Airport ',
    'WDS  - Shiyan Wudangshan Airport ',
    'WEA  - Parker County Airport ',
    'WED  - Wedau Airport ',
    'WEF  - Weifang Airport ',
    'WEH  - Weihai Dashuibo Airport ',
    'WEI  - Weipa Airport ',
    'WEL  - Welkom Airport ',
    'WEP  - Weam Airport ',
    'WET  - Waghete Airport ',
    'WEW  - Wee Waa Airport ',
    'WFB  - Ketchikan Harbor Seaplane Base    FAA  5KE   ',
    'WFD  - Woodford Aerodrome ',
    'WFI  - Fianarantsoa Airport ',
    'WFK  - Northern Aroostook Regional Airport    FAA  FVE   ',
    'WGA  - Wagga Wagga Airport ',
    'WGC  - Warangal Airport ',
    'WGE  - Walgett Airport ',
    'WGN  - Shaoyang Wugang Airport ',
    'WGO  - Winchester Regional Airport    FAA  OKV   ',
    'WGP  - Mau Hau Airport    Umbu Mehang Kunda Airport   ',
    'WGT  - Wangaratta Airport ',
    'WGU  - Wagau Airport ',
    'WGY  - Wagny Airport ',
    'WHA  - Wuhu Xuanzhou Airport ',
    'WHD  - Hyder Seaplane Base    FAA  4Z7   ',
    'WHF  - Wadi Halfa Airport ',
    'WHK  - Whakatane Airport ',
    'WHL  - Welshpool Airport ',
    'WHO  - Franz Josef Glacier Aerodrome ',
    'WHP  - Whiteman Airport ',
    'WHS  - Whalsay Airstrip ',
    'WHT  - Wharton Regional Airport    FAA  ARM   ',
    'WIB  - Wilbarger County Airport  1      FAA  F05   ',
    'WIC  - Wick Airport ',
    'WIE  - Wiesbaden Army Airfield ',
    'WIK  - Waiheke Island Aerodrome ',
    'WIL  - Wilson Airport ',
    'WIN  - Winton Airport ',
    'WIO  - Wilcannia Airport ',
    'WIR  - Wairoa Aerodrome ',
    'WIT  - Wittenoom Airport ',
    'WIU  - Witu Airport ',
    'WJA  - Woja Airport ',
    'WJF  - General William J  Fox Airfield ',
    'WJR  - Wajir Airport ',
    'WJU  - Wonju Airport ',
    'WKA  - Wanaka Airport ',
    'WKB  - Warracknabeal Airport ',
    'WKF  - Air Force Base Waterkloof ',
    'WKI  - Hwange Town Airport ',
    'WKJ  - Wakkanai Airport ',
    'WKK  - Aleknagik Airport    FAA  5A8   ',
    'WKN  - Wakunai Airport ',
    'WKR  - Walker s Cay Airport ',
    'WLA  - Wallal Airport ',
    'WLC  - Walcha Airport ',
    'WLD  - Strother Field ',
    'WLE  - Miles Airport ',
    'WLG  - Wellington International Airport ',
    'WLH  - Walaha Airport ',
    'WLK  - Selawik Airport ',
    'WLL  - Wollogorang Airport ',
    'WLO  - Waterloo Airport ',
    'WLP  - West Angelas Airport ',
    'WLR  - Loring Seaplane Base    FAA  13Z   ',
    'WLS  - Hihifo Airport ',
    'WLW  - Willows Glenn County Airport ',
    'WMA  - Mandritsara Airport ',
    'WMB  - Warrnambool Airport ',
    'WMC  - Winnemucca Municipal Airport ',
    'WMD  - Mandabe Airport ',
    'WME  - Mount Keith Airport ',
    'WMH  - Ozark Regional Airport    FAA  BPK   ',
    'WMI  - Warsaw Modlin Airport ',
    'WMK  - Meyers Chuck Seaplane Base    FAA  84K   ',
    'WML  - Malaimbandy Airport ',
    'WMN  - Maroantsetra Airport ',
    'WMO  - White Mountain Airport ',
    'WMP  - Mampikony Airport ',
    'WMR  - Mananara Nord Airport ',
    'WMT  - Zunyi Maotai Airport ',
    'WMV  - Madirovalo Airport ',
    'WMX  - Wamena Airport ',
    'WNA  - Napakiak Airport ',
    'WND  - Windarra Airport ',
    'WNE  - Wora na Yeno Airport ',
    'WNH  - Wenshan Puzhehei Airport ',
    'WNI  - Matahora Airport ',
    'WNN  - Wunnummin Lake Airport  1      TC  CKL3   ',
    'WNP  - Naga Airport ',
    'WNR  - Windorah Airport ',
    'WNS  - Nawabshah Airport ',
    'WNU  - Wanuma Airport ',
    'WNZ  - Wenzhou Longwan International Airport ',
    'WOA  - Wonenara Airport ',
    'WOE  - Woensdrecht Air Base ',
    'WOK  - Uonquén Airport ',
    'WOL  - Shellharbour Airport ',
    'WON  - Wondoola Airport ',
    'WOR  - Moramba Airport ',
    'WOS  - Wonsan Kalma International Airport ',
    'WOT  - Wang an Airport ',
    'WOW  - Willow Airport    FAA  UUO   ',
    'WPA  - Cabo Juan Román Airfield ',
    'WPB  - Port Bergé Airport ',
    'WPC  - Pincher Creek Airport ',
    'WPK  - Wrotham Park Airport ',
    'WPL  - Powell Lake Water Aerodrome  1      TC  CAQ8   ',
    'WPM  - Wipim Airport ',
    'WPO  - North Fork Valley Airport    FAA  7V2   ',
    'WPR  - Capitán Fuentes Martínez Airport ',
    'WPU  - Guardiamarina Zañartu Airport ',
    'WRA  - Warder Airport ',
    'WRB  - Robins Air Force Base ',
    'WRE  - Whangarei Airport ',
    'WRG  - Wrangell Airport ',
    'WRI  - McGuire Air Force Base ',
    'WRL  - Worland Municipal Airport ',
    'WRN  - Windarling Airport ',
    'WRO  - Copernicus Airport Wrocław ',
    'WRT  - Warton Aerodrome ',
    'WRW  - Warrawagine Airport ',
    'WRY  - Westray Airport ',
    'WRZ  - Weerawila Airport ',
    'WSA  - Wasua Airport ',
    'WSB  - Steamboat Bay Seaplane Base ',
    'WSD  - Condron Army Airfield ',
    'WSF  - Cape Sarichef Airport  1      FAA  26AK   ',
    'WSG  - Washington County Airport    FAA  AFJ   ',
    'WSH  - Brookhaven Airport    FAA  HWV   ',
    'WSI  - Western Sydney Airport    Nancy Bird Walton Airport   ',
    'WSK  - Chongqing Wushan Airport ',
    'WSM  - Wiseman Airport ',
    'WSN  - South Naknek Airport ',
    'WSO  - Washabo Airport ',
    'WSP  - Waspam Airport ',
    'WSR  - Wasior Airport ',
    'WST  - Westerly State Airport ',
    'WSU  - Wasu Airport ',
    'WSX  - Westsound Seaplane Base  1      FAA  WA83   ',
    'WSY  - Whitsunday Airport ',
    'WSZ  - Westport Airport ',
    'WTA  - Tambohorano Airport ',
    'WTB  - Toowoomba Wellcamp Airport ',
    'WTD  - West End Airport ',
    'WTE  - Wotje Airport    FAA  N36   ',
    'WTK  - Noatak Airport ',
    'WTL  - Tuntutuliak Airport    FAA  A61   ',
    'WTN  - RAF Waddington ',
    'WTO  - Wotho Airport ',
    'WTP  - Woitape Airport ',
    'WTR  - Whiteriver Airport    FAA  E24   ',
    'WTS  - Tsiroanomandidy Airport ',
    'WTT  - Wantoat Airport ',
    'WTZ  - Whitianga Aerodrome ',
    'WUA  - Wuhai Airport ',
    'WUD  - Wudinna Airport ',
    'WUG  - Wau Airport ',
    'WUH  - Wuhan Tianhe International Airport ',
    'WUI  - Murrin Murrin Airport ',
    'WUM  - Wasum Airport ',
    'WUN  - Wiluna Airport ',
    'WUS  - Wuyishan Airport ',
    'WUT  - Xinzhou Wutaishan Airport ',
    'WUU  - Wau Airport ',
    'WUV  - Wuvulu Island Airport ',
    'WUX  - Sunan Shuofang International Airport ',
    'WUZ  - Wuzhou Xijiang Airport ',
    'WVB  - Walvis Bay Airport ',
    'WVI  - Watsonville Municipal Airport ',
    'WVK  - Manakara Airport ',
    'WVL  - Waterville Robert LaFleur Airport ',
    'WVN  - JadeWeserAirport ',
    'WWA  - Wasilla Airport    FAA  IYS   ',
    'WWD  - Cape May Airport ',
    'WWI  - Woodie Woodie Airport ',
    'WWK  - Wewak Airport ',
    'WWP  - North Whale Seaplane Base    FAA  96Z   ',
    'WWR  - West Woodward Airport ',
    'WWT  - Newtok Airport    FAA  EWU   ',
    'WWY  - West Wyalong Airport ',
    'WXN  - Wanzhou Wuqiao Airport ',
    'WYA  - Whyalla Airport ',
    'WYB  - Yes Bay Lodge Seaplane Base    FAA  78K   ',
    'WYE  - Yengema Airport ',
    'WYN  - Wyndham Airport ',
    'WYS  - Yellowstone Airport ',
    'WZA  - Wa Airport ',
    'WZQ  - Urad Middle Banner Airport ',
    'XAI  - Xinyang Minggang Airport ',
    'XAL  - Álamos Airport ',
    'XAP  - Serafin Enoss Bertaso Airport ',
    'XAR  - Aribinda Airport ',
    'XAU  - Saül Airport ',
    'XBB  - Blubber Bay Seaplane Base ',
    'XBE  - Bearskin Lake Airport    TC  CNE3   ',
    'XBG  - Bogande Airport ',
    'XBJ  - Birjand International Airport ',
    'XBL  - Bedele Airport    Buno Bedele Airport   ',
    'XBN  - Biniguni Airport ',
    'XBO  - Boulsa Airport ',
    'XBR  - Brockville Regional Tackaberry Airport    TC  CNL3   ',
    'XCH  - Christmas Island Airport ',
    'XCL  - Cluff Lake Airport    TC  CJS3   ',
    'XCM  - Chatham Kent Airport ',
    'XCN  - Coron Airport ',
    'XCO  - Colac Airport ',
    'XCR  - Châlons Vatry Airport ',
    'XDE  - Diébougou Airport ',
    'XDJ  - Djibo Airport ',
    'XEN  - Xingcheng Airport ',
    'XES  - Grand Geneva Resort Airport    FAA  C02   ',
    'XFN  - Xiangyang Liuji Airport ',
    'XFW  - Hamburg Finkenwerder Airport ',
    'XGA  - Gaoua Airport    Amilcar Cabral Airport   ',
    'XGG  - Gorom Gorom Airport ',
    'XGN  - Xangongo Airport ',
    'XGR  - Kangiqsualujjuaq   Georges River   Airport ',
    'XIC  - Xichang Qingshan Airport ',
    'XIE  - Xienglom Airport ',
    'XIG  - Xinguara Municipal Airport ',
    'XIJ  - Ahmad al Jaber Air Base ',
    'XIL  - Xilinhot Airport ',
    'XIN  - Xingning Air Base ',
    'XIY  - Xi an Xianyang International Airport ',
    'XJD  - Al Udeid Air Base ',
    'XJM  - Mangla Airport ',
    'XKA  - Kantchari Airport ',
    'XKH  - Xieng Khouang Airport ',
    'XKS  - Kasabonika Airport ',
    'XKY  - Kaya Airport ',
    'XLB  - Lac Brochet Airport ',
    'XLO  - Long Xuyen Airport ',
    'XLS  - Saint Louis Airport ',
    'XLU  - Leo Airport ',
    'XLW  - Lemwerder Airport ',
    'XMA  - Maramag Airport ',
    'XMC  - Mallacoota Airport ',
    'XMD  - Madison Municipal Airport    FAA  MDS   ',
    'XMG  - Mahendranagar Airport ',
    'XMH  - Manihi Airport ',
    'XMI  - Masasi Airport ',
    'XML  - Minlaton Airport ',
    'XMN  - Xiamen Gaoqi International Airport ',
    'XMP  - Macmillan Pass Airport  1      TC  CFC4   ',
    'XMS  - Edmundo Carvajal Airport ',
    'XMY  - Yam Island Airport ',
    'XNA  - Northwest Arkansas Regional Airport ',
    'XNG  - Quang Ngai Airport ',
    'XNH  - Ali Air Base ',
    'XNN  - Xining Caojiabao Airport ',
    'XNT  - Xingtai Dalian Airport ',
    'XNU  - Nouna Airport ',
    'XPA  - Pama Airport ',
    'XPK  - Pukatawagan Airport ',
    'XPL  - Soto Cano Air Base ',
    'XPP  - Poplar River Airport ',
    'XPR  - Pine Ridge Airport    FAA  IEN   ',
    'XQP  - Quepos La Managua Airport ',
    'XQU  - Qualicum Beach Airport    TC  CAT4   ',
    'XRH  - RAAF Base Richmond ',
    'XRQ  - Xinbarag Youqi Baogede Airport ',
    'XRR  - Ross River Airport ',
    'XRY  - Jerez Airport    La Parra Airport   ',
    'XSB  - Sir Bani Yas Airport ',
    'XSC  - South Caicos Airport ',
    'XSD  - Tonopah Test Range Airport    FAA  TNX   ',
    'XSE  - Sebba Airport ',
    'XSI  - South Indian Lake Airport ',
    'XSO  - Siocon Airport ',
    'XSP  - Seletar Airport ',
    'XTA  - Homey Airport ',
    'XTG  - Thargomindah Airport ',
    'XTL  - Tadoule Lake Airport ',
    'XTO  - Taroom Airport ',
    'XTR  - Tara Airport ',
    'XUZ  - Xuzhou Guanyin Airport ',
    'XVL  - Vinh Long Airport ',
    'XWA  - Williston Basin International Airport ',
    'XYA  - Yandina Airport ',
    'XYE  - Ye Airport ',
    'XYR  - Edwaki Airport ',
    'XZA  - Zabré Airport ',
    'YAA  - Anahim Lake Airport    TC  CAJ4   ',
    'YAB  - Arctic Bay Airport    TC  CJX7   ',
    'YAC  - Cat Lake Airport ',
    'YAD  - Moose Lake Airport    TC  CJB4   ',
    'YAG  - Fort Frances Municipal Airport ',
    'YAH  - La Grande 4 Airport ',
    'YAI  - General Bernardo O Higgins Airport ',
    'YAJ  - Lyall Harbour Seaplane Base ',
    'YAK  - Yakutat Airport ',
    'YAL  - Alert Bay Airport ',
    'YAM  - Sault Ste  Marie Airport ',
    'YAN  - Yangambi Airport ',
    'YAO  - Yaoundé Airport ',
    'YAP  - Yap International Airport    FAA  T11   ',
    'YAQ  - Maple Bay Seaplane Base ',
    'YAR  - La Grande 3 Airport ',
    'YAS  - Yasawa Island Airport ',
    'YAT  - Attawapiskat Airport ',
    'YAU  - Kattiniq  Donaldson Airport    TC  CTP9   ',
    'YAV  - Mayne Island Water Aerodrome    Miner s Bay Seaplane Base     TC  CAW7   ',
    'YAX  - Angling Lake  Wapekeka Airport  1      TC  CKB6   ',
    'YAY  - St  Anthony Airport ',
    'YAZ  - Tofino  Long Beach Airport ',
    'YBA  - Banff Airport ',
    'YBB  - Kugaaruk Airport ',
    'YBC  - Baie Comeau Airport ',
    'YBE  - Uranium City Airport ',
    'YBF  - Bamfield Water Aerodrome    TC  CAE9   ',
    'YBG  - Canadian Forces Base Bagotville ',
    'YBH  - Bull Harbour Waterdrome ',
    'YBI  - Black Tickle Airport    TC  CCE4   ',
    'YBJ  - Baie Johan Beetz Seaplane Base ',
    'YBK  - Baker Lake Airport ',
    'YBL  - Campbell River Airport ',
    'YBO  - Bob Quinn Lake Airport    TC  CBW4   ',
    'YBP  - Yibin Wuliangye Airport ',
    'YBQ  - Telegraph Harbour Seaplane Base ',
    'YBR  - Brandon Municipal Airport    McGill Field   ',
    'YBS  - Opapimiskan Lake Airport  1      TC  CKM8   ',
    'YBT  - Brochet Airport ',
    'YBV  - Berens River Airport ',
    'YBW  - Bedwell Harbour Water Aerodrome    TC  CAB3   ',
    'YBX  - Lourdes de Blanc Sablon Airport ',
    'YBY  - Bonnyville Airport ',
    'YCA  - Courtenay Airpark    TC  CAH3   ',
    'YCB  - Cambridge Bay Airport ',
    'YCC  - Cornwall Regional Airport ',
    'YCD  - Nanaimo Airport ',
    'YCE  - Centralia  James T  Field Memorial Aerodrome ',
    'YCF  - Cortes Island Aerodrome    TC  CCI9   ',
    'YCG  - West Kootenay Regional Airport    Castlegar Airport   ',
    'YCH  - Miramichi Airport ',
    'YCK  - Colville Lake  Tommy Kochon Aerodrome ',
    'YCL  - Charlo Airport ',
    'YCM  - St  Catharines  Niagara District Airport ',
    'YCN  - Cochrane Aerodrome ',
    'YCO  - Kugluktuk Airport ',
    'YCQ  - Chetwynd Airport ',
    'YCR  - Cross Lake   Charlie Sinclair Memorial   Airport ',
    'YCS  - Chesterfield Inlet Airport ',
    'YCT  - Coronation Airport ',
    'YCU  - Yuncheng Guangong Airport ',
    'YCW  - Chilliwack Airport ',
    'YCY  - Clyde River Airport ',
    'YCZ  - Fairmont Hot Springs Airport ',
    'YDA  - Dawson City Airport ',
    'YDB  - Burwash Airport ',
    'YDC  - Drayton Valley Industrial Airport    TC  CER3   ',
    'YDE  - Paradise River Airport    TC  CDF4   ',
    'YDF  - Deer Lake Regional Airport ',
    'YDG  - Digby  Annapolis Regional Airport ',
    'YDJ  - Hatchet Lake Airport  1      TC  CJL2   ',
    'YDL  - Dease Lake Airport ',
    'YDN  - Lt  Col W G    Billy   Barker VC Airport ',
    'YDO  - Dolbeau Saint Félicien Airport ',
    'YDP  - Nain Airport ',
    'YDQ  - Dawson Creek Airport ',
    'YDT  - Boundary Bay Airport ',
    'YDU  - Kasba Lake Airport  1      TC  CJL8   ',
    'YDV  - Bloodvein River Airport ',
    'YDW  - Obre Lake  North of Sixty Airport    TC  CKV4   ',
    'YEA  - metropolitan area1 ',
    'YEB  - Bar River Airport  1      TC  CPF2   ',
    'YEC  - Yecheon Air Base ',
    'YEG  - Edmonton International Airport ',
    'YEI  - Yenişehir Airport ',
    'YEK  - Arviat Airport ',
    'YEL  - Elliot Lake Municipal Airport ',
    'YEM  - Manitowaning  Manitoulin East Municipal Airport ',
    'YEN  - Estevan Regional Aerodrome ',
    'YEO  - Royal Naval Air Station Yeovilton ',
    'YEQ  - Yankisa Airport ',
    'YER  - Fort Severn Airport ',
    'YES  - Yasuj Airport ',
    'YET  - Edson Airport ',
    'YEU  - Eureka Aerodrome ',
    'YEV  - Inuvik   Mike Zubko   Airport ',
    'YEY  - Amos  Magny Airport ',
    'YFA  - Fort Albany Airport ',
    'YFB  - Iqaluit Airport ',
    'YFC  - Fredericton International Airport ',
    'YFE  - Forestville Airport ',
    'YFG  - Fontanges Airport    TC  CTU2   ',
    'YFH  - Fort Hope Airport ',
    'YFI  - Fort MacKay  Firebag Aerodrome  1      TC  CFG6   ',
    'YFJ  - Wekweètì Airport  1      Snare Lake Airport     TC  CFJ7   ',
    'YFL  - Fort Reliance Water Aerodrome    CJN8   ',
    'YFO  - Flin Flon Airport ',
    'YFR  - Fort Resolution Airport ',
    'YFS  - Fort Simpson Airport ',
    'YFT  - Taipa Ferry Terminal ',
    'YFX  - St  Lewis   Fox Harbour   Airport    TC  CCK4   ',
    'YGB  - Texada  Gillies Bay Airport ',
    'YGC  - Grande Cache Airport    TC  CEQ5   ',
    'YGE  - Gorge Harbour Water Aerodrome ',
    'YGG  - Ganges Water Aerodrome    TC  CAX6   ',
    'YGH  - Fort Good Hope Airport ',
    'YGJ  - Miho–Yonago Airport ',
    'YGK  - Kingston  Norman Rogers Airport ',
    'YGL  - La Grande Rivière Airport ',
    'YGM  - Gimli Industrial Park Airport ',
    'YGN  - Greenway Sound Water Aerodrome ',
    'YGO  - Gods Lake Narrows Airport ',
    'YGP  - Michel Pouliot Gaspé Airport ',
    'YGQ  - Geraldton   Greenstone Regional   Airport ',
    'YGR  - Îles de la Madeleine Airport ',
    'YGT  - Igloolik Airport ',
    'YGV  - Havre Saint Pierre Airport ',
    'YGW  - Kuujjuarapik Airport ',
    'YGX  - Gillam Airport ',
    'YGZ  - Grise Fiord Airport ',
    'YHA  - Port Hope Simpson Airport    TC  CCP4   ',
    'YHB  - Hudson Bay Airport ',
    'YHC  - Hakai Passage Water Aerodrome ',
    'YHD  - Dryden Regional Airport ',
    'YHE  - Hope Aerodrome ',
    'YHF  - Hearst   René Fontaine   Municipal Airport ',
    'YHG  - Charlottetown Airport    TC  CCH4   ',
    'YHH  - Campbell River Water Aerodrome    TC  CAE3   ',
    'YHI  - Ulukhaktok  Holman Airport ',
    'YHK  - Gjoa Haven Airport ',
    'YHM  - John C  Munro Hamilton International Airport ',
    'YHN  - Hornepayne Municipal Airport ',
    'YHO  - Hopedale Airport ',
    'YHP  - Poplar Hill Airport ',
    'YHR  - Chevery Airport ',
    'YHS  - Sechelt Aerodrome    TC  CAP3   ',
    'YHT  - Haines Junction Airport ',
    'YHU  - Montréal  Saint Hubert Airport ',
    'YHY  - Hay River  Merlyn Carter Airport ',
    'YHZ  - Halifax Stanfield International Airport ',
    'YIA  - Yogyakarta International Airport ',
    'YIB  - Atikokan Municipal Airport ',
    'YIC  - Yichun Mingyueshan Airport ',
    'YIE  - Arxan Yi ershi Airport ',
    'YIF  - Saint Augustin Airport ',
    'YIG  - Big Bay Water Aerodrome    TC  CAF6   ',
    'YIH  - Yichang Sanxia Airport ',
    'YIK  - Ivujivik Airport ',
    'YIN  - Yining Airport ',
    'YIO  - Pond Inlet Airport ',
    'YIP  - Willow Run Airport ',
    'YIV  - Island Lake Airport    Garden Hill Airport   ',
    'YIW  - Yiwu Airport ',
    'YJA  - Jasper Airport ',
    'YJF  - Fort Liard Airport ',
    'YJN  - Saint Jean Airport ',
    'YJP  - Hinton  Jasper Hinton Airport    TC  CEC4   ',
    'YJS  - Samjiyon Airport ',
    'YJT  - Stephenville International Airport ',
    'YKA  - Kamloops Airport ',
    'YKC  - Collins Bay Airport ',
    'YKD  - Kincardine Municipal Airport ',
    'YKE  - Knee Lake Airport    TC  CJT3   ',
    'YKF  - Region of Waterloo International Airport ',
    'YKG  - Kangirsuk Airport ',
    'YKH  - Yingkou Lanqi Airport ',
    'YKJ  - Key Lake Airport ',
    'YKK  - Kitkatla Water Aerodrome    TC  CAP7   ',
    'YKL  - Schefferville Airport ',
    'YKM  - Yakima Air Terminal    McAllister Field   ',
    'YKN  - Chan Gurney Municipal Airport ',
    'YKO  - Hakkari Yüksekova Airport ',
    'YKQ  - Waskaganish Airport ',
    'YKS  - Yakutsk Airport ',
    'YKT  - Klemtu Water Aerodrome ',
    'YKU  - Chisasibi Airport    TC  CSU2   ',
    'YKX  - Kirkland Lake Airport ',
    'YKY  - Kindersley Regional Airport ',
    'YKZ  - Buttonville Municipal Airport ',
    'YLB  - Lac La Biche Airport ',
    'YLC  - Kimmirut Airport ',
    'YLD  - Chapleau Airport ',
    'YLE  - Whatì Airport    TC  CEM3   ',
    'YLG  - Yalgoo Airport ',
    'YLH  - Lansdowne House Airport ',
    'YLI  - Ylivieska Airfield ',
    'YLJ  - Meadow Lake Airport ',
    'YLK  - Lake Simcoe Regional Airport ',
    'YLL  - Lloydminster Airport ',
    'YLM  - Clinton Creek Airport ',
    'YLN  - Yilan Airport ',
    'YLP  - Mingan Airport ',
    'YLQ  - La Tuque Airport ',
    'YLR  - Leaf Rapids Airport ',
    'YLS  - Lebel sur Quévillon Airport    TC  CSH4   ',
    'YLT  - Alert Airport ',
    'YLV  - Yevlakh Airport ',
    'YLW  - Kelowna International Airport ',
    'YLX  - Yulin Fumian Airport ',
    'YLY  - Langley Regional Airport ',
    'YMA  - Mayo Airport ',
    'YMB  - Merritt Airport    TC  CAD5   ',
    'YMD  - Mould Bay Airport ',
    'YME  - Matane Airport ',
    'YMF  - Montague Harbour Water Aerodrome ',
    'YMG  - Manitouwadge Airport ',
    'YMH  - Mary s Harbour Airport ',
    'YMJ  - CFB Moose Jaw    C M  McEwen Airport   ',
    'YMK  - Mys Kamenny Airport ',
    'YML  - Charlevoix Airport ',
    'YMM  - Fort McMurray International Airport ',
    'YMN  - Makkovik Airport ',
    'YMO  - Moosonee Airport ',
    'YMP  - Port McNeill Airport    TC  CAT5   ',
    'YMQ  - metropolitan area3 ',
    'YMS  - Moisés Benzaquén Rengifo Airport ',
    'YMT  - Chibougamau  Chapais Airport ',
    'YMU  - Mansons Landing Water Aerodrome  1      TC  CAV7   ',
    'YMV  - Mary River Aerodrome  1      TC  CMR2   ',
    'YMW  - Maniwaki Airport ',
    'YMX  - Montréal Mirabel International Airport ',
    'YNA  - Natashquan Airport ',
    'YNB  - Yanbu Airport    Yenbo Airport   ',
    'YNC  - Wemindji Airport ',
    'YND  - Gatineau Ottawa Executive Airport ',
    'YNE  - Norway House Airport ',
    'YNG  - Youngstown Warren Regional Airport ',
    'YNH  - Hudson s Hope Airport ',
    'YNJ  - Yanji Chaoyangchuan Airport ',
    'YNL  - Points North Landing Airport ',
    'YNM  - Matagami Airport ',
    'YNN  - Yandicoogina Airport ',
    'YNO  - North Spirit Lake Airport    TC  CKQ3   ',
    'YNP  - Natuashish Airport  1      TC  CNH2   ',
    'YNS  - Nemiscau Airport ',
    'YNT  - Yantai Penglai International Airport ',
    'YNX  - Snap Lake Airport  1      TC  CSK6   ',
    'YNY  - Yangyang International Airport ',
    'YNZ  - Yancheng Nanyang International Airport ',
    'YOA  - Ekati Airport ',
    'YOC  - Old Crow Airport ',
    'YOD  - CFB Cold Lake    R W  McNair Airport   ',
    'YOE  - Donnelly Airport    TC  CFM4   ',
    'YOG  - Ogoki Post Airport ',
    'YOH  - Oxford House Airport ',
    'YOJ  - High Level Airport ',
    'YOL  - Yola Airport ',
    'YON  - Yongphulla Airport  1      Yonphula Airport   ',
    'YOO  - Oshawa Airport ',
    'YOP  - Rainbow Lake Airport ',
    'YOS  - Billy Bishop Regional Airport ',
    'YOT  - Yotvata Airfield ',
    'YOW  - Ottawa Macdonald–Cartier International Airport ',
    'YPA  - Prince Albert   Glass Field   Airport ',
    'YPB  - Alberni Valley Regional Airport    TC  CBS8   ',
    'YPC  - Nora Aliqatchialuk Ruben Airport ',
    'YPD  - Parry Sound Area Municipal Airport    TC  CNK4   ',
    'YPE  - Peace River Airport ',
    'YPG  - Portage la Prairie  Southport Airport ',
    'YPH  - Inukjuak Airport ',
    'YPI  - Port Simpson Water Aerodrome    TC  CAN8   ',
    'YPJ  - Aupaluk Airport ',
    'YPL  - Pickle Lake Airport ',
    'YPM  - Pikangikum Airport ',
    'YPN  - Port Menier Airport ',
    'YPO  - Peawanuck Airport ',
    'YPQ  - Peterborough Airport ',
    'YPR  - Prince Rupert Airport ',
    'YPS  - Port Hawkesbury Airport ',
    'YPT  - Pender Harbour Water Aerodrome    TC  CAG8   ',
    'YPW  - Powell River Airport ',
    'YPX  - Puvirnituq Airport ',
    'YPY  - Fort Chipewyan Airport ',
    'YPZ  - Burns Lake Airport ',
    'YQA  - Muskoka Airport ',
    'YQB  - Québec City Jean Lesage International Airport ',
    'YQC  - Quaqtaq Airport ',
    'YQD  - The Pas Airport ',
    'YQF  - Red Deer Regional Airport ',
    'YQG  - Windsor International Airport ',
    'YQH  - Watson Lake Airport ',
    'YQI  - Yarmouth Airport ',
    'YQJ  - April Point Water Aerodrome ',
    'YQK  - Kenora Airport ',
    'YQL  - Lethbridge Airport ',
    'YQM  - Greater Moncton International Airport ',
    'YQN  - Nakina Airport ',
    'YQQ  - CFB Comox ',
    'YQR  - Regina International Airport ',
    'YQS  - St  Thomas Municipal Airport ',
    'YQT  - Thunder Bay International Airport ',
    'YQU  - Grande Prairie Airport ',
    'YQV  - Yorkton Municipal Airport ',
    'YQW  - North Battleford Airport    Cameron McIntosh Airport   ',
    'YQX  - Gander International Airport      CFB Gander ',
    'YQY  - Sydney  J A  Douglas McCurdy Airport ',
    'YQZ  - Quesnel Airport ',
    'YRA  - Gamètì  Rae Lakes Airport ',
    'YRB  - Resolute Bay Airport ',
    'YRC  - Refuge Cove Water Aerodrome ',
    'YRD  - Dean River Airport ',
    'YRF  - Cartwright Airport ',
    'YRG  - Rigolet Airport    TC  CCZ2   ',
    'YRI  - Rivière du Loup Airport ',
    'YRJ  - Roberval Airport ',
    'YRL  - Red Lake Airport ',
    'YRM  - Rocky Mountain House Airport ',
    'YRN  - Rivers Inlet Water Aerodrome    TC  CAU8   ',
    'YRO  - Ottawa  Rockcliffe Airport ',
    'YRQ  - Trois Rivières Airport ',
    'YRR  - Stuart Island Airport ',
    'YRS  - Red Sucker Lake Airport ',
    'YRT  - Rankin Inlet Airport ',
    'YRV  - Revelstoke Airport ',
    'YSA  - Sable Island Aerodrome ',
    'YSB  - Sudbury Airport ',
    'YSC  - Sherbrooke Airport ',
    'YSE  - Squamish Airport ',
    'YSF  - Stony Rapids Airport ',
    'YSG  - Lutselk e Airport ',
    'YSH  - Smiths Falls Montague Airport ',
    'YSI  - Parry Sound  Frying Pan Island Sans Souci Water Aerodrome    TC  CPS9   ',
    'YSJ  - Saint John Airport ',
    'YSK  - Sanikiluaq Airport ',
    'YSL  - Saint Léonard Aerodrome ',
    'YSM  - Fort Smith Airport ',
    'YSN  - Salmon Arm Airport ',
    'YSO  - Postville Airport    TC  CCD4   ',
    'YSP  - Marathon Aerodrome ',
    'YSQ  - Songyuan Chaganhu Airport ',
    'YST  - St  Theresa Point Airport ',
    'YSU  - Summerside Airport ',
    'YSX  - Bella Bella  Shearwater Water Aerodrome    TC  CAW8   ',
    'YSY  - Sachs Harbour   David Nasogaluak Jr  Saaryuaq   Airport ',
    'YTA  - Pembroke Airport ',
    'YTB  - Hartley Bay Water Aerodrome    TC  CAY4   ',
    'YTD  - Thicket Portage Airport ',
    'YTE  - Cape Dorset Airport ',
    'YTF  - Alma Airport ',
    'YTG  - Sullivan Bay Water Aerodrome    TC  CAV5   ',
    'YTH  - Thompson Airport ',
    'YTL  - Big Trout Lake Airport ',
    'YTM  - Mont Tremblant International Airport ',
    'YTO  - metropolitan area4 ',
    'YTP  - Tofino Harbour Water Aerodrome    TC  CAB4   ',
    'YTQ  - Tasiujaq Airport ',
    'YTR  - CFB Trenton ',
    'YTS  - Timmins  Victor M  Power Airport ',
    'YTT  - Tisdale Airport    TC  CJY3   ',
    'YTU  - Tasu Water Aerodrome ',
    'YTW  - Yutian Wanfang Airport ',
    'YTX  - Telegraph Creek Airport    TC  CBM5   ',
    'YTY  - Yangzhou Taizhou Airport ',
    'YTZ  - Billy Bishop Toronto City Airport    Toronto Island Airport   ',
    'YUA  - Yuanmou Air Base ',
    'YUB  - Tuktoyaktuk  James Gruben Airport ',
    'YUD  - Umiujaq Airport ',
    'YUE  - Yuendumu Airport ',
    'YUL  - Montréal–Trudeau International Airport ',
    'YUM  - Yuma International Airport      MCAS Yuma    FAA  NYL   ',
    'YUS  - Yushu Batang Airport ',
    'YUT  - Repulse Bay Airport ',
    'YUX  - Hall Beach Airport ',
    'YUY  - Rouyn Noranda Airport ',
    'YVA  - Iconi Airport ',
    'YVB  - Bonaventure Airport ',
    'YVC  - La Ronge   Barber Field   Airport ',
    'YVD  - Yeva Airport ',
    'YVE  - Vernon Regional Airport ',
    'YVG  - Vermilion Airport ',
    'YVM  - Qikiqtarjuaq Airport ',
    'YVN  - Cape Dyer Airport ',
    'YVO  - Val d Or Airport ',
    'YVP  - Kuujjuaq Airport ',
    'YVQ  - Norman Wells Airport ',
    'YVR  - Vancouver International Airport ',
    'YVT  - Buffalo Narrows Airport ',
    'YVV  - Wiarton Airport ',
    'YVZ  - Deer Lake Airport ',
    'YWA  - Petawawa Airport ',
    'YWB  - Kangiqsujuaq   Wakeham Bay   Airport ',
    'YWG  - Winnipeg James Armstrong Richardson International Airport ',
    'YWH  - Victoria Harbour Water Airport ',
    'YWJ  - Déline Airport ',
    'YWK  - Wabush Airport ',
    'YWL  - Williams Lake Airport ',
    'YWM  - Williams Harbour Airport    TC  CCA6   ',
    'YWP  - Webequie Airport ',
    'YWQ  - Chutes des Passes  Lac Margane Water Aerodrome  1      TC  CTM3   ',
    'YWR  - White River Water Aerodrome    TC  CNJ8   ',
    'YWS  - Whistler  Green Lake Water Aerodrome    TC  CAE5   ',
    'YWY  - Wrigley Airport ',
    'YXC  - Cranbrook  Canadian Rockies International Airport ',
    'YXD  - Edmonton City Centre   Blatchford Field   Airport ',
    'YXE  - Saskatoon John G  Diefenbaker International Airport ',
    'YXH  - Medicine Hat Airport ',
    'YXJ  - Fort St  John Airport    North Peace Airport   ',
    'YXK  - Rimouski Airport ',
    'YXL  - Sioux Lookout Airport ',
    'YXN  - Whale Cove Airport ',
    'YXP  - Pangnirtung Airport ',
    'YXQ  - Beaver Creek Airport ',
    'YXR  - Earlton   Timiskaming Regional   Airport ',
    'YXS  - Prince George Airport ',
    'YXT  - Northwest Regional Airport ',
    'YXU  - London International Airport ',
    'YXX  - Abbotsford International Airport ',
    'YXY  - Erik Nielsen Whitehorse International Airport ',
    'YXZ  - Wawa Airport ',
    'YYA  - Yueyang Sanhe Airport ',
    'YYB  - North Bay  Jack Garland Airport ',
    'YYC  - Calgary International Airport ',
    'YYD  - Smithers Airport ',
    'YYE  - Northern Rockies Regional Airport ',
    'YYF  - Penticton Regional Airport ',
    'YYG  - Charlottetown Airport ',
    'YYH  - Taloyoak Airport ',
    'YYI  - Rivers Airport ',
    'YYJ  - Victoria International Airport ',
    'YYL  - Lynn Lake Airport ',
    'YYM  - Cowley Airport ',
    'YYN  - Swift Current Airport ',
    'YYQ  - Churchill Airport ',
    'YYR  - CFB Goose Bay ',
    'YYT  - St  John s International Airport ',
    'YYU  - Kapuskasing Airport ',
    'YYW  - Armstrong Airport ',
    'YYY  - Mont Joli Airport ',
    'YYZ  - Toronto Pearson International Airport ',
    'YZA  - Cache Creek Airport    Ashcroft Regional Airport   ',
    'YZE  - Gore Bay Manitoulin Airport ',
    'YZF  - Yellowknife Airport ',
    'YZG  - Salluit Airport ',
    'YZH  - Slave Lake Airport ',
    'YZP  - Sandspit Airport ',
    'YZR  - Sarnia Chris Hadfield Airport ',
    'YZS  - Coral Harbour Airport ',
    'YZT  - Port Hardy Airport ',
    'YZU  - Whitecourt Airport ',
    'YZV  - Sept Îles Airport ',
    'YZW  - Teslin Airport ',
    'YZX  - CFB Greenwood ',
    'YZY  - Zhangye Ganzhou Airport ',
    'YZZ  - Trail Airport  1      TC  CAD4   ',
    'ZAA  - Alice Arm  Silver City Water Aerodrome    TC  CAC3   ',
    'ZAC  - York Landing Airport ',
    'ZAD  - Zadar Airport ',
    'ZAG  - Franjo Tuđman Airport ',
    'ZAH  - Zahedan Airport ',
    'ZAJ  - Zaranj Airport ',
    'ZAL  - Pichoy Airport ',
    'ZAM  - Zamboanga International Airport ',
    'ZAO  - Cahors   Lalbenque Airport ',
    'ZAR  - Zaria Airport ',
    'ZAT  - Zhaotong Airport ',
    'ZAZ  - Zaragoza Airport ',
    'ZBE  - Zábřeh Airport ',
    'ZBF  - Bathurst Airport ',
    'ZBK  - Žabljak Airport ',
    'ZBL  - Biloela Airport ',
    'ZBM  - Roland Désourdy Airport ',
    'ZBO  - Bowen Airport ',
    'ZBR  - Konarak Airport ',
    'ZBY  - Sayaboury Airport ',
    'ZCL  - General Leobardo C  Ruiz International Airport ',
    'ZCO  - La Araucanía Airport ',
    'ZDJ  - Bern Railway Station ',
    'ZDY  - Dalma Airport ',
    'ZEC  - Secunda Airport ',
    'ZEG  - Senggo Airport ',
    'ZEL  - Bella Bella   Campbell Island   Airport ',
    'ZEM  - Eastmain River Airport ',
    'ZEN  - Zenag Airport ',
    'ZER  - Zero Airport    Ziro Airport   ',
    'ZFA  - Faro Airport ',
    'ZFD  - Fond du Lac Airport ',
    'ZFM  - Fort McPherson Airport ',
    'ZFN  - Tulita Airport ',
    'ZFW  - Fairview Airport    TC  CEB5   ',
    'ZGF  - Grand Forks Airport ',
    'ZGI  - Gods River Airport ',
    'ZGL  - South Galway Airport ',
    'ZGM  - Ngoma Airport ',
    'ZGR  - Little Grand Rapids Airport ',
    'ZGS  - La Romaine Airport    TC  CTT5   ',
    'ZGU  - Gaua Airport ',
    'ZHA  - Zhanjiang Airport ',
    'ZHM  - Shamshernagar Airport ',
    'ZHP  - High Prairie Airport ',
    'ZHT  - Geneva Railway Station ',
    'ZHY  - Zhongwei Shapotou Airport    Zhongwei Xiangshan Airport   ',
    'ZIA  - Ramenskoye Airport  1      Zhukovsky Airport   ',
    'ZIC  - Victoria Airport ',
    'ZIG  - Ziguinchor Airport ',
    'ZIH  - Ixtapa Zihuatanejo International Airport ',
    'ZIS  - Alzintan Airport ',
    'ZIX  - Zhigansk Airport ',
    'ZIZ  - Zamzama Airport ',
    'ZJG  - Jenpeg Airport ',
    'ZJN  - Swan River Airport ',
    'ZJT  - Tanjung Pelepas Port Airport ',
    'ZKB  - Kasaba Bay Airport ',
    'ZKE  - Kashechewan Airport ',
    'ZKG  - Kegaska Airport    TC  CTK6   ',
    'ZKP  - Zyryanka Airport ',
    'ZLO  - Playa de Oro International Airport ',
    'ZLT  - La Tabatière Airport    TC  CTU5   ',
    'ZLW  - Pasir Gudang Port Airport ',
    'ZLX  - Zalingei Airport ',
    'ZMD  - Sena Madureira Airport ',
    'ZMH  - South Cariboo Regional Airport ',
    'ZMM  - Zamora National Airport ',
    'ZMT  - Masset Airport ',
    'ZNA  - Nanaimo Harbour Water Airport    TC  CAC8   ',
    'ZNC  - Nyac Airport ',
    'ZND  - Zinder Airport ',
    'ZNE  - Newman Airport ',
    'ZNU  - Namu Water Aerodrome ',
    'ZNZ  - Abeid Amani Karume International Airport ',
    'ZOF  - Ocean Falls Water Aerodrome    TC  CAH2   ',
    'ZOS  - Cañal Bajo Carlos Hott Siebert Airport ',
    'ZPB  - Sachigo Lake Airport ',
    'ZPC  - Pucón Airport ',
    'ZPH  - Zephyrhills Municipal Airport ',
    'ZPO  - Pinehouse Lake Airport ',
    'ZQN  - Queenstown Airport ',
    'ZQS  - Queen Charlotte City Water Aerodrome    TC  CAQ6   ',
    'ZQW  - Zweibrücken Airport ',
    'ZQZ  - Zhangjiakou Ningyuan Airport ',
    'ZRH  - Zurich Airport ',
    'ZRI  - Serui Airport ',
    'ZRJ  - Round Lake   Weagamow Lake   Airport ',
    'ZRM  - Sarmi Orai Airport ',
    'ZSA  - San Salvador Airport    Cockburn Town Airport   ',
    'ZSE  - Pierrefonds Airport ',
    'ZSJ  - Sandy Lake Airport ',
    'ZSS  - Sassandra Airport ',
    'ZST  - Stewart Aerodrome ',
    'ZSW  - Prince Rupert  Seal Cove Water Airport ',
    'ZTA  - Tureia Airport ',
    'ZTB  - Tête à la Baleine Airport    TC  CTB6   ',
    'ZTH  - Zakynthos International Airport    Dionysios SolomosAirport   ',
    'ZTM  - Shamattawa Airport ',
    'ZTR  - Zhytomyr Airport ',
    'ZTS  - Tahsis Water Aerodrome    TC  CAL9   ',
    'ZTU  - Zaqatala International Airport ',
    'ZUC  - Ignace Municipal Airport ',
    'ZUD  - Pupelde Airfield ',
    'ZUH  - Zhuhai Jinwan Airport    Zhuhai Sanzao Airport   ',
    'ZUL  - Zilfi Airport ',
    'ZUM  - Churchill Falls Airport ',
    'ZVA  - Miandrivazo Airport ',
    'ZVG  - Springvale Airport ',
    'ZVK  - Savannakhet Airport ',
    'ZWA  - Andapa Airport ',
    'ZWL  - Wollaston Lake Airport ',
    'ZXT  - Zabrat Airport ',
    'ZYI  - Zunyi Xinzhou Airport ',
    'ZYL  - Osmani International Airport ',
    'ZZE  - Zangilan International Airport ',
    'ZZO  - Zonalnoye Airport ',
    'ZZU  - Mzuzu Airport ',
    'ZZV  - Zanesville Municipal Airport '
];


export default suggestionsList;