import React, { useEffect } from 'react'
import './about.css'
import video1 from '../../Assets/home.mp4'

import {FiChevronRight} from 'react-icons/fi'
import { MdFlight } from "react-icons/md";
import img from '../../Assets/FearoDealz-Logo.png'

import Aos from 'aos'
import 'aos/dist/aos.css'


const About = () => {


    
// scroll animation 
useEffect(()=>{
    Aos.init({duration: 2000})
  }, [])

  return (
    <section className="footer">
      <div className="videoDiv">
      <video src={video1} muted autoPlay loop type="video/mp4"></video>
      </div>

      <div className="secContent container">
        <div className="contactDiv flex">
          <div data-aos="fade-up" className="text">
            <small>KEEP IN TOUCH</small>
            <h2>About Us</h2>
          </div>
        </div>

        <div className="footerCard flex">
          <div className="footerIntro flex">
            <div className="logoDiv">
              <a href="#" className='logo flex'>
              <img src={img} alt="Wynva"/>
              </a>
              <a href="#" className='logo flex'>
              Why choose Wynva? 

              </a>
              
            </div>

            <div data-aos="fade-up" className="footerParagraph">
            <p>It's simple - we prioritize your satisfaction above all else. From our easy-to-navigate website to our dedicated customer support team, every aspect of your booking experience is designed with you in mind. Say goodbye to endless hours of searching for the best deals - with Wynva, you can find competitive prices and exclusive offers with just a few clicks.</p>
            <br/>
            <p>But we're more than just a booking platform - we're your trusted travel companion. Whether you need assistance with itinerary planning, flight changes, or anything in between, our knowledgeable team is here to help every step of the way. With Wynva, you can rest assured that your travel plans are in good hands.</p>
            <br/>
            <p>So why wait? Join the thousands of satisfied travelers who have made Wynva their go-to choice for all their flight booking needs. Let us help you turn your travel dreams into reality - because with Wynva, the sky's the limit!</p>
            </div>
          </div>



        </div>
            

      </div>
    </section>
  )
}

export default About
