import React, { useEffect } from 'react';
import './app.css';
import './common.css';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Flight from './Components/Flight/Flight';
import Hotel from './Components/Hotel/Hotel';
import Package from './Components/Package/Package';
import Main from './Components/Main/Main';
import Mainhotel from './Components/Hotel/Mainhotel';
import Mainpackage from './Components/Package/Mainpackage';
import Footer from './Components/Footer/Footer';
import Tc from './Components/Tc/Tc';
import About from './Components/About/About';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<div><Home /><Main /></div>} />
        <Route path="/flight" element={<div><Flight /><Main /></div>} />
        <Route path="/hotel" element={<div><Hotel /><Mainhotel /></div>} />
        <Route path="/package" element={<div><Package /><Mainpackage /></div>} />
        <Route path="/tc" element={<div><Tc /></div>} />
        <Route path="/about" element={<div><About /></div>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
