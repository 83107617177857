import React, { useState, useEffect } from 'react';
import site from "../../config/site.js";
import './home.css';
import './sug.css';
import video from '../../Assets/home.mp4';
import { GrLocation } from 'react-icons/gr';
import { FiFacebook } from 'react-icons/fi';
import { AiOutlineInstagram } from 'react-icons/ai';
import { SiTripadvisor } from 'react-icons/si';
import { BsListTask } from 'react-icons/bs';
import { TbApps } from 'react-icons/tb';
import Aos from 'aos';
import 'aos/dist/aos.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import suggestionsList from './sug';
import {AiFillCloseCircle} from 'react-icons/ai';
import { FaHeadset } from "react-icons/fa";

const Hotel = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    setCurrentURL(window?.location?.href);
  }, []);
  const [currentURL, setCurrentURL] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);
  const [toFieldDisabled, setToFieldDisabled] = useState(true);
  const [showPopup, setShowPopup] = useState(false); // State for managing popup display


  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
  
    return inputLength === 0
      ? []
      : suggestionsList.filter((suggestion) => {
          if (inputLength <= 3) {
            // Filter by the first three letters
            return suggestion.toLowerCase().slice(0, inputLength) === inputValue;
          } else {
            // Filter by the full input string
            return suggestion.toLowerCase().includes(inputValue);
          }
        });
  };

  const onSuggestionSelected = (field, suggestion) => {
    if (field === 'from') {
      setFromValue(suggestion);
      setFromSuggestions([]);
      setToFieldDisabled(false);
      setToValue('');
      setEndDate(null);
    } else {
      setToValue(suggestion);
      setToSuggestions([]);
    }
  };

  const onInputChange = (field, value) => {
    if (field === 'from') {
      setFromValue(value);
      setFromSuggestions(getSuggestions(value));
    } else {
      setToValue(value);
      setToSuggestions(getSuggestions(value));
    }
  };

  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };



  const handleHotelSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      type: 'hotel', // Add type field
      destination: toValue,
      startDate: startDate,
      endDate: endDate,
      name: e.target.elements.hotelName.value,
      phone: e.target.elements.hotelPhone.value,
      currentURL: currentURL,
    };
   
    // Send formData to the server
    try {
      const response = await fetch(site.api_url + "submit-hotel", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
  
      if (response?.status == 200) {
        // alert(
        //   "Your request submitted successfully. Will get back to you soon."
        // );
        console.log('Form submitted successfully');
        // Reset form fields if needed
        setShowPopup(true);
        // console.log("Hotel form submitted:", formData);
        setToValue('');
        setStartDate(null);
        setEndDate(null);
        e.target.elements.hotelName.value = '';
        e.target.elements.hotelPhone.value = '';
      } else {
        // alert("Something went wrong with your request, Try again later.!");
      
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  return (
    <section className='home'>
      <div className='overlay'></div>
      <video src={video} muted autoPlay loop type='video/mp4'></video>

      <div className='homeContent container'>
      <div className='textDiv'>
          {/* <span data-aos='fade-up' className='smallText'>
            Our Packages
          </span> */}
          <h1 data-aos='fade-up' className='homeTitle'>
          📣 Get Unpublished deals call now.
          </h1>
          <span  className='smallText'>
          <a href="tel:+1(855)-921-5823" data-aos='fade-up' className='numb'><FaHeadset /><span className='numbe'> +1(855)-921-5823</span></a>
          </span>
        </div>



        {/* Hotel */}
        <form onSubmit={handleHotelSubmit}>
          <div onClick={() => setShowPopup(false)} data-aos='fade-up' className='cardDiv2 grid'>
          <div className='destinationInput'>
            <label className='city'>Destination:</label>
            <div className='input flex'>
              <div className="autosuggest-container">
                <input
                  type="text"
                  value={toValue}
                  onChange={(e) => onInputChange('to', e.target.value)}
                  placeholder="Destination City"
                  // disabled={toFieldDisabled}
                  required 
                />
                {toSuggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {toSuggestions.map((suggestion, index) => (
                      <li key={index} onClick={() => onSuggestionSelected('to', suggestion)}>
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <GrLocation className='icon' />
            </div>
          </div>

          <div className='dateInput'>
            <label htmlFor='startDate'>Select your dates:</label>
            <div className='input flex'>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat='MMM-dd-yyyy'
                placeholderText='Check-in Date' required 
                minDate={getToday()} // Set minimum date to today's date
              />&nbsp;&nbsp;&nbsp;
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat='MMM-dd-yyyy'
                placeholderText='Check-Out Date' required 
                minDate={startDate} // Set minimum date to start date
              />
            </div>
          </div>

          <div className='destinationInput'>
            <label className='city'>Name:</label>
            <div className='input flex'>
              <input id="hotelName" type='text' placeholder='Enter Your Name ' required />
              <GrLocation className='icon' />
            </div>
          </div>

          <div className='destinationInput'>
            <label className='city'>Phone:</label>
            <div className='input flex'>
              <input id="hotelPhone" type='number' placeholder='Enter Phone number' required />
              <GrLocation className='icon' />
            </div>
          </div>

          <div className='searchOptions flex'>
            <button className='btn'>Search</button>
          </div>
          </div>
          </form>
          
          <div data-aos='fade-up' className="thomas">
            <span  className='smallText'>
              <a href="tel:+1(855)-921-5823" data-aos='fade-up' className='numb'><FaHeadset /><span className='numbe'> +1(855)-921-5823</span></a>
            </span>
        </div>
        {/* Popup Message */}
        {showPopup && (
          <div className="popup">
            <div onClick={() => setShowPopup(false)} className="closeNavbar">
              <AiFillCloseCircle className="icon"/>
            </div>            
            <div className="popup-inner">
              {/* Displaying the image */}
              <img src="https://flyadvisers.com/wp-content/uploads/2022/09/WhatsApp-Image-2024-04-10-at-12.34.02-PM.jpeg" alt="Flight" />

              {/* Buttons */}
              <div className="buttonGroup">
                <button className="btn" onClick={() => setShowPopup(false)}><a href="tel:+18559215823">Continue</a></button>
                &nbsp;&nbsp;&nbsp;
                <button className="btn" onClick={() => setShowPopup(false)}><a href="tel:+18559215823">Call Now</a></button>
              </div>
            </div>
          </div>
        )}
        <div data-aos='fade-up' className='homeFooterIcons flex'>
          <div className='rightIcons'>
            <FiFacebook className='icon' />
            <AiOutlineInstagram className='icon' />
            <SiTripadvisor className='icon' />
          </div>

          <div className='leftIcons'>
            <BsListTask className='icon' />
            <TbApps className='icon' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hotel;
